<div class="slider" [ngClass]="{'slider--reverse': sliderFlip}">
    <div class="grid__front">
        <div class="text-rotate">
            <p class="mono blue">{{sliderTitle}}</p>
        </div>
        <div class="grid__front--small">
            <div class="slider-small__container" appCursorHover [noLinkEvent]='true' [cursorUrl]=sliderCursorNext (click)="prevSlide()">
                <div *ngFor="let slide of sliderItem; let i = index;" class="slider__container">
                    <div *ngIf="indexSlide == [i]" class="slider-img__container" @containerAnimation>
                        <div @slideAnimation *ngIf="indexSlide == [i]" class="slider-img">
                            <picture @imageSlideAnimation *ngIf="indexSlide == [i]" class="slider-img__img">
                                <source sizes="(max-width: 1023px) 50vw, 20vw" type="image/webp" srcset="{{slide.webpSmall}}">
                                <source sizes="(max-width: 1023px) 50vw, 20vw" type="image/jpg" srcset="{{slide.jpgSmall}}">
                                <img sizes="(max-width: 1023px) 50vw, 20vw" src="{{slide.imgSmall}}" srcset="assets/images/icon/firma_150.png 150w, assets/images/icon/firma_300.png 300w, assets/images/icon/firma.png 500w" alt="{{slide.altSmall}}">
                            </picture>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!isMobile" class="image-change__number">
                <span [ngClass]="{active: indexSlide == [i]}" *ngFor="let slide of sliderItem; let i = index;">{{slide.number}}</span>
            </div>
            <div class="slider-arrow" *ngIf="isMobile">
                <div class="slider-arrow__prev" (click)="prevSlide()"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.05 10"><path d="M9.06,4.22,6,1.12,7.07,0l5,5-5,5L5.92,8.86,9,5.78H0V4.22Z"/></svg></div>
                <div class="slider-arrow__next" (click)="nextSlide()"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.05 10"><path d="M9.06,4.22,6,1.12,7.07,0l5,5-5,5L5.92,8.86,9,5.78H0V4.22Z"/></svg></div>
            </div>
        </div>
        <div class="grid__front--2-4">
            <div class="slider-big__container" appCursorHover [noLinkEvent]='true' [cursorUrl]=sliderCursorPrev (click)="nextSlide()">
                <div *ngFor="let slide of sliderItem; let i = index;" class="slider__container">
                    <div *ngIf="indexSlide == [i]" class="slider-img__container" @containerAnimation>
                        <div @slideAnimation *ngIf="indexSlide == [i]" class="slider-img">
                            <picture @imageSlideAnimation *ngIf="indexSlide == [i]" class="slider-img__img">
                                <source sizes="(max-width: 1023px) 90vw, 50vw" type="image/webp" srcset="{{slide.webpBig}}">
                                <source sizes="(max-width: 1023px) 90vw, 50vw" type="image/jpg" srcset="{{slide.jpgBig}}">
                                <img sizes="(max-width: 1023px) 90vw, 50vw" src="{{slide.imgBig}}" srcset="{{slide.jpgBig}}" alt="{{slide.altBig}}">
                            </picture>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
// import * as EventEmitter from "events";
import { IPayPalConfig, ICreateOrderRequest } from "ngx-paypal";
import { shippingFee } from "src/app/constants/shipping-fee";
import { User } from "src/app/models/user.model";
import { AddressService } from "src/app/services/address.service";
import { CartService } from "src/app/services/cart.service";
import { CheckoutService } from "src/app/services/checkout.service";
import { UserService } from "src/app/services/user.service";
import { environment } from "src/environments/environment";
import { ErrorDialogComponent } from "../error-dialog/error-dialog.component";

declare var paypal;

@Component({
  selector: "app-paypal-payment",
  templateUrl: "./paypal-payment.component.html",
  styleUrls: ["./paypal-payment.component.css"],
})
export class PaypalPaymentComponent implements OnInit {
  constructor(
    private checkoutService: CheckoutService,
    private dialog: MatDialog,
    private router: Router,
    private cartService: CartService,
    private addressService: AddressService,
    private userService: UserService
  ) {}

  public payPalConfig?: IPayPalConfig;
  @Input() orderItems = [];
  @Input() discountCode: string;
  buyerDetails: any = {};
  @Input() analyticsOrderItems = [];
  @Output() onPaymentLoading = new EventEmitter();
  baseUrl = environment.baseUrl;

  @ViewChild("paypal", { static: true }) paypalElement: ElementRef;
  
  cart: any;
  ngOnInit(): void {
    this.cartService.getCartItems().subscribe(res => {
      if(res && res.length > 0 ){
        this.cart = res;
      }
    })
    this.getInformations();
  }

  openDialog(message): void {
    this.dialog.open(ErrorDialogComponent, {
      width: "60%",
      data: { message: message },
    });
  }

  private initConfig(): void {
    let formData = new FormData();
    formData.append("order_items", JSON.stringify(this.orderItems));
    formData.append("buyer_details", JSON.stringify(this.buyerDetails));
    formData.append("payment_type_id", "2");

    let self = this;
    if (this.discountCode) formData.append("discount_code", this.discountCode);
    window.paypal
      .Buttons({
        // Call your server to set up the transaction
        createOrder: (data, actions) => {
          self.onPaymentLoading.emit(true);
          return fetch(self.baseUrl + "/orders", {
            method: "post",
            body: formData,
            headers: { Authorization: localStorage.getItem("zecchini_token") },
          })
            .then(function (res) {
              return res.json();
            })
            .then(function (orderData) {
              self.paymentInfoDataLayer(self.cart)
              self.onPaymentLoading.emit(false);
              return orderData.result.id;
            })
            .catch((err) => {
              self.onPaymentLoading.emit(false);
              self.openDialog(err.error.message);
            });
        },
        // Call your server to finalize the transaction
        onApprove: function (data, actions) {
          self.onPaymentLoading.emit(true);
          return fetch(
            self.baseUrl + "/orders/capture/paypal/" + data.orderID,
            {
              method: "post",
              headers: {
                Authorization: localStorage.getItem("zecchini_token"),
              },
            })
            .then(function (res) {
              return res.json();
            })
            .then(function (orderData) {
              self.onPaymentLoading.emit(false);
              
              var errorDetail = Array.isArray(orderData.details) && orderData.details[0];
              if (errorDetail && errorDetail.issue === "INSTRUMENT_DECLINED") {
                return actions.restart();
              }
              if (errorDetail) {
                var msg = "Sorry, your transaction could not be processed.";
                if (errorDetail.description)
                  msg += "\n\n" + errorDetail.description;
                if (orderData.debug_id) msg += " (" + orderData.debug_id + ")";
                // return alert(msg); // Show a failure message
                self.openDialog(msg);
                return;
              }
              self.cartService.getCart().subscribe((res) => {
                self.cartService.setCartItems(res);
              });
              if(localStorage.getItem('guest_addresses')) localStorage.removeItem('guest_addresses');
              if(localStorage.getItem('guest_user')) localStorage.removeItem('guest_user');
              if(localStorage.getItem("discount_code")) localStorage.removeItem("discount_code");
              localStorage.setItem("isRegisteredGuest", "0");
              self.dataLayers(data.orderID, orderData.result.purchase_units[0].payments.captures[0].amount.currency_code, orderData.result.purchase_units[0].payments.captures[0].amount.value);

              if(self.user.role.id == '4' && self.user.registration_guest == '1' && self.user.active == '0'){
                self.userService.createGuestAccess().subscribe((res:any) => {
                  self.userService.setUser(res);
                  self.userService.authSubject.next(true);
                  localStorage.setItem('zecchini_token', res.token);
                  localStorage.setItem('show_guest_message', '1')
                  self.cartService.getCart().subscribe(res => {
                    self.cartService.setCartItems(res);
                  })
                })
              } else {
                self.cartService.getCart().subscribe(res => {
                  self.cartService.setCartItems(res);
                })
                self.router.navigate(["/thank-you-page"]);
              }
            });
        },
        onCancel: function (data, actions) {
          self.cartService.getCart().subscribe((res) => {
            self.cartService.setCartItems(res);
          });
          self.onPaymentLoading.emit(false);
        },
        onError: function (err) {
          self.onPaymentLoading.emit(false);
          self.openDialog(err.error.message || err.error || err);
        },
      })
      .render(this.paypalElement.nativeElement);
  }

  dataLayers(paymentIntentId, currency, amount) {
    (<any>window).dataLayer.push({
      event: "purchase",
      payment_type: "PayPal",
      ecommerce: {
        currency: currency, // Required.
        value: amount,
        tax: ((22 / 100) * parseFloat(amount)) / 100,
        shipping: shippingFee,
        affiliation: "Online store",
        transaction_id: paymentIntentId, // Required.
        coupon: this.discountCode ? this.discountCode : "",
        items: this.analyticsOrderItems,
      },
    });
    this.onPaymentLoading.emit(false);
  }


  user: User;
  getInformations(){
    this.userService.getUser().subscribe((user: User) => {
      if (user) {
        this.user = user;
        let isGuest = user.user_type ? false : true;
        if(!isGuest || localStorage.getItem('isRegisteredGuest') == '1'){
          this.addressService.getAddresses().subscribe((addresses: any) => {
            this.createBuyerDetails(user, addresses);
          });
        } else if(isGuest && localStorage.getItem('isRegisteredGuest') != '1') {
          let guest_user: User = JSON.parse(localStorage.getItem('guest_user'))
          let guest_addresses = JSON.parse(localStorage.getItem('guest_addresses'));
          this.createBuyerDetails(guest_user, guest_addresses);
        }
      } 
    });
  }

  createBuyerDetails(user, addresses){
    let primaryAddress = addresses.filter(item => item.primary_address == '1')[0];

    this.buyerDetails.first_name = user?.first_name;
    this.buyerDetails.last_name = user?.last_name;
    this.buyerDetails.email = user?.email;
    this.buyerDetails.phone = user?.phone;
    this.buyerDetails.address = user?.address;
    this.buyerDetails.postal_code = user?.postal_code;
    this.buyerDetails.city = user?.city;
    this.buyerDetails.province = user?.province;
    this.buyerDetails.notes = user?.shipping_notes || user?.notes || user?.note;
    this.buyerDetails.receive_invoice = user?.receive_invoice;
    this.buyerDetails.newsletter_subscribed = user?.newsletter_subscribed;
    this.buyerDetails.accept_privacy = user?.accept_privacy;
    this.buyerDetails.business_name = user?.business_name;
    this.buyerDetails.vat_number = user?.vat_number;
    this.buyerDetails.pec = user?.pec;
    this.buyerDetails.sdi = user?.sdi;
    this.buyerDetails.client_type = user?.client_type || user?.user_type;
    this.buyerDetails.different_shipping = user?.different_shipping;
    this.buyerDetails.fiscal_code = user?.fiscal_code;

    this.buyerDetails.shipping_first_name = primaryAddress?.first_name;
    this.buyerDetails.shipping_last_name = primaryAddress?.last_name;
    this.buyerDetails.shipping_phone = primaryAddress?.phone;
    this.buyerDetails.shipping_province = primaryAddress?.province;
    this.buyerDetails.shipping_city = primaryAddress?.city;
    this.buyerDetails.shipping_address = primaryAddress?.address;
    this.buyerDetails.shipping_postal_code = primaryAddress?.postal_code;
    this.buyerDetails.shipping_notes = primaryAddress?.shipping_notes || primaryAddress?.notes;

    this.initConfig();
  }

  paymentInfoDataLayer(cart){
    let dataLayerCartItems = [];
    cart.forEach((item,index) => {
      dataLayerCartItems.push(
        {
          item_name: item?.product?.title, // Name or ID is required.
          item_id: item?.product?.id, // Name or ID is required.
          price: item?.product?.price * item?.product?.quantity,
          item_brand: item?.product?.producer_name,
          item_category: item?.product?.category_label,
          item_category_2: item?.product?.subcategory_label, // The categories can be up to 4
          // item_variant: item.product_variant,
          quantity: item?.product?.quantity
        }
      ) 
    })
    setTimeout(() => {
      (<any>window).dataLayer.push({
        event: 'add_payment_info', 
        payment_type: 'Stripe',
        ecommerce: {
          items: dataLayerCartItems
        }
      });
    }, 500);
  }
}

import { isPlatformBrowser, NgStyle } from '@angular/common';
import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { staggerOpacTransAnimation, staggerOpacTransAnimationMobile, transitionMenuAnimation} from '../../../router/animations/component-animations'; 
// import { staggerOpacTransAnimation, staggerOpacTransAnimationMobile, transitionMenuAnimation} from '../../router/animations/component-animations'; 
import { animate, animateChild, query, state, style, transition, trigger } from '@angular/animations';
import { UserService } from 'src/app/services/user.service';
import { CartService } from 'src/app/services/cart.service';
import { ShopService } from 'src/app/services/shop.service';
import { ProductCategory, ProductCategoryModel } from 'src/app/models/product-category.model';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  animations: [staggerOpacTransAnimation, staggerOpacTransAnimationMobile, transitionMenuAnimation, 
    trigger('openClose', [
      transition('* => *', [
        query('@openCloseLine1, @openCloseLine2', animateChild()),
      ])
    ]),
    trigger('openCloseLine1', [
      state('closed',
        style({
          transform: 'translate(0, -3px)'
        })
      ),
      state('open',
        style({
          backgroundColor: 'white',
          transform: 'rotate(45deg)'
        })
      ),
      transition('closed => open', [
        animate('0.5s cubic-bezier(.72,.01,.07,1)'),
      ]),
      transition('open => closed', [
        animate('0.2s cubic-bezier(.72,.01,.07,1)'),
      ]),
    ]),
    trigger('openCloseLine2', [
      state('closed',
        style({
          transform: 'translate(3px, 3px)'
        })
      ),
      state('open',
        style({
          backgroundColor: 'white',
          transform: 'rotate(-45deg)'
        })
      ),
      transition('closed => open', [
        animate('0.5s cubic-bezier(.72,.01,.07,1)'),
      ]),
      transition('open => closed', [
        animate('0.2s cubic-bezier(.72,.01,.07,1)'),
      ]),
    ]),
  ]
})
export class HeaderComponent implements OnInit {
  isBrowser;
  tlHeader;
  activeView: string;
  windowWidth: number = window.innerWidth;
  isMobile: boolean = false;
  prevScrollPos;
  browser;
  state: string = 'closed';
  shop: ProductCategory[] = [];

  shopMenu = [
    {
    label: 'biciclette',
    routerLink: ['/biciclette'],
    id: '1',
    image:['url(assets/images/cursor/biciclette.png)']
  },{
    label: 'e-bike',
    routerLink: ['/ebike'],
    id: '2',
    image:['url(assets/images/cursor/ebike.png)']
  },{
    label: 'accessori',
    routerLink: ['/accessori-bici'],
    id: '3',
    image:['url(assets/images/cursor/accessori.png)']
  },{
    label: 'componenti',
    routerLink: ['/componenti-bici'],
    id: '4',
    image:['url(assets/images/cursor/componenti.png)']
  },{
    label: 'abbigliamento',
    routerLink: ['/abbigliamento-bici'],
    id: '5',
    image:['url(assets/images/cursor/componenti.png)']
  }
]

  about = [
    {
      label: 'storia',
      routerLink: ['/storia'],
      image: ['url(assets/images/cursor/storia.png)']
    },
    {
      label: 'servizi',
      routerLink: ['/servizi'],
      image: ['url(assets/images/cursor/servizi.png)']
    },{
      label: 'consigli',
      routerLink: ['/consigli'],
      image: ['url(assets/images/cursor/consigli.png)']
    }
  ]

  contact = [
    {
      label: 'contattaci',
      routerLink: ['/contatti'],
      image: ['url(assets/images/cursor/contatti.png)']
    },
    {
      label: 'rivenditori',
      routerLink: ['/rivenditori'],
      image: ['url(assets/images/cursor/rivenditori.png)']
    }
  ];
  
  cartLength: number = 0;
  user: User;

  constructor(
    @Inject(PLATFORM_ID) platformId: string, 
    private router: Router, 
    private userService: UserService, 
    private cartService: CartService,
    private shopService: ShopService) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (!this.isBrowser) {
      return;
    }
    gsap.registerPlugin(ScrollTrigger);  
  }
  isLoggedIn: boolean = false;
  roleId: any;
  ngOnInit(): void {
    this.userService.isLoggedIn().subscribe(res => {this.isLoggedIn = res})
    this.userService.getUser().subscribe((value: User) => {
      if(value){
        this.roleId = value.role.id || value.role
        this.user = value;
      }
    });
    this.cartService.getCartItems().subscribe(cart => {
      if(cart){
        this.cartLength = cart.length;
      }
    })
    this.mobileDetect();
    this.shopService.getProductCategories().subscribe((res: ProductCategory[]) => {
      this.shop = res;
    }) 
  }

  mobileDetect() {
    if(this.windowWidth < 1023){
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  } 

  menuOver(view) {
     if(!this.isMobile) {
      this.activeView = view;
     }
  }

  closeMenu(view) {
    this.activeView = view;
    if(this.state == 'open') {
    this.state = this.state === 'open' ? 'closed' : 'open'
    document.body.style.overflow = 'unset'
    }
  }

  toggleStateMenu(view) {
    this.state = this.state === 'open' ? 'closed' : 'open'
    if(this.state == 'open') {
      this.activeView = view;
      document.body.style.overflow = 'hidden'
    } else {
      this.activeView = '';
      document.body.style.overflow = 'unset'
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = window.innerWidth; 
    this.mobileDetect();
  }

  ngAfterViewInit() {
    if (!this.isBrowser) {
      return;
    }
    this.prevScrollPos  = window.pageYOffset;
    this.tlHeader = gsap.timeline({paused: true});
    this.tlHeader.to('.header__background', {
      duration:0.3,
      height:'100%'
    })

    this.router.events.subscribe((val) => {
      if(val instanceof NavigationEnd) {
        setTimeout(()=>{
          this.tlHeader.reverse();
        },100)
      }

  });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if(window.pageYOffset > 50 ) {
      this.tlHeader.play();
    } else {
      this.tlHeader.reverse();
    }
  }
}

<div class="background"></div>
<div class="container container--double">
    <div class="container--white container__left" *ngIf="firstContainerIsVisible">
        <h1>Profilo utente</h1>
        <h2>Ciao <span class="orange">{{(user$ | async).first_name}}</span></h2>
        <p class="blue"><b>Felici di rivederti</b></p>
        <p class="padding-top--30 padding-bottom--30">All’interno del tuo profilo puoi gestire</p>
        <div class="button-link__container">
            <!-- [ngClass]="{active: activeView == 'editInvoice'}" (click)="setView('editInvoice')" -->
            <button [routerLink]="[ROUTE_NAMES.PURCHASE_INFORMATION]" routerLinkActive="active">
                Informazioni d'acquisto
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 34.86">
                    <path class="cls-1" d="M31.57,14.7,20.79,3.92,24.64,0,42,17.43,24.64,34.86l-4-4L31.43,20.16H0V14.7Z"/>
                </svg>
            </button>
            <!-- [ngClass]="{active: activeView == 'viewShipping'}" (click)="setView('viewShipping')" -->
            <button [routerLink]="[ROUTE_NAMES.SHIPPING_ADDRESSES]" routerLinkActive="active">
                Indirizzi di spedizione
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 34.86">
                    <path class="cls-1" d="M31.57,14.7,20.79,3.92,24.64,0,42,17.43,24.64,34.86l-4-4L31.43,20.16H0V14.7Z"/>
                </svg>
            </button>
            <!-- [ngClass]="{active: activeView == 'viewOrder'}" (click)="setView('viewOrder')" -->
            <button [routerLink]="[ROUTE_NAMES.ORDERS]" routerLinkActive="active">
                Ordini effettuati
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 34.86">
                    <path class="cls-1" d="M31.57,14.7,20.79,3.92,24.64,0,42,17.43,24.64,34.86l-4-4L31.43,20.16H0V14.7Z"/>
                </svg>
            </button>
            <!-- [ngClass]="{active: activeView == 'editHelp'}" (click)="setView('editHelp')" -->
            <button [routerLink]="[ROUTE_NAMES.HELP]" routerLinkActive="active">
                Aiuto
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 34.86">
                    <path class="cls-1" d="M31.57,14.7,20.79,3.92,24.64,0,42,17.43,24.64,34.86l-4-4L31.43,20.16H0V14.7Z"/>
                </svg>
            </button>
            <button (click)="logout()">
                Logout
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 34.86">
                    <path class="cls-1" d="M31.57,14.7,20.79,3.92,24.64,0,42,17.43,24.64,34.86l-4-4L31.43,20.16H0V14.7Z"/>
                </svg>
            </button>
        </div>
    </div>
    <div class="container__right">
        <router-outlet></router-outlet>
    </div>
</div>
<div class="container">
    <h1>Carrello</h1>
    <div *ngIf="emptyCart" @imageFadeAnimation>
        <h2>Il carrello è vuoto</h2>
        <p>Seleziona dei prodotti per poter procedere all'acquisto</p>
        <div class="action-float">
            <div class="frontend-link">
                <a [routerLink]="['/']">torna alla home</a>
                <div class="frontend-link__line"></div>
                <div class="frontend-link__line2"></div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.05 10"><path d="M9.06,4.22,6,1.12,7.07,0l5,5-5,5L5.92,8.86,9,5.78H0V4.22Z"/></svg>
            </div>
        </div>
    </div>
    <div class="cart__product" *ngIf="!emptyCart" @imageFadeAnimation>
        <table width="100%">
            <thead>
                <tr>
                    <th>PRODOTTO</th>
                    <th>QUANTITÀ</th>
                    <th>INFORMAZIONI</th>
                    <th>PREZZO</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let cartItem of cart" [ngClass]="{overlay: !cartItem.hasQuantity}">
                    <td class="cart-img__container">
                        <div class="cart__img">
                            <!-- <img width="200" height="160" src="assets/images/shop/bici-1.jpg"> -->
                            <!-- <picture>
                                <source sizes="(max-width: 1023px) 150px, 200px" type="image/webp" srcset="assets/images/shop/bici-2_400.webp 400w">
                                <source sizes="(max-width: 1023px) 150px, 200px" type="image/jpg" srcset="assets/images/shop/bici-2_400.jpg 400w">
                                <img sizes="(max-width: 1023px) 150px, 200px" src="assets/images/shop/bici-2_400.jpg" srcset="assets/images/shop/bici-2_400.jpg 400w" alt="City bike Zecchini">
                            </picture> -->
                            <picture *ngIf="cartItem.image">
                                <source sizes="(max-width: 1023px) 150px, 200px" type="image/webp" [srcset]="cartItem.image + '_400.webp 400w'">
                                <source sizes="(max-width: 1023px) 150px, 200px" type="image/jpg" [srcset]="cartItem.image + '_400.jpg 400w'">
                                <img sizes="(max-width: 1023px) 150px, 200px" [src]="cartItem.image + '_400.jpg'" [srcset]="cartItem.image + '_400.jpg 400w, '+ cartItem.image +'_400.jpg 400w'" alt="{{cartItem.product.title}}">
                            </picture>
                            <picture *ngIf="!cartItem.image">
                                <source sizes="(max-width: 1023px) 150px, 200px" type="image/webp" srcset="assets/images/shop/bici-2_400.webp 400w">
                                <source sizes="(max-width: 1023px) 150px, 200px" type="image/jpg" srcset="assets/images/shop/bici-2_400.jpg 400w">
                                <img sizes="(max-width: 1023px) 150px, 200px" src="assets/images/shop/bici-2_400.jpg" srcset="assets/images/shop/bici-2_400.jpg 400w" alt="{{cartItem.product.title}}">
                            </picture>
                        </div>
                    </td>
                    <td class="td--quantity">
                        <div class="cart__quantity cart__row" style="height: initial;">
                            <button [disabled]="isCartLoading" (click)="changeQuantity(cartItem, 'decrease')">-</button>
                            <div>{{cartItem.quantity}}</div>
                            <button [disabled]="isCartLoading" (click)="changeQuantity(cartItem, 'increase')">+</button>
                        </div>
                        <p class="quantity__error" *ngIf="!cartItem.hasCorrectQuantity && cartItem.hasQuantity">La quantità massima disponibile è {{cartItem.product_variant.available_quantity}}.</p>
                    </td>
                    <td class="td--info">
                        <div class="cart__info">
                            <div class="cart__row">
                                <p class="info__name">{{cartItem.product.title}}</p>
                                <p class="info__typology">{{cartItem.product.subcategory_label}}</p>
                            </div>
                            <div>
                                <p class="info__detail">TAGLIA</p>
                                <p class="price__shipment" [innerHTML]="cartItem.product_variant.size"></p>
                            </div>
                        </div>
                    </td>
                    <td class="td--price">
                        <div *ngIf="cartItem.hasQuantity" class="cart__price">
                            <div class="cart__row">
                                <p class="price__partial"><span>€</span>{{(cartItem.product.price * cartItem.quantity) | number : '1.2-2'}}</p>
                            </div>
                            <div *ngIf="cartItem.product.product_category_id == '1' || cartItem.product.product_category_id == '2'">
                                <p class="info__detail">SPESE DI SPEDIZIONE</p>
                                <p class="price__shipment"><span>€</span>{{cartItem.product.shipping_fee * cartItem.quantity}}</p>
                            </div>
                        </div>
                        <div class="esaurito" *ngIf="!cartItem.hasQuantity">Esaurito</div>
                    </td>
                </tr>
        </table>
    </div>
    <div class="cart-subtotal" *ngIf="discountPercentage">
        <div class="cart-subtotal__discount">
            <p class="discount-code__title">SCONTO APPLICATO</p>
            <p><span class="cart-subtotal__price cart-subtotal__price--corss-line">{{totalPriceWithoutShipping | number : '1.2-2'}}€</span> - {{discountPercentage}}%</p>
        </div>
    </div>
    <div class="cart-subtotal padding-top--10" *ngIf="cart && cart.length > 0">
        <div class="cart-subtotal__discount">
            <p class="discount-code__title grey">SPESE DI SPEDIZIONE BICI <button class="button__info" (click)="openDialogBike()">?</button></p>
            <p><span class="price__shipment">€ {{accessoryTotalPrice > 0 && accessoryTotalPrice <= 100 ? totalShippingFee-4 : totalShippingFee}}</span></p>
        </div>
    </div>
    <div class="cart-subtotal" *ngIf="accessoryTotalPrice > 0 && accessoryTotalPrice <= 100">
        <div class="cart-subtotal__discount">
            <p class="discount-code__title grey">SPESE DI SPEDIZIONE ARTICOLI <button class="button__info" (click)="openDialogAccessories()">?</button></p>
            <p><span class="price__shipment">€ 4</span></p>
        </div>
    </div>
    <div class="cart-total__container" *ngIf="!emptyCart" @imageFadeAnimation>
        <div class="cart__discount-code">
            <div class="discount-code">
                <p class="discount-code__title">CODICE SCONTO?</p>
                <div class="form__container">
                    <form (ngSubmit)="appendDiscountCode()">
                        <mat-form-field appearance="standard">
                            <mat-label>Inseriscilo qui</mat-label>
                            <input matInput [formControl]="discountCodeFormControl">
                        </mat-form-field>
                        <mat-error *ngIf="discountError">
                            {{discountError}}
                        </mat-error>
                    </form>
                </div>
            </div>
            <div class="button__container">
                <div class="button__background2"></div>
                <div class="button__background"></div>
                <button class="action__button" (click)="appendDiscountCode()">Applica
            <div class="arrow-left"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.14 16.48"><line y1="8.24" x2="19.55" y2="8.24"/><polyline points="11.4 0.5 19.14 8.24 11.4 15.98"/></svg></div>
            </button>
            </div>
        </div>
        <div>
            <div class="cart__total">
                <p class="discount-code__title">TOTALE</p>
                <div class="total" *ngIf="!discountPercentage">
                    <p><span>€</span>{{totalPrice | number : '1.2-2'}}</p>
                </div>
                <div class="total" *ngIf="discountPercentage">
                    <!-- <p><span>€</span>{{((totalPrice - (discountPercentage / 100) * totalPrice)) | number : '1.2-2'}}</p> -->
                    <p><span>€</span>{{((totalPriceWithoutShipping - (discountPercentage / 100) * totalPriceWithoutShipping) + totalShippingFee) | number : '1.2-2'}}</p>
                </div>
                <div class="button__container">
                    <div class="button__background2"></div>
                    <div class="button__background"></div>
                    <a class="action__button" (click)="cartProceed()">Procedi
                <div class="arrow-left"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.14 16.48"><line y1="8.24" x2="19.55" y2="8.24"/><polyline points="11.4 0.5 19.14 8.24 11.4 15.98"/></svg></div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
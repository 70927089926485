import { HttpClient, HttpParams } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ContactService } from "src/app/services/contact.service";
import { mailchimp } from 'src/app/env';
import { MailChimpResponse } from "src/app/models/mailchimp-response.model";
import { CustomSnackBarComponent } from "../../custom-snack-bar/custom-snack-bar.component";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent implements OnInit {
  public newsletterForm: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private contactService: ContactService,
    private http: HttpClient
  ) {
    this.newsletterForm = formBuilder.group({
      newsletterEmail: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"),
        ],
      ],
      newsletterPrivacy: ["", Validators.requiredTrue],
    });
  }

  @ViewChild('form') form;

  ngOnInit(): void {}

  get f() {
    return this.newsletterForm.controls;
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
    });
  }

  sendNewsletterForm() {
    if (this.newsletterForm.invalid) {
      this.markFormGroupTouched(this.newsletterForm);
      return;
    }

    let data = this.newsletterForm.value;
    let url = mailchimp.apiUrl;

    const params = new HttpParams()
      .set("EMAIL", data.newsletterEmail)
      .set(mailchimp.secret, ""); // hidden input name

    const mailChimpUrl = url + params.toString();

    this.http.jsonp<MailChimpResponse>(mailChimpUrl, "c").subscribe(
      (response) => {
        if (response.result && response.result !== 'error') {
          // this.newsletterForm.reset();
          this.form.resetForm();
          this._snackBar.open("Grazie per esserti registrato alla nostra newsletter", 'OK', {
            duration: 5000
          });
				}
				else {
          this._snackBar.openFromComponent(CustomSnackBarComponent, {
            data: {
              html: response.msg
            },
            duration: 5000,
          });
				}
      },
      (error) => {
      }
    );
  }
}
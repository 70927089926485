import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, OnInit, Output, PLATFORM_ID, ViewChild } from '@angular/core';
import { animate, animateChild, state, style, transition, trigger, query, group } from '@angular/animations';
import { imageFadeAnimation } from '../../router/animations/component-animations';
import { slider } from 'src/app/constants/slider';
import { products } from '../../constants/products'
import gsap from 'gsap';
import { SplitText } from 'gsap/all';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { AppRoutingTransitionService } from 'src/app/services/app-routing-transition.service';
import { ShopService } from 'src/app/services/shop.service';
import { Product } from 'src/app/models/product.model';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css'],
  animations: [imageFadeAnimation,
    trigger('titleEnter', [
      state('false',
        style({
          opacity: 0
        })
      ),
      transition('false => true', [
        animate('0.3s cubic-bezier(0.55, 0.31, 0.15, 0.93)'),
        group([
          query('@titleEnterSpan', animateChild(), { optional: true }),
        ]),
        group([
          query('@titleEnterText', animateChild(), { optional: true }),
        ])
      ]),
    ]),
    trigger('titleEnterSpan', [
      transition('false => true', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('{{durationAnim}}s cubic-bezier(.6,.36,.07,.99)', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
    ]),
    trigger('titleEnterText', [
      transition('false => true', [
        style({ opacity: 0 }),
        animate('0.3s ease-in', style({ opacity: 1 })),
      ]),
    ]),
    trigger('imgEnter', [
      state('false',
        style({
          opacity: 0,
          transform: 'translateY({{transformDistancy}}vw)',
        }), { params: { transformDistancy: 15 } }
      ),
      transition('false => true', [
        animate('1s cubic-bezier(.72,.01,.07,1)'),
        query('@imgScale', animateChild({ duration: 1 }), { optional: true }),
      ]),
    ]),
    trigger('imgScale', [
      state('false',
        style({
          transform: 'scale(1)'
        })
      ),
      transition('false => true', [
        style({
          transform: 'scale(1.25)'
        }),
        animate('1s cubic-bezier(.72,.01,.07,1)')
      ]),
    ]),
    trigger('showHidden', [
      state('show', style({
        opacity: 1
      })),
      state('hidden', style({
        opacity: 0
      })),
      transition('* => *', [
        animate('1s ease-in-out')
      ]),
    ]),
  ]
})
export class HomepageComponent implements OnInit {
  public isEnter: boolean = false;
  windowWidth: number = window.innerWidth;
  isMobile: boolean = false;
  prodotti = products;
  activeImg: string;
  activeText: string;
  activeText2: string;
  homeSlider1 = slider.homeSlider1;
  homeSlider2 = slider.homeSlider2;
  // textInifinite;
  isBrowser;
  tlInfiniteText;
  @ViewChild('split1') split1;
  @ViewChild('split2') split2;
  @ViewChild('split3') split3;
  @ViewChild('split4') split4;
  @ViewChild('split5') split5;
  tlSplit;
  section0Tl;
  section1Tl;
  section2Tl;
  sliderTlAnim;
  sliderTlAnim2;
  productTl;
  myEventSubscription;
  stravaMobileTl;
  marchiMobileTl;
  // @ViewChild('infiniteScrollContainer') infiniteScrollContainer;
  // @ViewChild('infiniteScroll') infiniteScroll;
  newestBikes: Product[];
  newestAccessories: Product[];
  newestEBikes: Product[];
  constructor(
    @Inject(PLATFORM_ID) platformId: string, 
    private appService: AppRoutingTransitionService,
    private shopService: ShopService) {
    this.isBrowser = isPlatformBrowser(platformId);
  }


  ngOnInit(): void {
    this.activeImg = 'citybike';
    this.shopService.getBestSellingProducts().subscribe((res: any) => {
      this.newestBikes = res.newest_bikes.map(item => new Product(item, this.shopService.categories.value, this.shopService.subcategories.value))
      this.newestEBikes = res.newest_ebikes.map(item => new Product(item, this.shopService.categories.value, this.shopService.subcategories.value))
      this.newestAccessories = res.newest_accessories.map(item => new Product(item, this.shopService.categories.value, this.shopService.subcategories.value))
    })
    this.mobileDetect();
    if (!this.isBrowser) {
      return;
    }
    this.myEventSubscription = this.appService.stringSubject.subscribe(() => {
        this.isEnter = true;
      }
    );
  }

  mobileDetect() {
    if(this.windowWidth < 1023){
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  } 

  textPlay() {
    if(!this.isMobile) {
      this.tlInfiniteText.timeScale(1);
    }
  }

  textPause() {
    if(!this.isMobile) {
    this.tlInfiniteText.timeScale(0.2);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = window.innerWidth; 
    this.mobileDetect();
  }

  animEnd(event) {
    if (event.fromState == false) {
      this.section0Tl = gsap.timeline({
        scrollTrigger: {
          id: "intro",
          trigger: ".intro-page",
          start: "top top",
          end: "bottom 30%",
          scrub: 1
        }
      })

      this.section0Tl.to('.intro-page .box-right', {
        duration: 1,
        y: -50,
      }).to('.intro-page .box-left', {
        duration: 1,
        y: -100,
      }, '-=1')

      this.section1Tl = gsap.timeline({
        scrollTrigger: {
          id: "tl1",
          toggleActions: "play complete none none",
          trigger: "#tl1",
          start: "100px 90%"
        }
      })

      this.section1Tl.from('.image-change img', {
        duration: 1,
        opacity: 0.5,
        scale: 1.1,
        ease: "power2.out"
      }).from('.image-change', {
        duration: 1,
        y: '10vw',
        ease: "power2.out"
      }, '-=1')

      this.section2Tl = gsap.timeline({
        scrollTrigger: {
          id: "tl2",
          toggleActions: "play complete none none",
          trigger: ".consigli__img",
          start: "top 90%"
        }
      })

      this.section2Tl.from('.consigli__img img', {
        duration: 1,
        opacity: 0.5,
        scale: 1.1,
        ease: "power2.out"
      }).from('.consigli__img', {
        duration: 1,
        y: '10vw',
        ease: "power2.out"
      }, '-=1')

      gsap.utils.toArray(".parallaxText").forEach(section => {
        let parallax: any = section;

        gsap.from(parallax, {
          scrollTrigger: {
            id: 'parallax',
            trigger: parallax,
            start: 'top 90%',
            scrub: 2
          },
          ease: "power3.out",
          y: -100,
          duration: 1,
        });
      });

      gsap.utils.toArray(".text-rotate p").forEach(section => {
        let rotate: any = section;

        gsap.from(rotate, {
          scrollTrigger: {
            id: 'rotate',
            trigger: rotate,
            start: 'top 90%',
            scrub: false
          },
          ease: "power3.out",
          y: 40,
          duration: 1,
        });
      });

      gsap.utils.toArray('.split').forEach(section => {
        let split: any = section;

        this.tlSplit = gsap.timeline({
          scrollTrigger: {
            id: 'split',
            trigger: split,
            start: 'top 90%',
            scrub: false
          }
        })

        this.tlSplit.from(split.querySelectorAll(".lineChild"), {
          duration: 0.5,
          opacity: 0,
          y: 30,
          stagger: 0.05,
          ease: "power2.inOut"
        })
      })

      gsap.utils.toArray(".textOpacity").forEach(section => {
        let textOpacity: any = section;

        gsap.from(textOpacity, {
          scrollTrigger: {
            id: 'rotate',
            trigger: textOpacity,
            start: 'top 90%',
            scrub: false
          },
          ease: "power3.out",
          opacity: 0.3,
          duration: 1,
        });
      });


      this.sliderTlAnim = gsap.timeline({
        scrollTrigger: {
          id: 'slider',
          trigger: '#slider1',
          start: 'top 90%',
          scrub: 1
        }
      })

      this.sliderTlAnim.to('#slider1 .grid__front--small', {
        duration: 1,
        y: -100
      }).to('#slider1 .slider-big__container', {
        duration: 1,
        y: -50
      }, '-=1')

      this.sliderTlAnim2 = gsap.timeline({
        scrollTrigger: {
          id: 'slider2',
          trigger: '#slider2',
          start: 'top 90%',
          scrub: 1
        }
      })

      this.sliderTlAnim2.to('#slider2 .grid__front--small', {
        duration: 1,
        y: -100
      }).to('#slider2 .slider-big__container', {
        duration: 1,
        y: -50
      }, '-=1')

      gsap.utils.toArray(".product").forEach(section => {
        let productImage: any = section;

        gsap.from(productImage, {
          scrollTrigger: {
            id: 'product',
            trigger: productImage,
            start: 'top bottom'
          },
          duration: 0.7,
          x: 100,
          opacity: 0,
          stagger: 0.1,
        });
      });

      if(this.isMobile) {

        this.marchiMobileTl = gsap.timeline({
          scrollTrigger: {
            id: 'marchi',
            trigger: '.marchi',
            start: 'top 80%',
            toggleActions: "play complete none reverse",
            onEnter: ()=> this.activeText = 'xp',
            onLeaveBack: ()=> this.activeText = ''
          }
        })

        this.stravaMobileTl = gsap.timeline({
          scrollTrigger: {
            id: 'strava',
            trigger: '.strava__img--home',
            start: 'top 80%',
            toggleActions: "play complete none reverse",
            onEnter: ()=> this.activeText2 = 'strava',
            onLeaveBack: ()=> this.activeText2 = ''
          }
        });
      } 

    }
  }

  ngAfterViewInit() {
    if (!this.isBrowser) {
      return;
    }
    new SplitText([this.split1.nativeElement, this.split2.nativeElement, this.split3.nativeElement, this.split4.nativeElement, this.split5.nativeElement], { type: "lines", linesClass: "lineChild" });
    new SplitText([this.split1.nativeElement, this.split2.nativeElement, this.split3.nativeElement, this.split4.nativeElement, this.split5.nativeElement], { type: "lines", linesClass: "lineParent" });

    this.tlInfiniteText = gsap.timeline()
    this.tlInfiniteText.to('.infinite-scroll__container', {
      duration:15,
      x: '-100%',
      ease: 'none',
      repeat: -1
    });

    //this.isEnter = 'true';
    // let copy = this.infiniteScrollContainer.nativeElement.cloneNode(true);
    // let copy2 = this.infiniteScrollContainer.nativeElement.cloneNode(true);
    // let wrap = this.infiniteScroll.nativeElement;
    // wrap.appendChild(copy);
    // wrap.appendChild(copy2);

  }

  txtOver(view) {
    this.activeImg = view;
  }

  textInfiniteOver(view) {
    this.activeText = view;
    this.activeText2 = view;
  }

  ngOnDestroy() {
    if (!this.isBrowser) {
      return;
    }
    ScrollTrigger.getAll().map((scrollTrigger) => {
      scrollTrigger.kill();
    })
    this.myEventSubscription.unsubscribe();
  }

}


import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "src/app/services/user.service";
import { MustMatch } from "src/app/shared/validators/must-match.validator";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"],
})
export class ForgotPasswordComponent implements OnInit {
  public forgotPassword: FormGroup;
  token: any;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private _matSnackBar: MatSnackBar
  ) {
    this.forgotPassword = formBuilder.group(
      {
        newPassword: ["", [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[0-9])(?=.*[A-Z]).{8,}')]],
        confirmPassword: ["", [Validators.required, Validators.minLength(8)]],
      },
      {
        validator: MustMatch("newPassword", "confirmPassword"),
      }
    );
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.token) this.token = params.token;
    });
  }

  get f() {
    return this.forgotPassword.controls;
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
    });
  }

  forgotPasswordSend() {
    if (this.forgotPassword.invalid) {
      this.markFormGroupTouched(this.forgotPassword);
      return;
    }
    let formData = new FormData();
    formData.append("token", this.token);
    formData.append("new_password", this.forgotPassword.value.newPassword);

    this.userService.resetPassword(formData).subscribe(
      (res) => {
        this.router.navigateByUrl("/login");
      },
      (err) => {
        this._matSnackBar.open(err.error.message, "Okay", { duration: 5000 });
      }
    );
  }
}

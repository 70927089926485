import { Component, HostListener, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, RouterOutlet } from '@angular/router';
import { SeoService } from './services/seo.service';
import { AppRoutingTransitionService } from './services/app-routing-transition.service';
import { filter, map, mergeMap } from 'rxjs/operators';
import { slideInAnimation } from './router/animations/router-transition';
import { imageFadeAnimation } from './router/animations/component-animations';
import { UserService } from './services/user.service';
import { isPlatformBrowser } from '@angular/common';
import { CartService } from './services/cart.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    slideInAnimation, imageFadeAnimation
  ]
})
export class AppComponent {
  title = 'zecchini-frontend';
  hasPrepared: boolean;
  loading: boolean = true;
  windowWidth: number = window.innerWidth;
  isMobile: boolean = false;
  isMobileLandscape: boolean = false;
  scrollTopParams = 0;

  constructor(
    private router: Router, 
    private activatedRoute: ActivatedRoute, 
    private seoService: SeoService, 
    private renderer:Renderer2, 
    private appService: AppRoutingTransitionService,
    private cartService: CartService) { }

   mobileDetect() {
    if(this.windowWidth < 1023 && window.innerHeight > window.innerWidth){
      this.isMobile = true;
      this.isMobileLandscape = false;
    } else if(this.windowWidth < 900 && window.innerHeight < window.innerWidth && this.windowWidth > 500) {
      this.isMobile = true;
      this.isMobileLandscape = true;
    } else {
      this.isMobileLandscape = false;
      this.isMobile = false;
    }
 } 

  prepareRoute(outlet: RouterOutlet) {
    const value = outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation && outlet.activatedRoute.snapshot.url.join("");
    return {
      value: value,
      params: { scroll: - this.scrollTopParams + 'px' }
    };
  }

  animPageEnd($event) {
    if ($event.fromState != 'void' && this.loading == false) {
      this.appService.endPageAnim();
    }
  }

  animPageStart($event) {
    this.appService.startPageAnim();
  }

  onLoadingEnd() {
    this.appService.endPageAnim();
  }

  onLoadingHidden() {
    this.loading = false;
  }

  WebpIsSupported(callback) {
    if (!window.createImageBitmap) {
      callback(false);
      return;
    }
    var webpdata = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=';
    fetch(webpdata).then(function (response) {
      return response.blob();
    }).then(function (blob) {
      createImageBitmap(blob).then(function () {
        callback(true);
      }, function () {
        callback(false);
      });
    });
  }

@HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = window.innerWidth; 
    this.mobileDetect();
  }

  ngOnInit() {
    this.getCart();
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(e => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
    ).subscribe(data => {
      if (data && data['seo']) {
        let seoData = data['seo'];
        this.seoService.updateTitle(seoData['title']);
        this.seoService.updateMetaTags(seoData['metaTags']);
        this.seoService.setCanonicalURL();
        this.scrollTopParams = window.scrollY;
      }
    });
    this.seoService.initMetaTag();

    this.WebpIsSupported((isSupported) => {
      if (isSupported) {
        this.renderer.addClass(document.body, 'webp');
      } else {
        this.renderer.addClass(document.body, 'no-webp');
      }
  });
  this.mobileDetect()
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    location.reload();
  }

  getCart(){
    this.cartService.getCart().subscribe(res => {
      this.cartService.setCartItems(res)
    })
  }
}

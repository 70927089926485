<footer>
    <div class="newsletter">
        <div class="newsletter__container">
            <p class="orange"><b>Iscriviti alla newsletter.</b></p>
            <p>Te lo promettiamo: pochi messaggi, ma molto interessanti.</p>
            <form #form="ngForm" [formGroup]="newsletterForm" (ngSubmit)="sendNewsletterForm()">
                <div>
                    <mat-form-field appearance="standard">
                        <mat-label>e-mail</mat-label>
                        <input type="email" matInput formControlName="newsletterEmail" required>
                        <mat-error *ngIf="f.newsletterEmail.invalid && (f.newsletterEmail.dirty || f.newsletterEmail.touched)">
                            <mat-error *ngIf="(f.newsletterEmail.errors.required)">
                                campo <strong>obbligatorio</strong>
                            </mat-error>
                            <mat-error *ngIf="(f.newsletterEmail.errors.pattern)">
                                inserisci un indirizzo e-mail <strong>valido</strong>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                    <div class="newsletter__terms">
                        <div>
                            <mat-checkbox formControlName="newsletterPrivacy" required>*Acconsento al trattamento dei miei dati secondo l’<a class="link-underline" [routerLink]="['/privacy']">informativa sulla privacy</a></mat-checkbox>
                            <mat-error *ngIf="f.newsletterPrivacy.invalid && (f.newsletterPrivacy.dirty || f.newsletterPrivacy.touched)">
                                campo <strong>obbligatorio</strong>
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="button__container">
                    <div class="button__background2"></div>
                    <div class="button__background"></div>
                    <button type="submit" class="action__button">Invia
            <div class="arrow-left"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.14 16.48"><line y1="8.24" x2="19.55" y2="8.24"/><polyline points="11.4 0.5 19.14 8.24 11.4 15.98"/></svg></div>
            </button>
                </div>
            </form>
        </div>
    </div>
    <div class="footer grid__container grid__container--4">
        <div class="grid-mobile--1-2">
            <p class="blue"><b>ZECCHINI & C. Srl</b></p>
            <p>Via Panoramica 1/A - Brescia</p>
            <p>P.I. 01992300176</p>
            <p>Tel <a class="orange" href="tel:+390303755432">+39 030 3755432</a></p>
        </div>
        <div>
            <ul>
                <li><a [routerLink]="['/storia']">Storia</a></li>
                <li><a [routerLink]="['/servizi']">Servizi</a></li>
                <li><a [routerLink]="['/contatti']">Contatti</a></li>
                <li><a [routerLink]="['/rivenditori']">Rivenditori</a></li>
            </ul>
        </div>
        <div>
            <ul>
                <li><a [routerLink]="['/privacy']">Privacy policy</a></li>
                <li><a [routerLink]="['/cookie']">Cookie policy</a></li>
                <li><a [routerLink]="['/condizioni-di-vendita']">Condizioni di vendita</a></li>
            </ul>
        </div>
        <div class="grid-mobile--1-2">
            <div class="footer-link">
                <a href="https://www.facebook.com/biciclettezecchini" target="_blank">facebook</a>
                <a href="https://www.instagram.com/biciclettezecchini/" target="_blank">instagram</a>
                <a href="https://www.strava.com/clubs/882628" target="_blank">strava</a>
            </div>
            <p class="footer-link__title">Metodi di pagamento</p>
            <div class="footer-link">
                <p>Paypal</p>
                <p>Stripe</p>
            </div>
        </div>
        <div class="credits">
            <a href="https://www.menatcode.it/" target="_blank">Designed by Men At Code</a>
        </div>
    </div>
</footer>
export interface UserModel {
    id: string;    
    accept_privacy: string;
    address: string;
    business_name?: string;
    city: string;
    email: string;
    first_name: string;
    fiscal_code: string;
    last_name: string;
    phone: string;
    username: string;
    shipping_notes?: string;
    vat_number?: string;
    pec?: string;
    sdi?: string;
    user_type: string;
    receive_invoice: boolean;
    newsletter_subscribed: boolean;
    created_at: Date;
    province: string;
    first_login: string;
    active: string;
    postal_code: string;
    role?: Role;
    registration_guest?: string
}

export interface Address {
    id: number;
    first_name: string;
    last_name: string;
    address: string;
    postal_code: string;
    city: string;
    province: string;
    phone: string;
    note?: string;
    notes?: string;
    isPrimary:boolean;
    shipping_notes: string;
}

interface Role {
    id?: string;
    name?: string;
    description?: string;
}

export class User implements UserModel {
    id: string;    
    accept_privacy: string;
    address: string;
    business_name?: string;
    city: string;
    email: string;
    first_name: string;
    fiscal_code: string;
    last_name: string;
    phone: string;
    username: string;
    shipping_notes?: string;
    vat_number?: string;
    pec?: string;
    sdi?: string;
    user_type: string;
    receive_invoice: boolean;
    newsletter_subscribed: boolean;
    created_at: Date;
    province: string;
    first_login: string;
    active: string;
    postal_code: string;
    role?: Role;
    registration_guest?: string
    
	constructor(user: UserModel) {
        // this.id = user.id;
        // this.first_name = user.first_name;
        // this.last_name = user.last_name;
        // this.email = user.email;
        // this.phone = user.phone;
        // this.username = user.username;
        // this.notes = user.notes;
        // this.business_name = user.business_name;
        // this.vat_number = user.vat_number;
        // this.pec = user.pec;
        // this.sdi = user.sdi;
        // this.user_type = user.user_type;
        // this.receive_invoice = user.receive_invoice;
        // this.newsletter_subscribed = user.newsletter_subscribed;
        // this.created_at = user.created_at;
        if(user) Object.assign(this, user);
	}
}
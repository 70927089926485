
export const products = [
  {
    "InternalID": "4",
    "Code": "00001",
    "Description": "Condorino 28'' - 6V",
    "DescriptionHtml": "<p>\n  Vivi in città? Hai bisogno di muoverti rapidamente per lavoro? Vuoi restare in forma?\n  <b>Condorino ''28<\/b> è la bicicletta che fa per te: struttura maneggevole, design\n  accattivante, è il mezzo ideale non solo per viaggiare velocemente attraverso il\n  traffico cittadino, ma anche per le gite fuori porta del fine settimana. \n<\/p>\n\n<p>\n  Grazie alla buona resistenza di freni, cambio e ruote, nonché al comfort generato\n  dalla sella, puoi percorrere con tranquillità e sicurezza il caotico mondo di oggi.\n<\/p>\n\n<p>\n  <br /> \n<\/p>\n\n<p>\n  CONDORINO28'' possiede tutto quello che ti serve per non restare a piedi: \n<\/p>\n\n<ul>\n  <li>\n    <b>telaio <\/b>Hi-Ten; \n  <\/li> \n  <li>\n    <b>forcella<\/b> Hi-Ten; \n  <\/li> \n  <li>\n    <b>freni e leve<\/b> in alluminio V-Brake; \n  <\/li> \n  <li>\n    <b>cerchi<\/b> in alluminio; \n  <\/li> \n  <li>\n    <b>mozzi <\/b>in alluminio; \n  <\/li> \n  <li>\n    <b>cambio<\/b> Shimano TY21 6V; \n  <\/li> \n  <li>\n    <b>comando<\/b> Shimano Revoshift RS36 6V; \n  <\/li> \n  <li>\n    <b>ruota libera<\/b> Shimano TZ20 6V; \n  <\/li> \n  <li>\n    <b>piantone<\/b> in alluminio; \n  <\/li> \n  <li>\n    <b>piega manubrio<\/b> in alluminio \"Peudeot\"; \n  <\/li> \n  <li>\n    <b>ruote<\/b> 28&rdquo;; \n  <\/li> \n<\/ul>\n\n<p>\n  &nbsp;\n<\/p>",
    "Category": "Biciclette",
    "Subcategory": "City bike uomo",
    "Vat": {
      "@Perc": "22",
      "@Description": "Imponibile 22%",
      "#text": "22"
    },
    "Um": "kg",
    "NetPrice1": "228.69",
    "GrossPrice1": "279",
    "ProducerName": "Zecchini",
    "SizeUm": "cm",
    "WeightUm": "kg",
    "ManageWarehouse": "true",
    "AvailableQty": "4",
    "CustomField1": "28''",
    "CustomField2": "Shimano TY21 6V",
    "CustomField3": "Hi-Ten",
    "CustomField4": "Alluminio V-Brake",
    "Notes": "Vivi in città? Hai bisogno di muoverti rapidamente per lavoro? Vuoi restare in forma? CONDORINO CTB 28U 6V è la bicicletta che fa per te: struttura maneggevole, design accattivante, è il mezzo ideale.",
    "Variants": [
      {
        "Size": "38",
        "Color": "bianco",
        "Barcode": "00001/38/bia2",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "bianco",
        "Barcode": "00001/40/bia2",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "bianco",
        "Barcode": "00001/44/bia2",
        "AvailableQty": "0"
      },
      {
        "Size": "47",
        "Color": "bianco",
        "Barcode": "00001/47/bia2",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "bianco",
        "Barcode": "00001/52/bia2",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "bianco",
        "Barcode": "00001/54/bia2",
        "AvailableQty": "1"
      },
      {
        "Size": "57",
        "Color": "bianco",
        "Barcode": "00001/57/bia2",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "nero opaco",
        "Barcode": "00001/38/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "nero opaco",
        "Barcode": "00001/40/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "nero opaco",
        "Barcode": "00001/44/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "47",
        "Color": "nero opaco",
        "Barcode": "00001/47/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "nero opaco",
        "Barcode": "00001/52/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "nero opaco",
        "Barcode": "00001/54/nero",
        "AvailableQty": "1"
      },
      {
        "Size": "57",
        "Color": "nero opaco",
        "Barcode": "00001/57/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "panna",
        "Barcode": "00001/38/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "panna",
        "Barcode": "00001/40/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "panna",
        "Barcode": "00001/44/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "47",
        "Color": "panna",
        "Barcode": "00001/47/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "panna",
        "Barcode": "00001/52/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "panna",
        "Barcode": "00001/54/pann",
        "AvailableQty": "1"
      },
      {
        "Size": "57",
        "Color": "panna",
        "Barcode": "00001/57/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "verde inglese",
        "Barcode": "00001/38/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "verde inglese",
        "Barcode": "00001/40/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "verde inglese",
        "Barcode": "00001/44/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "47",
        "Color": "verde inglese",
        "Barcode": "00001/47/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "verde inglese",
        "Barcode": "00001/52/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "verde inglese",
        "Barcode": "00001/54/verd",
        "AvailableQty": "1"
      },
      {
        "Size": "57",
        "Color": "verde inglese",
        "Barcode": "00001/57/verd",
        "AvailableQty": "0"
      }
    ]
  },
  {
    "InternalID": "6",
    "Code": "00002",
    "Description": "Olanda 26'' - 6V",
    "DescriptionHtml": "<p>\n  Se sei una persona tranquilla, abitudinaria e che nel tempo libero vuole principalmente\n  rilassarsi, non puoi scegliere che il modello di bicicletta OLANDA 26 SF: bicicletta\n  da passeggio per eccellenza, pratica ed elegante, permette a chi vuole mantenersi\n  in forma pur nelle brevi distanze di ottenere il massimo risultato con il minimo\n  sforzo. \n<\/p>\n\n<p>\n  <br /> \n<\/p>\n\n<p>\n  In ogni caso, OLANDA 26'' non rinuncia, nella sua struttura, ai requisiti di tenuta\n  e resistenza: \n<\/p>\n\n<ul>\n  <li>\n    <b>telaio <\/b>Hi-Ten; \n  <\/li> \n  <li>\n    <b>forcella<\/b> Hi-Ten; \n  <\/li> \n  <li>\n    <b>freni e leve<\/b> in alluminio V-Brake; \n  <\/li> \n  <li>\n    <b>cerchi<\/b> in alluminio; \n  <\/li> \n  <li>\n    <b>mozzi <\/b>Hi-Ten; \n  <\/li> \n  <li>\n    <b>cambio<\/b> Shimano TY21 6V<b>;<\/b> \n  <\/li> \n  <li>\n    <b>piantone<\/b> Hi-Ten; \n  <\/li>  <li>\n    <b>piega manubrio<\/b> Hi-Ten; \n  <\/li> \n  <li>\n    <b>ruote<\/b> 26&rdquo;; \n  <\/li> \n<\/ul>\n\n<p>\n  &nbsp;\n<\/p>\n\n<p>\n  &nbsp;\n<\/p>",
    "Category": "Biciclette",
    "Subcategory": "City bike donna",
    "Vat": {
      "@Perc": "22",
      "@Description": "Imponibile 22%",
      "#text": "22"
    },
    "Um": "kg",
    "NetPrice1": "143.44",
    "GrossPrice1": "175",
    "ProducerName": "Zecchini",
    "SizeUm": "cm",
    "WeightUm": "kg",
    "ManageWarehouse": "true",
    "AvailableQty": "7",
    "CustomField1": "26''",
    "CustomField2": "Shimano TY21 6V",
    "CustomField3": "Hi-Ten",
    "CustomField4": "Alluminio V-Brake",
    "Notes": "Se sei una persona tranquilla, abitudinaria e che nel tempo libero vuole principalmente rilassarsi, non puoi scegliere che il modello di bicicletta OLANDA 26''",
    "Variants": [
      {
        "Size": "38",
        "Color": "amaranto",
        "Barcode": "00002/38/amar",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "amaranto",
        "Barcode": "00002/40/amar",
        "AvailableQty": "1"
      },
      {
        "Size": "44",
        "Color": "amaranto",
        "Barcode": "00002/44/amar",
        "AvailableQty": "0"
      },
      {
        "Size": "47",
        "Color": "amaranto",
        "Barcode": "00002/47/amar",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "amaranto",
        "Barcode": "00002/52/amar",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "amaranto",
        "Barcode": "00002/54/amar",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "amaranto",
        "Barcode": "00002/57/amar",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "argento",
        "Barcode": "00002/38/arge",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "argento",
        "Barcode": "00002/40/arge",
        "AvailableQty": "1"
      },
      {
        "Size": "44",
        "Color": "argento",
        "Barcode": "00002/44/arge",
        "AvailableQty": "0"
      },
      {
        "Size": "47",
        "Color": "argento",
        "Barcode": "00002/47/arge",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "argento",
        "Barcode": "00002/52/arge",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "argento",
        "Barcode": "00002/54/arge",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "argento",
        "Barcode": "00002/57/arge",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "bianco",
        "Barcode": "00002/38/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "bianco",
        "Barcode": "00002/40/bian",
        "AvailableQty": "1"
      },
      {
        "Size": "44",
        "Color": "bianco",
        "Barcode": "00002/44/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "47",
        "Color": "bianco",
        "Barcode": "00002/47/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "bianco",
        "Barcode": "00002/52/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "bianco",
        "Barcode": "00002/54/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "bianco",
        "Barcode": "00002/57/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "nero",
        "Barcode": "00002/38/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "nero",
        "Barcode": "00002/40/nero",
        "AvailableQty": "1"
      },
      {
        "Size": "44",
        "Color": "nero",
        "Barcode": "00002/44/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "47",
        "Color": "nero",
        "Barcode": "00002/47/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "nero",
        "Barcode": "00002/52/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "nero",
        "Barcode": "00002/54/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "nero",
        "Barcode": "00002/57/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "panna",
        "Barcode": "00002/38/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "panna",
        "Barcode": "00002/40/pann",
        "AvailableQty": "1"
      },
      {
        "Size": "44",
        "Color": "panna",
        "Barcode": "00002/44/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "47",
        "Color": "panna",
        "Barcode": "00002/47/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "panna",
        "Barcode": "00002/52/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "panna",
        "Barcode": "00002/54/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "panna",
        "Barcode": "00002/57/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "rosso",
        "Barcode": "00002/38/ross",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "rosso",
        "Barcode": "00002/40/ross",
        "AvailableQty": "1"
      },
      {
        "Size": "44",
        "Color": "rosso",
        "Barcode": "00002/44/ross",
        "AvailableQty": "0"
      },
      {
        "Size": "47",
        "Color": "rosso",
        "Barcode": "00002/47/ross",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "rosso",
        "Barcode": "00002/52/ross",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "rosso",
        "Barcode": "00002/54/ross",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "rosso",
        "Barcode": "00002/57/ross",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "verde inglese",
        "Barcode": "00002/38/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "verde inglese",
        "Barcode": "00002/40/verd",
        "AvailableQty": "1"
      },
      {
        "Size": "44",
        "Color": "verde inglese",
        "Barcode": "00002/44/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "47",
        "Color": "verde inglese",
        "Barcode": "00002/47/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "verde inglese",
        "Barcode": "00002/52/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "verde inglese",
        "Barcode": "00002/54/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "verde inglese",
        "Barcode": "00002/57/verd",
        "AvailableQty": "0"
      }
    ]
  },
  {
    "InternalID": "7",
    "Code": "00003",
    "Description": "Pieghevole 20''",
    "DescriptionHtml": "<p>\n  La bicicletta pieghevole 20&rdquo; è perfetta per chi vuole evadere temporaneamente\n  dalla routine del proprio ambiente di vita e di lavoro per percorrere ed esplorare\n  posti lontani, ma senza dover procurarsi una bicicletta in loco o essere costretto\n  a portarsene dietro da casa un modello troppo pesante. \n<\/p>\n\n<p>\n  <br /> \n<\/p>\n\n<p>\n  Le sue caratteristiche strutturali sono all&rsquo;insegna della praticità e della\n  versatilità: \n<\/p>\n\n<ul>\n  <li>\n    <b>telaio <\/b>Hi-Ten pieghevole; \n  <\/li> \n  <li>\n    <b>forcella<\/b> Hi-Ten; \n  <\/li> \n  <li>\n    <b>freni e leve<\/b> in alluminio V-Brake; \n  <\/li> \n  <li>\n    <b>cerchi<\/b> in alluminio; \n  <\/li> \n  <li>\n    <b>mozzi <\/b>in alluminio; \n  <\/li> \n  <li>\n    <b>ruote<\/b> 20&rdquo;; \n  <\/li> \n<\/ul>\n\n<p>\n  &nbsp;\n<\/p>\n\n<p>\n  &nbsp;\n<\/p>",
    "Category": "Biciclette",
    "Subcategory": "Biciclette pieghevoli",
    "Vat": {
      "@Perc": "22",
      "@Description": "Imponibile 22%",
      "#text": "22"
    },
    "NetPrice1": "163.93",
    "GrossPrice1": "200",
    "ProducerName": "Zecchini",
    "SizeUm": "cm",
    "WeightUm": "kg",
    "ManageWarehouse": "true",
    "AvailableQty": "7",
    "CustomField1": "20''",
    "CustomField2": "No",
    "CustomField3": "Hi-Ten Pieghevole",
    "CustomField4": "Alluminio V-Brake",
    "Notes": "La bicicletta Pieghevole 20” è perfetta per chi vuole evadere temporaneamente dalla routine del proprio ambiente di vita e di lavoro per percorrere ed esplorare posti lontani",
    "Variants": [
      {
        "Size": "38",
        "Color": "argento",
        "Barcode": "00003/38/arge",
        "AvailableQty": "1"
      },
      {
        "Size": "40",
        "Color": "argento",
        "Barcode": "00003/40/arge",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "argento",
        "Barcode": "00003/44/arge",
        "AvailableQty": "0"
      },
      {
        "Size": "47",
        "Color": "argento",
        "Barcode": "00003/47/arge",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "argento",
        "Barcode": "00003/52/arge",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "argento",
        "Barcode": "00003/54/arge",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "argento",
        "Barcode": "00003/57/arge",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "azzurro",
        "Barcode": "00003/38/azzu",
        "AvailableQty": "1"
      },
      {
        "Size": "40",
        "Color": "azzurro",
        "Barcode": "00003/40/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "azzurro",
        "Barcode": "00003/44/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "47",
        "Color": "azzurro",
        "Barcode": "00003/47/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "azzurro",
        "Barcode": "00003/52/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "azzurro",
        "Barcode": "00003/54/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "azzurro",
        "Barcode": "00003/57/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "bianco",
        "Barcode": "00003/38/bian",
        "AvailableQty": "1"
      },
      {
        "Size": "40",
        "Color": "bianco",
        "Barcode": "00003/40/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "bianco",
        "Barcode": "00003/44/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "47",
        "Color": "bianco",
        "Barcode": "00003/47/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "bianco",
        "Barcode": "00003/52/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "bianco",
        "Barcode": "00003/54/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "bianco",
        "Barcode": "00003/57/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "blu",
        "Barcode": "00003/38/blu",
        "AvailableQty": "1"
      },
      {
        "Size": "40",
        "Color": "blu",
        "Barcode": "00003/40/blu",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "blu",
        "Barcode": "00003/44/blu",
        "AvailableQty": "0"
      },
      {
        "Size": "47",
        "Color": "blu",
        "Barcode": "00003/47/blu",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "blu",
        "Barcode": "00003/52/blu",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "blu",
        "Barcode": "00003/54/blu",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "blu",
        "Barcode": "00003/57/blu",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "nero",
        "Barcode": "00003/38/nero",
        "AvailableQty": "1"
      },
      {
        "Size": "40",
        "Color": "nero",
        "Barcode": "00003/40/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "nero",
        "Barcode": "00003/44/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "47",
        "Color": "nero",
        "Barcode": "00003/47/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "nero",
        "Barcode": "00003/52/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "nero",
        "Barcode": "00003/54/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "nero",
        "Barcode": "00003/57/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "panna",
        "Barcode": "00003/38/pann",
        "AvailableQty": "1"
      },
      {
        "Size": "40",
        "Color": "panna",
        "Barcode": "00003/40/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "panna",
        "Barcode": "00003/44/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "47",
        "Color": "panna",
        "Barcode": "00003/47/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "panna",
        "Barcode": "00003/52/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "panna",
        "Barcode": "00003/54/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "panna",
        "Barcode": "00003/57/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "rosso",
        "Barcode": "00003/38/ross",
        "AvailableQty": "1"
      },
      {
        "Size": "40",
        "Color": "rosso",
        "Barcode": "00003/40/ross",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "rosso",
        "Barcode": "00003/44/ross",
        "AvailableQty": "0"
      },
      {
        "Size": "47",
        "Color": "rosso",
        "Barcode": "00003/47/ross",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "rosso",
        "Barcode": "00003/52/ross",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "rosso",
        "Barcode": "00003/54/ross",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "rosso",
        "Barcode": "00003/57/ross",
        "AvailableQty": "0"
      }
    ]
  },
  {
    "InternalID": "8",
    "Code": "00004",
    "Description": "Retrò 28'' - 6V",
    "DescriptionHtml": "<p>\n  Se per caso, oltre che del moto all&rsquo;aria aperta, sei anche un amante dell&rsquo;antico,\n  abbiamo disponibile per te un modello di bicicletta speciale:il modello Retrò per\n  uomini. Dedicato a te e a tutti quelli che come te non soltanto amano pedalare ma\n  desiderano anche farlo con un certo stile. \n<\/p>\n\n<p>\n  <br /> \n<\/p>\n\n<p>\n  L&rsquo;eleganza del design e della struttura sono testimoniati dalle sue caratteristiche\n  tecniche: \n<\/p>\n\n<ul>\n  <li>\n    <b>telaio <\/b>Hi-Ten; \n  <\/li> \n  <li>\n    <b>forcella<\/b> Hi-Ten \n  <\/li> \n  <li>\n    <b>freni e leve<\/b> in alluminio V-Brake; \n  <\/li> \n  <li>\n    <b>cerchi<\/b> in alluminio; \n  <\/li> \n  <li>\n    <b>mozzi <\/b>in alluminio; \n  <\/li> \n  <li>\n    <b>cambio<\/b> Shimano TY21 6V; \n  <\/li> \n  <li>\n    <b>comando<\/b> Shimano Revoshift RS36 6V; \n  <\/li> \n  <li>\n    <b>ruota libera<\/b> Shimano TZ20 6V; \n  <\/li> \n  <li>\n    <b>piantone<\/b> in alluminio; \n  <\/li> \n  <li>\n    <b>piega manubrio<\/b> in alluminio \"Torino\"; \n  <\/li> \n  <li>\n    <b>ruote<\/b> 28&rdquo;; \n  <\/li> \n<\/ul>",
    "Category": "Biciclette",
    "Subcategory": "City bike uomo",
    "Vat": {
      "@Perc": "22",
      "@Description": "Imponibile 22%",
      "#text": "22"
    },
    "NetPrice1": "221.31",
    "GrossPrice1": "270",
    "ProducerName": "Zecchini",
    "SizeUm": "cm",
    "WeightUm": "kg",
    "ManageWarehouse": "true",
    "AvailableQty": "15",
    "CustomField1": "28''",
    "CustomField2": "Shimano TY21 6V",
    "CustomField3": "Hi-Ten",
    "CustomField4": "Alluminio V-Brake",
    "Notes": "Se per caso, oltre che del moto all’aria aperta, sei anche un amante dell’antico, abbiamo disponibile per te un modello di bicicletta speciale:il modello Retrò per uomini.",
    "Variants": [
      {
        "Size": "38",
        "Color": "bianco e marrone",
        "Barcode": "00004/38/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "bianco e marrone",
        "Barcode": "00004/40/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "bianco e marrone",
        "Barcode": "00004/44/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "47",
        "Color": "bianco e marrone",
        "Barcode": "00004/47/bian",
        "AvailableQty": "1"
      },
      {
        "Size": "52",
        "Color": "bianco e marrone",
        "Barcode": "00004/52/bian",
        "AvailableQty": "1"
      },
      {
        "Size": "54",
        "Color": "bianco e marrone",
        "Barcode": "00004/54/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "bianco e marrone",
        "Barcode": "00004/57/bian",
        "AvailableQty": "1"
      },
      {
        "Size": "38",
        "Color": "grigio taurus e marrone",
        "Barcode": "00004/38/grig",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "grigio taurus e marrone",
        "Barcode": "00004/40/grig",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "grigio taurus e marrone",
        "Barcode": "00004/44/grig",
        "AvailableQty": "0"
      },
      {
        "Size": "47",
        "Color": "grigio taurus e marrone",
        "Barcode": "00004/47/grig",
        "AvailableQty": "1"
      },
      {
        "Size": "52",
        "Color": "grigio taurus e marrone",
        "Barcode": "00004/52/grig",
        "AvailableQty": "1"
      },
      {
        "Size": "54",
        "Color": "grigio taurus e marrone",
        "Barcode": "00004/54/grig",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "grigio taurus e marrone",
        "Barcode": "00004/57/grig",
        "AvailableQty": "1"
      },
      {
        "Size": "38",
        "Color": "nero e marrone",
        "Barcode": "00004/38/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "nero e marrone",
        "Barcode": "00004/40/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "nero e marrone",
        "Barcode": "00004/44/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "47",
        "Color": "nero e marrone",
        "Barcode": "00004/47/nero",
        "AvailableQty": "1"
      },
      {
        "Size": "52",
        "Color": "nero e marrone",
        "Barcode": "00004/52/nero",
        "AvailableQty": "1"
      },
      {
        "Size": "54",
        "Color": "nero e marrone",
        "Barcode": "00004/54/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "nero e marrone",
        "Barcode": "00004/57/nero",
        "AvailableQty": "1"
      },
      {
        "Size": "38",
        "Color": "panna e marrone",
        "Barcode": "00004/38/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "panna e marrone",
        "Barcode": "00004/40/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "panna e marrone",
        "Barcode": "00004/44/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "47",
        "Color": "panna e marrone",
        "Barcode": "00004/47/pann",
        "AvailableQty": "1"
      },
      {
        "Size": "52",
        "Color": "panna e marrone",
        "Barcode": "00004/52/pann",
        "AvailableQty": "1"
      },
      {
        "Size": "54",
        "Color": "panna e marrone",
        "Barcode": "00004/54/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "panna e marrone",
        "Barcode": "00004/57/pann",
        "AvailableQty": "1"
      },
      {
        "Size": "38",
        "Color": "verde inglese e marrone",
        "Barcode": "00004/38/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "verde inglese e marrone",
        "Barcode": "00004/40/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "verde inglese e marrone",
        "Barcode": "00004/44/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "47",
        "Color": "verde inglese e marrone",
        "Barcode": "00004/47/verd",
        "AvailableQty": "1"
      },
      {
        "Size": "52",
        "Color": "verde inglese e marrone",
        "Barcode": "00004/52/verd",
        "AvailableQty": "1"
      },
      {
        "Size": "54",
        "Color": "verde inglese e marrone",
        "Barcode": "00004/54/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "verde inglese e marrone",
        "Barcode": "00004/57/verd",
        "AvailableQty": "1"
      }
    ]
  },
  {
    "InternalID": "9",
    "Code": "00005",
    "Description": "Retrò 28'' - 6V",
    "DescriptionHtml": "<p>\n  Se per caso, oltre che del moto all&rsquo;aria aperta, sei anche un amante dell&rsquo;antico,\n  abbiamo disponibile per te un modello di bicicletta speciale:il modello Retrò 28''\n  per donne. Dedicato a te e a tutti quelli che come te non soltanto amano pedalare\n  ma desiderano anche farlo con un certo stile. \n<\/p>\n\n<p>\n  <br /> \n<\/p>\n\n<p>\n  L&rsquo;eleganza del design e della struttura sono testimoniati dalle sue caratteristiche\n  tecniche: \n<\/p>\n\n<ul>\n  <li>\n    <b>telaio <\/b>Hi-Ten; \n  <\/li> \n  <li>\n    <b>forcella<\/b> Hi-Ten; \n  <\/li> \n  <li>\n    <b>freni e leve<\/b> in alluminio V-Brake; \n  <\/li> \n  <li>\n    <b>cerchi<\/b> in alluminio; \n  <\/li> \n  <li>\n    <b>mozzi <\/b>in alluminio; \n  <\/li> \n  <li>\n    <b>cambio<\/b> Shimano TY21 6V, \n  <\/li> \n  <li>\n    <b>comando<\/b> Shimano Revoshift RS36 6V; \n  <\/li> \n  <li>\n    <b>ruota libera<\/b> Shimano TZ20 6V; \n  <\/li> \n  <li>\n    <b>piantone<\/b> in alluminio; \n  <\/li> \n  <li>\n    <b>piega manubrio<\/b> in alluminio \"Torino\"; \n  <\/li> \n  <li>\n    <b>ruote<\/b> 28&rdquo;; \n  <\/li> \n<\/ul>\n\n<p>\n  &nbsp;\n<\/p>",
    "Category": "Biciclette",
    "Subcategory": "City bike donna",
    "Vat": {
      "@Perc": "22",
      "@Description": "Imponibile 22%",
      "#text": "22"
    },
    "NetPrice1": "221.31",
    "GrossPrice1": "270",
    "ProducerName": "Zecchini",
    "SizeUm": "cm",
    "WeightUm": "kg",
    "ManageWarehouse": "true",
    "AvailableQty": "5",
    "CustomField1": "28''",
    "CustomField2": "Shimano TY21 6V",
    "CustomField3": "Hi-Ten",
    "CustomField4": "Alluminio V-Brake",
    "Notes": "Se per caso, oltre che del moto all’aria aperta, sei anche un amante dell’antico, abbiamo disponibile per te un modello di bicicletta speciale:il modello Retrò 28'' per donne.",
    "Variants": [
      {
        "Size": "38",
        "Color": "bianco e marrone",
        "Barcode": "00005/38/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "bianco e marrone",
        "Barcode": "00005/40/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "bianco e marrone",
        "Barcode": "00005/44/bian",
        "AvailableQty": "1"
      },
      {
        "Size": "47",
        "Color": "bianco e marrone",
        "Barcode": "00005/47/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "bianco e marrone",
        "Barcode": "00005/52/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "bianco e marrone",
        "Barcode": "00005/54/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "bianco e marrone",
        "Barcode": "00005/57/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "grigio taurus e marrone",
        "Barcode": "00005/38/grig",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "grigio taurus e marrone",
        "Barcode": "00005/40/grig",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "grigio taurus e marrone",
        "Barcode": "00005/44/grig",
        "AvailableQty": "1"
      },
      {
        "Size": "47",
        "Color": "grigio taurus e marrone",
        "Barcode": "00005/47/grig",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "grigio taurus e marrone",
        "Barcode": "00005/52/grig",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "grigio taurus e marrone",
        "Barcode": "00005/54/grig",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "grigio taurus e marrone",
        "Barcode": "00005/57/grig",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "nero e marrone",
        "Barcode": "00005/38/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "nero e marrone",
        "Barcode": "00005/40/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "nero e marrone",
        "Barcode": "00005/44/nero",
        "AvailableQty": "1"
      },
      {
        "Size": "47",
        "Color": "nero e marrone",
        "Barcode": "00005/47/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "nero e marrone",
        "Barcode": "00005/52/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "nero e marrone",
        "Barcode": "00005/54/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "nero e marrone",
        "Barcode": "00005/57/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "panna e marrone",
        "Barcode": "00005/38/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "panna e marrone",
        "Barcode": "00005/40/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "panna e marrone",
        "Barcode": "00005/44/pann",
        "AvailableQty": "1"
      },
      {
        "Size": "47",
        "Color": "panna e marrone",
        "Barcode": "00005/47/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "panna e marrone",
        "Barcode": "00005/52/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "panna e marrone",
        "Barcode": "00005/54/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "panna e marrone",
        "Barcode": "00005/57/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "verde inglese e marrone",
        "Barcode": "00005/38/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "verde inglese e marrone",
        "Barcode": "00005/40/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "verde inglese e marrone",
        "Barcode": "00005/44/verd",
        "AvailableQty": "1"
      },
      {
        "Size": "47",
        "Color": "verde inglese e marrone",
        "Barcode": "00005/47/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "verde inglese e marrone",
        "Barcode": "00005/52/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "verde inglese e marrone",
        "Barcode": "00005/54/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "verde inglese e marrone",
        "Barcode": "00005/57/verd",
        "AvailableQty": "0"
      }
    ]
  },
  {
    "InternalID": "10",
    "Code": "00006",
    "Description": "Rondine 28'' - 6V",
    "DescriptionHtml": "<p>\n  Per voi, ancora per voi, amanti del vintage, è disponibile un classico del genere:\n  la bicicletta CTB 28D 6V rondine. Proprio lei, la vecchia rondinella della nonna:\n  ve la ricordate? Dedicata a voi che quando pedalate desiderate farlo immersi nei\n  ricordi, in un&rsquo;atmosfera quasi familiare, con amici o parenti, nei posti che\n  conoscete da sempre. \n<\/p>\n\n<p>\n  <br /> \n<\/p>\n\n<p>\n  Il modello CTB 28D 6V ha tutto per servirvi: \n<\/p>\n\n<ul>\n  <li>\n    <b>telaio <\/b>Hi-Ten; \n  <\/li> \n  <li>\n    <b>forcella<\/b> Hi-Ten; \n  <\/li> \n  <li>\n    <b>freni e leve<\/b> in alluminio V-Brake; \n  <\/li> \n  <li>\n    <b>cerchi<\/b> in alluminio; \n  <\/li> \n  <li>\n    <b>mozzi <\/b>in alluminio; \n  <\/li> \n  <li>\n    <b>cambio<\/b> Shimano TY21 6V; \n  <\/li> \n  <li>\n    <b>comando<\/b> Shimano Revoshift RS36 6V; \n  <\/li> \n  <li>\n    <b>ruota libera<\/b> Shimano TZ20 6V; \n  <\/li> \n  <li>\n    <b>piantone<\/b> in alluminio; \n  <\/li> \n  <li>\n    <b>piega manubrio<\/b> in alluminio \"Torino\"; \n  <\/li> \n  <li>\n    <b>ruote<\/b> 28&rdquo;; \n  <\/li> \n<\/ul>\n\n<p>\n  &nbsp;\n<\/p>",
    "Category": "Biciclette",
    "Subcategory": "City bike donna",
    "Vat": {
      "@Perc": "22",
      "@Description": "Imponibile 22%",
      "#text": "22"
    },
    "NetPrice1": "228.69",
    "GrossPrice1": "279",
    "ProducerName": "Zecchini",
    "SizeUm": "cm",
    "WeightUm": "kg",
    "ManageWarehouse": "true",
    "AvailableQty": "5",
    "CustomField1": "28''",
    "CustomField2": "Shimano TY21 6V",
    "CustomField3": "Hi-Ten",
    "CustomField4": "Alluminio V-Brake",
    "Notes": "Per voi, ancora per voi, amanti del vintage, è disponibile un classico del genere: la bicicletta CTB 28D 6V rondine. Proprio lei, la vecchia rondinella della nonna: ve la ricordate?",
    "Variants": [
      {
        "Size": "38",
        "Color": "azzurro",
        "Barcode": "00006/38/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "azzurro",
        "Barcode": "00006/40/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "azzurro",
        "Barcode": "00006/44/azzu",
        "AvailableQty": "1"
      },
      {
        "Size": "47",
        "Color": "azzurro",
        "Barcode": "00006/47/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "azzurro",
        "Barcode": "00006/52/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "azzurro",
        "Barcode": "00006/54/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "azzurro",
        "Barcode": "00006/57/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "bianco",
        "Barcode": "00006/38/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "bianco",
        "Barcode": "00006/40/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "bianco",
        "Barcode": "00006/44/bian",
        "AvailableQty": "1"
      },
      {
        "Size": "47",
        "Color": "bianco",
        "Barcode": "00006/47/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "bianco",
        "Barcode": "00006/52/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "bianco",
        "Barcode": "00006/54/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "bianco",
        "Barcode": "00006/57/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "nero opaco",
        "Barcode": "00006/38/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "nero opaco",
        "Barcode": "00006/40/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "nero opaco",
        "Barcode": "00006/44/nero",
        "AvailableQty": "1"
      },
      {
        "Size": "47",
        "Color": "nero opaco",
        "Barcode": "00006/47/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "nero opaco",
        "Barcode": "00006/52/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "nero opaco",
        "Barcode": "00006/54/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "nero opaco",
        "Barcode": "00006/57/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "panna",
        "Barcode": "00006/38/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "panna",
        "Barcode": "00006/40/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "panna",
        "Barcode": "00006/44/pann",
        "AvailableQty": "1"
      },
      {
        "Size": "47",
        "Color": "panna",
        "Barcode": "00006/47/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "panna",
        "Barcode": "00006/52/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "panna",
        "Barcode": "00006/54/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "panna",
        "Barcode": "00006/57/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "verde inglese",
        "Barcode": "00006/38/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "verde inglese",
        "Barcode": "00006/40/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "verde inglese",
        "Barcode": "00006/44/verd",
        "AvailableQty": "1"
      },
      {
        "Size": "47",
        "Color": "verde inglese",
        "Barcode": "00006/47/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "verde inglese",
        "Barcode": "00006/52/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "verde inglese",
        "Barcode": "00006/54/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "verde inglese",
        "Barcode": "00006/57/verd",
        "AvailableQty": "0"
      }
    ]
  },
  {
    "InternalID": "13",
    "Code": "00009",
    "Description": "Vintage 28'' - 21V",
    "DescriptionHtml": "<p>\n  Per tutte le appassionate del genere, ecco la bicicletta Vintage 28'': il nome non\n  deve però trarre in inganno perché, nonostante l&rsquo;apparenza, le sue capacità\n  di tenuta e resistenza nelle brevi distanze sono insuperabili. E&rsquo; l&rsquo;ideale\n  per chi vuole una bicicletta pratica ma allo stesso tempo&ldquo;da collezione&rdquo;.\n<\/p>\n\n<p>\n  &nbsp;\n<\/p>\n\n<p>\n  Possiede: \n<\/p>\n\n<ul>\n  <li>\n    <b>telaio <\/b>in alluminio; \n  <\/li> \n  <li>\n    <b>forcella<\/b> Hi-Ten; \n  <\/li> \n  <li>\n    <b>freni e leve<\/b> in alluminio V-Brake; \n  <\/li> \n  <li>\n    <b>cerchi<\/b> in alluminio; \n  <\/li> \n  <li>\n    <b>mozzi <\/b>in alluminio; \n  <\/li> \n  <li>\n    <b>cambio<\/b> Shimano TX35 7V; \n  <\/li> \n  <li>\n    <b>deragliatore<\/b> Shimano Revo RS36 21V; \n  <\/li> \n  <li>\n    <b>comando<\/b> Shimano Revoshift RS36 21V; \n  <\/li> \n  <li>\n    <b>ruota libera<\/b> Shimano TZ21 7V; \n  <\/li> \n  <li>\n    <b>guarnitura<\/b> in alluminio; \n  <\/li> \n  <li>\n    <b>canotto<\/b> in alluminio; \n  <\/li> \n  <li>\n    <b>collarino<\/b> in alluminio; \n  <\/li> \n  <li>\n    <b>piantone<\/b> in alluminio regolabile; \n  <\/li> \n  <li>\n    <b>piega manubrio<\/b> in alluminio; \n  <\/li> \n  <li>\n    <b>ruote<\/b> 28&rdquo;; \n  <\/li> \n<\/ul>\n\n<p>\n  &nbsp;\n<\/p>",
    "Category": "Biciclette",
    "Subcategory": "City bike donna",
    "Vat": {
      "@Perc": "22",
      "@Description": "Imponibile 22%",
      "#text": "22"
    },
    "NetPrice1": "270.49",
    "GrossPrice1": "330",
    "ProducerName": "Zecchini",
    "SizeUm": "cm",
    "WeightUm": "kg",
    "ManageWarehouse": "true",
    "AvailableQty": "5",
    "CustomField1": "28''",
    "CustomField2": "Shimano TX35 7V",
    "CustomField3": "Alluminio",
    "CustomField4": "Alluminio V-Brake",
    "Notes": "Per tutte le appassionate del genere, ecco la bicicletta Vintage 28'': il nome non deve però trarre in inganno perché, nonostante l’apparenza, le sue capacità di tenuta e resistenza nelle brevi distanze sono insuperabili.",
    "Variants": [
      {
        "Size": "38",
        "Color": "azzurro pastello",
        "Barcode": "00009/38/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "azzurro pastello",
        "Barcode": "00009/40/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "azzurro pastello",
        "Barcode": "00009/44/azzu",
        "AvailableQty": "1"
      },
      {
        "Size": "47",
        "Color": "azzurro pastello",
        "Barcode": "00009/47/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "azzurro pastello",
        "Barcode": "00009/52/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "azzurro pastello",
        "Barcode": "00009/54/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "azzurro pastello",
        "Barcode": "00009/57/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "bianco",
        "Barcode": "00009/38/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "bianco",
        "Barcode": "00009/40/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "bianco",
        "Barcode": "00009/44/bian",
        "AvailableQty": "1"
      },
      {
        "Size": "47",
        "Color": "bianco",
        "Barcode": "00009/47/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "bianco",
        "Barcode": "00009/52/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "bianco",
        "Barcode": "00009/54/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "bianco",
        "Barcode": "00009/57/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "nero opaco",
        "Barcode": "00009/38/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "nero opaco",
        "Barcode": "00009/40/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "nero opaco",
        "Barcode": "00009/44/nero",
        "AvailableQty": "1"
      },
      {
        "Size": "47",
        "Color": "nero opaco",
        "Barcode": "00009/47/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "nero opaco",
        "Barcode": "00009/52/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "nero opaco",
        "Barcode": "00009/54/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "nero opaco",
        "Barcode": "00009/57/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "panna",
        "Barcode": "00009/38/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "panna",
        "Barcode": "00009/40/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "panna",
        "Barcode": "00009/44/pann",
        "AvailableQty": "1"
      },
      {
        "Size": "47",
        "Color": "panna",
        "Barcode": "00009/47/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "panna",
        "Barcode": "00009/52/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "panna",
        "Barcode": "00009/54/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "panna",
        "Barcode": "00009/57/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "verde inglese",
        "Barcode": "00009/38/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "verde inglese",
        "Barcode": "00009/40/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "verde inglese",
        "Barcode": "00009/44/verd",
        "AvailableQty": "1"
      },
      {
        "Size": "47",
        "Color": "verde inglese",
        "Barcode": "00009/47/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "verde inglese",
        "Barcode": "00009/52/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "verde inglese",
        "Barcode": "00009/54/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "verde inglese",
        "Barcode": "00009/57/verd",
        "AvailableQty": "0"
      }
    ]
  },
  {
    "InternalID": "14",
    "Code": "00010",
    "Description": "Vintage 28'' - 21V",
    "DescriptionHtml": "<p>\n  Per tutti gli appassionati del genere, ecco la bicicletta Vintage 28'': il nome\n  non deve però trarre in inganno perché, nonostante l&rsquo;apparenza, le sue capacità\n  di tenuta e resistenza nelle brevi distanze sono insuperabili. E&rsquo; l&rsquo;ideale\n  per chi vuole una bicicletta pratica ma allo stesso tempo&ldquo;da collezione&rdquo;.\n<\/p>\n\n<p>\n  &nbsp;\n<\/p>\n\n<p>\n  Possiede: \n<\/p>\n\n<ul>\n  <li>\n    <b>telaio <\/b>in alluminio; \n  <\/li> \n  <li>\n    <b>forcella<\/b> Hi-Ten; \n  <\/li> \n  <li>\n    <b>freni e leve<\/b> in alluminio V-Brake; \n  <\/li> \n  <li>\n    <b>cerchi<\/b> in alluminio; \n  <\/li> \n  <li>\n    <b>mozzi <\/b>in alluminio; \n  <\/li> \n  <li>\n    <b>cambio<\/b> Shimano TX35 7V; \n  <\/li> \n  <li>\n    <b>deragliatore<\/b> Shimano Revo RS36 21V; \n  <\/li> \n  <li>\n    <b>comando<\/b> Shimano Revoshift RS36 21V; \n  <\/li> \n  <li>\n    <b>ruota libera<\/b> Shimano TZ21 7V; \n  <\/li> \n  <li>\n    <b>guarnitura<\/b> in alluminio; \n  <\/li> \n  <li>\n    <b>canotto<\/b> in alluminio; \n  <\/li> \n  <li>\n    <b>collarino<\/b> in alluminio; \n  <\/li> \n  <li>\n    <b>piantone<\/b> in alluminio regolabile; \n  <\/li> \n  <li>\n    <b>piega manubrio<\/b> in alluminio; \n  <\/li> \n  <li>\n    <b>ruote<\/b> 28&rdquo;; \n  <\/li> \n<\/ul>\n\n<p>\n  &nbsp;\n<\/p>",
    "Category": "Biciclette",
    "Subcategory": "City bike uomo",
    "Vat": {
      "@Perc": "22",
      "@Description": "Imponibile 22%",
      "#text": "22"
    },
    "NetPrice1": "270.49",
    "GrossPrice1": "330",
    "ProducerName": "Zecchini",
    "SizeUm": "cm",
    "WeightUm": "kg",
    "ManageWarehouse": "true",
    "AvailableQty": "5",
    "CustomField1": "28''",
    "CustomField2": "Shimano TX35 7V",
    "CustomField3": "Alluminio",
    "CustomField4": "Alluminio V-Brake",
    "Notes": "Per tutti gli appassionati del genere, ecco la bicicletta Vintage 28'': il nome non deve però trarre in inganno perché, nonostante l’apparenza, le sue capacità di tenuta e resistenza nelle brevi distanze sono insuperabili.",
    "Variants": [
      {
        "Size": "38",
        "Color": "azzurro pastello",
        "Barcode": "00010/38/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "azzurro pastello",
        "Barcode": "00010/40/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "azzurro pastello",
        "Barcode": "00010/44/azzu",
        "AvailableQty": "1"
      },
      {
        "Size": "47",
        "Color": "azzurro pastello",
        "Barcode": "00010/47/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "azzurro pastello",
        "Barcode": "00010/52/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "azzurro pastello",
        "Barcode": "00010/54/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "azzurro pastello",
        "Barcode": "00010/57/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "bianco",
        "Barcode": "00010/38/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "bianco",
        "Barcode": "00010/40/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "bianco",
        "Barcode": "00010/44/bian",
        "AvailableQty": "1"
      },
      {
        "Size": "47",
        "Color": "bianco",
        "Barcode": "00010/47/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "bianco",
        "Barcode": "00010/52/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "bianco",
        "Barcode": "00010/54/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "bianco",
        "Barcode": "00010/57/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "nero opaco",
        "Barcode": "00010/38/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "nero opaco",
        "Barcode": "00010/40/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "nero opaco",
        "Barcode": "00010/44/nero",
        "AvailableQty": "1"
      },
      {
        "Size": "47",
        "Color": "nero opaco",
        "Barcode": "00010/47/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "nero opaco",
        "Barcode": "00010/52/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "nero opaco",
        "Barcode": "00010/54/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "nero opaco",
        "Barcode": "00010/57/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "panna",
        "Barcode": "00010/38/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "panna",
        "Barcode": "00010/40/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "panna",
        "Barcode": "00010/44/pann",
        "AvailableQty": "1"
      },
      {
        "Size": "47",
        "Color": "panna",
        "Barcode": "00010/47/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "panna",
        "Barcode": "00010/52/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "panna",
        "Barcode": "00010/54/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "panna",
        "Barcode": "00010/57/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "verde inglese",
        "Barcode": "00010/38/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "verde inglese",
        "Barcode": "00010/40/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "verde inglese",
        "Barcode": "00010/44/verd",
        "AvailableQty": "1"
      },
      {
        "Size": "47",
        "Color": "verde inglese",
        "Barcode": "00010/47/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "verde inglese",
        "Barcode": "00010/52/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "verde inglese",
        "Barcode": "00010/54/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "verde inglese",
        "Barcode": "00010/57/verd",
        "AvailableQty": "0"
      }
    ]
  },
  {
    "InternalID": "11",
    "Code": "00007",
    "Description": "Vintage 28'' - 6V",
    "DescriptionHtml": "<p>\n  Per tutte le appassionate del genere, ecco la bicicletta Vintage 28'': il nome non\n  deve però trarre in inganno perché, nonostante l&rsquo;apparenza, le sue capacità\n  di tenuta e resistenza nelle brevi distanze sono insuperabili. E&rsquo; l&rsquo;ideale\n  per chi vuole una bicicletta pratica ma allo stesso tempo&ldquo;da collezione&rdquo;.\n<\/p>\n\n<p>\n  &nbsp;\n<\/p>\n\n<p>\n  Possiede: \n<\/p>\n\n<ul>\n  <li>\n    <b>telaio <\/b>in alluminio; \n  <\/li> \n  <li>\n    <b>forcella<\/b> Hi-Ten; \n  <\/li> \n  <li>\n    <b>freni e leve<\/b> in alluminio V-Brake; \n  <\/li> \n  <li>\n    <b>cerchi<\/b> in alluminio; \n  <\/li> \n  <li>\n    <b>mozzi <\/b>in alluminio; \n  <\/li> \n  <li>\n    <b>cambio<\/b> Shimano TY21 6V; \n  <\/li> \n  <li>\n    <b>comando<\/b> Shimano Revoshift RS36 6V; \n  <\/li> \n  <li>\n    <b>ruota libera<\/b> Shimano TZ20 6V; \n  <\/li> \n  <li>\n    <b>guarnitura<\/b> in alluminio; \n  <\/li> \n  <li>\n    <b>canotto<\/b> in alluminio; \n  <\/li> \n  <li>\n    <b>collarino<\/b> in alluminio; \n  <\/li> \n  <li>\n    <b>piantone<\/b> in alluminio regolabile; \n  <\/li> \n  <li>\n    <b>piega manubrio<\/b> in alluminio; \n  <\/li> \n  <li>\n    <b>ruote<\/b> 28&rdquo;; \n  <\/li> \n<\/ul>\n\n<p>\n  &nbsp;\n<\/p>",
    "Category": "Biciclette",
    "Subcategory": "City bike donna",
    "Vat": {
      "@Perc": "22",
      "@Description": "Imponibile 22%",
      "#text": "22"
    },
    "NetPrice1": "237.7",
    "GrossPrice1": "290",
    "ProducerName": "Zecchini",
    "SizeUm": "cm",
    "WeightUm": "kg",
    "ManageWarehouse": "true",
    "AvailableQty": "5",
    "CustomField1": "28''",
    "CustomField2": "Shimano TY21 6V",
    "CustomField3": "Alluminio",
    "CustomField4": "Alluminio V-Brake",
    "Notes": "Per tutte le appassionate del genere, ecco la bicicletta Vintage 28'': il nome non deve però trarre in inganno perché, nonostante l’apparenza, le sue capacità di tenuta e resistenza nelle brevi distanze sono insuperabili.",
    "Variants": [
      {
        "Size": "38",
        "Color": "azzurro pastello",
        "Barcode": "00007/38/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "azzurro pastello",
        "Barcode": "00007/40/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "azzurro pastello",
        "Barcode": "00007/44/azzu",
        "AvailableQty": "1"
      },
      {
        "Size": "47",
        "Color": "azzurro pastello",
        "Barcode": "00007/47/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "azzurro pastello",
        "Barcode": "00007/52/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "azzurro pastello",
        "Barcode": "00007/54/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "azzurro pastello",
        "Barcode": "00007/57/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "bianco",
        "Barcode": "00007/38/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "bianco",
        "Barcode": "00007/40/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "bianco",
        "Barcode": "00007/44/bian",
        "AvailableQty": "1"
      },
      {
        "Size": "47",
        "Color": "bianco",
        "Barcode": "00007/47/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "bianco",
        "Barcode": "00007/52/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "bianco",
        "Barcode": "00007/54/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "bianco",
        "Barcode": "00007/57/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "nero opaco",
        "Barcode": "00007/38/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "nero opaco",
        "Barcode": "00007/40/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "nero opaco",
        "Barcode": "00007/44/nero",
        "AvailableQty": "1"
      },
      {
        "Size": "47",
        "Color": "nero opaco",
        "Barcode": "00007/47/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "nero opaco",
        "Barcode": "00007/52/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "nero opaco",
        "Barcode": "00007/54/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "nero opaco",
        "Barcode": "00007/57/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "panna",
        "Barcode": "00007/38/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "panna",
        "Barcode": "00007/40/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "panna",
        "Barcode": "00007/44/pann",
        "AvailableQty": "1"
      },
      {
        "Size": "47",
        "Color": "panna",
        "Barcode": "00007/47/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "panna",
        "Barcode": "00007/52/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "panna",
        "Barcode": "00007/54/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "panna",
        "Barcode": "00007/57/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "verde inglese",
        "Barcode": "00007/38/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "verde inglese",
        "Barcode": "00007/40/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "verde inglese",
        "Barcode": "00007/44/verd",
        "AvailableQty": "1"
      },
      {
        "Size": "47",
        "Color": "verde inglese",
        "Barcode": "00007/47/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "verde inglese",
        "Barcode": "00007/52/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "verde inglese",
        "Barcode": "00007/54/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "verde inglese",
        "Barcode": "00007/57/verd",
        "AvailableQty": "0"
      }
    ]
  },
  {
    "InternalID": "12",
    "Code": "00008",
    "Description": "Vintage 28'' - 6V",
    "DescriptionHtml": "<p>\n  Per tutte le appassionate del genere, ecco la bicicletta Vintage 28'': il nome non\n  deve però trarre in inganno perché, nonostante l&rsquo;apparenza, le sue capacità\n  di tenuta e resistenza nelle brevi distanze sono insuperabili. E&rsquo; l&rsquo;ideale\n  per chi vuole una bicicletta pratica ma allo stesso tempo&ldquo;da collezione&rdquo;.\n<\/p>\n\n<p>\n  &nbsp;\n<\/p>\n\n<p>\n  Possiede: \n<\/p>\n\n<ul>\n  <li>\n    <b>telaio <\/b>in alluminio; \n  <\/li> \n  <li>\n    <b>forcella<\/b> Hi-Ten; \n  <\/li> \n  <li>\n    <b>freni e leve<\/b> in alluminio V-Brake; \n  <\/li> \n  <li>\n    <b>cerchi<\/b> in alluminio; \n  <\/li> \n  <li>\n    <b>mozzi <\/b>in alluminio; \n  <\/li> \n  <li>\n    <b>cambio<\/b> Shimano TY21 6V; \n  <\/li> \n  <li>\n    <b>comando<\/b> Shimano Revoshift RS36 6V; \n  <\/li> \n  <li>\n    <b>ruota libera<\/b> Shimano TZ20 6V; \n  <\/li> \n  <li>\n    <b>guarnitura<\/b> in alluminio; \n  <\/li> \n  <li>\n    <b>canotto<\/b> in alluminio; \n  <\/li> \n  <li>\n    <b>collarino<\/b> in alluminio; \n  <\/li> \n  <li>\n    <b>piantone<\/b> in alluminio regolabile; \n  <\/li> \n  <li>\n    <b>piega manubrio<\/b> in alluminio; \n  <\/li> \n  <li>\n    <b>ruote<\/b> 28&rdquo;; \n  <\/li> \n<\/ul>\n\n<p>\n  &nbsp;\n<\/p>",
    "Category": "Biciclette",
    "Subcategory": "City bike uomo",
    "Vat": {
      "@Perc": "22",
      "@Description": "Imponibile 22%",
      "#text": "22"
    },
    "NetPrice1": "237.7",
    "GrossPrice1": "290",
    "ProducerName": "Zecchini",
    "SizeUm": "cm",
    "WeightUm": "kg",
    "ManageWarehouse": "true",
    "AvailableQty": "5",
    "CustomField1": "28''",
    "CustomField2": "Shimano TY21 6V",
    "CustomField3": "Alluminio",
    "CustomField4": "Alluminio V-Brake",
    "Notes": "Per tutte le appassionate del genere, ecco la bicicletta Vintage 28'': il nome non deve però trarre in inganno perché, nonostante l’apparenza, le sue capacità di tenuta e resistenza nelle brevi distanze sono insuperabili.",
    "Variants": [
      {
        "Size": "38",
        "Color": "azzurro pastello",
        "Barcode": "00008/38/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "azzurro pastello",
        "Barcode": "00008/40/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "azzurro pastello",
        "Barcode": "00008/44/azzu",
        "AvailableQty": "1"
      },
      {
        "Size": "47",
        "Color": "azzurro pastello",
        "Barcode": "00008/47/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "azzurro pastello",
        "Barcode": "00008/52/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "azzurro pastello",
        "Barcode": "00008/54/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "azzurro pastello",
        "Barcode": "00008/57/azzu",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "bianco",
        "Barcode": "00008/38/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "bianco",
        "Barcode": "00008/40/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "bianco",
        "Barcode": "00008/44/bian",
        "AvailableQty": "1"
      },
      {
        "Size": "47",
        "Color": "bianco",
        "Barcode": "00008/47/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "bianco",
        "Barcode": "00008/52/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "bianco",
        "Barcode": "00008/54/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "bianco",
        "Barcode": "00008/57/bian",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "nero opaco",
        "Barcode": "00008/38/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "nero opaco",
        "Barcode": "00008/40/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "nero opaco",
        "Barcode": "00008/44/nero",
        "AvailableQty": "1"
      },
      {
        "Size": "47",
        "Color": "nero opaco",
        "Barcode": "00008/47/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "nero opaco",
        "Barcode": "00008/52/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "nero opaco",
        "Barcode": "00008/54/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "nero opaco",
        "Barcode": "00008/57/nero",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "panna",
        "Barcode": "00008/38/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "panna",
        "Barcode": "00008/40/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "panna",
        "Barcode": "00008/44/pann",
        "AvailableQty": "1"
      },
      {
        "Size": "47",
        "Color": "panna",
        "Barcode": "00008/47/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "panna",
        "Barcode": "00008/52/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "panna",
        "Barcode": "00008/54/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "panna",
        "Barcode": "00008/57/pann",
        "AvailableQty": "0"
      },
      {
        "Size": "38",
        "Color": "verde inglese",
        "Barcode": "00008/38/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "40",
        "Color": "verde inglese",
        "Barcode": "00008/40/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "44",
        "Color": "verde inglese",
        "Barcode": "00008/44/verd",
        "AvailableQty": "1"
      },
      {
        "Size": "47",
        "Color": "verde inglese",
        "Barcode": "00008/47/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "52",
        "Color": "verde inglese",
        "Barcode": "00008/52/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "54",
        "Color": "verde inglese",
        "Barcode": "00008/54/verd",
        "AvailableQty": "0"
      },
      {
        "Size": "57",
        "Color": "verde inglese",
        "Barcode": "00008/57/verd",
        "AvailableQty": "0"
      }
    ]
  }
]
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.css']
})
export class ActivateAccountComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute, 
    private userService: UserService, 
    private cartService: CartService,
    private router: Router,
    private _matSnackBar: MatSnackBar) {
    }

  isLoading: boolean = false;  
  guestToken: string;

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if(params.token){
        this.isLoading = true;
        this.guestToken = localStorage.getItem('zecchini_token');
        let formData = new FormData();
        formData.append('token', params.token);

        this.userService.activateAccount(formData).subscribe((res: any) => {

          if(localStorage.getItem('guest_user')) localStorage.removeItem('guest_user');
          if(localStorage.getItem('guest_addresses')) localStorage.removeItem('guest_addresses');
          this.router.navigate(['/login']);

          // localStorage.setItem('zecchini_token', res.token);
          // let mergeCartsFormData = new FormData();
          // mergeCartsFormData.append('guest_token', this.guestToken);
          // this.cartService.mergeCarts(mergeCartsFormData).subscribe(mergeRes => {
          //   this.cartService.getCart().subscribe((cart:any) => {
          //     this.cartService.setCartItems(cart);
          //     if(cart.length > 0){
          //       this.router.navigate(['carrello']);
          //     }
          //     else {
          //       this.router.navigate(['reserved-area']);
          //     }
          //   })
          // })

        }, err => {
          this.isLoading = false;
          this._matSnackBar.open(err.error.message || err.error, 'Cancel', {duration: 3000})
          setTimeout(() => {
            this.router.navigate([''])
          }, 3000);
        })
      } else {
        this.router.navigate([''])
      }
    })
  }
}

import { HttpClient, HttpParams } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { pairwise, startWith } from "rxjs/operators";
import { mailchimp } from "src/app/env";
import { MailChimpResponse } from "src/app/models/mailchimp-response.model";
import { User } from "src/app/models/user.model";
import { CartService } from "src/app/services/cart.service";
import { UserService } from "src/app/services/user.service";
import { MustMatch } from "src/app/shared/validators/must-match.validator";

@Component({
  selector: "app-registration",
  templateUrl: "./registration.component.html",
  styleUrls: ["./registration.component.css"],
})
export class RegistrationComponent implements OnInit, OnDestroy {
  public registrationForm: FormGroup;
  PRIVATE_VALUE: string = "private";
  COMPANY_VALUE: string = "company";
  isRegister: boolean = true;
  isOnCheckoutProcess: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private http: HttpClient,
    private cartService: CartService
  ) {
    this.registrationForm = formBuilder.group(
      {
        client_type: [this.PRIVATE_VALUE, Validators.required],
        receive_invoice: [false],
        different_shipping: [false],
        first_name: ["", Validators.required],
        last_name: ["", Validators.required],
        email: ["", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$")]],
        phone: ["", Validators.required],
        address: ["", Validators.required],
        postal_code: ["", Validators.required],
        city: ["", Validators.required],
        province: ["", Validators.required],
        password: ["", [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[0-9])(?=.*[A-Z]).{8,}')]],
        registrationConfirmPassword: [""],
        note: [""],// Validators.required
        business_name: [""], // Validators.required
        fiscal_code: [""], //[Validators.required, Validators.minLength(16), Validators.maxLength(16)]
        vat_number: [""], //[Validators.required, Validators.minLength(11), Validators.maxLength(11)]
        pec: [""], //[Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]
        sdi: [""],

        shipping_first_name: [""],
        shipping_last_name: [""],
        shipping_phone: [""],
        shipping_address: [""],
        shipping_postal_code: [""],
        shipping_city: [""],
        shipping_province: [""],
        shipping_notes: [""],

        accept_privacy: [false, Validators.requiredTrue],
        newsletter_subscribed: [false],
      },
      {
        validator: MustMatch(
          "password",
          "registrationConfirmPassword"
        ),
      }
    );
  }

  ngOnInit(): void {
    this.cartService.isOnCheckoutProcessValue().subscribe(res => {
      this.isOnCheckoutProcess = res;
    })
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params["isRegister"]) {
        this.isRegister = false;
        this.registrationForm.controls["password"].setValidators([]);
        this.registrationForm.controls["password"].updateValueAndValidity();
      }
    });
    this.updateFormValidators();
  }

  updateFormValidators() {
    let companyUserFields = [
      // {
      //   formControlName: "fiscal_code",
      //   validations: [
      //     Validators.required,
      //     Validators.minLength(16),
      //     Validators.maxLength(16),
      //   ],
      // },
      {
        formControlName: "vat_number",
        validations: [
          Validators.required,
          Validators.minLength(11),
          Validators.maxLength(11),
        ],
      },
      {
        formControlName: "pec",
        validations: [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"),
        ],
      },
      {
        formControlName: "sdi",
        validations: [Validators.required],
      },
      {
        formControlName: "business_name",
        validations: [Validators.required],
      },
    ];

    let privateUserFields = [
      {
        formControlName: "fiscal_code",
        validations: [
          Validators.required,
          Validators.minLength(16),
          Validators.maxLength(16),
        ],
      },
    ];

    this.registrationForm.valueChanges
      .pipe(startWith(""), pairwise())
      .subscribe(([oldValue, newValue]) => {
        //change "Desidero la fattura" fields validation by receive_invoice and client_type values
        if (oldValue.client_type != newValue.client_type || oldValue.receive_invoice != newValue.receive_invoice) {
          companyUserFields.map((field) => {
            
            this.registrationForm.controls[field.formControlName].setValidators(
              newValue.client_type == this.COMPANY_VALUE && newValue.receive_invoice == true ? field.validations : []
            );
            this.registrationForm.controls[field.formControlName].updateValueAndValidity();
          });
          privateUserFields.map((field) => {
            this.registrationForm.controls[field.formControlName].setValidators(
              newValue.client_type == this.PRIVATE_VALUE && newValue.receive_invoice == true ? field.validations : []
            );
            this.registrationForm.controls[field.formControlName].updateValueAndValidity();
          });
        }
        //change "Indirizzo di spedizione differente" fields validation by different_shipping value
        else if (
          oldValue.different_shipping !=
          newValue.different_shipping
        ) {
          let differentShippingAddressFields = [
            "shipping_first_name",
            "shipping_last_name",
            "shipping_phone",
            "shipping_address",
            "shipping_postal_code",
            "shipping_city",
            "shipping_province",
          ];
          differentShippingAddressFields.map((field) => {
            this.registrationForm.controls[field].setValidators(
              newValue.different_shipping == true
                ? [Validators.required]
                : []
            );
            this.registrationForm.controls[field].updateValueAndValidity();
          });
        }
      });
  }

  get f() {
    return this.registrationForm.controls;
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
    });
  }

  isLoading = false;
  sendRegistrationForm() {
    if (this.registrationForm.invalid) {
      this.markFormGroupTouched(this.registrationForm);
      return;
    }
    // registration_guest
    let data = this.registrationForm.value;
    
    let formData = new FormData();
    if(this.isOnCheckoutProcess == true){
      formData.append("registration_guest", '1');
    }
    
    formData.append("client_type", data.client_type);
    formData.append("accept_privacy", data.accept_privacy == true ? '1' : '0');
    formData.append("newsletter_subscribed", data.newsletter_subscribed == true ? '1' : '0');


    if(data.receive_invoice) formData.append("receive_invoice", '1');
    if(data.different_shipping) formData.append("different_shipping",'1');

    //user data
    formData.append("first_name", data.first_name);
    formData.append("last_name", data.last_name);
    formData.append("email", data.email);
    formData.append("phone", data.phone);
    formData.append("address", data.address); //indirizzo
    formData.append("postal_code", data.postal_code); //postal_code
    formData.append("city", data.city);
    formData.append("province", data.province);
    formData.append("password", data.password);
    if (data.note)
      formData.append("notes", data.note);

    if (data.receive_invoice) {
      if (data.client_type == this.PRIVATE_VALUE) {
        formData.append("fiscal_code", data.fiscal_code);
      } else if (data.client_type == this.COMPANY_VALUE) {
        formData.append(
          "business_name",
          data.business_name
        );
        formData.append("vat_number", data.vat_number);
        formData.append("pec", data.pec);
        formData.append("sdi", data.sdi);
      }
    }

    if (data.different_shipping) {
      formData.append("shipping_first_name", data.shipping_first_name);
      formData.append("shipping_last_name", data.shipping_last_name);
      formData.append("shipping_phone", data.shipping_phone);
      formData.append("shipping_address", data.shipping_address);
      formData.append("shipping_postal_code", data.shipping_postal_code);
      formData.append("shipping_city", data.shipping_city);
      formData.append("shipping_province", data.shipping_province);
      if (data.shipping_notes)
        formData.append("shipping_notes",data.shipping_notes);
    }

    let guestToken: string = ''; 
    if (this.isRegister) {
      this.isLoading = true;
      this.userService.register(formData).subscribe((res: any) => {
        (<any>window).dataLayer.push({
          'event' : 'sign_up',
        });
        localStorage.setItem('isRegisteredGuest', '1');
        if(this.isOnCheckoutProcess){
          guestToken = localStorage.getItem('zecchini_token');
          let mergeCartsFormData = new FormData();
          mergeCartsFormData.append('guest_token', guestToken);

          localStorage.setItem('zecchini_token', res.token);
          this.cartService.mergeCarts(mergeCartsFormData).subscribe(mergeRes => {
            this.userService.getProfile().subscribe((profile: any) => {
              this.userService.setUser(profile);
              this.userService.authSubject.next(true);
              this.cartService.getCart().subscribe(cart => {
                this.cartService.setCartItems(cart);
                this.router.navigateByUrl('/checkout');
              })
            })
          })
        } else {
          if(data.newsletter_subscribed == true){
            this.sendNewsletter(data.email)
          }
          this._snackBar.open('Controlla la tua email per poter confermare la tua iscrizione', 'OK', {
            duration: 5000
          });
          setTimeout(()=>{
            this.isLoading = false;
            this.router.navigateByUrl('/login');
          }, 3000)
        }        
      }, 
      err => {
        this.isLoading = false;
        if(err.erorr && err.erorr.message){
          this._snackBar.open(err.error.message, 'OK', {
            duration: 5000
          });
        } else {
          for (const [key, value] of Object.entries(err.error)) {
            if (this.registrationForm.get(key)) {
              this.registrationForm.get(key).setErrors({ serverError: { message: value } });
            }
          }
        }

      })
    } else {
      // guest user
      // address.client_type = data.client_type
      data.user_type = data.client_type;
      localStorage.setItem('guest_user', JSON.stringify(data));
      let address:any = {};
      if(data.different_shipping == true){
        address.id = 1;
        address.address = data.shipping_address;
        address.city = data.shipping_city;
        address.first_name = data.shipping_first_name;
        address.last_name = data.shipping_last_name;
        address.shipping_notes = data.shipping_notes || '';
        address.phone = data.shipping_phone;
        address.postal_code = data.shipping_postal_code;
        address.province = data.shipping_province;
        address.primary_address = '1';
        localStorage.setItem('guest_addresses', JSON.stringify([address]))
      } else {
        address.id = 1;
        address.address = data.address;
        address.city = data.city;
        address.first_name = data.first_name;
        address.last_name = data.last_name;
        address.shipping_notes = data.note || '';
        address.phone = data.phone;
        address.postal_code = data.postal_code;
        address.province = data.province;
        address.primary_address = '1';
        localStorage.setItem('guest_addresses', JSON.stringify([address]))
      }
      this.router.navigateByUrl("/checkout");
    }
  }

  sendNewsletter(email){
    let url = mailchimp.apiUrl;

    const params = new HttpParams()
      .set("EMAIL", email)
      .set(mailchimp.secret, ""); // hidden input name

    const mailChimpUrl = url + params.toString();
    this.http.jsonp<MailChimpResponse>(mailChimpUrl, "c").subscribe(
      (response) => {
        if (response.result && response.result !== 'error') {
          // this._snackBar.open("Grazie per esserti registrato alla nostra newsletter", 'OK', {
          //   duration: 2000
          // });
				}
				else {
          // console.error(response.msg);
          // this._snackBar.open(response.msg, 'OK', {
          //   duration: 2000
          // });
				}
      },
      (error) => {
        // console.error(error);
      }
    );

  }

  ngOnDestroy(){
    this.cartService.isOnCheckoutProcess.next(false);
  }
}

import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { from } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { CartService } from 'src/app/services/cart.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})

export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public forgotPasswordForm: FormGroup;
  activeView: string;
  isOnCheckoutProcess: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private _snackBar: MatSnackBar,
    private userService: UserService,
    private cartService: CartService
  ) {
    this.loginForm = formBuilder.group({
      loginEmail: ["", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$")]],
      loginPassword: ["", [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[0-9])(?=.*[A-Z]).{8,}')]],//, Validators.pattern('(?=.*[0-9])(?=.*[A-Z]).{8,}')
    });

    this.forgotPasswordForm = formBuilder.group({
      forgotEmail: ["", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$")]],
    });
  }
  ngOnInit(): void {
    this.cartService.isOnCheckoutProcessValue().subscribe(res => {
      this.isOnCheckoutProcess = res;
    })
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  get f2() {
    return this.forgotPasswordForm.controls;
  }

  guestToken: string;
  login() {
    if (this.loginForm.invalid) {
      this.markFormGroupTouched(this.loginForm);
      return;
    }
    this.guestToken = localStorage.getItem('zecchini_token');
    // if cart login checkout
    //this.router.navigateByUrl('/checkout');
    const formData = new FormData();
    formData.append("email", this.loginForm.value.loginEmail);
    formData.append("password", this.loginForm.value.loginPassword);
    this.userService.login(formData).subscribe((res: any) => {
      if(res.role.id == '3'){
        (<any>window).dataLayer.push({
          'event' : 'login',
        });
        localStorage.setItem('zecchini_token', res.token);
        let mergeCartsFormData = new FormData();
        mergeCartsFormData.append('guest_token', this.guestToken);
        this.cartService.mergeCarts(mergeCartsFormData).subscribe(mergeRes => {
          this.userService.getProfile().subscribe((profile: any) => {
            this.userService.setUser(profile);
            this.userService.authSubject.next(true);
            this.cartService.getCart().subscribe(res => {
              this.cartService.setCartItems(res);
              if(this.isOnCheckoutProcess){
                this.router.navigateByUrl('/checkout');
              } else {
                this.router.navigateByUrl('/area-riservata');
              }
            })
          })
        })
      } else {
        this._snackBar.open("You don't have access here!", 'OK', {duration:3000});
      }
    }, 
    err => {
      this._snackBar.open(err.error.message || err.error, 'OK', {});
    });
  }
  //  
  forgotPassword(message: string, action: string) {
    if (this.forgotPasswordForm.invalid) {
      this.markFormGroupTouched(this.forgotPasswordForm);
      return;
    }
    const formData = new FormData();
    formData.append("email", this.forgotPasswordForm.value.forgotEmail);
    this.userService.forgotPassword(formData).subscribe(res => {
      this._snackBar.open('Controlla la tua email per poter procedere con il cambio password', 'OK', {
        duration:5000
      });
      this.setView('')
    }, err => {
      this._snackBar.open(err.error.message, 'OK', {
        duration:5000
      });
    })
  }

  setView(view) {
    this.activeView = view;
  }
}
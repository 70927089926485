<div>
    <p class="padding-top--40 padding-bottom--30 blue"><b>Informazioni di spedizione</b></p>
    <form [formGroup]="addressForm" #form="ngForm">
        <div class="form__container">
            <mat-form-field appearance="standard">
                <mat-label>nome *</mat-label>
                <input type="text" matInput formControlName="first_name">
                <mat-error *ngIf="(f.first_name.dirty || f.first_name.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>cognome *</mat-label>
                <input type="text" matInput formControlName="last_name" >
                <mat-error *ngIf="(f.last_name.dirty || f.last_name.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard" class="grid--1-2">
                <mat-label>indirizzo *</mat-label>
                <input type="text" matInput formControlName="address" >
                <mat-error *ngIf="(f.address.dirty || f.address.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>CAP *</mat-label>
                <input type="number" matInput formControlName="postal_code" >
                <mat-error *ngIf="(f.postal_code.dirty || f.postal_code.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>città *</mat-label>
                <input type="text" matInput formControlName="city" >
                <mat-error *ngIf="(f.city.dirty || f.city.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>provincia *</mat-label>
                <input type="text" matInput formControlName="province" >
                <mat-error *ngIf="(f.province.dirty || f.province.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>telefono *</mat-label>
                <input type="tel" matInput formControlName="phone" >
                <mat-error *ngIf="(f.phone.dirty || f.phone.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard" class="grid--1-2">
                <mat-label>note spedizione</mat-label>
                <input type="text" matInput formControlName="shipping_notes">
            </mat-form-field>
        </div>
    </form>
    <div class="action-float">
        <button (click)="close()" class="link-underline">annulla</button>
        <div class="button__container">
            <div class="button__background2"></div>
            <div class="button__background"></div>
            <button (click)="onFormSubmit()" class="action__button">Salva
                <div class="arrow-left"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.14 16.48">
                    <line y1="8.24" x2="19.55" y2="8.24"/><polyline points="11.4 0.5 19.14 8.24 11.4 15.98"/></svg>
                </div>
            </button>
        </div>
    </div>
</div>
export const slider = {
    homeSlider1: [
        {
            number:'01',
            imgSmall: 'assets/images/home/slider/slider1/negozio1-vertical_400.jpg',
            webpSmall:'assets/images/home/slider/slider1/negozio1-vertical_400.webp 400w, assets/images/home/slider/slider1/negozio1-vertical_200.webp 200w',
            jpgSmall:'assets/images/home/slider/slider1/negozio1-vertical_400.jpg 400w, assets/images/home/slider/slider1/negozio1-vertical_200.jpg 200w',
            imgBig: 'assets/images/home/slider/slider1/negozio1-horizontal_1200.jpg',
            webpBig:'assets/images/home/slider/slider1/negozio1-horizontal_1200.webp 1200w, assets/images/home/slider/slider1/negozio1-horizontal_800.webp 800w, assets/images/home/slider/slider1/negozio1-horizontal_600.webp 600w, assets/images/home/slider/slider1/negozio1-horizontal_400.webp 400w',
            jpgBig:'assets/images/home/slider/slider1/negozio1-horizontal_1200.jpg 1200w, assets/images/home/slider/slider1/negozio1-horizontal_800.jpg 800w, assets/images/home/slider/slider1/negozio1-horizontal_600.jpg 600w, assets/images/home/slider/slider1/negozio1-horizontal_400.jpg 400w',
            altSmall: 'alt img',
            altBig: 'alt img'
        },
        {
            number:'02',
            imgSmall: 'assets/images/home/slider/slider1/negozio2-vertical_400.jpg',
            webpSmall:'assets/images/home/slider/slider1/negozio2-vertical_400.webp 400w, assets/images/home/slider/slider1/negozio2-vertical_200.webp 200w',
            jpgSmall:'assets/images/home/slider/slider1/negozio2-vertical_400.jpg 400w, assets/images/home/slider/slider1/negozio2-vertical_200.jpg 200w',
            imgBig: 'assets/images/home/slider/slider1/negozio2-horizontal_1200.jpg',
            webpBig:'assets/images/home/slider/slider1/negozio2-horizontal_1200.webp 1200w, assets/images/home/slider/slider1/negozio2-horizontal_800.webp 800w, assets/images/home/slider/slider1/negozio2-horizontal_600.webp 600w, assets/images/home/slider/slider1/negozio2-horizontal_400.webp 400w',
            jpgBig:'assets/images/home/slider/slider1/negozio2-horizontal_1200.jpg 1200w, assets/images/home/slider/slider1/negozio2-horizontal_800.jpg 800w, assets/images/home/slider/slider1/negozio2-horizontal_600.jpg 600w, assets/images/home/slider/slider1/negozio2-horizontal_400.jpg 400w',
            altSmall: 'alt img',
            altBig: 'alt img'
        },
        {
            number:'03',
            imgSmall: 'assets/images/home/slider/slider1/negozio3-vertical_400.jpg',
            webpSmall:'assets/images/home/slider/slider1/negozio3-vertical_400.webp 400w, assets/images/home/slider/slider1/negozio3-vertical_200.webp 200w',
            jpgSmall:'assets/images/home/slider/slider1/negozio3-vertical_400.jpg 400w, assets/images/home/slider/slider1/negozio3-vertical_200.jpg 200w',
            imgBig: 'assets/images/home/slider/slider1/negozio3-horizontal_1200.jpg',
            webpBig:'assets/images/home/slider/slider1/negozio3-horizontal_1200.webp 1200w, assets/images/home/slider/slider1/negozio3-horizontal_800.webp 800w, assets/images/home/slider/slider1/negozio3-horizontal_600.webp 600w, assets/images/home/slider/slider1/negozio3-horizontal_400.webp 400w',
            jpgBig:'assets/images/home/slider/slider1/negozio3-horizontal_1200.jpg 1200w, assets/images/home/slider/slider1/negozio3-horizontal_800.jpg 800w, assets/images/home/slider/slider1/negozio3-horizontal_600.jpg 600w, assets/images/home/slider/slider1/negozio3-horizontal_400.jpg 400w',
            altSmall: 'alt img',
            altBig: 'alt img'
        },
    ],
    homeSlider2: [
        {
            number:'01',
            imgSmall: 'assets/images/home/slider/slider2/bici1-vertical_400.jpg',
            webpSmall:'assets/images/home/slider/slider2/bici1-vertical_400.webp 400w, assets/images/home/slider/slider2/bici1-vertical_200.webp 200w',
            jpgSmall:'assets/images/home/slider2/bici1-vertical_400.jpg 400w, assets/images/home/slider2/bici1-vertical_200.jpg 200w',
            imgBig: 'assets/images/home/slider/slider2/bici1-horizontal_1200.jpg',
            webpBig:'assets/images/home/slider/slider2/bici1-horizontal_1200.webp 1200w, assets/images/home/slider/slider2/bici1-horizontal_800.webp 800w, assets/images/home/slider/slider2/bici1-horizontal_600.webp 600w, assets/images/home/slider/slider2/bici1-horizontal_400.webp 400w',
            jpgBig:'assets/images/home/slider/slider2/bici1-horizontal_1200.jpg 1200w, assets/images/home/slider/slider2/bici1-horizontal_800.jpg 800w, assets/images/home/slider/slider2/bici1-horizontal_600.jpg 600w, assets/images/home/slider/slider2/bici1-horizontal_400.jpg 400w',
            altSmall: 'alt img',
            altBig: 'alt img'
        },
        {
            number:'02',
            imgSmall: 'assets/images/home/slider/slider2/bici2-vertical_400.jpg',
            webpSmall:'assets/images/home/slider/slider2/bici2-vertical_400.webp 400w, assets/images/home/slider/slider2/bici2-vertical_200.webp 200w',
            jpgSmall:'assets/images/home/slider2/bici2-vertical_400.jpg 400w, assets/images/home/slider2/bici2-vertical_200.jpg 200w',
            imgBig: 'assets/images/home/slider/slider2/bici2-horizontal_1200.jpg',
            webpBig:'assets/images/home/slider/slider2/bici2-horizontal_1200.webp 1200w, assets/images/home/slider/slider2/bici2-horizontal_800.webp 800w, assets/images/home/slider/slider2/bici2-horizontal_600.webp 600w, assets/images/home/slider/slider2/bici2-horizontal_400.webp 400w',
            jpgBig:'assets/images/home/slider/slider2/bici2-horizontal_1200.jpg 1200w, assets/images/home/slider/slider2/bici2-horizontal_800.jpg 800w, assets/images/home/slider/slider2/bici2-horizontal_600.jpg 600w, assets/images/home/slider/slider2/bici2-horizontal_400.jpg 400w',
            altSmall: 'alt img',
            altBig: 'alt img'
        },
        {
            number:'03',
            imgSmall: 'assets/images/home/slider/slider2/bici3-vertical_400.jpg',
            webpSmall:'assets/images/home/slider/slider2/bici3-vertical_400.webp 400w, assets/images/home/slider/slider2/bici3-vertical_200.webp 200w',
            jpgSmall:'assets/images/home/slider2/bici3-vertical_400.jpg 400w, assets/images/home/slider2/bici3-vertical_200.jpg 200w',
            imgBig: 'assets/images/home/slider/slider2/bici3-horizontal_1200.jpg',
            webpBig:'assets/images/home/slider/slider2/bici3-horizontal_1200.webp 1200w, assets/images/home/slider/slider2/bici3-horizontal_800.webp 800w, assets/images/home/slider/slider2/bici3-horizontal_600.webp 600w, assets/images/home/slider/slider2/bici3-horizontal_400.webp 400w',
            jpgBig:'assets/images/home/slider/slider2/bici3-horizontal_1200.jpg 1200w, assets/images/home/slider/slider2/bici3-horizontal_800.jpg 800w, assets/images/home/slider/slider2/bici3-horizontal_600.jpg 600w, assets/images/home/slider/slider2/bici3-horizontal_400.jpg 400w',
            altSmall: 'alt img',
            altBig: 'alt img'
        },
    ], storiaSlider1: [
        {
            number:'01',
            imgSmall: 'assets/images/storia/slider/slider1/corse-1-vertical_400.jpg',
            webpSmall:'assets/images/storia/slider/slider1/corse-1-vertical_400.webp 400w, assets/images/storia/slider/slider1/corse-1-vertical_200.webp 200w',
            jpgSmall:'assets/images/storia/slider/slider1/corse-1-vertical_400.jpg 400w, assets/images/storia/slider/slider1/corse-1-vertical_200.jpg 200w',
            imgBig: 'assets/images/storia/slider/slider1/corse-1-horizontal_1200.jpg',
            webpBig:'assets/images/storia/slider/slider1/corse-1-horizontal_1200.webp 1200w, assets/images/storia/slider/slider1/corse-1-horizontal_800.webp 800w, assets/images/storia/slider/slider1/corse-1-horizontal_600.webp 600w, assets/images/storia/slider/slider1/corse-1-horizontal_400.webp 400w',
            jpgBig:'assets/images/storia/slider/slider1/corse-1-horizontal_1200.jpg 1200w, assets/images/storia/slider/slider1/corse-1-horizontal_800.jpg 800w, assets/images/storia/slider/slider1/corse-1-horizontal_600.jpg 600w, assets/images/storia/slider/slider1/corse-1-horizontal_400.jpg 400w',
            altSmall: 'alt img',
            altBig: 'alt img'
        },
        {
            number:'02',
            imgSmall: 'assets/images/storia/slider/slider1/corse-2-vertical_400.jpg',
            webpSmall:'assets/images/storia/slider/slider1/corse-2-vertical_400.webp 400w, assets/images/storia/slider/slider1/corse-2-vertical_200.webp 200w',
            jpgSmall:'assets/images/storia/slider/slider1/corse-2-vertical_400.jpg 400w, assets/images/storia/slider/slider1/corse-2-vertical_200.jpg 200w',
            imgBig: 'assets/images/storia/slider/slider1/corse-2-horizontal_1200.jpg',
            webpBig:'assets/images/storia/slider/slider1/corse-2-horizontal_1200.webp 1200w, assets/images/storia/slider/slider1/corse-2-horizontal_800.webp 800w, assets/images/storia/slider/slider1/corse-2-horizontal_600.webp 600w, assets/images/storia/slider/slider1/corse-2-horizontal_400.webp 400w',
            jpgBig:'assets/images/storia/slider/slider1/corse-2-horizontal_1200.jpg 1200w, assets/images/storia/slider/slider1/corse-2-horizontal_800.jpg 800w, assets/images/storia/slider/slider1/corse-2-horizontal_600.jpg 600w, assets/images/storia/slider/slider1/corse-2-horizontal_400.jpg 400w',
            altSmall: 'alt img',
            altBig: 'alt img'
        },
        {
            number:'03',
            imgSmall: 'assets/images/storia/slider/slider1/corse-3-vertical_400.jpg',
            webpSmall:'assets/images/storia/slider/slider1/corse-3-vertical_400.webp 400w, assets/images/storia/slider/slider1/corse-3-vertical_200.webp 200w',
            jpgSmall:'assets/images/storia/slider/slider1/corse-3-vertical_400.jpg 400w, assets/images/storia/slider/slider1/corse-3-vertical_200.jpg 200w',
            imgBig: 'assets/images/storia/slider/slider1/corse-3-horizontal_1200.jpg',
            webpBig:'assets/images/storia/slider/slider1/corse-3-horizontal_1200.webp 1200w, assets/images/storia/slider/slider1/corse-3-horizontal_800.webp 800w, assets/images/storia/slider/slider1/corse-3-horizontal_600.webp 600w, assets/images/storia/slider/slider1/corse-3-horizontal_400.webp 400w',
            jpgBig:'assets/images/storia/slider/slider1/corse-3-horizontal_1200.jpg 1200w, assets/images/storia/slider/slider1/corse-3-horizontal_800.jpg 800w, assets/images/storia/slider/slider1/corse-3-horizontal_600.jpg 600w, assets/images/storia/slider/slider1/corse-3-horizontal_400.jpg 400w',
            altSmall: 'alt img',
            altBig: 'alt img'
        },
    ], storiaSlider2: [
        {
            number:'01',
            imgSmall: 'assets/images/storia/slider/slider2/modelli-1-vertical_400.jpg',
            webpSmall:'assets/images/storia/slider/slider2/modelli-1-vertical_400.webp 400w, assets/images/storia/slider/slider2/modelli-1-vertical_200.webp 200w',
            jpgSmall:'assets/images/storia/slider/slider2/modelli-1-vertical_400.jpg 400w, assets/images/storia/slider/slider2/modelli-1-vertical_200.jpg 200w',
            imgBig: 'assets/images/storia/slider/slider2/modelli-1-horizontal_1200.jpg',
            webpBig:'assets/images/storia/slider/slider2/modelli-1-horizontal_1200.webp 1200w, assets/images/storia/slider/slider2/modelli-1-horizontal_800.webp 800w, assets/images/storia/slider/slider2/modelli-1-horizontal_600.webp 600w, assets/images/storia/slider/slider2/modelli-1-horizontal_400.webp 400w',
            jpgBig:'assets/images/storia/slider/slider2/modelli-1-horizontal_1200.jpg 1200w, assets/images/storia/slider/slider2/modelli-1-horizontal_800.jpg 800w, assets/images/storia/slider/slider2/modelli-1-horizontal_600.jpg 600w, assets/images/storia/slider/slider2/modelli-1-horizontal_400.jpg 400w',
            altSmall: 'alt img',
            altBig: 'alt img'
        },
        {
            number:'02',
            imgSmall: 'assets/images/storia/slider/slider2/modelli-2-vertical_400.jpg',
            webpSmall:'assets/images/storia/slider/slider2/modelli-2-vertical_400.webp 400w, assets/images/storia/slider/slider2/modelli-2-vertical_200.webp 200w',
            jpgSmall:'assets/images/storia/slider/slider2/modelli-2-vertical_400.jpg 400w, assets/images/storia/slider/slider2/modelli-2-vertical_200.jpg 200w',
            imgBig: 'assets/images/storia/slider/slider2/modelli-2-horizontal_1200.jpg',
            webpBig:'assets/images/storia/slider/slider2/modelli-2-horizontal_1200.webp 1200w, assets/images/storia/slider/slider2/modelli-2-horizontal_800.webp 800w, assets/images/storia/slider/slider2/modelli-2-horizontal_600.webp 600w, assets/images/storia/slider/slider2/modelli-2-horizontal_400.webp 400w',
            jpgBig:'assets/images/storia/slider/slider2/modelli-2-horizontal_1200.jpg 1200w, assets/images/storia/slider/slider2/modelli-2-horizontal_800.jpg 800w, assets/images/storia/slider/slider2/modelli-2-horizontal_600.jpg 600w, assets/images/storia/slider/slider2/modelli-2-horizontal_400.jpg 400w',
            altSmall: 'alt img',
            altBig: 'alt img'
        },
        {
            number:'03',
            imgSmall: 'assets/images/storia/slider/slider2/modelli-3-vertical_400.jpg',
            webpSmall:'assets/images/storia/slider/slider2/modelli-3-vertical_400.webp 400w, assets/images/storia/slider/slider2/modelli-3-vertical_200.webp 200w',
            jpgSmall:'assets/images/storia/slider/slider2/modelli-3-vertical_400.jpg 400w, assets/images/storia/slider/slider2/modelli-3-vertical_200.jpg 200w',
            imgBig: 'assets/images/storia/slider/slider2/modelli-3-horizontal_1200.jpg',
            webpBig:'assets/images/storia/slider/slider2/modelli-3-horizontal_1200.webp 1200w, assets/images/storia/slider/slider2/modelli-3-horizontal_800.webp 800w, assets/images/storia/slider/slider2/modelli-3-horizontal_600.webp 600w, assets/images/storia/slider/slider2/modelli-3-horizontal_400.webp 400w',
            jpgBig:'assets/images/storia/slider/slider2/modelli-3-horizontal_1200.jpg 1200w, assets/images/storia/slider/slider2/modelli-3-horizontal_800.jpg 800w, assets/images/storia/slider/slider2/modelli-3-horizontal_600.jpg 600w, assets/images/storia/slider/slider2/modelli-3-horizontal_400.jpg 400w',
            altSmall: 'alt img',
            altBig: 'alt img'
        },
    ], serviziSlider1: [
        {
            number:'01',
            imgSmall: 'assets/images/servizi/slider/slider1/zecchini-1-vertical_400.jpg',
            webpSmall:'assets/images/servizi/slider/slider1/zecchini-1-vertical_400.webp 400w, assets/images/servizi/slider/slider1/zecchini-1-vertical_200.webp 200w',
            jpgSmall:'assets/images/servizi/slider/slider1/zecchini-1-vertical_400.jpg 400w, assets/images/servizi/slider/slider1/zecchini-1-vertical_200.jpg 200w',
            imgBig: 'assets/images/servizi/slider/slider1/zecchini-1-horizontal_1200.jpg',
            webpBig:'assets/images/servizi/slider/slider1/zecchini-1-horizontal_1200.webp 1200w, assets/images/servizi/slider/slider1/zecchini-1-horizontal_800.webp 800w, assets/images/servizi/slider/slider1/zecchini-1-horizontal_600.webp 600w, assets/images/servizi/slider/slider1/zecchini-1-horizontal_400.webp 400w',
            jpgBig:'assets/images/servizi/slider/slider1/zecchini-1-horizontal_1200.jpg 1200w, assets/images/servizi/slider/slider1/zecchini-1-horizontal_800.jpg 800w, assets/images/servizi/slider/slider1/zecchini-1-horizontal_600.jpg 600w, assets/images/servizi/slider/slider1/zecchini-1-horizontal_400.jpg 400w',
            altSmall: 'alt img',
            altBig: 'alt img'
        },
        {
            number:'02',
            imgSmall: 'assets/images/servizi/slider/slider1/zecchini-2-vertical_400.jpg',
            webpSmall:'assets/images/servizi/slider/slider1/zecchini-2-vertical_400.webp 400w, assets/images/servizi/slider/slider1/zecchini-2-vertical_200.webp 200w',
            jpgSmall:'assets/images/servizi/slider/slider1/zecchini-2-vertical_400.jpg 400w, assets/images/servizi/slider/slider1/zecchini-2-vertical_200.jpg 200w',
            imgBig: 'assets/images/servizi/slider/slider1/zecchini-2-horizontal_1200.jpg',
            webpBig:'assets/images/servizi/slider/slider1/zecchini-2-horizontal_1200.webp 1200w, assets/images/servizi/slider/slider1/zecchini-2-horizontal_800.webp 800w, assets/images/servizi/slider/slider1/zecchini-2-horizontal_600.webp 600w, assets/images/servizi/slider/slider1/zecchini-2-horizontal_400.webp 400w',
            jpgBig:'assets/images/servizi/slider/slider1/zecchini-2-horizontal_1200.jpg 1200w, assets/images/servizi/slider/slider1/zecchini-2-horizontal_800.jpg 800w, assets/images/servizi/slider/slider1/zecchini-2-horizontal_600.jpg 600w, assets/images/servizi/slider/slider1/zecchini-2-horizontal_400.jpg 400w',
            altSmall: 'alt img',
            altBig: 'alt img'
        },
        {
            number:'03',
            imgSmall: 'assets/images/servizi/slider/slider1/zecchini-3-vertical_400.jpg',
            webpSmall:'assets/images/servizi/slider/slider1/zecchini-3-vertical_400.webp 400w, assets/images/servizi/slider/slider1/zecchini-3-vertical_200.webp 200w',
            jpgSmall:'assets/images/servizi/slider/slider1/zecchini-3-vertical_400.jpg 400w, assets/images/servizi/slider/slider1/zecchini-3-vertical_200.jpg 200w',
            imgBig: 'assets/images/servizi/slider/slider1/zecchini-3-horizontal_1200.jpg',
            webpBig:'assets/images/servizi/slider/slider1/zecchini-3-horizontal_1200.webp 1200w, assets/images/servizi/slider/slider1/zecchini-3-horizontal_800.webp 800w, assets/images/servizi/slider/slider1/zecchini-3-horizontal_600.webp 600w, assets/images/servizi/slider/slider1/zecchini-3-horizontal_400.webp 400w',
            jpgBig:'assets/images/servizi/slider/slider1/zecchini-3-horizontal_1200.jpg 1200w, assets/images/servizi/slider/slider1/zecchini-3-horizontal_800.jpg 800w, assets/images/servizi/slider/slider1/zecchini-3-horizontal_600.jpg 600w, assets/images/servizi/slider/slider1/zecchini-3-horizontal_400.jpg 400w',
            altSmall: 'alt img',
            altBig: 'alt img'
        },
    ], serviziSlider2: [
        {
            number:'01',
            imgSmall: 'assets/images/servizi/slider/slider2/exploring-1-vertical_400.jpg',
            webpSmall:'assets/images/servizi/slider/slider2/exploring-1-vertical_400.webp 400w, assets/images/servizi/slider/slider2/exploring-1-vertical_200.webp 200w',
            jpgSmall:'assets/images/servizi/slider/slider2/exploring-1-vertical_400.jpg 400w, assets/images/servizi/slider/slider2/exploring-1-vertical_200.jpg 200w',
            imgBig: 'assets/images/servizi/slider/slider2/exploring-1-horizontal_1200.jpg',
            webpBig:'assets/images/servizi/slider/slider2/exploring-1-horizontal_1200.webp 1200w, assets/images/servizi/slider/slider2/exploring-1-horizontal_800.webp 800w, assets/images/servizi/slider/slider2/exploring-1-horizontal_600.webp 600w, assets/images/servizi/slider/slider2/exploring-1-horizontal_400.webp 400w',
            jpgBig:'assets/images/servizi/slider/slider2/exploring-1-horizontal_1200.jpg 1200w, assets/images/servizi/slider/slider2/exploring-1-horizontal_800.jpg 800w, assets/images/servizi/slider/slider2/exploring-1-horizontal_600.jpg 600w, assets/images/servizi/slider/slider2/exploring-1-horizontal_400.jpg 400w',
            altSmall: 'alt img',
            altBig: 'alt img'
        },
        {
            number:'02',
            imgSmall: 'assets/images/servizi/slider/slider2/exploring-2-vertical_400.jpg',
            webpSmall:'assets/images/servizi/slider/slider2/exploring-2-vertical_400.webp 400w, assets/images/servizi/slider/slider2/exploring-2-vertical_200.webp 200w',
            jpgSmall:'assets/images/servizi/slider/slider2/exploring-2-vertical_400.jpg 400w, assets/images/servizi/slider/slider2/exploring-2-vertical_200.jpg 200w',
            imgBig: 'assets/images/servizi/slider/slider2/exploring-2-horizontal_1200.jpg',
            webpBig:'assets/images/servizi/slider/slider2/exploring-2-horizontal_1200.webp 1200w, assets/images/servizi/slider/slider2/exploring-2-horizontal_800.webp 800w, assets/images/servizi/slider/slider2/exploring-2-horizontal_600.webp 600w, assets/images/servizi/slider/slider2/exploring-2-horizontal_400.webp 400w',
            jpgBig:'assets/images/servizi/slider/slider2/exploring-2-horizontal_1200.jpg 1200w, assets/images/servizi/slider/slider2/exploring-2-horizontal_800.jpg 800w, assets/images/servizi/slider/slider2/exploring-2-horizontal_600.jpg 600w, assets/images/servizi/slider/slider2/exploring-2-horizontal_400.jpg 400w',
            altSmall: 'alt img',
            altBig: 'alt img'
        },
        {
            number:'03',
            imgSmall: 'assets/images/servizi/slider/slider2/exploring-3-vertical_400.jpg',
            webpSmall:'assets/images/servizi/slider/slider2/exploring-3-vertical_400.webp 400w, assets/images/servizi/slider/slider2/exploring-3-vertical_200.webp 200w',
            jpgSmall:'assets/images/servizi/slider/slider2/exploring-3-vertical_400.jpg 400w, assets/images/servizi/slider/slider2/exploring-3-vertical_200.jpg 200w',
            imgBig: 'assets/images/servizi/slider/slider2/exploring-3-horizontal_1200.jpg',
            webpBig:'assets/images/servizi/slider/slider2/exploring-3-horizontal_1200.webp 1200w, assets/images/servizi/slider/slider2/exploring-3-horizontal_800.webp 800w, assets/images/servizi/slider/slider2/exploring-3-horizontal_600.webp 600w, assets/images/servizi/slider/slider2/exploring-3-horizontal_400.webp 400w',
            jpgBig:'assets/images/servizi/slider/slider2/exploring-3-horizontal_1200.jpg 1200w, assets/images/servizi/slider/slider2/exploring-3-horizontal_800.jpg 800w, assets/images/servizi/slider/slider2/exploring-3-horizontal_600.jpg 600w, assets/images/servizi/slider/slider2/exploring-3-horizontal_400.jpg 400w',
            altSmall: 'alt img',
            altBig: 'alt img'
        },
    ]
}
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { User, Address } from 'src/app/models/user.model';
import { AddressService } from 'src/app/services/address.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-shipping-addresses',
  templateUrl: './shipping-addresses.component.html',
  styleUrls: ['./shipping-addresses.component.css']
})
export class ShippingAddressesComponent implements OnInit {
  public addressForm: FormGroup;
  user: User;

  VIEW_STATE: string = 'view';
  EDIT_STATE: string = 'edit';

  activeView:string = this.VIEW_STATE;

  primaryAdrress: Address;
  addresses: Address[];
  selectedAddressId: Address;

  constructor(private userService: UserService, private addressService: AddressService) {}

  ngOnInit(): void {
    this.userService.getUser().subscribe((user: User) => {
      this.user = user;
      // let primaryAddress = user.addresses.filter(address => address.isPrimary);
      // if(primaryAddress.length > 0) this.primaryAdrress = primaryAddress[0]; 
      // let addresses = user.addresses.filter(address => !address.isPrimary);
      // if(addresses.length > 0) this.addresses = addresses; 
    })

    this.getAddresses();
  }

  setView(view) {
    window.scroll(0,0);
    this.activeView = view;
  }

  makePrimary(id){
    this.addressService.changePrimaryAddress(id).subscribe(res => {
      this.getAddresses();
    })
  }

  state:any;
  goToEditAddressScreen(selectedAddressId){
    this.selectedAddressId = selectedAddressId;
    this.setView(this.EDIT_STATE);
    this.state = 'edit';
  }

  goToCreateAddress(){
    this.state = 'create';
    this.setView(this.EDIT_STATE);
  }

  onShippingAddressChange(change){
    this.setView(this.VIEW_STATE)
    this.getAddresses();
  }

  getAddresses(){
    this.addressService.getAddresses().subscribe((addresses:any) => {
      let otherAddresses = [];
      addresses.map(address => {
        if(address.primary_address == '1') this.primaryAdrress = address; 
        else otherAddresses.push(address);
      })
      this.addresses = otherAddresses;
    })
  }
}
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.css']
})
export class CookieComponent implements OnInit {

  constructor(private http: HttpClient) { }

  content:any;
  ngOnInit(): void {
    this.http.get('https://www.iubenda.com/api/privacy-policy/87626306/cookie-policy').subscribe(
      res => {
        this.content = res;
      }
    )
  }

}

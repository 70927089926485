import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { map, share } from "rxjs/operators";
import { User, Address } from "../models/user.model";
import { ApiCommands } from "../http/api.commands";
import {API} from './api.constants';

@Injectable({
  providedIn: "root",
})

export class NewsService {

  constructor(private apiCommands: ApiCommands) {}

  public getNews() {
    return this.apiCommands
      .get(API.POST.url)
      .pipe(map((res) => res));
  }

  public getPost(id) {
    return this.apiCommands
      .get(API.POST.url + '/' + id)
      .pipe(map((res) => res));
  }


  private getToken() {
    return `${localStorage.getItem("zecchini_token")}`;
  }

}

<div class="right__container">
    <button [routerLink]='["/area-riservata/"]' class="link-underline link-underline--back">torna indietro</button>
    <p class="blue padding-top--40 padding-bottom--30"><b>Richiedi assistenza</b></p>
    <form [formGroup]="helpForm" #form="ngForm">
        <div class="form__container">
            <mat-form-field appearance="standard">
                <mat-label>nome</mat-label>
                <input type="text" matInput formControlName="first_name" required>
                <mat-error *ngIf="(f.first_name.dirty || f.first_name.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>cognome</mat-label>
                <input type="text" matInput formControlName="last_name" required>
                <mat-error *ngIf="(f.last_name.dirty || f.last_name.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>e-mail</mat-label>
                <input type="email" matInput formControlName="email" required>
                <mat-error *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)">
                    <mat-error *ngIf="(f.email.errors.required)">
                        campo <strong>obbligatorio</strong>
                    </mat-error>
                    <mat-error *ngIf="(f.email.errors.pattern)">
                        inserisci un indirizzo e-mail <strong>valido</strong>
                    </mat-error>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>titolo</mat-label>
                <input type="text" matInput formControlName="title" required>
                <mat-error *ngIf="(f.title.dirty || f.title.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard" class="grid--1-2">
                <mat-label>testo</mat-label>
                <textarea matInput formControlName="text" required></textarea>
                <mat-error *ngIf="(f.text.dirty || f.text.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
            </mat-form-field>
        </div>
    </form>
    <div class="action-float">
        <button [routerLink]='["/area-riservata/"]' class="link-underline">annulla</button>
        <div class="button__container">
            <div class="button__background2"></div>
            <div class="button__background"></div>
            <button class="action__button" (click)="submitForm()" [disabled]="isLoading">Invia
            <div class="arrow-left"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.14 16.48">
                    <line y1="8.24" x2="19.55" y2="8.24" />
                    <polyline points="11.4 0.5 19.14 8.24 11.4 15.98" /></svg>
                </div>
        </button>
        </div>
    </div>
</div>
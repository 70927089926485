<section class="container--full container--white">
    <div class="consigli-intro" [@titleEnter]="isEnter">
        <div class="subtitle orange"><span [@titleEnterSpan]="{value: isEnter, params: {durationAnim: 0.5}}">Professionalità e competenza:</span></div>
        <div class="subtitle orange"><span [@titleEnterSpan]="{value: isEnter, params: {durationAnim: 0.6}}">ogni nostro rivenditore è</span></div>
        <div class="subtitle orange"><span [@titleEnterSpan]="{value: isEnter, params: {durationAnim: 0.7}}">una parte importante della famiglia</span></div>
        <h1 [@titleEnterText]="isEnter" class="padding-top--30">Tutti i negozi in cui potete trovare biciclette a marchio Zecchini</h1>
    </div>
</section>
<section class="container--full" [@sectionEnter]="isEnter">
    <div class="rivenditori__container" [@sectionItemEnter]="isEnter">
        <div class="rivenditori" *ngFor="let rivenditore of rivenditori">
            <p class="orange"><b>{{rivenditore.name}}</b></p>
            <p class="padding-top--10">{{rivenditore.address}}</p>
            <p>{{rivenditore.city}}</p>
            <p *ngIf="rivenditore.tel">Tel. <a href="tel:+39{{rivenditore.tel}}">{{rivenditore.tel}}</a></p>
            <!-- <p *ngIf="rivenditore.email"><a href="mailto:{{rivenditore.email}}">{{rivenditore.email}}</a></p> -->
            <div class="rivenditori__map"><a href="{{rivenditore.mapUrl}}" target="_blank">Scopri sulla mappa↘</a></div>
            <div class="social" *ngIf="rivenditore.facebookUrl || rivenditore.instagramUrl || rivenditore.websiteUrl">
                <a *ngIf="rivenditore.facebookUrl" href="{{rivenditore.facebookUrl}}" target="_blank">Facebook</a>
                <a *ngIf="rivenditore.instagramUrl" href="{{rivenditore.instagramUrl}}" target="_blank">Instagram</a>
                <a *ngIf="rivenditore.websiteUrl" href="{{rivenditore.websiteUrl}}" target="_blank">Website</a>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>
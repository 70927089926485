import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(private title: Title, private meta: Meta, @Inject(DOCUMENT) private dom) { }

  updateTitle(title: string){
    this.title.setTitle('Zecchini - ' + title);
  }
  updateMetaTags(metaTags: MetaDefinition[]){
    metaTags.forEach(m=> this.meta.updateTag(m));
  }

  initMetaTag() {
    this.meta.addTags([
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Zecchini' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { charset: 'UTF-8' }
    ]);
  }

  setCanonicalURL() {
    let oldlink: HTMLLinkElement = this.dom.querySelector('link[rel=canonical]');
    if (oldlink) {
    oldlink.remove();
    }
    let link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', this.dom.URL);
    }

}


import { trigger, animate, transition, style, query as q, state, animateChild, group } from '@angular/animations';
const query = (style, animate, optional = { optional: true }) => q(style, animate, optional)

export const slideInAnimation = trigger('routeAnimations', [
    transition('* => *', [
      style({ position: 'relative' }),
      query(':leave', [
        style({
          position: 'absolute',
          left: 0,
          top: '{{scroll}}',
          width: '100%',
        })
      ]),
      query(':enter', [
        style({
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          transform: 'translateY(100vh)'
        })
      ]),
      query(':leave', [
        animate('0.5s ease-out', style({ opacity: 0.6 }))
      ]),
      group([
      query(':leave', [
        animate('1s cubic-bezier(0.55, 0.31, 0.15, 0.93)', style({ transform: 'translateY(-50vh)', opacity:0 }))
      ]),
      query(':enter', [
        animate('1s cubic-bezier(0.55, 0.31, 0.15, 0.93)', style({ transform: 'translateY(0)' }))
      ])
    ]),
    ]),
])

import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, Output, PLATFORM_ID, EventEmitter } from '@angular/core';
import gsap, { Power4 } from 'gsap';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {
  isBrowser;
  tlLoading;
  newPercent;
  tlLoadingClose;
  @Output()
  loadingEndEvent = new EventEmitter();
  @Output()
  loadingHiddenEvent = new EventEmitter();

  constructor(@Inject(PLATFORM_ID) platformId: string,) { 
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
  }

  loadingEnd() {
    this.loadingEndEvent.emit();
  }

  loadingHidden() {
    this.loadingHiddenEvent.emit();
  }

  ngAfterViewInit() {
    if(!this.isBrowser) {
      return;
    }

    this.tlLoading = gsap.timeline({
      onUpdate:()=> {
        this.newPercent = Math.floor((this.tlLoading.progress()*100));
      }, onComplete:() => {
        this.tlLoadingClose.play();
      }
    });
    this.tlLoading.set('html, body', {
      overflow: 'hidden'
    }).to('.loading__line', {
      duration:4,
      height: 'calc(40vh - 40px)',
      ease: Power4.easeInOut
    }).to('.loading__logo', {
      duration:0.5,
      delay:0.2,
      autoAlpha:1
    }, '-=4')

    this.tlLoadingClose = gsap.timeline({paused:true, onComplete:()=>{
      this.loadingHidden();
    }})
    this.tlLoadingClose.set('.header__logo', {
      autoAlpha:0
    }).to('.loading__number', {
      duration:0.3,
      opacity:0
    }).call(()=>this.loadingEnd()).to('.loading__line div', {
      duration:0.5,
      opacity:0,
      y:'-100%',
      ease: Power4.easeInOut
    }).to('.loading__logo', {
      duration: 1,
      width: '80px',
      // y: - window.innerHeight / 2,
      y: '-50vh',
      paddingTop: '50px',
      ease: Power4.easeInOut
    }, '-=0.5').to('.loading__logo svg', {
      duration: 1,
      fill: '#203754'
    }, '-=1').to('.loading-logo__data', {
      duration: 1,
      autoAlpha:0
    }, '-=1').set('html, body', {
      overflow: 'unset'
    }).to('.loading__container', {
      duration: 1,
      backgroundColor: 'transparent'
    }, '-=1').set('.header__logo', {
      autoAlpha:1
    })
  }

}

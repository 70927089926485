import { animate, animateChild, group, query, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { AppRoutingTransitionService } from 'src/app/services/app-routing-transition.service';
import { NewsService } from 'src/app/services/news.service';
import { SeoService } from 'src/app/services/seo.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.css'],
  animations: [
    trigger('titleEnter', [
      state('false',
        style({
          opacity: 0
        })
      ),
      transition('false => true', [
        animate('0.3s cubic-bezier(0.55, 0.31, 0.15, 0.93)'),
        group([
          query('@titleEnterSpan', animateChild(), { optional: true }),
        ]),
        group([
          query('@titleEnterText', animateChild(), { optional: true }),
        ])
      ]),
    ]),
    trigger('titleEnterSpan', [
      transition('false => true', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('{{durationAnim}}s cubic-bezier(.6,.36,.07,.99)', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
    ]),
    trigger('titleEnterText', [
      transition('false => true', [
        style({ opacity: 0 }),
        animate('0.3s ease-in', style({ opacity: 1 })),
      ]),
    ]),
    trigger('imgEnter', [
      state('false',
        style({
          opacity: 0,
          transform: 'translateY(15vw)',
        })
      ),
      transition('false => true', [
        animate('1s cubic-bezier(.72,.01,.07,1)'),
        query('@imgScale', animateChild({ duration: 1 }), { optional: true }),
      ]),
    ]),
    trigger('imgScale', [
      state('false',
        style({
          transform: 'scale(1)'
        })
      ),
      transition('false => true', [
        style({
          transform: 'scale(1.05)'
        }),
        animate('1s cubic-bezier(.72,.01,.07,1)')
      ]),
    ])
  ]
})
export class NewsDetailComponent implements OnInit {
  //news = news;
  news;
  public isEnter: boolean = false;
  assetsUrl = environment.assetsUrl;
  myEventSubscription;
  slideConfig = {
    autoplay: false,
    slidesToShow: 1,
    arrows: false,
    dots: true,
  };
  @ViewChild('slickModal') slider: SlickCarouselComponent;

  constructor(
    private route: ActivatedRoute, 
    private seoService: SeoService, 
    private appService: AppRoutingTransitionService,
    private newsService: NewsService) {
   }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.newsService.getPost(params.get('newsId')).subscribe((res:any) => {
          this.news = res;
          this.seoService.updateTitle(res.title);
          this.seoService.updateMetaTags([
            { name: 'description', content: res.subtitle },
            { property: 'og:title', content: "Zecchini - " + res.title },
            { property: 'og:description', content: res.subtitle },
            { property: 'og:image', content: this.assetsUrl + res.image_attachments[0].name },
            { property: 'og:url', content: environment.apiUrl + 'news/' + res.slug },
            { property: 'og:type', content: 'website' },
            { property: 'og:site_name', content: 'Zecchini' },
            { property: 'fb:app_id', content: '141377273234274' },
            { name: "twitter:card", content: "summary_large_image" },
            { name: "twitter:site", content: "Zecchini" },
            { name: "twitter:title", content: "Zecchini - " + res.title },
            { name: "twitter:image", content: this.assetsUrl + res.image_attachments[0].name },
            { name: "twitter:description", content: res.subtitle }
          ]);
      })
    });
    this.myEventSubscription = this.appService.stringSubject.subscribe(() => {
        this.isEnter = true;
      }
    );
  }

  ngAfterViewInit(): void {
    this.slider.unslick = ()=>{};
  }

}

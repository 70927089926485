import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSelectModule} from '@angular/material/select';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatDialogModule} from '@angular/material/dialog';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxMasonryModule } from 'ngx-masonry';



import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from'@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { HomepageComponent } from './views/homepage/homepage.component';
import { ShopComponent } from './views/shop/shop.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { CartComponent } from './views/cart/cart.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { LoginComponent } from './views/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegistrationComponent } from './views/registration/registration.component';
import { CheckoutComponent } from './views/checkout/checkout.component';
import { ThankYouPageComponent } from './views/thank-you-page/thank-you-page.component';
import { ReservedAreaComponent } from './views/reserved-area/reserved-area.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { ProductDetailComponent } from './views/product-detail/product-detail.component';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { CursorComponent } from './shared/cursor/cursor.component';
import { CursorHoverDirective } from './shared/directives/cursor-hover.directive';
import { SliderComponent } from './shared/slider/slider.component';
import { ServiziComponent } from './views/servizi/servizi.component';
import { StoriaComponent } from './views/storia/storia.component';
import { ConsigliComponent } from './views/consigli/consigli.component';
import { PurchaseInformationComponent } from './views/reserved-area/purchase-information/purchase-information.component';
import { ShippingAddressesComponent } from './views/reserved-area/shipping-addresses/shipping-addresses.component';
import { OrdersComponent } from './views/reserved-area/orders/orders.component';
import { HelpComponent } from './views/reserved-area/help/help.component';
import { NewsDetailComponent } from './views/news-detail/news-detail.component';
import { RivenditoriComponent } from './views/rivenditori/rivenditori.component';
import { ContactsComponent } from './views/contacts/contacts.component';
import { LoadingComponent } from './shared/loading/loading.component';
import { ApiCommands } from './http/api.commands';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AddEditShippingAddressesComponent } from './shared/components/add-edit-shipping-addresses/add-edit-shipping-addresses.component';
import { EditBillingInformationsComponent } from './shared/components/edit-billing-informations/edit-billing-informations.component';
import { CustomSnackBarComponent } from './shared/custom-snack-bar/custom-snack-bar.component';
import {MatButtonModule} from '@angular/material/button';
import { RivenditoriDettaglioComponent } from './views/rivenditori-dettaglio/rivenditori-dettaglio.component';
import { BlockLandscapeComponent } from './shared/block-landscape/block-landscape.component';
import { CookieComponent } from './views/cookie/cookie.component';
import { PrivacyComponent } from './views/privacy/privacy.component';
import { TermsComponent } from './views/terms/terms.component';
import { SizeDialogComponent } from './shared/size-dialog/size-dialog.component';
import { AppConfigService } from './app.config.service';
import { StripePaymentComponent } from './shared/components/stripe-payment/stripe-payment.component';
import { NgxStripeModule } from 'ngx-stripe';
import { FullPageLoaderComponent } from './shared/components/full-page-loader/full-page-loader.component';
import { ErrorDialogComponent } from './shared/components/error-dialog/error-dialog.component';
import { PaypalPaymentComponent } from './shared/components/paypal-payment/paypal-payment.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { ActivateAccountComponent } from './views/activate-account/activate-account.component';
import { AccessoriesShippingFeesComponent } from './shared/accessories-shipping-fees/accessories-shipping-fees.component';
import { BikeShippingFeesComponent } from './shared/bike-shipping-fees/bike-shipping-fees.component';
import { environment } from 'src/environments/environment';

export function configService(configService: AppConfigService) {
  return () => configService.load(); 
}

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    ShopComponent,
    HeaderComponent,
    CartComponent,
    FooterComponent,
    LoginComponent,
    RegistrationComponent,
    CheckoutComponent,
    ThankYouPageComponent,
    ReservedAreaComponent,
    ForgotPasswordComponent,
    CursorComponent,
    CursorHoverDirective,
    SliderComponent,
    ServiziComponent,
    StoriaComponent,
    ConsigliComponent,
    PurchaseInformationComponent,
    ShippingAddressesComponent,
    OrdersComponent,
    HelpComponent,
    NewsDetailComponent,
    RivenditoriComponent,
    ContactsComponent,
    LoadingComponent,
    AddEditShippingAddressesComponent,
    EditBillingInformationsComponent,
    CustomSnackBarComponent,
    RivenditoriDettaglioComponent,
    BlockLandscapeComponent,
    CookieComponent,
    PrivacyComponent,
    TermsComponent,
    SizeDialogComponent,
    StripePaymentComponent,
    FullPageLoaderComponent,
    ErrorDialogComponent,
    PaypalPaymentComponent,
    ActivateAccountComponent,
    ProductDetailComponent,
    AccessoriesShippingFeesComponent,
    BikeShippingFeesComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatInputModule,
    MatFormFieldModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatSelectModule,
    MatPaginatorModule,
    FormsModule,
    ReactiveFormsModule,
    SlickCarouselModule,
    NgxMasonryModule,
    HttpClientJsonpModule,
    MatButtonModule,
    MatDialogModule,
    NgxStripeModule.forRoot(environment.stripeKey),
    NgxPayPalModule,
  ],
  providers: [
    ApiCommands,
    // {provide: LocationStrategy, useClass: HashLocationStrategy},
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: configService,
      deps: [AppConfigService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {
  public helpForm: FormGroup;
  @ViewChild('form') form;


  constructor(private formBuilder: FormBuilder, private userService: UserService, private _snackBar: MatSnackBar) { 
    this.helpForm = formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
      title: ['', Validators.required],
      text: ['', Validators.required]
    });
  }

  ngOnInit(): void {
  }

  get f(){
    return this.helpForm.controls;
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
        control.markAsTouched();
    });
  }

  isLoading: boolean = false;
  submitForm(){
    if (this.helpForm.invalid) {
      this.markFormGroupTouched(this.helpForm);
      return
    }
    (<any>window).dataLayer.push({
      'event' : 'user_request',
    });
    this.isLoading = true;
    let data = this.helpForm.value;
    let formData = new FormData();
    formData.append('first_name', data.first_name);
    formData.append('last_name', data.last_name);
    formData.append('email', data.email);
    formData.append('title', data.title);
    formData.append('message', data.text);
    formData.append('request_type', 'request_help');
    formData.append('source', 'request_help');

    this.userService.requestHelp(formData).subscribe(res => {
      (<any>window).dataLayer.push({
        event:'contact_form',
        attributes: {
          name: 'request_help',
        }
      });
      this.form.resetForm();
      this._snackBar.open("Messaggio inviato correttamente", "OK", {duration: 5000});
      this.isLoading = false;
    },
    err => {
      this.isLoading = false;
      this._snackBar.open(err.error.message, "OK");
    })
  }
}

import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, ActivationEnd, NavigationStart, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { ROUTE_NAMES } from 'src/app/constants/route-names';
import { User } from 'src/app/models/user.model';
import { CartService } from 'src/app/services/cart.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-reserved-area',
  templateUrl: './reserved-area.component.html',
  styleUrls: ['./reserved-area.component.css']
})
export class ReservedAreaComponent implements OnInit {
  ROUTE_NAMES = ROUTE_NAMES;
  user$:Observable<User> = this.userService.getUser(); 
  
  constructor(
    private formBuilder: FormBuilder, 
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cartService: CartService) {
      this.toggleFirstContainer();
  }

  ngOnInit(): void {
    this.userService.getUser().subscribe((user:User) => {
      if(user.role.id !='3'){
        this.router.navigate(['/dashboard'])
      }
    })
    this.router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
        this.toggleFirstContainer();
      }
    });
  }

  windowWidth: number = window.innerWidth;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = window.innerWidth; 
    this.toggleFirstContainer();
  }


  firstContainerIsVisible: boolean
  toggleFirstContainer(){
    setTimeout(() => {
      if(this.windowWidth > 1023){
        this.firstContainerIsVisible = true;
        return;
      }
      if (this.activatedRoute.snapshot.children.length == 0) {
        this.firstContainerIsVisible = true;
      } else {
        this.firstContainerIsVisible = false;
      }
    }, 0);
  }

  logout(){
    // localStorage.removeItem('zecchini_token');
    // this.userService.authSubject.next(false);
    // this.userService.setUser({})
    // this.router.navigate(['/']);
    this.userService.createGuestAccess().subscribe((res:any) => {
      this.userService.setUser(res);
      this.userService.authSubject.next(true);
      this.router.navigate(['/']);
      localStorage.setItem('zecchini_token', res.token);
      this.cartService.getCart().subscribe(res => {
        this.cartService.setCartItems(res);
      })
    })
  }
}
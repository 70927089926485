import { Endpoint } from "../models/endpoint.model";

export const API = {
  LOGIN: Endpoint.create('login'),
  FORGOT_PASSWORD: Endpoint.create('users/forgetpassword'),
  RESET_PASSWORD: Endpoint.create('users/resetpassword'),
  ACTIVATE_ACCOUNT: Endpoint.create('users/activateaccount'),
  REGISTER: Endpoint.create('users/register'),
  CONTACT_US: Endpoint.create('contact/contactform'),
  PROFILE:  Endpoint.create('profile'),
  USER_ORDERS:  Endpoint.create('orders'),
  UPDATE_PROFILE:  Endpoint.create('users/updateuser'),
  ADD_ADDRESS: Endpoint.create('addresses'),
  CHANGE_PRIMARY_ADDRESS: Endpoint.create('addresses/changeprimaryaddress/'),
  GET_USER_ADDRESSES: Endpoint.create('addresses'),
  EDIT_ADDRESS: Endpoint.create('addresses/'),
  DELETE_ADDRESS: Endpoint.create('addresses/'),
  GET_ADDRESS_BY_ID: Endpoint.create('addresses/'),
  REQUEST_HELP: Endpoint.create('users/help'),
  GUEST_ACCESS: Endpoint.create('login/guest'),
  PRODUCT_CATEGORIES: Endpoint.create('productcategories'),
  PRODUCT_SUBCATEGORIES: Endpoint.create('productsubcategories'),
  BEST_SELLING_PRODUCTS: Endpoint.create('products/bestselling'),
  PRODUCTS: Endpoint.create('products'),
  CART: Endpoint.create('cart'),
  CHECK_DISCOUNT: Endpoint.create('checkdiscount'),
  MERGE_CARTS: Endpoint.create('mergecart'),
  ORDER: Endpoint.create('orders'),
  PAYMENT_INTENT: Endpoint.create('orders/createpaymentintent'),
  CAPTURE_PAYPAL_ORDER: Endpoint.create('orders/capture/paypal/'),
  REQUEST_RETURN: Endpoint.create('orders/requestreturn/'),
  POST: Endpoint.create('posts'),
};

import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { map, share } from "rxjs/operators";
import { User, Address } from "../models/user.model";
import { ApiCommands } from "../http/api.commands";
import {API} from './api.constants';

@Injectable({
  providedIn: "root",
})

export class UserService {
  private user: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public authSubject: BehaviorSubject<any> = new BehaviorSubject<any>(this.hasToken());

  constructor(private apiCommands: ApiCommands) {}

  public setUser(user){
    this.user.next(user);
  }

  public getUser(): Observable<any> {
    return this.user.pipe(map(user => new User(user)));
  }

  public isLoggedIn(): Observable<boolean> {
    return this.authSubject.asObservable().pipe(share());
  }

  public login(body) {
    return this.apiCommands
      .post(API.LOGIN.url, body)
      .pipe(map((res) => res));
  }

  public getProfile() {
    return this.apiCommands
    .get(API.PROFILE.url, this.getToken())
    .pipe(map(res => res));
  }

  public getUserOrders(obj) {
    let params = "?";
    if(obj.page) params += 'page=' + obj.page + '&';
    return this.apiCommands
    .get(API.USER_ORDERS.url + params, this.getToken())
    .pipe(map(res => res));
  }

  public getUserOrderById(id) {
    return this.apiCommands
    .get(API.USER_ORDERS.url + '/' + id, this.getToken())
    .pipe(map(res => res));
  }

  public requestProductReturn(id, body) {
    return this.apiCommands
    .post(API.REQUEST_RETURN.url + id, body, this.getToken())
    .pipe(map(res => res));
  }

  public updateProfile(body) {
    return this.apiCommands
    .post(API.PROFILE.url, body ,this.getToken())
    .pipe(map(res => res));
  }

  public forgotPassword(body) {
    return this.apiCommands
      .post(API.FORGOT_PASSWORD.url, body)
      .pipe(map((res) => res));
  }

  public resetPassword(body) {
    return this.apiCommands
      .post(API.RESET_PASSWORD.url, body)
      .pipe(map((res) => res));
  }

  public activateAccount(body) {
    return this.apiCommands
      .post(API.ACTIVATE_ACCOUNT.url, body)
      .pipe(map((res) => res));
  }

  public register(body) {
    return this.apiCommands
      .post(API.REGISTER.url, body)
      .pipe(map((res) => res));
  }

  public requestHelp(body) {
    return this.apiCommands
      .post(API.REQUEST_HELP.url, body, this.getToken())
      .pipe(map((res) => res));
  }

  public createGuestAccess() {
    return this.apiCommands
      .post(API.GUEST_ACCESS.url, '')
      .pipe(map((res) => res));
  }

  getAuthHeaders(auth: string) {
    return {
      headers: { Authorization: auth, "Content-Type": "application/json" },
    };
  }

  private getToken() {
    return `${localStorage.getItem("zecchini_token")}`;
  }

  private hasToken() {
    return !!localStorage.getItem('zecchini_token')
  }
}

import { Directive, HostListener, Input } from '@angular/core';

import gsap from 'gsap';

@Directive({
  selector: '[appCursorHover]'
})
export class CursorHoverDirective {
  @Input() cursorUrl: string;
  @Input() noLinkEvent: boolean;
  constructor() {}

  @HostListener('mouseenter') mouseover() {
    gsap.to('.cursor-circle', {
      width: 80,
      height: 80,
      backgroundColor: 'transparent'
    })

    gsap.to('#cursorImage', {
      backgroundImage: this.cursorUrl,
      opacity:1
    })
  }

  @HostListener('mouseleave') mouseleave() {
    gsap.to('.cursor-circle', {
      width: 20,
      height: 20,
      backgroundColor: '#aeb1b1'
    })
    gsap.to('#cursorImage', {
      opacity: 0
    })
  }

  @HostListener('click') click() {
    if(!this.noLinkEvent) {
      gsap.to('.cursor-circle', {
        width: 20,
        height: 20,
        backgroundColor: '#aeb1b1'
      })

      gsap.to('#cursorImage', {
        opacity: 0
      })
    }
  }

}

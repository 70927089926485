import { environment } from "src/environments/environment";
import { Product, ProductVariant } from "./product.model"

export interface CartModel {
    id: string;
    product_variant_id: string;
    product_id: string;
    user_id: string;
    quantity: any;
    product: Product;
    product_variant: ProductVariant;
    image?: any;
    hasQuantity?: boolean;
    hasCorrectQuantity?: boolean;
};

export class CartItem implements CartModel {
    id: string;
    product_variant_id: string;
    product_id: string;
    user_id: string;
    quantity: any;
    product: Product;
    product_variant: ProductVariant;
    image?: any;
    hasQuantity?: boolean;
    hasCorrectQuantity?: boolean;

	constructor(cart: CartModel, categories, subcategories) {
        if(cart) Object.assign(this, cart);
        this.product = new Product(cart.product, categories, subcategories);
        this.quantity = parseInt(cart.quantity);
        this.hasQuantity = !(parseInt(cart?.product_variant?.available_quantity) <= 0); 
        this.hasCorrectQuantity = !(parseInt(cart.quantity) > parseInt(cart?.product_variant?.available_quantity));
	}
}
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiCommands } from '../http/api.commands';
import { API } from './api.constants';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor(private apiCommands: ApiCommands) { }

  public getAddresses() {
    return this.apiCommands
    .get(API.GET_USER_ADDRESSES.url, this.getToken())
    .pipe(map(res => res));
  }

  public getAddressesById(id) {
    return this.apiCommands
    .get(API.GET_ADDRESS_BY_ID.url + id, this.getToken())
    .pipe(map(res => res));
  }

  public addAddress(body) {
    return this.apiCommands
    .post(API.ADD_ADDRESS.url, body, this.getToken())
    .pipe(map(res => res));
  }

  public editAddress(body, id) {
    return this.apiCommands
    .post(API.EDIT_ADDRESS.url + id, body, this.getToken())
    .pipe(map(res => res));
  }

  public changePrimaryAddress(id) {
    return this.apiCommands
    .post(API.CHANGE_PRIMARY_ADDRESS.url + id, '', this.getToken())
    .pipe(map(res => res));
  }

  private getToken() {
    return `${localStorage.getItem("zecchini_token")}`;
  }
}

export const rivenditori = [
    {
        slug:'serlonghi',
        name: 'Serlonghi',
        description: null,
        descriptionHTML: null,
        address: 'Via Guglielmo Marconi, 18',
        city: 'Calcinato (BS)',
        tel: '030963190',
        mapUrl: 'https://www.google.com/maps/place/Elettrodomestici+Serlonghi+Ennio+E+C.+Snc/@45.4572969,10.4175419,15z/data=!4m5!3m4!1s0x0:0xa800342f9afb2c58!8m2!3d45.4572969!4d10.4175419',
        facebookUrl: null,
        instagramUrl: null,
        websiteUrl: null,
        img:null,
        email:null
    },{
        slug:'hobby-bike',
        name: 'Hobby Bike',
        description: null,
        descriptionHTML: null,
        address: 'Via Monsignor Oscar Romero, 1',
        city: 'Montichiari (BS)',
        tel: '0309650839',
        mapUrl: 'https://www.google.com/maps/place/Hobby+Bike+di+Carini+Giovanni/@45.4175004,10.4016904,15z/data=!4m2!3m1!1s0x0:0xe7537ebc61ad0d77?sa=X&ved=2ahUKEwiVjsf9jKjvAhWFHuwKHfm_B8kQ_BIwEnoECCcQBQ',
        facebookUrl: 'https://www.facebook.com/hobby.montichiari/',
        instagramUrl: 'https://www.instagram.com/hobbybike/',
        websiteUrl: 'https://www.hobby-bike.it/',
        img:null,
        email:null
    },{
        slug:'tosoni-paolo',
        name: 'Tosoni Paolo',
        description: null,
        descriptionHTML: null,
        address: 'Via San Michele, 5',
        city: 'Prevalle (BS)',
        tel: '030603221',
        mapUrl: 'https://www.google.com/maps/place/Via+S.+Michele,+5,+25080+Prevalle+BS/@45.5498476,10.4246188,17z/data=!4m13!1m7!3m6!1s0x47819a8e6ab81117:0x9e5f5061b5b6ba10!2sVia+S.+Michele,+5,+25080+Prevalle+BS!3b1!8m2!3d45.5498231!4d10.4246334!3m4!1s0x47819a8e6ab81117:0x9e5f5061b5b6ba10!8m2!3d45.5498231!4d10.4246334',
        facebookUrl: null,
        instagramUrl: null,
        websiteUrl: null,
        img:null,
        email:null
    },{
        slug:'b-bike',
        name: 'B-Bike',
        description: null,
        descriptionHTML: null,
        address: 'Via Trento, 88',
        city: 'Nuvolento (BS)',
        tel: '0303375928',
        mapUrl: 'https://www.google.com/maps/place/B+Bike/@45.542572,10.3875453,15z/data=!4m2!3m1!1s0x0:0x5991670f62c88430?sa=X&ved=2ahUKEwj0v4-SkqjvAhXJKewKHVDYDtQQ_BIwCnoECBwQBQ',
        facebookUrl: 'https://www.facebook.com/b.bikenuvolento',
        instagramUrl: 'https://www.instagram.com/b.bike_nuvolento/?hl=it',
        websiteUrl: null,
        img:null,
        email:'b-bike@outlook.com'
    },{
        slug:'mr-bike',
        name: 'Mr-Bike',
        description: null,
        descriptionHTML: null,
        address: 'Piazza Paganora, 20',
        city: 'Mazzano (BS)',
        tel: '0302794287',
        mapUrl: 'https://www.google.com/maps/place/Mr+Bike/@45.5180347,10.3542176,15z/data=!4m2!3m1!1s0x0:0xfd816a96fea06451?sa=X&ved=2ahUKEwj51rKok6jvAhWLtKQKHeglBkkQ_BIwEnoECCcQBQ',
        facebookUrl: 'https://www.facebook.com/MR-BIKE-349729201875285?locale=it_IT',
        instagramUrl: null,
        websiteUrl: null,
        img:null,
        email: null,
    },{
        slug:'egg-s-bike',
        name: 'EGG’s Bike',
        description: null,
        descriptionHTML: null,
        address: 'Via Camillo Benso Conte di Cavour, 9',
        city: 'Casalmorano (CR)',
        tel: '037474175',
        mapUrl: 'https://www.google.com/maps/place/Guadrini+Enrico/@45.2898862,9.8985951,15z/data=!4m2!3m1!1s0x0:0x17fa17e9c9c2aaed?sa=X&ved=2ahUKEwjpytCnjajvAhXZOewKHUlfDkEQ_BIwFHoECCYQBQ',
        facebookUrl: 'https://www.facebook.com/cicliguadrini',
        instagramUrl: 'https://www.instagram.com/cicliguadrini/',
        websiteUrl: 'http://www.cicliguadrini.it/',
        img:null,
        email:'info@cicliguadrini.it'
    },{
        slug:'only-bike',
        name: 'Only Bike',
        description: null,
        descriptionHTML: null,
        address: 'Via Camillo Benso Cavour, 43',
        city: 'Manerbio (BS)',
        tel: '0309937645',
        mapUrl: 'https://www.google.com/maps/place/Only+Bike+Di+Omissoni+Roberto/@45.3520812,10.1433256,15z/data=!4m2!3m1!1s0x0:0x2f19b9b9659ac08b?sa=X&ved=2ahUKEwifq6ixjajvAhVINewKHSmDA_8Q_BIwCnoECBwQBQ',
        facebookUrl: 'https://www.facebook.com/Only-Bike-664909086956114?locale=it_IT',
        instagramUrl: null,
        websiteUrl: 'http://only-bike.com/',
        img:'rivenditori1',
        email:'info@pippo.it'
    },{
        slug:'mafezzoni-biciclette',
        name: 'Mafezzoni Biciclette',
        description: null,
        descriptionHTML: null,
        address: 'Via Badia, 64',
        city: 'Leno (BS)',
        tel: '3342669403',
        mapUrl: 'https://www.google.com/maps/place/Mafezzoni+Biciclette/@45.3641925,10.219702,15z/data=!4m2!3m1!1s0x0:0x2c4cc79a264d63f0?sa=X&ved=2ahUKEwicy7i3jajvAhUXHOwKHc0tC9YQ_BIwEnoECCMQBQ',
        facebookUrl: 'https://www.facebook.com/Mafezzoni-bike-879216678834561/',
        instagramUrl: null,
        websiteUrl: null,
        img:null,
        email:'mafezzonibiciclette@hotmail.it'
    },{
        slug:'zaccaria-maurizio',
        name: 'Zaccaria Maurizio',
        description: null,
        descriptionHTML: null,
        address: 'Via Cavour, 21',
        city: 'Gambara (BS)',
        tel: '3336708246',
        mapUrl: 'https://www.google.com/maps/place/Maurizio+Zaccaria/@45.252381,10.2974631,15z/data=!4m2!3m1!1s0x0:0x1e2fbb5d2bcf3fc7?sa=X&ved=2ahUKEwj455K_jajvAhWnPOwKHedSDT8Q_BIwCnoECBgQBQ',
        facebookUrl: 'https://www.facebook.com/Riparazione-e-vendita-biciclette-Maurizio-Zaccaria-107542174164565?locale=it_IT',
        instagramUrl: null,
        websiteUrl: null,
        img:null,
        email:null
    },{
        slug:'barbieri-domenico',
        name: 'Barbieri Domenico',
        description: null,
        descriptionHTML: null,
        address: 'Via A.Zanaboni, 12',
        city: 'Isorella (BS)',
        tel: '0309958179',
        mapUrl: 'https://www.google.com/maps/place/Barbieri+Domenico/@45.309568,10.323031,15z/data=!4m2!3m1!1s0x0:0xa80c797129748f3b?sa=X&ved=2ahUKEwiW85XGjajvAhXHhqQKHXPtChoQ_BIwCnoECBgQBQ',
        facebookUrl: null,
        instagramUrl: null,
        websiteUrl: null,
        img:null,
        email:null
    },{
        slug:'capelli-moto',
        name: 'Capelli Moto',
        description: null,
        descriptionHTML: null,
        address: 'Via Visano, 75',
        city: 'Isorella (BS)',
        tel: '0309958840',
        mapUrl: 'https://www.google.com/maps/place/Capelli+Moto/@45.3099249,10.3354474,15z/data=!4m2!3m1!1s0x0:0x45ade5f8f2e5836?sa=X&ved=2ahUKEwjAxvGu1PvyAhUqgf0HHaR9AXsQ_BJ6BAhiEAU',
        facebookUrl: 'https://www.facebook.com/Capelli-Moto-125665047607242/',
        instagramUrl: 'https://www.instagram.com/capelli_moto/?hl=it',
        websiteUrl: 'http://www.capellimoto.it/',
        img:null,
        email:'info@capellimoto.it'
    },{
        slug:'png-bikes',
        name: 'PGN Bikes di Pagani Alberto',
        description: null,
        descriptionHTML: null,
        address: 'Via Fermi, 67',
        city: "Urago D'oglio BS (BS)",
        tel: '3394073231',
        mapUrl: 'https://www.google.com/maps/place/Pgn+Bikes+di+Pagani+Alberto/@45.5167312,9.868269,15z/data=!4m2!3m1!1s0x0:0x5f06f79655803fc7?sa=X&ved=2ahUKEwig7pbXjajvAhVG3KQKHVM7BvYQ_BIwCnoECBgQBQ',
        facebookUrl: null,
        instagramUrl: null,
        websiteUrl: 'https://pgnbikes.business.site/',
        img:null,
        email:null
    },{
        slug:'cicli-mant-s',
        name: 'Cicli Mant’s',
        description: null,
        descriptionHTML: null,
        address: 'Viale Teosa, 21',
        city: 'Chiari (BS)',
        tel: '0307001010',
        mapUrl: 'https://www.google.com/maps/place/Ciclimant+S+Di+Mantegari+Aldo+C/@45.5363209,9.9254574,15z/data=!4m2!3m1!1s0x0:0xe53577077fb02d44?sa=X&ved=2ahUKEwiUnanimqjvAhVLwAIHHVeJCMsQ_BIwG3oECC0QBQ',
        facebookUrl: 'https://www.facebook.com/Ciclimant-s-1576107735934755?locale=it_IT',
        instagramUrl: null,
        websiteUrl: 'http://www.ciclimants.it/',
        img:null,
        email:'ciclimants@libero.it'
    },{
        slug:'roby-moto',
        name: 'Roby Moto',
        description: null,
        descriptionHTML: null,
        address: 'Via Sarnico, 9',
        city: 'Capriolo (BS)',
        tel: '0307365431',
        mapUrl: 'https://www.google.com/maps/place/CICLI+ROBY+CAPRIOLO/@45.6428375,9.9348757,15z/data=!4m2!3m1!1s0x0:0x1afd6320f76f16b5?sa=X&ved=2ahUKEwjd9oKYnqjvAhWKHOwKHVd9DHMQ_BIwCnoECBoQBQ',
        facebookUrl: 'https://www.facebook.com/CICLIROBYCAPRIOLO/',
        instagramUrl: null,
        websiteUrl: null,
        img:null,
        email:'ciclirobymoto@libero.it'
    },{
        slug:'cicli-foresti-andrea',
        name: 'Cicli Foresti Andrea',
        description: null,
        descriptionHTML: null,
        address: 'Via Provinciale Valle Calepio, 80/A',
        city: 'Castelli Calepio (BG)',
        tel: '0354425588',
        mapUrl: 'https://www.google.com/maps/place/Cicli+Foresti+Andrea/@45.6535986,9.9180906,15z/data=!4m2!3m1!1s0x0:0xc1052105f1f75ec4?sa=X&ved=2ahUKEwjj-8btjajvAhUJ_KQKHSywBbwQ_BIwCnoECBsQBQ',
        facebookUrl: 'https://www.facebook.com/profile.php?id=100063583693234&locale=it_IT',
        instagramUrl: 'https://www.instagram.com/cicliforesti/?hl=it',
        websiteUrl: 'https://www.cicliforestiandrea.com/',
        img:null,
        email:'ciclirobymoto@libero.it'
    },{
        slug:'vagliani-giulio',
        name: 'Vagliani Giulio Ferramenta Utensileria',
        description: null,
        descriptionHTML: null,
        address: 'Viale Europa, 37',
        city: "Canneto sull'Oglio (MN)",
        tel: '037670669',
        mapUrl: 'https://www.google.com/maps/place/Vagliani+Giulio/@45.1513134,10.3826836,15z/data=!4m2!3m1!1s0x0:0x84ef6825236d833b?sa=X&ved=2ahUKEwjDqqrOkcvvAhVSDOwKHZXXAw4Q_BIwCnoECBoQBQ',
        facebookUrl: 'https://www.facebook.com/FerramentaBiciVagliani',
        instagramUrl: 'https://www.instagram.com/ferramentavaglianigiulio/',
        websiteUrl: 'https://ferramenta-utensileria-vagliani-giulio.business.site/',
        img:null,
        email:'vagliani.giulio@gmail.com'
    },{
        slug:'riparazioni-da-piero',
        name: 'Riparazioni da Piero',
        description: null,
        descriptionHTML: null,
        address: 'Via Gramsci, 24/26',
        city: "Castenedolo (BS)",
        tel: '3496872190',
        mapUrl: 'https://www.google.it/maps/place/Riparazioni+da+Piero/@45.4698776,10.2971423,17z/data=!4m12!1m6!3m5!1s0x47819fdd4fa4579b:0x925515542b70cc0b!2sRiparazioni+da+Piero!8m2!3d45.4698587!4d10.2993562!3m4!1s0x47819fdd4fa4579b:0x925515542b70cc0b!8m2!3d45.4698587!4d10.2993562',
        facebookUrl: 'https://www.facebook.com/Riparazioni-da-Piero-100941758635455?locale=it_IT',
        instagramUrl: 'https://www.instagram.com/riparazionidapiero/?hl=it',
        websiteUrl: null,
        img:null,
        email:'riparazionidapiero@alice.it'
    },{
        slug:'giomar-bike',
        name: 'Giomar Bike',
        description: null,
        descriptionHTML: null,
        address: 'Via Caduti Del Lavoro, 60',
        city: "Carpenedolo (BS)",
        tel: '3929963789',
        mapUrl: 'https://www.google.com/maps/uv?pb=!1s0x4781bd34179cc953%3A0xd822573a912607e4!3m1!7e115!4s%2Fmaps%2Fplace%2Fgiomar%2Bbike%2F%4045.3548899%2C10.4366916%2C3a%2C75y%2C169.8h%2C90t%2Fdata%3D*213m4*211e1*213m2*211swX7Ot1MO4LJZoaANgnG9aQ*212e0*214m2*213m1*211s0x4781bd34179cc953%3A0xd822573a912607e4%3Fsa%3DX!5sgiomar%20bike%20-%20Cerca%20con%20Google!15sCgIgAQ&imagekey=!1e2!2swX7Ot1MO4LJZoaANgnG9aQ&hl=it&sa=X&ved=2ahUKEwik74XWufvyAhXYhP0HHY3yCgsQpx96BAhkEAg',
        facebookUrl: 'https://www.facebook.com/circuitogiomar',
        instagramUrl: 'https://www.instagram.com/giomarbike/?hl=it',
        websiteUrl: null,
        img:null,
        email:'giomarbike@gmail.com'
    },{
        slug:'lissignoli-gino',
        name: 'Lissignoli Gino & C. S.N.C.',
        description: null,
        descriptionHTML: null,
        address: 'Via Europa, 110',
        city: "Passirano (BS)",
        tel: '0306850658',
        mapUrl: 'https://www.google.com/maps/place/Lissignoli+Gino+%26+C.+S.N.C./@45.6222742,10.0704607,14.99z/data=!4m5!3m4!1s0x0:0x5f980f48406ff102!8m2!3d45.6222687!4d10.070513',
        facebookUrl: null,
        instagramUrl: null,
        websiteUrl: null,
        img:null,
        email:null
    },{
        slug:'garda-bike-center',
        name: 'Garda Bike Center',
        description: null,
        descriptionHTML: null,
        address: 'Via Trevisago, 53',
        city: "Manerba del Garda (BS)",
        tel: '03651920038',
        mapUrl: 'https://www.google.com/maps?ll=45.539238,10.532003&z=14&t=m&hl=it-IT&gl=IT&mapclient=embed&cid=14634972704369066581',
        facebookUrl: 'https://www.facebook.com/gardabikecenter',
        instagramUrl: 'https://www.instagram.com/gardabikecenter/',
        websiteUrl: 'http://www.gardabikecenter.com/',
        img:null,
        email:'info@gardabikecenter.com'
    },{
        slug:'officina-cigala-billy',
        name: 'Officina Cigala Billy',
        description: null,
        descriptionHTML: null,
        address: 'Via Isorella, 25',
        city: "Calvisano (BS)",
        tel: '3385015626',
        mapUrl: 'https://www.google.com/maps/place/Officina+Cigala+Billy/@45.3428351,10.3412292,3a,75y,350h,83.57t/data=!3m7!1e1!3m5!1s9-6LCCcEcm58bOKzwlzYSQ!2e0!6shttps:%2F%2Fstreetviewpixels-pa.googleapis.com%2Fv1%2Fthumbnail%3Fpanoid%3D9-6LCCcEcm58bOKzwlzYSQ%26cb_client%3Dsearch.gws-prod.gps%26w%3D86%26h%3D86%26yaw%3D98.31593%26pitch%3D0%26thumbfov%3D100!7i16384!8i8192!4m5!3m4!1s0x0:0x9bba2f46d972c0dc!8m2!3d45.3428219!4d10.3414399',
        facebookUrl: null,
        instagramUrl: null,
        websiteUrl: null,
        img:null,
        email:null
    },{
        slug:'autoaccessori-zani-battista',
        name: 'Autoaccessori Zani Battista',
        description: null,
        descriptionHTML: null,
        address: 'Via Giuseppe Mazzini, 146',
        city: "Lumezzane (BS)",
        tel: '030829826',
        mapUrl: 'https://www.google.com/maps/place/Autoaccessori+Zani+Battista/@45.6518554,10.2531304,15z/data=!4m2!3m1!1s0x0:0xed4cce60ea570aa3?sa=X&ved=2ahUKEwicuv3SuvvyAhXI_7sIHUKfAnoQ_BJ6BAhTEAU',
        facebookUrl: null,
        instagramUrl: 'https://www.instagram.com/zani_autoaccessori/',
        websiteUrl: null,
        img:null,
        email:null
    },{
        slug:"the-bikers-dream",
        name: "The biker's dream",
        description: null,
        descriptionHTML: null,
        address: 'Via Carpenedolo, 84',
        city: "Castiglione delle Stiviere (MN)",
        tel: '3458497115',
        mapUrl: 'https://www.google.com/maps/place/Masini+Giancarlo/@45.3847398,10.4749882,15z/data=!4m2!3m1!1s0x0:0x4ce6d108d34ae88c?sa=X&ved=2ahUKEwj1mOu5yfvyAhUc_7sIHX_6AUMQ_BJ6BAhQEAU',
        facebookUrl: 'https://www.facebook.com/davidemasini99/?ref=page_internal',
        instagramUrl: 'https://www.instagram.com/the_bikers_dream_/',
        websiteUrl: null,
        img:null,
        email:null
    },{
        slug:"guerandi-carlo",
        name: "Guerandi Carlo",
        description: null,
        descriptionHTML: null,
        address: 'Via G. Galilei, 3',
        city: "Orzinuovi (BS)",
        tel: '030941647',
        mapUrl: 'https://www.google.com/maps/place/Guerandi+Carlo/@45.4032051,9.9249571,15z/data=!4m5!3m4!1s0x0:0xb36e848e6caf452d!8m2!3d45.4032137!4d9.9249589',
        facebookUrl: null,
        instagramUrl: null,
        websiteUrl: null,
        img:null,
        email:null
    },{
        slug:"mb-motors-bikes",
        name: "MB Motors & Bikes",
        description: null,
        descriptionHTML: null,
        address: 'Via Michelangelo Buonarroti, 100',
        city: "Forte dei Marmi (LU)",
        tel: '05841851152',
        mapUrl: 'https://www.google.com/maps/place/MB+Motors+%26+Bikes/@43.9677556,10.177651,15z/data=!4m2!3m1!1s0x0:0x6137fc4e2f474a33?sa=X&ved=2ahUKEwiJ25m71Pn0AhUDhv0HHUyMCDUQ_BJ6BAgoEAU',
        facebookUrl: "https://www.facebook.com/mbmotorsbikes",
        instagramUrl: "https://www.instagram.com/mb_motorsbikes/",
        websiteUrl: null,
        img:null,
        email:"info@mbmotorsbikes.com"
    }
]
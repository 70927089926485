import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import gsap from 'gsap';

@Component({
  selector: 'app-cursor',
  templateUrl: './cursor.component.html',
  styleUrls: ['./cursor.component.css']
})
export class CursorComponent implements OnInit {
  @ViewChild('cursor') cursor;
  pos;
  mouse;
  speed;
  xSet;
  ySet;
  isBrowser;

  constructor(@Inject(PLATFORM_ID) platformId: string) { 
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    if (!this.isBrowser) {
      return;
    }
    //gsap.set('.cursor-circle', {xPercent: -50, yPercent: -50});
    this.pos = {
      x: window.innerWidth / 2,
      y: window.innerHeight / 2
    };
    this.mouse = {
      x: this.pos.x,
      y: this.pos.y
    };
    this.speed = 0.05;
    this.cursor = '.cursor-circle';
    this.xSet = gsap.quickSetter(this.cursor, 'x', 'px');
    this.ySet = gsap.quickSetter(this.cursor, 'y', 'px');
    window.addEventListener('mousemove', e => {
      this.mouse.x = e.x;
      this.mouse.y = e.y;
    });
    gsap.ticker.add(() => {
      this.pos.x += (this.mouse.x - this.pos.x) * this.speed;
      this.pos.y += (this.mouse.y - this.pos.y) * this.speed;
      this.xSet(this.pos.x + 10);
      this.ySet(this.pos.y + 5);
    });
  }

}

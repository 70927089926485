import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-size-dialog',
  templateUrl: './size-dialog.component.html',
  styleUrls: ['./size-dialog.component.css']
})
export class SizeDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit, Output, ViewChild, EventEmitter, Input } from '@angular/core';
 
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import {
  StripeCardElementOptions,
  StripeElementsOptions
} from '@stripe/stripe-js';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CartItem } from 'src/app/models/cart.model';
import { CartService } from 'src/app/services/cart.service';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user.model';
import { AddressService } from 'src/app/services/address.service';
import { CheckoutService } from 'src/app/services/checkout.service';
import { Router } from '@angular/router';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { shippingFee } from 'src/app/constants/shipping-fee';

@Component({
  selector: 'app-stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: ['./stripe-payment.component.css']
})
export class StripePaymentComponent implements OnInit {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  public paymentForm: FormGroup;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };
 
  elementsOptions: StripeElementsOptions = {
    locale: 'it'
  };
 
  constructor(
    private stripeService: StripeService, 
    private formBuilder: FormBuilder,
    private cartService: CartService,
    private userService: UserService,
    private addressService: AddressService,
    private checkoutService: CheckoutService,
    private router: Router,
    private dialog: MatDialog) {
    this.paymentForm = formBuilder.group({
      paymentTerms: ["", Validators.required],
    });
  }

  @Input() orderItems = [];
  @Input() discountCode: string;
  buyerDetails: any = {};
  @Output() onPaymentLoading = new EventEmitter();
  @Input() analyticsOrderItems = [];
  isStripeValid: boolean = false;

  onStripeChange(event){
    this.isStripeValid = event.complete;
  }

  openDialog(message): void {
    this.dialog.open(ErrorDialogComponent, {
      width: '60%',
      data: {message: message}
    });
  }

  cart:any;
  ngOnInit(): void {
    // this.dataLayers()
    this.cartService.getCartItems().subscribe(res => {
      if(res && res.length > 0 ){
        this.cart = res;
      }
    })
    this.getInformations()
  }

  paymentComplete() {
    this.paymentInfoDataLayer(this.cart)
    this.onPaymentLoading.emit(true);
    this.stripeService.createToken(this.card.element).subscribe((result) => {
      if(result.token) {
        this.createPaymentIntent();
      } else if (result.error) {
        // Error creating the token
        this.onPaymentLoading.emit(false);
        this.openDialog(result.error.message);
      }
    })
  }

  // payment complete
  dataLayers(paymentIntentId, currency, amount) {
    (<any>window).dataLayer.push({
      event: 'purchase',
      payment_type: 'Stripe',
      ecommerce: {
        currency: currency, // Required.
        value: amount / 100,
        tax: ((22 / 100) * parseFloat(amount)) / 100,
        shipping: shippingFee,
        affiliation: 'Online store',
        transaction_id: paymentIntentId, // Required.
        coupon: this.discountCode ? this.discountCode : '',
        items: this.analyticsOrderItems
      }
    });
    this.router.navigate(["/thank-you-page"]);
  }

  get f() {
    return this.paymentForm.controls;
  }

  createPaymentIntent(){
    let formData = new FormData();
    formData.append('order_items', JSON.stringify(this.orderItems));
    if(this.discountCode) formData.append('discount_code', this.discountCode);
    this.checkoutService.createPaymentIntent(formData).subscribe((res: any) => {
      this.stripeService.confirmCardPayment(res.client_secret, {payment_method: { card: this.card.element}}).subscribe(res_ => {
        if(res_.error){
          this.onPaymentLoading.emit(false);
          this.openDialog(res_.error.message);
          return;
        } else {
          this.createOrder(res_.paymentIntent.id,res_.paymentIntent.currency, res_.paymentIntent.amount);
        }
      })
    })
  }

  createOrder(paymentIntentId, currency, amount){
    let formData = new FormData();
    formData.append('order_items', JSON.stringify(this.orderItems));
    formData.append('buyer_details', JSON.stringify(this.buyerDetails));
    formData.append('payment_intent_id', paymentIntentId);
    formData.append('payment_type_id', '1');
    if(this.discountCode) formData.append('discount_code', this.discountCode);

    this.checkoutService.createOrder(formData).subscribe(res => {
      if(localStorage.getItem('discount_code')) localStorage.removeItem('discount_code');
      if(localStorage.getItem('guest_addresses')) localStorage.removeItem('guest_addresses');
      if(localStorage.getItem('guest_user')) localStorage.removeItem('guest_user');
      localStorage.setItem('isRegisteredGuest', '0')
      this.onPaymentLoading.emit(false);
      this.cartService.deleteCart().subscribe(res => {
        // this.cartService.getCart().subscribe(res => {
          this.cartService.setCartItems([])

          if(this.user.role.id == '4' && this.user.registration_guest == '1' && this.user.active == '0'){
            this.userService.createGuestAccess().subscribe((res:any) => {
              this.userService.setUser(res);
              this.userService.authSubject.next(true);
              localStorage.setItem('zecchini_token', res.token);
              localStorage.setItem('show_guest_message', '1')
              this.dataLayers(paymentIntentId, currency, amount);
              this.cartService.getCart().subscribe(res => {
                this.cartService.setCartItems(res);
              })
            })
          } else {
            this.dataLayers(paymentIntentId, currency, amount);
          }
        // })
      })
    }, err => {
      this.openDialog(err.error.message);
      this.onPaymentLoading.emit(false);
    })
  }

  user: User;
  getInformations(){
    this.userService.getUser().subscribe((user: User) => {
      if (user) {
        this.user = user;
        let isGuest = user.user_type ? false : true;

        if(!isGuest || localStorage.getItem('isRegisteredGuest') == '1'){
          this.addressService.getAddresses().subscribe((addresses: any) => {
            this.createBuyerDetails(user, addresses);
          });
        } else if(isGuest && localStorage.getItem('isRegisteredGuest') != '1') {
          let guest_user: User = JSON.parse(localStorage.getItem('guest_user'))
          let guest_addresses = JSON.parse(localStorage.getItem('guest_addresses'));
          this.createBuyerDetails(guest_user, guest_addresses);
        }
      } 
    });
  }

  createBuyerDetails(user, addresses){
    let primaryAddress = addresses.filter(item => item.primary_address == '1')[0];
    
    this.buyerDetails.first_name = user?.first_name;
    this.buyerDetails.last_name = user?.last_name;
    this.buyerDetails.email = user?.email;
    this.buyerDetails.phone = user?.phone;
    this.buyerDetails.address = user?.address;
    this.buyerDetails.postal_code = user?.postal_code;
    this.buyerDetails.city = user?.city;
    this.buyerDetails.province = user?.province;
    this.buyerDetails.notes = user?.shipping_notes || user?.notes || user?.note;
    this.buyerDetails.receive_invoice = user?.receive_invoice;
    this.buyerDetails.newsletter_subscribed = user?.newsletter_subscribed;
    this.buyerDetails.accept_privacy = user?.accept_privacy;
    this.buyerDetails.business_name = user?.business_name;
    this.buyerDetails.vat_number = user?.vat_number;
    this.buyerDetails.pec = user?.pec;
    this.buyerDetails.sdi = user?.sdi;
    this.buyerDetails.client_type = user?.client_type || user?.user_type;
    this.buyerDetails.different_shipping = user?.different_shipping;
    this.buyerDetails.fiscal_code = user?.fiscal_code;
    this.buyerDetails.shipping_first_name = primaryAddress?.first_name;
    this.buyerDetails.shipping_last_name = primaryAddress?.last_name;
    this.buyerDetails.shipping_phone = primaryAddress?.phone;
    this.buyerDetails.shipping_province = primaryAddress?.province;
    this.buyerDetails.shipping_city = primaryAddress?.city;
    this.buyerDetails.shipping_address = primaryAddress?.address;
    this.buyerDetails.shipping_postal_code = primaryAddress?.postal_code;
    this.buyerDetails.shipping_notes = primaryAddress?.shipping_notes || primaryAddress?.notes;
  }

  paymentInfoDataLayer(cart){
    let dataLayerCartItems = [];
    cart.forEach((item,index) => {
      dataLayerCartItems.push(
        {
          item_name: item?.product?.title, // Name or ID is required.
          item_id: item?.product?.id, // Name or ID is required.
          price: item?.product?.price * item?.product?.quantity,
          item_brand: item?.product?.producer_name,
          item_category: item?.product?.category_label,
          item_category_2: item?.product?.subcategory_label, // The categories can be up to 4
          // item_variant: item.product_variant,
          // index: index+1, // If associated with a list selection.
          quantity: item?.product?.quantity
        }
      ) 
    })
    setTimeout(() => {
      (<any>window).dataLayer.push({
        event: 'add_payment_info', 
        payment_type: 'Stripe',
        ecommerce: {
          items: dataLayerCartItems
        }
      });
    }, 500);
  }
}
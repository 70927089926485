import { animate, animateChild, group, query, state, style, transition, trigger } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppRoutingTransitionService } from 'src/app/services/app-routing-transition.service';
import { SeoService } from 'src/app/services/seo.service';
import { environment } from 'src/environments/environment';
import { rivenditori } from '../../constants/rivenditori'

@Component({
  selector: 'app-rivenditori-dettaglio',
  templateUrl: './rivenditori-dettaglio.component.html',
  styleUrls: ['./rivenditori-dettaglio.component.css'],
  animations: [
    trigger('titleEnter', [
      state('false',
        style({
          opacity: 0
        })
      ),
      transition('false => true', [
        animate('0.3s cubic-bezier(0.55, 0.31, 0.15, 0.93)'),
        group([
          query('@titleEnterSpan', animateChild(), { optional: true }),
        ]),
        group([
          query('@titleEnterText', animateChild(), { optional: true }),
        ])
      ]),
    ]),
    trigger('titleEnterSpan', [
      transition('false => true', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('{{durationAnim}}s cubic-bezier(.6,.36,.07,.99)', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
    ]),
    trigger('titleEnterText', [
      transition('false => true', [
        style({ opacity: 0 }),
        animate('0.3s ease-in', style({ opacity: 1 })),
      ]),
    ]),
    trigger('imgEnter', [
      state('false',
        style({
          opacity: 0,
          transform: 'translateY(15vw)',
        })
      ),
      transition('false => true', [
        animate('1s cubic-bezier(.72,.01,.07,1)'),
        query('@imgScale', animateChild({ duration: 1 }), { optional: true }),
      ]),
    ]),
    trigger('imgScale', [
      state('false',
        style({
          transform: 'scale(1)'
        })
      ),
      transition('false => true', [
        style({
          transform: 'scale(1.25)'
        }),
        animate('1s cubic-bezier(.72,.01,.07,1)')
      ]),
    ])
  ]
})
export class RivenditoriDettaglioComponent implements OnInit {
  public isEnter: boolean = false;
  rivenditori;
  isBrowser;
  myEventSubscription;
  constructor(@Inject(PLATFORM_ID) platformId: string,private route: ActivatedRoute, private seoService: SeoService, private appService: AppRoutingTransitionService) { 
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      rivenditori.map(r => {
        if (r.slug == params.get('rivenditoriId')) {
          this.rivenditori = r;
          this.seoService.updateTitle(r.name);
          this.seoService.updateMetaTags([
            { name: 'description', content: r.descriptionHTML },
            { property: 'og:title', content: "Zecchini - " + r.name },
            { property: 'og:description', content: r.descriptionHTML },
            { property: 'og:image', content: environment.apiUrl + 'assets/images/opengraph-rivenditori.jpg'  },
            { property: 'og:url', content: environment.apiUrl },
            { property: 'og:type', content: 'website' },
            { property: 'og:site_name', content: 'Zecchini' },
            { property: 'fb:app_id', content: '141377273234274' },
            { name: "twitter:card", content: "summary_large_image" },
            { name: "twitter:site", content: "Zecchini" },
            { name: "twitter:title", content: "Zecchini - " + r.name },
            { name: "twitter:image", content: environment.apiUrl + 'assets/images/opengraph-rivenditori.jpg'  },
            { name: "twitter:description", content: r.descriptionHTML }
          ]);
        }
      })
    });
    if (!this.isBrowser) {
      return;
    }
    this.myEventSubscription = this.appService.stringSubject.subscribe(() => {
        this.isEnter = true;
      }
    );
  }

}

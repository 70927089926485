<div class="container">
    <h1>Informazioni cliente</h1>
    <h2>Inserisci i tuoi dati</h2>
    <form [formGroup]="registrationForm" (ngSubmit)="sendRegistrationForm()">
        <p>Prima di tutto chi sei</p>
        <div class="form__container">
            <mat-radio-group aria-label="Select an option" formControlName="client_type">
                <mat-radio-button #private [value]="PRIVATE_VALUE">Privato</mat-radio-button>
                <mat-radio-button #company [value]="COMPANY_VALUE">Azienda</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="form__container form__container--4">
            <mat-form-field appearance="standard">
                <mat-label>nome *</mat-label>
                <input type="text" matInput formControlName="first_name">
                <mat-error *ngIf="(f.first_name.dirty || f.first_name.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
                <mat-error *ngIf="(f?.first_name?.errors?.serverError)">
                    {{f.first_name.errors.serverError.message}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>cognome *</mat-label>
                <input type="text" matInput formControlName="last_name">
                <mat-error *ngIf="(f.last_name.dirty || f.last_name.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
                <mat-error *ngIf="(f?.last_name?.errors?.serverError)">
                    {{f.last_name.errors.serverError.message}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>e-mail *</mat-label>
                <input type="email" matInput formControlName="email">
                <mat-error *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)">
                    <mat-error *ngIf="(f.email.errors.required)">
                        campo <strong>obbligatorio</strong>
                    </mat-error>
                    <mat-error *ngIf="(f.email.errors.pattern)">
                        inserisci un indirizzo e-mail <strong>valido</strong>
                    </mat-error>
                </mat-error>
                <mat-error *ngIf="(f?.email?.errors?.serverError)">
                    {{f.email.errors.serverError.message}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>telefono *</mat-label>
                <input type="tel" matInput formControlName="phone">
                <mat-error *ngIf="(f.phone.dirty || f.phone.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
                <mat-error *ngIf="(f?.phone?.errors?.serverError)">
                    {{f.phone.errors.serverError.message}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>indirizzo *</mat-label>
                <input type="text" matInput formControlName="address">
                <mat-error *ngIf="(f.address.dirty || f.address.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
                <mat-error *ngIf="(f?.address?.errors?.serverError)">
                    {{f.address.errors.serverError.message}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>cap *</mat-label>
                <input type="number" matInput formControlName="postal_code">
                <mat-error *ngIf="(f.postal_code.dirty || f.postal_code.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
                <mat-error *ngIf="(f?.postal_code?.errors?.serverError)">
                    {{f.postal_code.errors.serverError.message}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>città *</mat-label>
                <input type="text" matInput formControlName="city">
                <mat-error *ngIf="(f.city.dirty || f.city.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
                <mat-error *ngIf="(f?.city?.errors?.serverError)">
                    {{f.city.errors.serverError.message}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>provincia *</mat-label>
                <input type="text" matInput formControlName="province">
                <mat-error *ngIf="(f.province.dirty || f.province.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
                <mat-error *ngIf="(f?.province?.errors?.serverError)">
                    {{f.province.errors.serverError.message}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard" *ngIf="isRegister">
                <mat-label>password *</mat-label>
                <input type="password" matInput formControlName="password">
                <mat-hint>La password deve essere di minimo 8 caratteri di cui almeno una lettera maiuscola e un numero.
                </mat-hint>
                <mat-error *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)">
                    <mat-error *ngIf="(f.password.errors.required)">
                        campo <strong>obbligatorio</strong>
                    </mat-error>
                    <mat-error *ngIf="(f.password.errors.minlength)">
                        deve essere di minimo <strong>8 caratteri</strong>
                    </mat-error>
                    <mat-error *ngIf="(f.password.errors.pattern)">
                        La password deve essere di minimo 8 caratteri di cui almeno una lettera maiuscola e un numero.
                    </mat-error>
                </mat-error>
                <mat-error *ngIf="(f?.password?.errors?.serverError)">
                    {{f.password.errors.serverError.message}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard" *ngIf="isRegister">
                <mat-label>conferma password *</mat-label>
                <input type="password" matInput formControlName="registrationConfirmPassword">
                <mat-error *ngIf="f.registrationConfirmPassword.invalid && (f.registrationConfirmPassword.dirty || f.registrationConfirmPassword.touched)">
                    <mat-error *ngIf="(f.registrationConfirmPassword.errors.required)">
                        campo <strong>obbligatorio</strong>
                    </mat-error>
                    <mat-error *ngIf="(f.registrationConfirmPassword.errors.minlength)">
                        deve essere di minimo <strong>8 caratteri</strong>
                    </mat-error>
                    <mat-error *ngIf="(f.registrationConfirmPassword.errors.mustMatch)">
                        le password non coincidono
                    </mat-error>
                </mat-error>

            </mat-form-field>
            <mat-form-field appearance="standard" class="grid--3-4">
                <mat-label>note spedizione</mat-label>
                <input type="text" matInput formControlName="note">
                <mat-error *ngIf="(f?.note?.errors?.serverError)">
                    {{f.note.errors.serverError.message}}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form__container padding-top--30">
            <mat-checkbox #invoice formControlName="receive_invoice">Desidero la fattura</mat-checkbox>
        </div>
        <div *ngIf="invoice.checked" class="form__container form__container--4">
            <mat-form-field *ngIf="private.checked" appearance="standard">
                <mat-label>codice fiscale *</mat-label>
                <input type="text" matInput formControlName="fiscal_code">
                <mat-error *ngIf="f.fiscal_code.invalid && (f.fiscal_code.dirty || f.fiscal_code.touched)">
                    <mat-error *ngIf="(f.fiscal_code.errors.required)">
                        campo <strong>obbligatorio</strong>
                    </mat-error>
                    <mat-error *ngIf="(f.fiscal_code.errors.minlength || f.fiscal_code.errors.maxlength)">
                        deve essere di <strong>16 caratteri</strong>
                    </mat-error>
                </mat-error>
                <mat-error *ngIf="(f?.fiscal_code?.errors?.serverError)">
                    {{f.fiscal_code.errors.serverError.message}}
                </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="company.checked" appearance="standard">
                <mat-label>ragione sociale *</mat-label>
                <input type="text" matInput formControlName="business_name">
                <mat-error *ngIf="(f.business_name.dirty || f.business_name.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
                <mat-error *ngIf="(f?.business_name?.errors?.serverError)">
                    {{f.business_name.errors.serverError.message}}
                </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="company.checked" appearance="standard">
                <mat-label>P.IVA *</mat-label>
                <input type="text" matInput formControlName="vat_number">
                <mat-error *ngIf="f.vat_number.invalid && (f.vat_number.dirty || f.vat_number.touched)">
                    <mat-error *ngIf="(f.vat_number.errors.required)">
                        campo <strong>obbligatorio</strong>
                    </mat-error>
                    <mat-error *ngIf="(f.vat_number.errors.minlength || f.vat_number.errors.maxlength)">
                        deve essere di <strong>11 caratteri</strong>
                    </mat-error>
                </mat-error>
                <mat-error *ngIf="(f?.vat_number?.errors?.serverError)">
                    {{f.vat_number.errors.serverError.message}}
                </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="company.checked" appearance="standard">
                <mat-label>PEC *</mat-label>
                <input type="email" matInput formControlName="pec">
                <mat-error *ngIf="f.pec.invalid && (f.pec.dirty || f.pec.touched)">
                    <mat-error *ngIf="(f.pec.errors.required)">
                        campo <strong>obbligatorio</strong>
                    </mat-error>
                    <mat-error *ngIf="(f.pec.errors.pattern)">
                        inserisci un indirizzo e-mail <strong>valido</strong>
                    </mat-error>
                </mat-error>
                <mat-error *ngIf="(f?.pec?.errors?.serverError)">
                    {{f.pec.errors.serverError.message}}
                </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="company.checked" appearance="standard">
                <mat-label>SDI *</mat-label>
                <input type="text" matInput formControlName="sdi">
                <mat-error *ngIf="(f?.sdi?.errors?.serverError)">
                    {{f.sdi.errors.serverError.message}}
                </mat-error>
                <mat-error *ngIf="f.sdi.invalid && (f.sdi.dirty || f.sdi.touched)">
                    <mat-error *ngIf="(f.sdi.errors.required)">
                        campo <strong>obbligatorio</strong>
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form__container padding-top--30">
            <mat-checkbox #shipping formControlName="different_shipping">Indirizzo di spedizione differente</mat-checkbox>
        </div>
        <div *ngIf="shipping.checked" class="form__container form__container--4">
            <mat-form-field appearance="standard">
                <mat-label>nome *</mat-label>
                <input type="text" matInput formControlName="shipping_first_name">
                <mat-error *ngIf="(f.shipping_first_name.dirty || f.shipping_first_name.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
                <mat-error *ngIf="(f?.fshipping_first_nameirst_name?.errors?.serverError)">
                    {{f.shipping_first_name.errors.serverError.message}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>cognome *</mat-label>
                <input type="text" matInput formControlName="shipping_last_name">
                <mat-error *ngIf="(f.shipping_last_name.dirty || f.shipping_last_name.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
                <mat-error *ngIf="(f?.shipping_last_name?.errors?.serverError)">
                    {{f.shipping_last_name.errors.serverError.message}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>telefono *</mat-label>
                <input type="tel" matInput formControlName="shipping_phone">
                <mat-error *ngIf="(f.shipping_phone.dirty || f.shipping_phone.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
                <mat-error *ngIf="(f?.shipping_phone?.errors?.serverError)">
                    {{f.shipping_phone.errors.serverError.message}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>indirizzo *</mat-label>
                <input type="text" matInput formControlName="shipping_address">
                <mat-error *ngIf="(f.shipping_address.dirty || f.shipping_address.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
                <mat-error *ngIf="(f?.shipping_address?.errors?.serverError)">
                    {{f.shipping_address.errors.serverError.message}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>cap *</mat-label>
                <input type="number" matInput formControlName="shipping_postal_code">
                <mat-error *ngIf="(f.shipping_postal_code.dirty || f.shipping_postal_code.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
                <mat-error *ngIf="(f?.shipping_postal_code?.errors?.serverError)">
                    {{f.shipping_postal_code.errors.serverError.message}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>città *</mat-label>
                <input type="text" matInput formControlName="shipping_city">
                <mat-error *ngIf="(f.shipping_city.dirty || f.shipping_city.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
                <mat-error *ngIf="(f?.shipping_city?.errors?.serverError)">
                    {{f.shipping_city.errors.serverError.message}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>provincia *</mat-label>
                <input type="text" matInput formControlName="shipping_province">
                <mat-error *ngIf="(f.shipping_province.dirty || f.shipping_province.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
                <mat-error *ngIf="(f?.shipping_province?.errors?.serverError)">
                    {{f.shipping_province.errors.serverError.message}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>note spedizione</mat-label>
                <input type="text" matInput formControlName="shipping_notes">
                <mat-error *ngIf="(f?.shipping_notes?.errors?.serverError)">
                    {{f.shipping_notes.errors.serverError.message}}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="action-float action-float--end">
            <div class="padding-top--30">
                <div>
                    <mat-checkbox formControlName="accept_privacy">*Acconsento al trattamento dei miei dati secondo l’<a class="link-underline" [routerLink]="['/privacy']">informativa sulla
                            privacy</a></mat-checkbox>
                    <mat-error *ngIf="f.accept_privacy.invalid && (f.accept_privacy.dirty || f.accept_privacy.touched)">
                        campo <strong>obbligatorio</strong>
                    </mat-error>
                </div>
                <div class="padding-top--10">
                    <mat-checkbox formControlName="newsletter_subscribed">Desidero iscrivermi alla newsletter per ricevere offerte e novità </mat-checkbox>
                </div>
            </div>
            <div class="button__container">
                <div class="button__background2"></div>
                <div class="button__background"></div>
                <!-- [disabled]="!registrationForm.valid" -->
                <button type="submit" class="action__button" [disabled]="isLoading">
                    Conferma
                    <div class="arrow-left">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.14 16.48">
                            <line y1="8.24" x2="19.55" y2="8.24" />
                            <polyline points="11.4 0.5 19.14 8.24 11.4 15.98" />
                        </svg>
                    </div>
                </button>
            </div>
        </div>
    </form>
</div>
<app-footer></app-footer>
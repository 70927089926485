import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Order } from 'src/app/models/order.model';
import { ShopService } from 'src/app/services/shop.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {
  public refoundForm: FormGroup;

  DETAILS_STATE: string = 'details';
  EDIT_STATE: string = 'edit';
  VIEW_STATE: string = 'view';

  activeView:string = this.VIEW_STATE;

  currentPage = 0;
  total = 0;
  perPage = 20;

  orders: Order[];
  selectedOrder: Order;

  @ViewChild('form') form;

  constructor(
    private formBuilder: FormBuilder, 
    private userService: UserService, 
    private shopService: ShopService,
    private _snackBar: MatSnackBar) {
    this.refoundForm = formBuilder.group({
      refoundText: ['', Validators.required]
    })
   }

  ngOnInit(): void {
    this.getOrders();
  }

  get f(){
    return this.refoundForm.controls;
  }

  setView(view) {
    window.scroll(0,0);
    this.activeView = view;
  }

  getOrders(){
    this.userService.getUserOrders({page: this.currentPage+1}).subscribe((res: any) => {
      this.orders = res.data.map(order => new Order(order, this.shopService.categories.value, this.shopService.subcategories.value))
      // this.currentPage = res.pagination.currentPage-1;
      this.total = res.pagination.total;
      this.perPage = res.pagination.perPage;
    })
  }

  viewOrder(id){
    this.userService.getUserOrderById(id).subscribe((order:Order) => {
      this.selectedOrder = new Order(order, this.shopService.categories.value, this.shopService.subcategories.value);
      this.setView(this.DETAILS_STATE)
    })
  }

  handlePageEvent(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.getOrders();
  }

  isLoading: boolean = false;
  requestProductReturn(){
    this.isLoading = true;
    let formData = new FormData();
    formData.append('request_return_notes', this.refoundForm.value.refoundText);
    formData.append('order_id', this.selectedOrder.id);
    this.userService.requestProductReturn(this.selectedOrder.id, formData).subscribe(res => {
      this.form.resetForm();
      this.isLoading = false;
      this.viewOrder(this.selectedOrder.id);
      this.refundDataLayer(this.selectedOrder);
      this._snackBar.open('La tua richiesta di reso verrà esaminata al più presto', 'OK', {
        duration: 5000
      });
    }, err => {
      if(err.erorr && err.erorr.message){
        this._snackBar.open(err.error.message, 'OK', {
          duration: 5000
        });
      }
      this.isLoading = false;
    })
  }

  refundDataLayer(order: Order){
    (<any>window).dataLayer.push({
      event: 'refund',
        ecommerce: {
          transaction_id: order?.payment?.payment_intent_id
        }
      });
      // if refund is partial
      // Example:
      // dataLayer.push({
      //   event: 'refund',
      //   ecommerce: {
      //     transaction_id: 'p115-20202000',
      //     items: [{
      //       item_name: 'Cinelli Zydeco 2021', // Name or ID is required.
      //       item_id: '67890', // Name or ID is required.
      //       price: '1890.00',
      //       item_brand: 'Cinelli',
      //       item_category: 'Bike',
      //       item_category_2: 'Gravel', // The categories can be up to 4
      //       item_variant: 'Black',
      //       quantity: '1' 
      //     }]
      //   }
      // });
  }  
}
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppRoutingTransitionService {
  public stringSubject = new Subject();
  public stringSubject2 = new Subject();

  endPageAnim() {
    this.stringSubject.next();
  }

  startPageAnim() {
    this.stringSubject2.next();
  }

  constructor() { }
}

import { CartItem } from "./cart.model";

export interface OrderModel {
    id: string;
    amount: string;
    amount_after_discount: string;
    created_at: string;
    currency: string;
    discount_code:string
    discount_code_amount: string;
    processor_order_id: string;
    purchase_date: string;
    request_return: any;
    request_return_notes: string;
    shipping_fee: string;
    status: string;
    status_label: string;
    total_amount: string;
    transaction_fee: string;
    user_id: string;
    updated_at: string;
    order_items?: CartItem[];
    payment_invoice?: any;
    payment?: any;
    tracking_url: any;
};

export class Order implements OrderModel {
    id: string;
    amount: string;
    amount_after_discount: string;
    created_at: string;
    currency: string;
    discount_code:string
    discount_code_amount: string;
    processor_order_id: string;
    purchase_date: string;
    request_return: any;
    request_return_notes: string;
    shipping_fee: string;
    status: string;
    status_label: string;
    total_amount: string;
    transaction_fee: string;
    user_id: string;
    updated_at: string;
    order_items?: CartItem[];
    payment_invoice?: any;
    payment?: any;
    tracking_url: any;


	constructor(order: OrderModel, categories, subcategories) {
        if(order) Object.assign(this, order);

        if(order.status == '1') this.status_label = 'not paid'
        else if(order.status == '2') this.status_label = 'In lavorazione' // paid
        else if(order.status == '3') this.status_label = 'Spedito' // sent
        else if(order.status == '4') this.status_label = 'Consegnato' // delivered
        else if(order.status == '5') this.status_label = 'Reso accettato' //returned

        if(order.order_items) {
            this.order_items = order.order_items.map(item => new CartItem(item, categories, subcategories))
        }
	}
}
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { imageSlideAnimation, slideAnimation, containerAnimation} from '../../router/animations/component-animations'; 

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css'],
  animations:[imageSlideAnimation, slideAnimation, containerAnimation]
})
export class SliderComponent implements OnInit {
  @Input() sliderItem: any;
  @Input() sliderTitle: string;
  @Input() sliderFlip: boolean;
  @Input() sliderCursorNext: string;
  @Input() sliderCursorPrev: string;
  indexSlide = 0;
  windowWidth: number = window.innerWidth;
  isMobile: boolean = false;
  totalSlide;

  constructor() { }

  mobileDetect() {
    if(this.windowWidth < 1023){
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
 } 

  nextSlide() {
    if(this.sliderFlip) {
      this.indexSlide--;
      if (this.indexSlide == -1) {
        this.indexSlide = this.totalSlide - 1
      }
    } else {
      this.indexSlide++;
      if (this.indexSlide == this.totalSlide) {
        this.indexSlide = 0
      }
    }
  }

  prevSlide() {
    if(this.sliderFlip) {
      this.indexSlide++;
      if (this.indexSlide == this.totalSlide) {
        this.indexSlide = 0
      }
    } else {
      this.indexSlide--;
      if (this.indexSlide == -1) {
        this.indexSlide = this.totalSlide - 1
      }
    }
  }

  setSliderElement() {
    this.totalSlide = this.sliderItem.length;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = window.innerWidth; 
    this.mobileDetect();
  }

  ngOnInit(): void {
    this.mobileDetect();
  }

  ngAfterViewInit() {
    this.setSliderElement();
  }

}

<section class="container--white news__container">
    <div class="news__left" [@titleEnter]="isEnter">
        <a [@titleEnterText]="isEnter" [routerLink]="['/consigli']" class="back">vedi tutti i consigli</a>
        <h1 class="intro-page__title"><span [@titleEnterSpan]="{value: isEnter, params: {durationAnim: 0.5}}">{{news?.title}}</span></h1>
        <h2 class="intro-page__title"><span [@titleEnterSpan]="{value: isEnter, params: {durationAnim: 0.6}}">{{news?.subtitle}}</span></h2>
        <p [@titleEnterText]="isEnter" class="text text--news" [innerHTML]="news?.text"></p>
        <div class="action-float" *ngIf="news?.button_url" [@titleEnterText]="isEnter">
            <div class="frontend-link">
                <a [href]="news?.button_url" target="_blank">{{news?.button_text}}</a>
                <div class="frontend-link__line"></div>
                <div class="frontend-link__line2"></div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.05 10"><path d="M9.06,4.22,6,1.12,7.07,0l5,5-5,5L5.92,8.86,9,5.78H0V4.22Z"/></svg>
            </div>
        </div>
        <div class="action-float" *ngFor="let allegato of news?.file_attachments" [@titleEnterText]="isEnter">
            <div class="frontend-link">
                <a [href]="assetsUrl + allegato.file_name" target="_blank">Vedi allegato</a>
                <div class="frontend-link__line"></div>
                <div class="frontend-link__line2"></div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.05 10"><path d="M9.06,4.22,6,1.12,7.07,0l5,5-5,5L5.92,8.86,9,5.78H0V4.22Z"/></svg>
            </div>
        </div>
    </div>
    <div class="news__right">
        <div class="news__img" [@imgEnter]="isEnter">
            <ngx-slick-carousel #slickModal="slick-carousel" class="slider" [config]="slideConfig">
                <div ngxSlickItem *ngFor="let slide of news?.image_attachments" class="slide">
                    <picture>
                        <source sizes="(max-width: 1023px) 80vw, 30vw" type="image/webp" [srcset]="assetsUrl + slide.file_name_noext +'_800.webp 800w ,'+ assetsUrl + slide.file_name_noext +'_400.webp 400w'">
                        <source sizes="(max-width: 1023px) 80vw, 30vw" type="image/jpg" [srcset]="assetsUrl + slide.file_name_noext +'_800.jpg 800w ,'+ assetsUrl + slide.file_name_noext +'_400.jpg 400w'">
                        <img [@imgScale]="isEnter" sizes="(max-width: 1023px) 80vw, 30vw" [src]="assetsUrl + slide.file_name_noext +'_800.jpg'" [srcset]="assetsUrl + slide.file_name_noext +'_800.jpg 800w, ' + assetsUrl + slide.file_name_noext + '_400.jpg 400w'" alt="{{slide.file_name_noext}}">
                    </picture>
                </div>
            </ngx-slick-carousel>
        </div>
    </div>
</section>
<app-footer></app-footer>
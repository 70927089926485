<section class="container container--double intro-page">
    <div class="container__left">
        <div [@titleEnter]="isEnter">
            <p class="orange intro-page__subtitle" [@titleEnterText]="isEnter">Trattiamo le biciclette come figlie e</p>
            <div class="intro-page__title"><span [@titleEnterSpan]="{value: isEnter, params: {durationAnim: 0.5}}">i nostri</span></div>
            <div class="intro-page__title"><span [@titleEnterSpan]="{value: isEnter, params: {durationAnim: 0.6}}">clienti sono</span></div>
            <div class="intro-page__title"><span [@titleEnterSpan]="{value: isEnter, params: {durationAnim: 0.7}}">come fratelli.</span></div>
            <h1 [@titleEnterText]="isEnter" (@titleEnterText.done)="animEnd($event)">Assistenza, riparazione<br>e manutenzione post vendita.</h1>
        </div>
    </div>
    <div class="container__right">
        <div class="intro-page__img" [@imgEnter]="isEnter">
            <picture>
                <source sizes="(max-width: 1023px) 80vw, 40vw" type="image/webp" srcset="assets/images/servizi/servizi1_800.webp 800w, assets/images/servizi/servizi1_600.webp 600w, assets/images/servizi/servizi1_400.webp 400w">
                <source sizes="(max-width: 1023px) 80vw, 40vw" type="image/jpg" srcset="assets/images/servizi/servizi1_800.jpg 800w, assets/images/servizi/servizi1_600.jpg 600w, assets/images/servizi/servizi1_400.jpg 400w">
                <img [@imgScale]="isEnter" sizes="(max-width: 1023px) 80vw, 40vw" src="assets/images/servizi/servizi1_800.jpg" srcset="assets/images/servizi/servizi1_800.jpg 800w, assets/images/servizi/servizi1_600.jpg 600w, assets/images/servizi/servizi1_400.jpg 400w"
                    alt="servizi Zecchini">
            </picture>
        </div>
    </div>
    <div class="bg--white-mobile"></div>
</section>
<section class="container--front container--white">
    <div class="grid__front">
        <div class="grid-mobile__center">
            <h2 class="orange split" #split1>“La differenza tra noi e le grandi catene è semplice: noi non lasciamo mai soli i nostri clienti, anche dopo l’acquisto.”</h2>
            <p class="padding-top--30">Giuseppina Zecchini</p>
        </div>
    </div>
</section>
<section class="container--front container--white">
    <div class="parallaxText">
        <div class="grid__front">
            <div class="grid--2-3">
                <div class="text-rotate text-rotate--title text-rotate--no-mobile">
                    <p class="mono">ASSISTENZA</p>
                </div>
                <p class="frontend__title">
                    Come ogni mezzo di trasporto anche la bicicletta ha bisogno di costante manutenzione, cure e assistenza per mantenerla sicura e efficiente.
                </p>
            </div>
        </div>
        <div class="grid__front">
            <div class="grid--2 textOpacity">
                <p>Siamo sempre qui, a <span class="orange">Brescia in via Panoramica 1/A</span>, disponibili per sistemare, riparare, ri-assettare, gonfiare, avvitare, lubrificare, stringere, allentare. Insomma, per fare tutto ciò che è necessario per mantenere
                    la vostra bicicletta nelle migliori condizioni possibili.</p>
            </div>
            <div class="textOpacity">
                <p>Su ogni modello acquistato offriamo un anno di assistenza gratuita e, sui modelli in possesso di numero telaio, possiamo sottoscrivere assicurazioni specifiche.</p>
            </div>
        </div>
    </div>
</section>
<section class="container--front container--white slider--orange container--slider">
    <div class="bg__orange bg__orange--bottom"></div>
    <app-slider id="sliderServizi1" [sliderCursorPrev]="'url(assets/images/cursor/dx.png)'" [sliderCursorNext]="'url(assets/images/cursor/sx.png)'" [sliderFlip]='false' [sliderItem]=serviziSlider1 sliderTitle='L’OFFICINA ZECCHINI'></app-slider>
</section>
<section class="container--front container--orange">
    <div class="grid__front parallaxText">
        <div>
            <h2 class="light-blue split" #split2>Servizi di assistenza e manutenzione forniti</h2>
        </div>
        <div class="grid--2-3 elenco-servizi">
            <h3 class="frontend__title padding-bottom--20 white">Assistenza post vendita</h3>
            <h3 class="frontend__title padding-bottom--20 white">Riparazioni e manutenzione</h3>
            <h3 class="frontend__title padding-bottom--20 white">Restauro modelli storici</h3>
            <h3 class="frontend__title padding-bottom--20 white">Messa a punto</h3>
        </div>
    </div>
</section>
<section class="container--front container--orange">
    <div class="grid__front">
        <div>
            <h2 class="light-blue split" #split3>Possiamo anche realizzare modelli custom secondo richieste ed esigenze specifiche.</h2>
        </div>
        <div class="textOpacity">
            <p class="white">Diciamocela tutta: sappiamo dove mettere le mani e grazie ad un catalogo di accessori e componenti di oltre 3000 pezzi, siamo in grado di trasformare i nostri stessi modelli nella bicicletta dei vostri sogni. Quella che meglio rappresenta
                la vostra personalità e le vostre esigenze.<br><br>Colore, sella, manopole, fanali, borse, ogni accessorio contribuisce a dare carattere e potrete scegliere tra moltissimi brand, la maggior parte dei quali Made in Italy.</p>
        </div>
        <div class="textOpacity">
            <p class="white">Non siamo solo il braccio: possiamo essere anche la mente dietro ad un progetto di customizzazione.<br><br>In cento anni ne abbiamo viste di biciclette e questo ci permette di potervi guidare nella scelta del pezzo migliore, dell’assetto perfetto
                senza perdere in comodità e sicurezza.<br><br>Perchè la bicicletta migliore è quella che riesce a soddisfare tutte le esigenze ed abitudini della persona che la utilizza.</p>
        </div>
    </div>
</section>
<section class="container--front slider--orange2 container--slider">
    <div class="bg__orange bg__orange--top"></div>
    <app-slider class="text--white" id="sliderServizi2" [sliderCursorPrev]="'url(assets/images/cursor/sx.png)'" [sliderCursorNext]="'url(assets/images/cursor/dx.png)'" [sliderFlip]='true' [sliderItem]=serviziSlider2 sliderTitle='IL NEGOZIO'></app-slider>
</section>
<section class="container--front">
    <div class="grid__front padding-bottom--0 parallaxText">
        <div>
            <h2 class="orange padding-bottom--70 split" #split4>Abbiamo creato un blog: un contenitore di notizie, consigli sul mondo bici, itinerari, storie e lifestyle.</h2>
            <p class="textOpacity">Vogliamo che diventi il punto di riferimento per tanti appassionati come noi e come voi; un luogo dove sentirsi parte di una community che esplora il mondo a colpi di pedale, con la quale potersi (di nuovo) incontrare.</p>
        </div>
        <div class="grid--2-3">
            <p class="frontend__title">La bicicletta è il futuro che abbiamo sempre avuto sotto agli occhi e non abbiamo mai scelto.</p>
        </div>
    </div>
    <div class="action-float--right action-float--end padding-bottom--70">
        <div class="frontend-link">
            <a [routerLink]="['/consigli']">leggi tutto</a>
            <div class="frontend-link__line"></div>
            <div class="frontend-link__line2"></div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.05 10"><path d="M9.06,4.22,6,1.12,7.07,0l5,5-5,5L5.92,8.86,9,5.78H0V4.22Z"/></svg>
        </div>
    </div>
</section>
<section class="container--front strava">
    <a href="https://www.strava.com/clubs/882628" target="_blank">
        <div class="infinite-scroll" (mouseenter)="textPause(0.5)" (mouseleave)="textPlay(1)">
            <div *ngFor="let c of [1, 2, 3]" class="infinite-scroll__container">
                <h3 [ngClass]="{'active': activeText == 'strava'}" (mouseenter)="textInfiniteOver('strava')" (mouseleave)="textInfiniteOver('')">#exploringurbanlandscapes</h3>
            </div>
        </div>
    </a>
    <div class="strava__img strava__img--servizi">
        <picture @imageFadeAnimation *ngIf="activeText == 'strava'">
            <source type="image/webp" sizes="(max-width: 1023px) 50vw, 35vw" srcset="assets/images/home/exploring_400.webp 400w, assets/images/home/exploring_200.webp 200w">
            <source type="image/jpg" sizes="(max-width: 1023px) 50vw, 35vw" srcset="assets/images/home/exploring_400.jpg 400w, assets/images/home/exploring_200.jpg 200w">
            <img src="assets/images/home/exploring_400.jpg" sizes="(max-width: 1023px) 50vw, 35vw" srcset="assets/images/home/exploring_400.jpg 400w, assets/images/home/exploring_200.jpg 200w" alt="la bici giusta per te">
        </picture>
        <div class="mono orange">PROVA LE SFIDE</div>
    </div>
</section>
<app-footer></app-footer>
<section class="container container--double container--info">
    <div class="container__left container--white">
        <ngx-slick-carousel #slickModal="slick-carousel" class="carousel" [config]="slideConfig" *ngIf="!loading">
            <div ngxSlickItem *ngFor="let slide of sliderImages" class="slide">
                <picture>
                    <source sizes="(max-width: 1023px) 90vw, 80vw" type="image/webp" srcset="{{slide.url}}_1500.webp 1500w, {{slide.url}}_800.webp 800w, {{slide.url}}_400.webp 400w">
                    <source sizes="(max-width: 1023px) 90vw, 80vw" type="image/jpg" srcset="{{slide.url}}_1500.jpg 1500w, {{slide.url}}_800.jpg 800w, {{slide.url}}_400.jpg 400w">
                    <img sizes="(max-width: 1023px) 90vw, 80vw" src="{{slide.url}}_1500.jpg" srcset="{{slide.url}}_1500.jpg 1500w, {{slide.url}}_800.jpg 800w, {{slide.url}}_400.jpg 400w" alt="{{product?.title}}">
                </picture>
            </div>
        </ngx-slick-carousel>
        <div *ngIf="singleDiscount" class="labelDiscount labelDiscount--detail">Sconto {{ singleDiscount }}%</div>
    </div>

    <div class="container__right">
        <div class="bread-crumb">
            <ul>
                <li>
                    <a routerLink="/{{urlSite}}" [queryParams]="{ category: product?.product_category_id }">{{product?.category_label}}</a>
                </li>
                <li>
                    <a routerLink="/{{urlSite}}" [queryParams]="{ category: product?.product_category_id, subcategory: product?.product_subcategory_id }">{{product?.subcategory_label}}</a>
                </li>
            </ul>
        </div>
        <div class="product-info">
            <h1 *ngIf="product?.title">{{product?.title}}</h1>
            <h2 *ngIf="product?.subcategory_label">{{product?.subcategory_label}}</h2>
            <div *ngIf="product?.producer_name">
                <p class="product-info__subtitle orange">MARCHIO</p>
                <p>{{product?.producer_name}}</p>
            </div>
            <div class="product-variants">
                <p class="product-info__subtitle orange">TAGLIE DISPONIBILI</p>
                <div class="product-variants__container">
                    <!-- <button 
                        *ngFor="let size of product.variants.sizes"
                        (click)="selectItem(size.item, 'size')"
                        class="variants" 
                        [ngClass]="[this.selectedSize == size.item ? 'selected': '', size.disabled == false ? 'active' : '']"
                        [disabled]="size.disabled">
                        {{size.item}}
                    </button> -->
                    <button *ngFor="let size of product?.filtered_variants.sizes" (click)="selectItem(size.size, 'size')" class="variants" [ngClass]="[size.selected == '1' ? 'selected': '', size.disabled == '0' ? 'active' : '']" [disabled]="size.disabled == '1'">
                        {{size.size}}
                    </button>
                </div>
                <button class="link-underline" (click)="openDialog()">tabella taglie</button>
            </div>
            <div class="product-variants">
                <p class="product-info__subtitle orange">COLORI DISPONIBILI</p>
                <div class="product-variants__container">
                    <!-- <button 
                        *ngFor="let color of product.variants.colors" 
                        (click)="selectItem(color.item, 'color')"
                        [disabled]="color.disabled"
                        [ngClass]="[this.selectedColor == color.item ? 'selected': '', color.disabled == false ? 'active' : '']"
                        class="variants ">
                        {{color.item}}
                    </button> -->
                    <button *ngFor="let color of product?.filtered_variants.colors" (click)="selectItem(color.color, 'color')" class="variants" [ngClass]="[color.selected == '1' ? 'selected': '', color.disabled == '0' ? 'active' : '']" [disabled]="color.disabled == '1'">
                        {{color.color}}
                    </button>
                </div>
            </div>
            <div class="product-color"></div>
            <div class="product__row">
                <div>
                    <p class="product-info__subtitle orange">QUANTITÀ</p>
                    <div class="product__quantity">
                        <button (click)="changeQuantity('decrease')">-</button>
                        <div>{{product?.quantity}}</div>
                        <button (click)="changeQuantity('increase')">+</button>
                    </div>
                </div>
                <div class="product__total">
                    <p class="product-info__subtitle orange">PREZZO</p>
                    <div class="total" [ngClass]="{'info__price--discount': singleDiscount}">
                        <div class="totalPrice">
                            <p><span>€</span>{{(product?.price * product?.quantity) | number : '1.2-2'}}</p>
                        </div>
                        <p *ngIf="singleDiscount" class="totalPrice--discount">€ {{ ((product?.price * product?.quantity) - discountAmount).toFixed(2) }}</p>
                    </div>
                </div>
            </div>
            <div class="action-float--right">
                <div class="button__container">
                    <div class="button__background2"></div>
                    <div class="button__background"></div>

                    <button class="action__button" (click)="addToCart()" [disabled]="product?.quantity < 1">
                        Aggiungi
                        <div class="arrow-left">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.14 16.48">
                                <line class="cls-1" x1="7.75" x2="7.75" y2="15.5" />
                                <line class="cls-1" x1="15.5" y1="7.75" y2="7.75" />
                            </svg>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="container container--double container--description">
    <div class="container__left container--white product__description">
        <h2>Descrizione<br>del prodotto</h2>
        <div class="list" [innerHTML]="product?.description"></div>
        <!-- <div class="list">{{text}}</div> -->
        <!-- {{product.DescriptionHtml}} -->
        <!-- <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip
            ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril
            delenit augue duis dolore te feugait nulla facilisi.</p> -->
    </div>
    <div class="container__right" *ngIf="product?.product_category_id == '1' || product?.product_category_id == '2'">
        <h2>Caratteristiche<br>tecniche</h2>
        <p class="product-info__subtitle orange">RUOTE</p>
        <p [innerHTML]="product?.cs_field_1"></p>
        <p class="product-info__subtitle orange">CAMBIO</p>
        <p [innerHTML]="product?.cs_field_2">
            <p class="product-info__subtitle orange">TELAIO</p>
            <p [innerHTML]="product?.cs_field_3"></p>
            <p class="product-info__subtitle orange">FRENI-LEVE</p>
            <p [innerHTML]="product?.cs_field_4"></p>
    </div>
</section>
<section class="container container--full container--white">
    <div class="grid__container grid__container--4">
        <div class="grid--2-3">
            <p class="consiglio">Non sai come scegliere il <span class="orange">modello di bicicletta</span> più in linea con le tue esigenze?</p>
        </div>
        <div class="action-float--right action-float--end">
            <div class="frontend-link">
                <a [routerLink]="['/consigli']">scoprilo</a>
                <div class="frontend-link__line"></div>
                <div class="frontend-link__line2"></div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.05 10">
                    <path d="M9.06,4.22,6,1.12,7.07,0l5,5-5,5L5.92,8.86,9,5.78H0V4.22Z" /></svg>
            </div>
        </div>
    </div>
</section>
<section class="container container--full container--white">
    <div class="grid__container grid__container--4">
        <div class="box">
            <p class="product-recommended__title detail__title">Informazioni d'acquisto</p>
        </div>
        <div class="box box--grey">
            <h2>Spese di spedizione articoli</h2>
            <p>Il costo di spedizione totale per ogni ordine effettuato è di <span class="orange">4 Euro</span>. Spese di spedizione gratuite per acquisti superiori a <span class="orange">100 Euro</span>, biciclette escluse.
            </p>
        </div>
        <div class="box box--grey">
            <h2>Spese di spedizione biciclette</h2>
            <p>Essendo considerati articoli ingombranti, il costo di spedizione è di <span class="orange">30 Euro</span> per ciascuna bicicletta ordinata.</p>
        </div>
        <div class="box box--grey">
            <h2>Metodi di pagamento sicuri</h2>
            <p>È possibile effeturare i pagamenti tramite <span class="orange">Paypal</span> o con le più comuni carte di credito con il sistema <span class="orange">Stripe</span></p>
        </div>
    </div>
</section>
<section class="container container--full product-recommended" *ngIf="product?.related_products.length > 0">
    <div class="grid__container grid__container--4">
        <div class="product-recommended__title">
            <p class="detail__title blue">Siamo sicuri che possano interessarti anche</p>
            <p>Altri modelli disponibili a catalogo</p>
        </div>
        <div *ngFor="let product_item of product?.related_products | slice:0:3" class="product">
            <a [routerLink]="['/prodotto/', product_item.id]" appCursorHover [cursorUrl]="'url(assets/images/cursor/scopri.png)'" (click)="selectItemDataLayer(product)">
                <picture>
                    <source sizes="20vw" type="image/webp" srcset="{{product_item.product_image}}_800.webp 800w, {{product_item.product_image}}_400.webp 400w">
                    <source sizes="20vw" type="image/jpg" srcset="{{product_item.product_image}}_800.jpg 800w, {{product_item.product_image}}_400.jpg 400w">
                    <img sizes="20vw" src="{{product_item.product_image}}_800.jpg" srcset="{{product_item.product_image}}_800.jpg 800w, {{product_item.product_image}}_400.jpg 400w" alt="{{product_item.title}}">
                </picture>
                <div *ngIf="product_item.discount>0" class="labelDiscount">Sconto {{ product_item.discount }}%</div>
                <div class="info__container">
                    <p class="info__subtitle">{{product_item.subcategory_label}}</p>
                    <div class="info__title">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12.5" viewBox="0 0 20 16.6">
                            <path d="M15,7,9.9,1.87,11.73,0,20,8.3l-8.27,8.3-1.9-1.9L15,9.6H0V7Z" /></svg>
                        <p>{{product_item.title}}</p>
                    </div>
                    <div class="price">
                        <p [ngClass]="{'info__price--discount': product_item.discount>0}" class="info__price">€{{product_item.gross_price}}</p>
                        <p *ngIf="product_item.discount>0" class="info__discount">€ {{ (product_item.gross_price-(product_item.gross_price*(product_item.discount/100))).toFixed(2) }} </p>
                    </div>
                </div>
            </a>
        </div>
    </div>
</section>
<app-footer></app-footer>
<section class="container--full container--white">
    <div class="consigli-intro" [@titleEnter]="isEnter">
        <div class="subtitle orange"><span [@titleEnterSpan]="{value: isEnter, params: {durationAnim: 0.5}}">Un viaggio nel nostro mondo</span></div>
        <div class="subtitle orange"><span [@titleEnterSpan]="{value: isEnter, params: {durationAnim: 0.6}}">e in tutto ciò che ci entusiasma.</span></div>
        <div class="subtitle orange"><span [@titleEnterSpan]="{value: isEnter, params: {durationAnim: 0.7}}">Su due ruote ovviamente.</span></div>
        <h1 [@titleEnterText]="isEnter" class="padding-top--30">Consigli, novità, eventi, vita e passione per la bicicletta ed il mondo Zecchini.</h1>
    </div>
</section>
<section class="container--news" [@sectionEnter]="isEnter">
    <ngx-masonry [@sectionOpacity]="isEnter">
        <div ngxMasonryItem class="news__container" *ngFor="let item of news; let i = index; index as newsId">
            <a [routerLink]="['/news', item.slug]" appCursorHover [cursorUrl]="'url(assets/images/cursor/leggi.png)'">
                <p class="frontend__title" [@sectionItemEnter]="isEnter">{{item.title}}</p>
                <!-- <div class="news__img" [ngStyle]="{'background': 'url(' + item.image_attachments[0].url + ')' }" [@sectionImgEnter]="isEnter">
                    <div class="box__overlay"></div>
                    <div class="box-link">
                        <div class="box-link__container">
                            <div>
                                <div class="mono">/</div>
                                <p>Leggi la news</p>
                            </div>
                            <div class="box-link__arrow">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.05 10"><path d="M9.06,4.22,6,1.12,7.07,0l5,5-5,5L5.92,8.86,9,5.78H0V4.22Z"/></svg>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="news__img" [@sectionImgEnter]="isEnter">
                    <picture *ngIf="item.image_attachments.length > 0">
                        <source sizes="(max-width: 1023px) 80vw, 20vw" type="image/webp" [srcset]="assetsUrl  + item.image_attachments[0].file_name_noext + '_800.webp 800w,' +  assetsUrl  + item.image_attachments[0].file_name_noext + '_400.webp 400w'">
                        <source sizes="(max-width: 1023px) 80vw, 20vw" type="image/jpg" [srcset]="assetsUrl  + item.image_attachments[0].file_name_noext + '_800.jpg 800w, '+ assetsUrl  + item.image_attachments[0].file_name_noext + '_400.jpg 400w'">
                        <img [@sectionImgEnter]="isEnter" sizes="(max-width: 1023px) 80vw, 20vw" [src]="assetsUrl  + item.image_attachments[0].file_name_noext + '_800.jpg'" [srcset]="assetsUrl  + item.image_attachments[0].file_name_noext + '_800.jpg 800w, ' + assetsUrl + item.image_attachments[0].file_name_noext +'_400.jpg 400w'"
                            alt="{{item.title}}">
                    </picture>
                    <div class="box__overlay"></div>
                    <div class="box-link">
                        <div class="box-link__container">
                            <div>
                                <div class="mono">/</div>
                                <p>Leggi la news</p>
                            </div>
                            <div class="box-link__arrow">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.05 10"><path d="M9.06,4.22,6,1.12,7.07,0l5,5-5,5L5.92,8.86,9,5.78H0V4.22Z"/></svg>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </ngx-masonry>
</section>
<app-footer></app-footer>
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}


if (environment.production) {
  const script = document.createElement('script');
  script.src = `https://www.paypal.com/sdk/js?client-id=${environment.paypalKey}&currency=EUR&disable-funding=bancontact,blik,eps,giropay,ideal,mercadopago,mybank,p24,sepa,sofort,venmo`;
  document.head.appendChild(script);
} else {
  const script = document.createElement('script');
  script.src = `https://www.paypal.com/sdk/js?client-id=${environment.paypalKey}&currency=EUR&disable-funding=bancontact,blik,eps,giropay,ideal,mercadopago,mybank,p24,sepa,sofort,venmo`;
  document.head.appendChild(script);
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
});

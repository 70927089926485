<section class="container--full container--white container--double padding-bottom--30">
    <div class="container--double__left">
        <div class="consigli-intro" [@titleEnter]="isEnter">
            <div class="subtitle orange"><span [@titleEnterSpan]="{value: isEnter, params: {durationAnim: 0.5}}">Saremo rapidi, efficaci</span></div>
            <div class="subtitle orange"><span [@titleEnterSpan]="{value: isEnter, params: {durationAnim: 0.6}}">ed esaustivi: per noi il mondo</span></div>
            <div class="subtitle orange"><span [@titleEnterSpan]="{value: isEnter, params: {durationAnim: 0.7}}">delle due ruote non ha segreti.</span></div>
            <h1 [@titleEnterText]="isEnter" class="padding-top--30">Per informazioni, dubbi e domande, compila il form in ogni sua parte.</h1>
        </div>
        <div class="container--double__img" [@imgEnter]="isEnter">
            <picture>
                <source sizes="40vw" type="image/webp" srcset="assets/images/contatti/contatti1_800.webp 800w, assets/images/contatti/contatti1_600.webp 600w, assets/images/contatti/contatti1_400.webp 400w">
                <source sizes="40vw" type="image/jpg" srcset="assets/images/contatti/contatti1_800.jpg 800w, assets/images/contatti/contatti1_600.jpg 600w, assets/images/contatti/contatti1_400.jpg 400w">
                <img [@imgScale]="isEnter" sizes="40vw" src="assets/images/contatti/contatti1_800.jpg" srcset="assets/images/contatti/contatti1_800.jpg 800w, assets/images/contatti/contatti1_600.jpg 600w, assets/images/contatti/contatti1_400.jpg 400w" alt="contatti Zecchini">
            </picture>
        </div>
    </div>
    <div class="container--double__right" [@imgEnter]="isEnter">
        <form #form="ngForm" [formGroup]="contactForm" (ngSubmit)="sendContactForm()">
            <div class="form__container">
                <mat-form-field appearance="standard">
                    <mat-label>nome</mat-label>
                    <input type="text" matInput formControlName="first_name" required>
                    <mat-error *ngIf="(f.first_name.dirty || f.first_name.touched)">
                        campo <strong>obbligatorio</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="standard">
                    <mat-label>cognome</mat-label>
                    <input type="text" matInput formControlName="last_name" required>
                    <mat-error *ngIf="(f.last_name.dirty || f.last_name.touched)">
                        campo <strong>obbligatorio</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="standard">
                    <mat-label>e-mail</mat-label>
                    <input type="email" matInput formControlName="email" required>
                    <mat-error *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)">
                        <mat-error *ngIf="(f.email.errors.required)">
                            campo <strong>obbligatorio</strong>
                        </mat-error>
                        <mat-error *ngIf="(f.email.errors.pattern)">
                            inserisci un indirizzo e-mail <strong>valido</strong>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="standard">
                    <mat-label>tipo di richiesta</mat-label>
                    <mat-select required formControlName="type">
                        <mat-option value="informazioni">informazioni</mat-option>
                        <mat-option value="diventa rivenditore">diventa rivenditore</mat-option>
                        <mat-option value="reso">reso</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="standard" class="grid--1-2">
                    <mat-label>messaggio</mat-label>
                    <textarea matInput formControlName="message" required></textarea>
                    <mat-error *ngIf="(f.message.dirty || f.message.touched)">
                        campo <strong>obbligatorio</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="padding-top--30">
                <mat-checkbox formControlName="privacyFlag" required>*Acconsento al trattamento dei miei dati secondo l’
                    <a class="link-underline" [routerLink]="['/privacy']">informativa sulla privacy</a></mat-checkbox>
                <mat-error *ngIf="f.privacyFlag.invalid && (f.privacyFlag.dirty || f.privacyFlag.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
            </div>
            <div class="action-float action-float--right" [ngClass]="{'pointer-events-none': isLoading}">
                <div class="button__container">
                    <div class="button__background2"></div>
                    <div class="button__background"></div>
                    <button type="submit" class="action__button" [disabled]="isLoading">Conferma
                        <div class="arrow-left"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.14 16.48">
                                <line y1="8.24" x2="19.55" y2="8.24" />
                                <polyline points="11.4 0.5 19.14 8.24 11.4 15.98" /></svg></div>
                    </button>
                </div>
            </div>
        </form>
        <p class="padding-top--30 sedi__text">Se volete fare un salto a trovarci<br>siamo sempre qui</p>
    </div>
</section>
<section class="container--full sedi" [@sectionEnter]="isEnter">
    <div class="grid__container" [@sectionItemEnter]="isEnter">
        <div>
            <p class="orange padding-bottom--20">INDIRIZZO</p>
            <p class="padding-bottom--20 blue">Via Panoramica, 1/A<br>25121 - Brescia</p>
            <p class="orange"><a href="tel:+390303755432">030 375 5432</a></p>
            <p class="map"><a href="https://www.google.com/maps/place/Zecchini+Biciclette/@45.5314603,10.2225224,15z/data=!4m2!3m1!1s0x0:0x99f78910546bbc83?sa=X&ved=2ahUKEwjVtN_8vcTzAhUx_rsIHSmzDOQQ_BJ6BAhpEAU" target="_blank">Scopri sulla mappa↘</a></p>
        </div>
        <div class="sedi--2">
            <p class="orange padding-bottom--20">DATI SOCIETARI</p>
            <p class="blue">Zecchini & C. Srl<br>P.I. 01992300176</p>
        </div>
    </div>
</section>
<app-footer></app-footer>
<div class="right__container">
    <button [routerLink]='["/area-riservata/"]' class="link-underline link-underline--back">torna indietro</button>
    <div *ngIf="activeView == VIEW_STATE && orders">
        <p class="blue padding-top--40 padding-bottom--30"><b>Ordini effettuati</b></p>
        <div *ngIf="orders.length == 0">
            <p>Non hai effettuato ancora nessun ordine.</p>
        </div>
        <table class="order-table" *ngIf="orders.length != 0">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>DATA</th>
                    <th>STATUS</th>
                    <th>TOTALE</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let order of orders">
                    <td>{{order.id}}</td>
                    <td>{{order.purchase_date | date:'dd/MM/yyyy'}}</td>
                    <td class="order--blue">{{order.status_label}}</td>
                    <td class="order--blue order--price"><span>€</span> {{order.total_amount}}</td>
                    <td class="order--action">
                        <button (click)="viewOrder(order.id)" class="link-underline">dettagli</button>
                        <ng-container *ngIf="order.tracking_url && order.status !=  '4' && order.status !=  '5'">
                            <a [href]="order.tracking_url" target="_blank" class="link-underline">tracking</a>
                        </ng-container>
                    </td>
                </tr>
            </tbody>
        </table>
        <div *ngIf="orders.length != 0">
            <mat-paginator [length]="total" [pageSize]="perPage" [pageIndex]="currentPage" (page)="handlePageEvent($event)"></mat-paginator>
        </div>
        <!-- <mat-paginator (page)="handlePageEvent($event)" [length]="total"  [pageSize]="perPage" [pageIndex]="pageIndex()" [pageSizeOptions]="[5, 10, 25, 100]">
        </mat-paginator> -->

    </div>
    <div *ngIf="activeView == DETAILS_STATE">
        <div class="order-detail__title">
            <p class="blue"><b>Ordine <span class="orange">{{selectedOrder.id}}</span></b></p>
            <button class="link-underline" (click)="setView(VIEW_STATE)">torna agli ordini</button>
        </div>
        <div class="order-detail__text padding-top--10">
            <p>DATA ORDINE: <span>{{selectedOrder.purchase_date | date:'dd/MM/yyyy'}}</span></p>
            <p>STATUS: <span>{{selectedOrder.status_label}}</span></p>
        </div>
        <div class="order-detail__table">
            <table>
                <thead>
                    <tr>
                        <th>PRODOTTI</th>
                        <th>QUANTITÀ</th>
                        <th>PREZZO</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of selectedOrder.order_items">
                        <td>{{item.product.title}} {{item.product.subcategory_label}}</td>
                        <td>{{item.quantity}}</td>
                        <td class="order--price"><span>€</span> {{item.product.price * item.quantity}}</td>
                    </tr>
                    <tr>
                        <td class="border--none padding-bottom--0"></td>
                        <td class="border--none order--partial padding-bottom--0">Totale parziale</td>
                        <td class="order--price border--none padding-bottom--0">€ {{selectedOrder.amount}}</td>
                    </tr>
                    <tr>
                        <td class="border--none order-partial--padding"></td>
                        <td class="border--none order--partial order-partial--padding">Spese di spedizione</td>
                        <td class="order--price border--none order-partial--padding">€ {{selectedOrder.shipping_fee}}</td>
                    </tr>
                    <tr>
                        <td class="border--none order-partial--padding"></td>
                        <td class="border--none order--partial order-partial--padding">Sconto applicato</td>
                        <td class="order--price border--none order-partial--padding">€ {{selectedOrder.amount_after_discount}}</td>
                    </tr>
                    <tr>
                        <td class="order--blue border--none order-partial--padding"></td>
                        <td class="order--blue border--none order--partial order-partial--padding">Totale</td>
                        <td class="order--blue order--price border--none order-partial--padding">€ {{selectedOrder.total_amount}}</td>
                    </tr>
                </tbody>
            </table>
            <p class="padding-top--30 padding-bottom--30 blue"><b>Indirizzo di fatturazione</b></p>
            <div class="step2__info step2__info--border">
                <div>
                    <p>{{selectedOrder?.payment_invoice?.buyer_details?.first_name}} {{selectedOrder?.payment_invoice?.buyer_details?.last_name}}</p>
                    <p>{{selectedOrder?.payment_invoice?.buyer_details?.address}} - {{selectedOrder?.payment_invoice?.buyer_details?.postal_code}} {{selectedOrder?.payment_invoice?.buyer_details?.province}}</p>
                    <p>{{selectedOrder?.payment_invoice?.buyer_details?.city}}</p>
                    <p>{{selectedOrder?.payment_invoice?.buyer_details?.phone}}</p>
                    <p>{{selectedOrder?.payment_invoice?.buyer_details?.notes}}</p>
                    <p>{{selectedOrder?.payment_invoice?.buyer_details?.vat_number}}</p>
                </div>
            </div>
            <p class="padding-top--30 padding-bottom--30 blue"><b>Indirizzo di spedizione</b></p>
            <div class="step2__info step2__info--border">
                <div>
                    <p>{{selectedOrder?.payment_invoice?.buyer_details?.shipping_first_name}} {{selectedOrder?.payment_invoice?.buyer_details?.shipping_last_name}}</p>
                    <p>{{selectedOrder?.payment_invoice?.buyer_details?.shipping_address}} - {{selectedOrder?.payment_invoice?.buyer_details?.shipping_postal_code}} {{selectedOrder?.payment_invoice?.buyer_details?.shipping_province}}</p>
                    <p>{{selectedOrder?.payment_invoice?.buyer_details?.shipping_city}}</p>
                    <p>{{selectedOrder?.payment_invoice?.buyer_details?.shipping_phone}}</p>
                    <p>{{selectedOrder?.payment_invoice?.buyer_details?.shipping_notes}}</p>
                </div>
            </div>
        </div>
        <div class="order-detail__text padding-top--10">
            <p>METODO DI PAGAMENTO: <span>{{selectedOrder?.payment?.payment_type?.title}}</span></p>
        </div>
        <div class="action-float">
            <button (click)="setView(VIEW_STATE)" class="link-underline">annulla</button>
            <div class="button__container" *ngIf="selectedOrder.status != '5' && selectedOrder.request_return != '1'">
                <div class="button__background2"></div>
                <div class="button__background"></div>
                <button (click)="setView(EDIT_STATE)" class="action__button">Richiedi Reso
                    <div class="arrow-left">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.14 16.48">
                            <line y1="8.24" x2="19.55" y2="8.24" />
                            <polyline points="11.4 0.5 19.14 8.24 11.4 15.98" />
                        </svg>
                    </div>
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="activeView == EDIT_STATE">
        <p class="blue padding-top--40 padding-bottom--30"><b>Richiesta di reso</b></p>
        <p>Ci dispiace che tu abbia avuto problemi con il tuo ordine.<br> Motivaci la richiesta di reso e una volta inviata attendi la nostra approvazione e le indicazioni per poterlo effettuare.</p>
        <form [formGroup]="refoundForm" #form="ngForm">
            <div class="form__container">
                <mat-form-field appearance="standard" class="grid--1-2">
                    <mat-label>Motivazione del reso</mat-label>
                    <textarea matInput formControlName="refoundText" required></textarea>
                    <mat-error *ngIf="(f.refoundText.dirty || f.refoundText.touched)">
                        campo <strong>obbligatorio</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </form>
        <div class="action-float">
            <button (click)="setView(DETAILS_STATE)" class="link-underline">annulla</button>
            <div class="button__container">
                <div class="button__background2"></div>
                <div class="button__background"></div>
                <button (click)="requestProductReturn()" class="action__button" [disabled]="!refoundForm.valid || isLoading">Invia richiesta
                <div class="arrow-left"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.14 16.48">
                        <line y1="8.24" x2="19.55" y2="8.24" />
                        <polyline points="11.4 0.5 19.14 8.24 11.4 15.98" /></svg></div>
            </button>
            </div>
        </div>
    </div>
</div>
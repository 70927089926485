<section class="container container--double intro-page">
    <div class="container__left">
        <div [@titleEnter]="isEnter" class="animate">
            <p [@titleEnterText]="isEnter">Da oltre 100 anni</p>
            <div class="intro-page__container">
                <div class="intro-page__title"><span [@titleEnterSpan]="{value: isEnter, params: {durationAnim: 0.5}}">la storia</span></div>
                <div class="intro-page__title"><span [@titleEnterSpan]="{value: isEnter, params: {durationAnim: 0.6}}">si muove</span></div>
                <div class="intro-page__title"><span [@titleEnterSpan]="{value: isEnter, params: {durationAnim: 0.7}}">sui pedali</span></div>
            </div>
            <h1 [@titleEnterText]="isEnter" (@titleEnterText.done)="animEnd($event)">Produzione di biciclette, vendita<br>e assistenza, nel cuore di Brescia.</h1>
        </div>
    </div>
    <div class="container__right">
        <div class="box box-right" [@imgEnter]="{value: isEnter, params: {transformDistancy: 30}}">
            <div class="box-img" [@imgScale]="isEnter"></div>
            <div class="box__overlay"></div>
            <p class="text-rotate mono">IN PRIMO PIANO</p>
            <a [routerLink]="['/biciclette']" [queryParams]="{category: 1}" appCursorHover [cursorUrl]="'url(assets/images/cursor/catalogo.png)'">
                <div class="box-link">
                    <div class="box-link__container">
                        <div>
                            <div class="text-rotate mono" *ngIf="!isMobile">01</div>
                            <p>Le city bike Zecchini</p>
                        </div>
                        <div class="box-link__arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.05 10"><path d="M9.06,4.22,6,1.12,7.07,0l5,5-5,5L5.92,8.86,9,5.78H0V4.22Z"/></svg>
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <div class="box box-left" [@imgEnter]="{value: isEnter, params: {transformDistancy: 15}}">
            <div class="box-img" [@imgScale]="isEnter"></div>
            <div class="box__overlay"></div>
            <p class="text-rotate mono">IN PRIMO PIANO</p>
            <a [routerLink]="['/ebike']" [queryParams]="{category: 2}" appCursorHover [cursorUrl]="'url(assets/images/cursor/catalogo.png)'">
                <div class="box-link">
                    <div class="box-link__container">
                        <div>
                            <div class="text-rotate mono" *ngIf="!isMobile">02</div>
                            <p>Le migliori eBike sul mercato</p>
                        </div>
                        <div class="box-link__arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.05 10"><path d="M9.06,4.22,6,1.12,7.07,0l5,5-5,5L5.92,8.86,9,5.78H0V4.22Z"/></svg>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>
</section>
<section class="container--front container--white">
    <div class="bg--grey"></div>
    <div class="image-change__container" id="tl1">
        <div class="image-change">
            <picture @imageFadeAnimation *ngIf="activeImg == 'citybike'">
                <source sizes="(max-width: 1023px) 80vw, 40vw" type="image/webp" srcset="assets/images/home/citybike_800.webp 800w, assets/images/home/citybike_600.webp 600w, assets/images/home/citybike_400.webp 400w">
                <source sizes="(max-width: 1023px) 80vw, 40vw" type="image/jpg" srcset="assets/images/home/citybike_800.jpg 800w, assets/images/home/citybike_600.jpg 600w, assets/images/home/citybike_400.jpg 400w">
                <img sizes="(max-width: 1023px) 80vw, 40vw" src="assets/images/home/citybike_800.jpg" srcset="assets/images/home/citybike_800.jpg 800w, assets/images/home/citybike_600.jpg 600w, assets/images/home/citybike_400.jpg 400w" alt="City bike Zecchini">
            </picture>
            <picture @imageFadeAnimation *ngIf="activeImg == 'mountain'">
                <source sizes="(max-width: 1023px) 80vw, 40vw" type="image/webp" srcset="assets/images/home/mountainbike_800.webp 800w, assets/images/home/mountainbike_600.webp 600w, assets/images/home/mountainbike_400.webp 400w">
                <source sizes="(max-width: 1023px) 80vw, 40vw" type="image/jpg" srcset="assets/images/home/mountainbike_800.jpg 800w, assets/images/home/mountainbike_600.jpg 600w, assets/images/home/mountainbike_400.jpg 400w">
                <img sizes="(max-width: 1023px) 80vw, 40vw" src="assets/images/home/mountainbike_800.jpg" srcset="assets/images/home/mountainbike_800.jpg 800w, assets/images/home/mountainbike_600.jpg 600w, assets/images/home/mountainbike_400.jpg 400w" alt="Mountain Zecchini">
            </picture>
            <picture @imageFadeAnimation *ngIf="activeImg == 'ebike'">
                <source sizes="(max-width: 1023px) 80vw, 40vw" type="image/webp" srcset="assets/images/home/ebike_800.webp 800w, assets/images/home/ebike_600.webp 600w, assets/images/home/ebike_400.webp 400w">
                <source sizes="(max-width: 1023px) 80vw, 40vw" type="image/jpg" srcset="assets/images/home/ebike_800.jpg 800w, assets/images/home/ebike_600.jpg 600w, assets/images/home/ebike_400.jpg 400w">
                <img sizes="(max-width: 1023px) 80vw, 40vw" src="assets/images/home/ebike_800.jpg" srcset="assets/images/home/ebike_800.jpg 800w, assets/images/home/ebike_600.jpg 600w, assets/images/home/ebike_400.jpg 400w" alt="Ebike Xp Bike">
            </picture>
            <div class="image-change__number">
                <span [ngClass]="{'active': activeImg == 'ebike'}">01</span>
                <span [ngClass]="{'active': activeImg == 'citybike'}">02</span>
                <span [ngClass]="{'active': activeImg == 'mountain'}">03</span></div>
        </div>
        <div class="image-change__text">
            <p class="blue split" #split1>“Non importa cosa ti spinga a pedalare, la cosa importante è non stare mai con i piedi per terra.”</p>
        </div>
        <div class="text-change__container">
            <h2 (mouseenter)="txtOver('ebike')" [ngClass]="{'active': activeImg == 'ebike'}">ebike</h2>
            <h2 (mouseenter)="txtOver('citybike')" [ngClass]="{'active': activeImg == 'citybike'}">citybike</h2>
            <h2 (mouseenter)="txtOver('mountain')" [ngClass]="{'active': activeImg == 'mountain'}">mountain bike</h2>
        </div>
    </div>
</section>
<section class="container--front container--white">
    <div class="perche__container parallaxText">
        <div class="text-rotate text-rotate--no-mobile">
            <p class="orange mono">PERCHÈ SIAMO QUI</p>
        </div>
        <h3 class="frontend__title">Pedalare ed esplorare il mondo: che tu lo faccia con una <a [routerLink]="['/biciclette']" [queryParams]="{category: 1}" appCursorHover [cursorUrl]="'url(assets/images/cursor/biciclette.png)'" class="orange">citybike</a>, una mountain bike, un modello vintage oppure un’
            <a [routerLink]="['/ebike']" [queryParams]="{category: 2}" appCursorHover [cursorUrl]="'url(assets/images/cursor/ebike.png)'" class="orange">ebike</a> non conta.<br><br>Conta solo la passione.</h3>
    </div>
</section>
<section class="container--front container--white" id="tl3">
    <div class="grid__front">
        <div>
            <h2 class="orange split" #split2>Zecchini è storia e passione per le due ruote</h2>
        </div>
        <div class="textOpacity">
            <p>Una passione che nasce più di 100 anni fa, quando Giulio Zecchini aprì il suo primo negozio di Biciclette a Brescia.<br><br>Un sogno che vive ancora in famiglia e ha attraversato 3 generazioni di sportivi ed amanti della due ruote.</p>
        </div>
        <div class="textOpacity">
            <p>Il sentimento e la voglia si sono trasformate in tecnica, esperienza e competenza riconosciute in tutta la provincia, diventando presto un punto di riferimento per gli amanti delle due ruote, quelle spinte con il sudore della fronte.</p>
        </div>
    </div>
    <div class="action-float--right action-float--end">
        <div class="frontend-link">
            <a [routerLink]="['/storia']">scopri di più</a>
            <div class="frontend-link__line"></div>
            <div class="frontend-link__line2"></div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.05 10"><path d="M9.06,4.22,6,1.12,7.07,0l5,5-5,5L5.92,8.86,9,5.78H0V4.22Z"/></svg>
        </div>
    </div>
</section>
<section class="container--front container--slider">
    <div class="bg--white2"></div>
    <app-slider id="slider1" [sliderCursorPrev]="'url(assets/images/cursor/dx.png)'" [sliderCursorNext]="'url(assets/images/cursor/sx.png)'" [sliderFlip]='false' [sliderItem]=homeSlider1 sliderTitle='PRODOTTI ZECCHINI'></app-slider>
</section>
<section class="container--front parallaxText cosa">
    <div class="grid__front">
        <div>
            <h2 #split3 class="orange split">Siamo un negozio storico nel cuore di Brescia.</h2>
        </div>
        <div class="grid__front--2-4 cosa__container">
            <div class="text-rotate text-rotate--title text-rotate--no-mobile">
                <p class="mono orange">COS'È ZECCHINI</p>
            </div>
            <h3 class="frontend__title">Produciamo, vendiamo e ripariamo <a [routerLink]="['/biciclette']" [queryParams]="{category: 1}" appCursorHover [cursorUrl]="'url(assets/images/cursor/biciclette.png)'" class="light-blue">biciclette di nostra creazione</a>, completandole con i migliori accessori sul mercato.</h3>
        </div>
        <div></div>
        <div class="textOpacity">
            <p>Abbiamo preso tutta la competenza, la conoscenza e la tecnica maturata nei primi anni di attività, quando Zecchini era conosciuto principalmente come marchio sportivo, e le abbiamo inserite nelle nostre city bike e mountain bike.</p>
        </div>
        <div class="textOpacity">
            <p>Risultato? Un’ampia scelta di modelli, per tutti i gusti e per tutte le esigenze, che hanno in comune la tecnica e l’affidabilità, restando alla portata di tutti.<br><br>Altra caratteristica fondamentale: restiamo sempre a vostra disposizione.</p>
        </div>
    </div>
    <div class="action-float--right action-float--end">
        <div class="frontend-link">
            <a [routerLink]="['/servizi']">servizi</a>
            <div class="frontend-link__line"></div>
            <div class="frontend-link__line2"></div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.05 10"><path d="M9.06,4.22,6,1.12,7.07,0l5,5-5,5L5.92,8.86,9,5.78H0V4.22Z"/></svg>
        </div>
    </div>
</section>
<section class="container--front">
    <div class="bg--white" *ngIf="!isMobile"></div>
    <div class="grid__front padding-bottom--0 marchi">
        <div *ngIf="!isMobile" class="infinite-scroll" #infiniteScroll (mouseenter)="textPause()" (mouseleave)="textPlay()">
            <div *ngFor="let c of [1, 2]" class="infinite-scroll__container" #infiniteScrollContainer>
                <h3 [ngClass]="{'active': activeText == 'xp'}" (mouseenter)="textInfiniteOver('xp')" (mouseleave)="textInfiniteOver('')">Xp Bikes</h3>
                <h3 [ngClass]="{'active': activeText == 'montegrappa'}" (mouseenter)="textInfiniteOver('montegrappa')" (mouseleave)="textInfiniteOver('')">Monte Grappa</h3>
                <h3 [ngClass]="{'active': activeText == 'shimano'}" (mouseenter)="textInfiniteOver('shimano')" (mouseleave)="textInfiniteOver('')">Shimano</h3>
                <h3 [ngClass]="{'active': activeText == 'vittoria'}" (mouseenter)="textInfiniteOver('vittoria')" (mouseleave)="textInfiniteOver('')">Vittoria</h3>
                <h3 [ngClass]="{'active': activeText == 'endura'}" (mouseenter)="textInfiniteOver('endura')" (mouseleave)="textInfiniteOver('')">Endura</h3>
                <h3 [ngClass]="{'active': activeText == 'sram'}" (mouseenter)="textInfiniteOver('sram')" (mouseleave)="textInfiniteOver('')">Sram</h3>
            </div>
        </div>
        <div *ngIf="isMobile" class="infinite-scroll">
            <div class="infinite-scroll__container--mobile">
                <h3 [ngClass]="{'active': activeText == 'xp'}" (mouseenter)="textInfiniteOver('xp')" (mouseleave)="textInfiniteOver('')">Xp Bikes</h3>
                <h3 [ngClass]="{'active': activeText == 'shimano'}" (mouseenter)="textInfiniteOver('shimano')" (mouseleave)="textInfiniteOver('')">Shimano</h3>
                <h3 [ngClass]="{'active': activeText == 'endura'}" (mouseenter)="textInfiniteOver('endura')" (mouseleave)="textInfiniteOver('')">Endura</h3>
                <h3 [ngClass]="{'active': activeText == 'montegrappa'}" (mouseenter)="textInfiniteOver('montegrappa')" (mouseleave)="textInfiniteOver('')">Monte Grappa</h3>
                <h3 [ngClass]="{'active': activeText == 'sram'}" (mouseenter)="textInfiniteOver('sram')" (mouseleave)="textInfiniteOver('')">Sram</h3>
                <h3 [ngClass]="{'active': activeText == 'vittoria'}" (mouseenter)="textInfiniteOver('vittoria')" (mouseleave)="textInfiniteOver('')">Vittoria</h3>
            </div>
        </div>
        <div class="grid__img">
            <picture @imageFadeAnimation [@showHidden]="activeText == 'xp' ? 'show' : 'hidden'">
                <source type="image/webp" sizes="(max-width: 1023px) 80vw, 20vw" srcset="assets/images/home/brand/xpbike_400.webp 400w, assets/images/home/brand/xpbike_200.webp 200w">
                <source type="image/jpg" sizes="(max-width: 1023px) 80vw, 20vw" srcset="assets/images/home/brand/xpbike_400.jpg 400w, assets/images/home/brand/xpbike_200.jpg 200w">
                <img src="assets/images/home/brand/xpbike_400.jpg" sizes="(max-width: 1023px) 80vw, 20vw" srcset="assets/images/home/brand/xpbike_400.jpg 400w, assets/images/home/brand/xpbike_200.jpg 200w" alt="Xp Bike biciclette elettriche">
            </picture>
            <picture @imageFadeAnimation [@showHidden]="activeText == 'montegrappa' ? 'show' : 'hidden'">
                <source type="image/webp" sizes="(max-width: 1023px) 80vw, 20vw" srcset="assets/images/home/brand/montegrappa_400.webp 400w, assets/images/home/brand/montegrappa_200.webp 200w">
                <source type="image/jpg" sizes="(max-width: 1023px) 80vw, 20vw" srcset="assets/images/home/brand/montegrappa_400.jpg 400w, assets/images/home/brand/montegrappa_200.jpg 200w">
                <img src="assets/images/home/brand/montegrappa_400.jpg" sizes="(max-width: 1023px) 80vw, 20vw" srcset="assets/images/home/brand/montegrappa_400.jpg 400w, assets/images/home/brand/montegrappa_200.jpg 200w" alt="Montegrappa selle bicicletta">
            </picture>
            <picture @imageFadeAnimation [@showHidden]="activeText == 'shimano' ? 'show' : 'hidden'">
                <source type="image/webp" sizes="(max-width: 1023px) 80vw, 20vw" srcset="assets/images/home/brand/shimano_400.webp 400w, assets/images/home/brand/shimano_200.webp 200w">
                <source type="image/jpg" sizes="(max-width: 1023px) 80vw, 20vw" srcset="assets/images/home/brand/shimano_400.jpg 400w, assets/images/home/brand/shimano_200.jpg 200w">
                <img src="assets/images/home/brand/shimano_400.jpg" sizes="(max-width: 1023px) 80vw, 20vw" srcset="assets/images/home/brand/shimano_400.jpg 400w, assets/images/home/brand/shimano_200.jpg 200w" alt="Shimano meccaniche e rapport">
            </picture>
            <picture @imageFadeAnimation [@showHidden]="activeText == 'vittoria' ? 'show' : 'hidden'">
                <source type="image/webp" sizes="(max-width: 1023px) 80vw, 20vw" srcset="assets/images/home/brand/vittoria_400.webp 400w, assets/images/home/brand/vittoria_200.webp 200w">
                <source type="image/jpg" sizes="(max-width: 1023px) 80vw, 20vw" srcset="assets/images/home/brand/vittoria_400.jpg 400w, assets/images/home/brand/vittoria_200.jpg 200w">
                <img src="assets/images/home/brand/vittoria_400.jpg" sizes="(max-width: 1023px) 80vw, 20vw" srcset="assets/images/home/brand/vittoria_400.jpg 400w, assets/images/home/brand/vittoria_200.jpg 200w" alt="Vittoria pneumatici">
            </picture>
            <picture @imageFadeAnimation [@showHidden]="activeText == 'endura' ? 'show' : 'hidden'">
                <source type="image/webp" sizes="(max-width: 1023px) 80vw, 20vw" srcset="assets/images/home/brand/endura_400.webp 400w, assets/images/home/brand/endura_200.webp 200w">
                <source type="image/jpg" sizes="(max-width: 1023px) 80vw, 20vw" srcset="assets/images/home/brand/endura_400.jpg 400w, assets/images/home/brand/endura_200.jpg 200w">
                <img src="assets/images/home/brand/endura_400.jpg" sizes="(max-width: 1023px) 80vw, 20vw" srcset="assets/images/home/brand/endura_400.jpg 400w, assets/images/home/brand/endura_200.jpg 200w" alt="Endura abbigliamento per ciclisti">
            </picture>
            <picture @imageFadeAnimation [@showHidden]="activeText == 'sram' ? 'show' : 'hidden'">
                <source type="image/webp" sizes="(max-width: 1023px) 80vw, 20vw" srcset="assets/images/home/brand/sram_400.webp 400w, assets/images/home/brand/sram_200.webp 200w">
                <source type="image/jpg" sizes="(max-width: 1023px) 80vw, 20vw" srcset="assets/images/home/brand/sram_400.jpg 400w, assets/images/home/brand/sram_200.jpg 200w">
                <img src="assets/images/home/brand/sram_400.jpg" sizes="(max-width: 1023px) 80vw, 20vw" srcset="assets/images/home/brand/sram_400.jpg 400w, assets/images/home/brand/sram_200.jpg 200w" alt="Sram tecnologia per le biciclette">
            </picture>
        </div>
        <div class="grid-title__container">
            <h2>Scegliamo solo i migliori marchi per biciclette</h2>
            <div class="mono">SELEZIONA UN BRAND</div>
        </div>
    </div>
    <div class="grid-ngif__container">
        <div class="grid__front grid-ngif" [@showHidden]="activeText == 'xp' && !isMobile ? 'show' : 'hidden'">
            <div></div>
            <div class="grid--2-3">
                <p class="grid-ngif__title">Il nostro partner<br>per il mondo delle eBike</p>
            </div>
            <div class="grid--2">
                <p>Dal 2004 Xp Bikes realizza eBike tecnologiche e sicure, dal design particolarmente curato, adatte ad esplorare il mondo.</p>
            </div>
            <div class="grid--3">
                <p>Utilizza solo i migliori componenti tecnici: freni a disco idraulici Tektro, computer di bordo con display lcd e cambio Shimano.</p>
            </div>
        </div>
        <div class="grid__front grid-ngif" [@showHidden]="activeText == 'montegrappa' && !isMobile ? 'show' : 'hidden'">
            <div></div>
            <div class="grid--2-3">
                <p class="grid-ngif__title">Storia e qualità artigianale<br>nelle selle dal 1970</p>
            </div>
            <div class="grid--2">
                <p>Costruisce selle e oggetti di pelletteria sportiva (borse e borselli) Made in Italy, di qualità ormai riconosciuta.</p>
            </div>
            <div class="grid--3">
                <p>Gli accessori sono progettati e realizzati a mano, con design e materiali accuratamente scelti e adatti ad ogni tipologia di bicicletta.</p>
            </div>
        </div>
        <div class="grid__front grid-ngif" [@showHidden]="activeText == 'shimano' && !isMobile ? 'show' : 'hidden'">
            <div></div>
            <div class="grid--2-3">
                <p class="grid-ngif__title">La leggenda delle meccaniche<br>e dei rapporti</p>
            </div>
            <div class="grid--2">
                <p>Shimano, azienda dalla storia lunga e gloriosa, è leader mondiale nella componentistica per city bike e bici da corsa.</p>
            </div>
            <div class="grid--3">
                <p>Oltre alle leggendarie meccaniche per i cambi, produce anche sistemi frenanti, mozzi, meccanismi a ruota libera, pedali e catene.</p>
            </div>
        </div>
        <div class="grid__front grid-ngif" [@showHidden]="activeText == 'vittoria' && !isMobile ? 'show' : 'hidden'">
            <div></div>
            <div class="grid--2-3">
                <p class="grid-ngif__title">Tra i migliori pneumatici<br>nel settore delle due ruote</p>
            </div>
            <div class="grid--2">
                <p>Marchio italiano che realizza e distribuisce in tutto il mondo pneumatici da strada per ottenere prestazioni ciclistiche insuperabili.</p>
            </div>
            <div class="grid--3">
                <p>All’interno di gomme e ruote vengono utilizzate particolari mescole che le rendono performanti e allo stesso tempo resistenti.</p>
            </div>
        </div>
        <div class="grid__front grid-ngif" [@showHidden]="activeText == 'endura' && !isMobile ? 'show' : 'hidden'">
            <div></div>
            <div class="grid--2-3">
                <p class="grid-ngif__title">Abbigliamento tecnico<br>per ciclismo</p>
            </div>
            <div class="grid--2">
                <p>Endura produce pantaloni, giacche e magliette che permettano ai ciclisti di sopportare le più diverse condizioni atmosferiche.</p>
            </div>
            <div class="grid--3">
                <p>Il suo abbigliamento sportivo garantisce infatti protezione dal vento e dalla pioggia, traspirazione ed elevato comfort, in ogni situazione.</p>
            </div>
        </div>
        <div class="grid__front grid-ngif" [@showHidden]="activeText == 'sram' && !isMobile ? 'show' : 'hidden'">
            <div></div>
            <div class="grid--2-3">
                <p class="grid-ngif__title">Tecnologia applicata<br>al mondo della bicicletta</p>
            </div>
            <div class="grid--2">
                <p>Sram è un’azienda americana specializzata in componenti meccaniche, soprattutto per mountainbike, settore in cui è uno dei leader.</p>
            </div>
            <div class="grid--3">
                <p>Le caratteristiche chiave sono: cambi rotanti Twister 1:1, catene apribili con la falsa maglia e mozzi con dinamo integrata.</p>
            </div>
        </div>
    </div>
</section>
<!-- da cambiare il collegamento con le ebike -->
<section class="container container--full product-recommended" *ngIf="newestBikes && newestBikes.length > 0">
    <div class="grid__container grid__container--4">
        <div class="product-recommended__title">
            <p class="detail__title blue">Un catalogo di oltre 100 biciclette</p>
            <p class="orange">Ultimi arrivi</p>
        </div>
        <div *ngFor="let product of newestBikes" class="product">
            <a [routerLink]="['/prodotto/', product.id]" appCursorHover [cursorUrl]="'url(assets/images/cursor/scopri.png)'">
                <picture *ngIf="product.product_image">
                    <source sizes="20vw" type="image/webp" [srcset]="product.product_image + '_800.webp 800w, '+ product.product_image +'_400.webp 400w'">
                    <source sizes="20vw" type="image/jpg" [srcset]="product.product_image + '_800.jpg 800w, '+ product.product_image +'_400.jpg 400w'">
                    <img sizes="20vw" [src]="product.product_image + '_800.jpg'" [srcset]="product.product_image + '_800.jpg 800w, '+ product.product_image +'_400.jpg 400w'" alt="{{product.title}}">
                </picture>
                <picture *ngIf="!product.product_image">
                    <source sizes="20vw" type="image/webp" [srcset]="'assets/images/shop/bici-2_800.webp 800w, assets/images/shop/bici-2_400.webp 400w'">
                    <source sizes="20vw" type="image/jpg" srcset="assets/images/shop/bici-2_800.jpg 800w, assets/images/shop/bici-2_400.jpg 400w">
                    <img sizes="20vw" src="assets/images/shop/bici-2_800.jpg" srcset="assets/images/shop/bici-2_800.jpg 800w, assets/images/shop/bici-2_400.jpg 400w" alt="{{product.title}}">
                </picture>
                <div *ngIf="product.discount>0" class="labelDiscount">Sconto {{product.discount}}%</div>
                <div class="info__container">
                    <p class="info__subtitle">{{product.subcategory_label}}</p>
                    <div class="info__title">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12.5" viewBox="0 0 20 16.6"><path d="M15,7,9.9,1.87,11.73,0,20,8.3l-8.27,8.3-1.9-1.9L15,9.6H0V7Z"/></svg>
                        <p>{{product.title}}</p>
                    </div>
                    <div class="price">
                        <p [ngClass]="{'info__price--discount': product.discount>0}" class="info__price">€{{product.gross_price}}</p>
                        <p *ngIf="product.discount>0" class="info__discount">€{{ (product.gross_price-(product.gross_price*(product.discount/100))).toFixed(2) }}</p>
                    </div>
                </div>
            </a>
        </div>
    </div>
    <div class="action-float--right action-float--end padding-top--40">
        <div class="frontend-link">
            <a [routerLink]="['/biciclette']" [queryParams]="{category: 1}">catalogo bici</a>
            <div class="frontend-link__line"></div>
            <div class="frontend-link__line2"></div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.05 10"><path d="M9.06,4.22,6,1.12,7.07,0l5,5-5,5L5.92,8.86,9,5.78H0V4.22Z"/></svg>
        </div>
    </div>
</section>
<section class="container container--full product-recommended padding-top--60" *ngIf="newestEBikes && newestEBikes.length > 0">
    <div class="grid__container grid__container--4">
        <div class="product-recommended__title">
            <p class="detail__title blue">Il prodotto dell'anno: le e-bike</p>
            <p class="orange">Ultimi arrivi</p>
        </div>
        <div *ngFor="let product of newestEBikes" class="product">
            <a [routerLink]="['/prodotto/', product.id]" appCursorHover [cursorUrl]="'url(assets/images/cursor/scopri.png)'">
                <picture *ngIf="product.product_image">
                    <source sizes="20vw" type="image/webp" [srcset]="product.product_image + '_800.webp 800w, '+ product.product_image +'_400.webp 400w'">
                    <source sizes="20vw" type="image/jpg" [srcset]="product.product_image + '_800.jpg 800w, '+ product.product_image +'_400.jpg 400w'">
                    <img sizes="20vw" [src]="product.product_image + '_800.jpg'" [srcset]="product.product_image + '_800.jpg 800w, '+ product.product_image +'_400.jpg 400w'" alt="{{product.title}}">
                </picture>
                <picture *ngIf="!product.product_image">
                    <source sizes="20vw" type="image/webp" [srcset]="'assets/images/shop/bici-2_800.webp 800w, assets/images/shop/bici-2_400.webp 400w'">
                    <source sizes="20vw" type="image/jpg" srcset="assets/images/shop/bici-2_800.jpg 800w, assets/images/shop/bici-2_400.jpg 400w">
                    <img sizes="20vw" src="assets/images/shop/bici-2_800.jpg" srcset="assets/images/shop/bici-2_800.jpg 800w, assets/images/shop/bici-2_400.jpg 400w" alt="{{product.title}}">
                </picture>
                <div *ngIf="product.discount>0" class="labelDiscount">Sconto {{product.discount}}%</div>
                <div class="info__container">
                    <p class="info__subtitle">{{product.subcategory_label}}</p>
                    <div class="info__title">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12.5" viewBox="0 0 20 16.6"><path d="M15,7,9.9,1.87,11.73,0,20,8.3l-8.27,8.3-1.9-1.9L15,9.6H0V7Z"/></svg>
                        <p>{{product.title}}</p>
                    </div>
                    <div class="price">
                        <p [ngClass]="{'info__price--discount': product.discount>0}" class="info__price">€{{product.gross_price}}</p>
                        <p *ngIf="product.discount>0" class="info__discount">€{{ (product.gross_price-(product.gross_price*(product.discount/100))).toFixed(2) }}</p>
                    </div>
                </div>
            </a>
        </div>
    </div>
    <div class="action-float--right action-float--end padding-top--40">
        <div class="frontend-link">
            <a [routerLink]="['/ebike']" [queryParams]="{category: 2}">catalogo e-bike</a>
            <div class="frontend-link__line"></div>
            <div class="frontend-link__line2"></div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.05 10"><path d="M9.06,4.22,6,1.12,7.07,0l5,5-5,5L5.92,8.86,9,5.78H0V4.22Z"/></svg>
        </div>
    </div>
</section>
<section class="container container--full padding-top--0 product-recommended" *ngIf="newestAccessories && newestAccessories.length > 0">
    <div class="grid__container grid__container--4">
        <div class="product-recommended__title">
            <p class="detail__title blue">e oltre 3000 prodotti</p>
            <p class="orange">Accessori più richiesti</p>
        </div>
        <div *ngFor="let product of newestAccessories" class="product">
            <a [routerLink]="['/prodotto/', product.id]" appCursorHover [cursorUrl]="'url(assets/images/cursor/scopri.png)'">
                <picture *ngIf="product.product_image">
                    <source sizes="20vw" type="image/webp" [srcset]="product.product_image + '_800.webp 800w, '+ product.product_image +'_400.webp 400w'">
                    <source sizes="20vw" type="image/jpg" [srcset]="product.product_image + '_800.jpg 800w, '+ product.product_image +'_400.jpg 400w'">
                    <img sizes="20vw" [src]="product.product_image + '_800.jpg'" [srcset]="product.product_image + '_800.jpg 800w, '+ product.product_image +'_400.jpg 400w'" alt="{{product.title}}">
                </picture>
                <picture *ngIf="!product.product_image">
                    <source sizes="20vw" type="image/webp" [srcset]="'assets/images/shop/bici-2_800.webp 800w, assets/images/shop/bici-2_400.webp 400w'">
                    <source sizes="20vw" type="image/jpg" srcset="assets/images/shop/bici-2_800.jpg 800w, assets/images/shop/bici-2_400.jpg 400w">
                    <img sizes="20vw" src="assets/images/shop/bici-2_800.jpg" srcset="assets/images/shop/bici-2_800.jpg 800w, assets/images/shop/bici-2_400.jpg 400w" alt="{{product.title}}">
                </picture>
                <div *ngIf="product.discount>0" class="labelDiscount">Sconto {{product.discount}}%</div>
                <div class="info__container">
                    <p class="info__subtitle">{{product.subcategory_label}}</p>
                    <div class="info__title">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12.5" viewBox="0 0 20 16.6"><path d="M15,7,9.9,1.87,11.73,0,20,8.3l-8.27,8.3-1.9-1.9L15,9.6H0V7Z"/></svg>
                        <p>{{product.title}}</p>
                    </div>
                    <div class="price">
                        <p [ngClass]="{'info__price--discount': product.discount>0}" class="info__price">€{{product.gross_price}}</p>
                        <p *ngIf="product.discount>0" class="info__discount">€{{ (product.gross_price-(product.gross_price*(product.discount/100))).toFixed(2) }}</p>
                    </div>
                </div>
            </a>
        </div>
    </div>
    <div class="action-float--right action-float--end padding-top--40">
        <div class="frontend-link">
            <a [routerLink]="['/accessori-bici']" [queryParams]="{category: 3}">catalogo accessori</a>
            <div class="frontend-link__line"></div>
            <div class="frontend-link__line2"></div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.05 10"><path d="M9.06,4.22,6,1.12,7.07,0l5,5-5,5L5.92,8.86,9,5.78H0V4.22Z"/></svg>
        </div>
    </div>
</section>
<section>
    <div class="bg--white"></div>
    <div class="container--front container--double consigli">
        <p class="frontend__title parallaxText">Molte persone ci chiedono: come faccio a scegliere la bicicletta giusta per le mie esigenze?</p>
        <div class="consigli__img">
            <div class="text-rotate">
                <p class="mono orange">PERCHÈ SIAMO QUI</p>
            </div>
            <picture>
                <source type="image/webp" sizes="(max-width: 1023px) 80vw, 35vw" srcset="assets/images/home/bici-consigli_800.webp 800w, assets/images/home/bici-consigli_600.webp 600w, assets/images/home/bici-consigli_400.webp 400w">
                <source type="image/jpg" sizes="(max-width: 1023px) 80vw, 35vw" srcset="assets/images/home/bici-consigli_800.jpg 800w, assets/images/home/bici-consigli_600.jpg 600w, assets/images/home/bici-consigli_400.jpg 400w">
                <img src="assets/images/home/bici-consigli_800.jpg" sizes="(max-width: 1023px) 80vw, 35vw" srcset="assets/images/home/bici-consigli_800.jpg 800w, assets/images/home/bici-consigli_600.jpg 600w, assets/images/home/bici-consigli_400.jpg 400w" alt="la bici giusta per te">
            </picture>
        </div>
    </div>
    <div class="container--front container--double consigli padding-top--0 container--white">
        <p class="consigli__text textOpacity">Questo e altri consigli, dritte e dettagli tecnici per capire che tipo di ciclista sei e decidere quale sarà la bicicletta più adatta alle tue esigenze.</p>
        <h2 #split4 class="orange split">“Siamo a disposizione<br>per consigliarvi e<br> guidarvi nell'acquisto.”</h2>
    </div>
    <div class="container--front padding-top--40 container--white">
        <div class="action-float--right action-float--end">
            <div class="frontend-link">
                <a [routerLink]="['/consigli']">consigli utili</a>
                <div class="frontend-link__line"></div>
                <div class="frontend-link__line2"></div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.05 10"><path d="M9.06,4.22,6,1.12,7.07,0l5,5-5,5L5.92,8.86,9,5.78H0V4.22Z"/></svg>
            </div>
        </div>
    </div>
</section>
<section class="container--front slider--white container--slider">
    <div class="bg--white"></div>
    <app-slider id="slider2" [sliderCursorNext]="'url(assets/images/cursor/dx.png)'" [sliderCursorPrev]="'url(assets/images/cursor/sx.png)'" [sliderFlip]='true' [sliderItem]=homeSlider2 sliderTitle='ESPLORIAMO LA CITTÀ'></app-slider>
</section>
<section class="container--front strava">
    <a href="https://www.strava.com/clubs/882628" target="_blank">
        <div class="infinite-scroll" (mouseenter)="textPause()" (mouseleave)="textPlay()">
            <div *ngFor="let c of [1, 2, 3]" class="infinite-scroll__container">
                <h3 [ngClass]="{'active': activeText2 == 'strava'}" (mouseenter)="textInfiniteOver('strava')" (mouseleave)="textInfiniteOver('')">#exploringurbanlandscapes</h3>
            </div>
        </div>
    </a>
    <div class="strava__img strava__img--home">
        <picture @imageFadeAnimation *ngIf="activeText2 == 'strava'">
            <source type="image/webp" sizes="(max-width: 1023px) 50vw, 35vw" srcset="assets/images/home/exploring_400.webp 400w, assets/images/home/exploring_200.webp 200w">
            <source type="image/jpg" sizes="(max-width: 1023px) 50vw, 35vw" srcset="assets/images/home/exploring_400.jpg 400w, assets/images/home/exploring_200.jpg 200w">
            <img src="assets/images/home/exploring_400.jpg" sizes="(max-width: 1023px) 50vw, 35vw" srcset="assets/images/home/exploring_400.jpg 400w, assets/images/home/exploring_200.jpg 200w" alt="la bici giusta per te">
        </picture>
        <div class="mono orange">PROVA LE SFIDE</div>
    </div>
</section>
<section class="container--front">
    <div class="grid__front padding-bottom--0 parallaxText">
        <div>
            <h2 #split5 class="orange padding-bottom--70 split">“Troverete la stessa nostra competenza e capacità di consigliarvi.”</h2>
            <p class="textOpacity">Non possiamo chiamarli clienti: ormai siamo una famiglia che condivide valori e passioni. Molti dei nostri modelli sono disponibili in questi negozi.</p>
        </div>
        <div class="grid--2-3">
            <p class="frontend__title">Zecchini non è solo a Brescia, ma anche <span class="orange">in ogni nostro rivenditore</span>.</p>
        </div>
    </div>
    <div class="action-float--right action-float--end padding-bottom--70">
        <div class="frontend-link">
            <a [routerLink]="['/rivenditori']">scoprili</a>
            <div class="frontend-link__line"></div>
            <div class="frontend-link__line2"></div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.05 10"><path d="M9.06,4.22,6,1.12,7.07,0l5,5-5,5L5.92,8.86,9,5.78H0V4.22Z"/></svg>
        </div>
    </div>
</section>
<app-footer></app-footer>
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UserService } from "./services/user.service";
import { environment } from "src/environments/environment";
import { User } from "./models/user.model";
import { ShopService } from "./services/shop.service";
// import 'rxjs/add/operator/toPromise';
// import { map } from 'rxjs/operator/map';

@Injectable()
export class AppConfigService {
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private shopService: ShopService
  ) {}

  baseUrl = environment.baseUrl;

  createGuest() {
    return this.http
      .post(this.baseUrl + "login/guest", {})
      .toPromise()
      .then((data: any) => {
        this.userService.setUser(data);
        this.userService.authSubject.next(true);
        localStorage.setItem("zecchini_token", data.token);
      })
      .catch((err) => {
        this.userService.authSubject.next(false);
      });
  }

  load(): Promise<any> {
    this.shopService.getProductCategories().subscribe((res: any) => {
      let transformed: any = {};
      res.map((item) => (transformed[item.id] = item.name));
      this.shopService.categories.next(transformed);
    });

    this.shopService.getProductSubCategories().subscribe((res: any) => {
      let transformed: any = {};
      res.map((item) => (transformed[item.id] = item.name));
      this.shopService.subcategories.next(transformed);
    });

    if (localStorage.getItem("zecchini_token")) {
      return this.http
        .get(this.baseUrl + "profile", {
          headers: {
            Authorization: localStorage.getItem("zecchini_token"),
            "Content-Type": "application/json",
          },
        })
        .toPromise()
        .then((data: User) => {
          this.userService.setUser(data);
          this.userService.authSubject.next(true);
        })
        .catch((err) => {
          this.userService.authSubject.next(false);
          if(err && err.status && err.status == 401) {
            return this.createGuest();
          }
        });
    } else {
      return this.createGuest();
    }
  }
}

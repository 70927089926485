<div class="container container--double container--cart">
    <section class="container__left container--white">
        <h1>Accedi al tuo account</h1>
        <h2>Sei già registrato?</h2>
        <!-- (ngSubmit)="login()" -->
        <form [formGroup]="loginForm"  (ngSubmit)="login()">
            <p>Accedi con le tue credenziali</p>
            <div class="form__container">
                <mat-form-field appearance="standard">
                    <mat-label>e-mail</mat-label>
                    <input type="email" matInput formControlName="loginEmail" required>
                    <mat-error *ngIf="f.loginEmail.invalid && (f.loginEmail.dirty || f.loginEmail.touched)">
                        <mat-error *ngIf="(f.loginEmail.errors.required)">
                            campo <strong>obbligatorio</strong>
                        </mat-error>
                        <mat-error *ngIf="(f.loginEmail.errors.pattern)">
                            inserisci un indirizzo e-mail <strong>valido</strong>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="standard">
                    <mat-label>password</mat-label>
                    <input type="password" matInput formControlName="loginPassword" required>
                    <mat-hint>La password deve essere di minimo 8 caratteri di cui almeno una lettera maiuscola e un numero.</mat-hint>
                    <mat-error *ngIf="f.loginPassword.invalid && (f.loginPassword.dirty || f.loginPassword.touched)">
                        <mat-error *ngIf="(f.loginPassword.errors.required)">
                            campo <strong>obbligatorio</strong>
                        </mat-error>
                        <mat-error *ngIf="(f.loginPassword.errors.minlength)">
                            deve essere di minimo <strong>8 caratteri</strong>
                        </mat-error>
                        <mat-error *ngIf="(f.loginPassword.errors.pattern)">
                            La password deve essere di minimo 8 caratteri di cui almeno una lettera maiuscola e un numero.
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="action-float forgot-password">
                <div>
                    <p class="blue"><b>Password dimenticata?</b></p>
                    <button class="link-underline" type="button" (click)="setView('forgotPasswordView')">Recuperala</button>
                </div>
                <div class="button__container">
                    <div class="button__background2"></div>
                    <div class="button__background"></div>
                    <button type="submit" class="action__button">
                        Accedi
                        <div class="arrow-left">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.14 16.48">
                                <line y1="8.24" x2="19.55" y2="8.24"/><polyline points="11.4 0.5 19.14 8.24 11.4 15.98"/>
                            </svg>
                        </div>
                    </button>
                </div>
            </div>
        </form>
 
        <div class="action-float action-float--end" *ngIf="activeView == 'forgotPasswordView'">
            <form class="form-forgot" [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPassword()">
                <div class="form__container">
                    <mat-form-field appearance="standard">
                        <mat-label>e-mail</mat-label>
                        <input type="email" matInput formControlName="forgotEmail" required>
                        <mat-error *ngIf="f2.forgotEmail.invalid && (f2.forgotEmail.dirty || f2.forgotEmail.touched)">
                            <mat-error *ngIf="(f2.forgotEmail.errors.required)">
                                campo <strong>obbligatorio</strong>
                            </mat-error>
                            <mat-error *ngIf="(f2.forgotEmail.errors.pattern)">
                                inserisci un indirizzo e-mail <strong>valido</strong>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                    <div class="action-float--right">
                        <div class="button__container">
                            <div class="button__background2"></div>
                            <div class="button__background"></div>
                            <button type="submit" class="action__button">Invia
                <div class="arrow-left"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.14 16.48"><line y1="8.24" x2="19.55" y2="8.24"/><polyline points="11.4 0.5 19.14 8.24 11.4 15.98"/></svg></div>
                    </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </section>
    <section class="container__right">
        <h2>Sei un nuovo cliente?</h2>
        <p>Registrati in maniera facile e veloce, così in futuro non dovrai ogni volta inserire i tuoi dati e potrai controllare il tracciamento della spedizione.</p>
        <div class="action-float">
            <h3>Registrati ora</h3>
            <div class="button__container">
                <div class="button__background2"></div>
                <div class="button__background"></div>
                <a [routerLink]="['/informazioni-cliente']" class="action__button">Procedi
            <div class="arrow-left"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.14 16.48"><line y1="8.24" x2="19.55" y2="8.24"/><polyline points="11.4 0.5 19.14 8.24 11.4 15.98"/></svg></div>
                </a>
            </div>
        </div>
        <ng-container *ngIf="isOnCheckoutProcess == true">
            <p>Oppure</p>
            <div class="action-float">
                <h3>Acquista<br>senza registrazione</h3>
                <div class="button__container">
                    <div class="button__background2"></div>
                    <div class="button__background"></div>
                    <a [routerLink]="['/informazioni-cliente']" [queryParams]="{ isRegister: false }" class="action__button">Continua
                <div class="arrow-left"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.14 16.48"><line y1="8.24" x2="19.55" y2="8.24"/><polyline points="11.4 0.5 19.14 8.24 11.4 15.98"/></svg></div>
                    </a>
                </div>
            </div>
        </ng-container>
    </section>
</div>
<app-footer></app-footer>
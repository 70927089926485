import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiCommands } from "../http/api.commands";
import { CartItem } from '../models/cart.model';
import { API } from './api.constants';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  constructor(private apiCommands: ApiCommands) { }

  public createOrder(body) {
    return this.apiCommands
      .post(API.ORDER.url, body, this.getToken())
      .pipe(map((res) => res));
  }

  public createPaymentIntent(body) {
    return this.apiCommands
      .post(API.PAYMENT_INTENT.url, body, this.getToken())
      .pipe(map((res) => res));
  }

  public capturePaypalOrder(id) {
    return this.apiCommands
      .post(API.CAPTURE_PAYPAL_ORDER.url + id, {}, this.getToken())
      .pipe(map((res) => res));
  }

  private getToken() {
    return `${localStorage.getItem("zecchini_token")}`;
  }
}

import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { shippingFee } from 'src/app/constants/shipping-fee';
import { CartItem } from 'src/app/models/cart.model';
import { Product } from 'src/app/models/product.model';
import { User } from 'src/app/models/user.model';
import { CartService } from 'src/app/services/cart.service';
import { ShopService } from 'src/app/services/shop.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { imageFadeAnimation } from '../../router/animations/component-animations';
import { AccessoriesShippingFeesComponent } from '../../shared/accessories-shipping-fees/accessories-shipping-fees.component';
import { BikeShippingFeesComponent } from '../../shared/bike-shipping-fees/bike-shipping-fees.component';


@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css'],
  animations: [
    imageFadeAnimation
  ]
})
export class CartComponent implements OnInit {

  constructor(
    private cartService: CartService, 
    private _snackBar: MatSnackBar, 
    public dialog: MatDialog,
    private userService: UserService, 
    private router: Router,
    private shopService: ShopService) { }

  cart: CartItem[] = [];
  discountCodeFormControl = new FormControl('');
  discountError: any;
  user:User;
  assetsUrl = environment.assetsUrl;
  shippingFee = shippingFee;
  emptyCart: boolean = false;

  openDialogAccessories(): void {
    const dialogRef = this.dialog.open(AccessoriesShippingFeesComponent, {
      width: '400px',
    });
  }

  openDialogBike(): void {
    const dialogRef = this.dialog.open(BikeShippingFeesComponent, {
      width: '400px',
    });
  }

  ngOnInit(): void {
    if(localStorage.getItem('discount_code')){
      this.discountCodeFormControl.setValue(localStorage.getItem('discount_code'));
      this.appendDiscountCode();
    }
    this.getCart(true);
    this.userService.getUser().subscribe((user: User) => {
      this.user = user;
    })
  }

  getCart(isFirst: boolean = false){
    this.cartService.getCart().subscribe((cart: CartItem[]) => {
      if(cart.length == 0) this.cart = [];
      
      cart.map((item, index) => {
          item = new CartItem(item, this.shopService.categories.value, this.shopService.subcategories.value)
          let dataLayerCartItems = [];
          dataLayerCartItems.push(
            {
              item_name: item?.product?.title, // Name or ID is required.
              item_id: item?.product?.id, // Name or ID is required.
              price: item?.product?.price * item?.product?.quantity,
              item_brand: item?.product?.producer_name,
              item_category: item?.product?.category_label,
              item_category_2: item?.product?.subcategory_label, // The categories can be up to 4
              item_variant: item.product_variant,
              quantity: item?.quantity
             }
          ) 
          this.viewCartDataLayer(dataLayerCartItems) 
      })
    })
    
    this.cartService.getCartItems().subscribe((cart: CartItem[]) => {
      cart.map((item, index) => {
        if(item?.product_variant?.product_variant_images?.length > 0){
          item.image = this.assetsUrl + item.product_variant.product_variant_images[0].file_name_noext;
        } 
        else if(item?.product?.product_images?.length > 0){
          item.image = this.assetsUrl + item.product.product_images[0].file_name_noext;
        }
        this.cart = cart;
      })
      this.calculateTotalPrice(cart);
    })
  }

  totalPrice:any = 0;
  accessoryTotalPrice = 0;
  totalPriceWithoutShipping = 0;
  totalShippingFee = 0;
  calculateTotalPrice(items: CartItem[]){
    let totalPrice = 0;
    this.accessoryTotalPrice = 0;
    this.totalPriceWithoutShipping = 0;
    this.totalShippingFee = 0;
    items.map(item => {
      if(item.hasQuantity){
        let itemPrice = item.product.price * item.quantity;
        this.totalPriceWithoutShipping = this.totalPriceWithoutShipping + itemPrice;
        if(item.product.product_category_id == '1' || item.product.product_category_id == '2'){
          item.product.shipping_fee = 30;
          totalPrice = (totalPrice + itemPrice) + (30 * item.quantity);
          this.totalShippingFee = this.totalShippingFee + (30 * item.quantity);
        } else {
          totalPrice = totalPrice + itemPrice;
          this.accessoryTotalPrice = this.accessoryTotalPrice + itemPrice;
        }
      } 
    })
    
    if(this.accessoryTotalPrice > 1 && this.accessoryTotalPrice <= 100) {
      totalPrice = totalPrice + 4;
      this.totalShippingFee = this.totalShippingFee + 4;
    }
    this.totalPrice = totalPrice.toFixed(2);

    if(items.length > 0) {
      this.emptyCart = false;
    } else {
      this.emptyCart = true;
    }
  }

  changeQuantity(item: CartItem, type){

    if(type == 'decrease' && item.quantity > item.product_variant.available_quantity){
      this.updateCart(item.id, item.product_variant.available_quantity, item, type);
      return;
    }

    if(type == 'increase' && item.product_variant.available_quantity == item.quantity){
      this._snackBar.open(`La quantità massima per questo prodotto è ${item.product_variant.available_quantity}`, 'OK', {
        duration: 5000,
      });
    }

    if(type == 'increase' && item.product_variant.available_quantity > item.quantity){
      this.updateCart(item.id, parseInt(JSON.parse(JSON.stringify(item.quantity))) + 1, item, type);
    } 
    else if(type == 'decrease' && item.quantity > 0){
      if(item.quantity == 1){
        this.deleteItem(item);
      } else {
        this.updateCart(item.id, parseInt(JSON.parse(JSON.stringify(item.quantity))) - 1, item, type);
      }
    }
  }

  
  isCartLoading: boolean = false;
  updateCart(id, quantity, item: CartItem, type){

    this.isCartLoading = true;
    let formData = new FormData();
    formData.append('quantity', quantity)
    this.cartService.updateCart(id, formData).subscribe((cart: CartItem[]) => {
      if(type == 'decrease'){
        this.removeFromCartDataLayer(item.product, item.product_variant, item.quantity);
      }
      if(type == 'increase'){
        this.addItemDataLayer(item.product, item.product_variant, item.quantity);
      }
      // cart = cart.map(item => new CartItem(item, this.shopService.categories.value, this.shopService.subcategories.value))
      // let changedCartItemIndex = cart.findIndex(item => item.id == id);
      // this.cart[changedCartItemIndex].quantity = cart[changedCartItemIndex].quantity;
      // this.calculateTotalPrice(cart);
      this.cartService.setCartItems(cart);
      this.isCartLoading = false;
    }, err => {
      this.isCartLoading = false;
      this._snackBar.open(err.error.message || err.message, 'OK', {duration:5000});
    })
  }

  deleteItem(item: CartItem){
    this.cartService.deleteCartItem(item.id).subscribe(res => {
      this.removeFromCartDataLayer(item.product, item.product_variant, item.quantity);
      this._snackBar.open(`Il prodotto ${item.product.title} è stato rimosso dal carrello`, 'OK', {
        duration: 5000,
      });
      this.getCart();
      this.cartService.getCart().subscribe(res => {
        this.cartService.setCartItems(res);
      })
    })
  }

  discountPercentage: any;
  appendDiscountCode(){
    // if(this.discountCodeFormControl.value){
      this.discountError = '';
      this.discountPercentage = '';
      let formData = new FormData();
      formData.append('discount_code', this.discountCodeFormControl.value)
      this.cartService.checkDiscountCode(formData).subscribe((res:any) => {
        this.discountPercentage = parseFloat(res.discount_percentage);
        localStorage.setItem('discount_code', res.discount_code);
      }, err => {
        localStorage.setItem('discount_code', '');
        this.discountError = err.error.message;
      })
    // }
  }

  cartProceed(){
    if(this.cart.length == 0 || this.cart.filter(item => !item.hasQuantity).length == this.cart.length) {
      this._snackBar.open(`Il carrello è vuoto`, 'Close', {
        duration: 4000,
      });
      return;
    }

    if(this.cart.filter(item => !item.hasCorrectQuantity && item.hasQuantity).length > 0) {
      this._snackBar.open(`Inserire una quantità corretta per poter proseguire con l'acquisto`, 'Close', {
        duration: 4000,
      });
      return;
    }

    let dataLayerCartItems = [];
    this.cart.map((item,index) => {
      dataLayerCartItems.push(
        {
          item_name: item?.product?.title, // Name or ID is required.
          item_id: item?.product?.id, // Name or ID is required.
          price: item?.product?.price * item?.product?.quantity,
          item_brand: item?.product?.producer_name,
          item_category: item?.product?.category_label,
          item_category_2: item?.product?.subcategory_label, // The categories can be up to 4
          item_variant: item.product_variant,
          // index: index+1, // If associated with a list selection.
          quantity: item?.quantity
        }
      ) 
    })

    let isGuest = this.user.user_type ? false : true;
    
    if(this.user.registration_guest == '1'){
      this.router.navigate(['checkout']);
      this.checkoutDataLayer(dataLayerCartItems);
      return;
    }
 
    if(isGuest){
      if(localStorage.getItem('guest_user')){
        this.router.navigate(['checkout']);
        this.checkoutDataLayer(dataLayerCartItems);
        return;
      }
      this.router.navigate(['login']);
      this.cartService.isOnCheckoutProcess.next(true);
    } else {
      this.checkoutDataLayer(dataLayerCartItems);
      this.router.navigate(['checkout']);
    }
  }

  viewCartDataLayer(products: Product[] = []){
    (<any>window).dataLayer.push({
      event: 'view_cart',
      ecommerce: {
        items: products
      }
    });
  }

  removeFromCartDataLayer(product: Product, productVariant, quantity){
    (<any>window).dataLayer.push({
      event: 'remove_from_cart',
      ecommerce: {
        items: [{
          item_name: product?.title, // Name or ID is required.
          item_id: product?.id, // Name or ID is required.
          price: product?.price * product?.quantity,
          item_brand: product?.producer_name,
          item_category: product?.category_label,
          item_category_2: product?.subcategory_label, // The categories can be up to 4
          item_variant: productVariant,
          // item_list_name: 'Related list', // If associated with a list selection.
          // item_list_id: 'RL1', // If associated with a list selection.
          // index: index+1, // If associated with a list selection.
          quantity: quantity - 1
        }]
      }
    });
  }

  addItemDataLayer(product: Product, productVariant, quantity){
    (<any>window).dataLayer.push({
      event: 'add_to_cart',
      ecommerce: {
        items: [{
          item_name: product?.title, // Name or ID is required.
          item_id: product?.id, // Name or ID is required.
          price: product?.price * product?.quantity,
          item_brand: product?.producer_name,
          item_category: product?.category_label,
          item_category_2: product?.subcategory_label, // The categories can be up to 4
          item_variant: productVariant,
          // item_list_name: 'Related list', // If associated with a list selection.
          // item_list_id: 'RL1', // If associated with a list selection.
          // index: index+1, // If associated with a list selection.
          quantity: quantity + 1
        }]
      }
    });
  }

  checkoutDataLayer(products: Product[] = []){
    (<any>window).dataLayer.push({
      event: 'begin_checkout',
      ecommerce: {
        items: products
      }
    });
  }
}
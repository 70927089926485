<div class="right__container">
    <button [routerLink]='["/area-riservata/"]' class="link-underline link-underline--back">torna indietro</button>
    <div *ngIf="activeView == VIEW_STATE">
        <p class="padding-top--30 padding-bottom--30 blue"><b>I tuoi indirizzi</b></p>

        <ng-container *ngIf="primaryAdrress">
            <p class="padding-top--30 padding-bottom--30 blue"><b>Indirizzo di spedizione principale</b></p>
            <div class="step2__info step2__info--border">
                <div>
                    <p>{{primaryAdrress.first_name}} {{primaryAdrress.last_name}}</p>
                    <!-- <p>Via Marco Aurelio 25 - 20127 Milano</p> -->
                    <p>{{primaryAdrress.address}} - {{primaryAdrress.postal_code}} {{primaryAdrress.city}}</p>
                    <p>{{primaryAdrress.province}}</p>
                    <p>{{primaryAdrress.phone}}</p>
                    <p *ngIf="primaryAdrress.shipping_notes">{{primaryAdrress.shipping_notes}}</p>
                </div>
                <div>
                    <button (click)="goToEditAddressScreen(primaryAdrress.id)" class="link-underline">modifica</button>
                </div>
            </div>
        </ng-container>

        <p class="padding-bottom--30 blue padding-top--30"><b>Altri indirizzi</b></p>
        <ng-container *ngFor="let address of addresses; let i = index">
            <div class="step2__info step2__info--border" [ngClass]="{'padding-top--30': i >= 1}">
                <div>
                    <p>{{address.first_name}} {{address.last_name}}</p>
                    <!-- <p>Via Marco Aurelio 25 - 20127 Milano</p> -->
                    <p>{{address.address}} - {{address.postal_code}} {{address.city}}</p>
                    <p>{{address.province}}</p>
                    <p>{{address.phone}}</p>
                    <p *ngIf="address.shipping_notes">{{address.shipping_notes}}</p>
                </div>
                <div>
                    <button (click)="goToEditAddressScreen(address.id)" class="link-underline">modifica</button>
                    &nbsp;
                    <button class="link-underline" (click)="makePrimary(address.id)">rendi principale</button>
                </div>
            </div>
        </ng-container>

        <!-- <div class="step2__info step2__info--border">
        <div>
            <p>Paolo Gares</p>
            <p>Via Marco Aurelio 25 - 20127 Milano</p>
            <p>Milano</p>
            <p>+393663431009</p>
            <p>Campanello Gares</p>
        </div>
        <div>
            <button (click)="setView(EDIT_STATE)" class="link-underline">modifica</button>
        </div>
    </div>
    <p class="padding-top--30 padding-bottom--30 blue"><b>Altri indirizzi</b></p>
    <div class="step2__info step2__info--border">
        <div>
            <p>Paolo Gares</p>
            <p>Via Paolo Lomazzo 27 - 20125 Milano</p>
            <p>Milano</p>
            <p>+393663431009</p>
            <p>Campanello Gares</p>
        </div>
        <div>
            <button (click)="setView(EDIT_STATE)" class="link-underline">modifica</button>
            &nbsp;
            <button class="link-underline">rendi principale</button>
        </div>
    </div> -->
        <div class="action-float">
            <button (click)="setView('')" class="link-underline">annulla</button>
            <div class="button__container">
                <div class="button__background2"></div>
                <div class="button__background"></div>
                <button (click)="goToCreateAddress()" class="action__button">Aggiungi
                    <div class="arrow-left"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.14 16.48">
                            <line class="cls-1" x1="7.75" x2="7.75" y2="15.5" />
                            <line class="cls-1" x1="15.5" y1="7.75" y2="7.75" /></svg></div>
                </button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="activeView == EDIT_STATE">
    <app-add-edit-shipping-addresses (onChange)="onShippingAddressChange($event)" [state]="state"
        [addressId]="selectedAddressId">
    </app-add-edit-shipping-addresses>
</div>
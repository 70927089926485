import { animate, state, style, transition, trigger } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, HostListener, Inject, OnInit, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { Pagination } from 'src/app/models/pagination.model';
import { Product } from 'src/app/models/product.model';
import { AppRoutingTransitionService } from 'src/app/services/app-routing-transition.service';
import { GoogleMicrodataService } from 'src/app/services/google-microdata.service';
import { SeoService } from 'src/app/services/seo.service';
import { ProductsParams, ShopService } from 'src/app/services/shop.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.css'],
  animations: [
    trigger('enterAnim', [
      state('false',
        style({
          transform: 'translateY(15%)',
          opacity: 0
        })
      ),
      transition('false => true', [
        animate('1s cubic-bezier(.72,.01,.07,1)',
          style({
            transform: 'translateY(0)',
            opacity: 1
          }))
      ]),
    ]),
    trigger('filterAnim', [
      state('false',
        style({
          opacity: 0
        })
      ),
      transition('false => true', [
        animate('0.4s cubic-bezier(.72,.01,.07,1)',
          style({
            opacity: 1
          }))
      ]),
    ]),
    // trigger('filterAnim', [
    //   transition('false => true', [
    //     style({ opacity: 0 }),
    //     animate('0.3s ease-in', style({ opacity: 1 })),
    //   ]),
    // ])
  ]
})

export class ShopComponent implements OnInit {
  @ViewChild('paginator') paginator: MatPaginator;
  product;
  isBrowser;
  tlFilter;
  myEventSubscription;
  public isEnter: boolean = false;
  public isOpen: boolean = false;
  windowWidth: number = window.innerWidth;
  isMobile: boolean = false;
  isSamePage: boolean = false;

  public filterForm: FormGroup;

  subcategories: any = [];
  sizes: string[] = [];

  assetsUrl = environment.assetsUrl;

  products: Product[] = [];
  pagination: Pagination = { pageCount: 1, perPage: 15 };
  total = 0;
  currentPage: any = 1;
  perPage = 15;

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) platformId: string,
    private appService: AppRoutingTransitionService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private shopService: ShopService,
    private GoogleMicrodataService: GoogleMicrodataService,
    private renderer2: Renderer2, private seoService: SeoService) {
    this.filterForm = formBuilder.group({
      subcategory: [''],
      min_price: [''],
      max_price: [''],
      // size: [''],
    })
    this.isBrowser = isPlatformBrowser(platformId);
    if (!this.isBrowser) {
      return;
    }
    gsap.registerPlugin(ScrollTrigger);

    this.router.onSameUrlNavigation = 'reload';
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  mobileDetect() {
    if (this.windowWidth < 1023) {
      this.isOpen = false;
      this.isMobile = true;
    } else {
      this.isOpen = true;
      this.isMobile = false;
    }
  }

  mobileDetectResize() {
    if (this.windowWidth < 900 && window.innerHeight < window.innerWidth && window.innerHeight > 500) {
      this.isOpen = false;
      this.isMobile = true;
    } else {
      this.isOpen = true;
      this.isMobile = false;
    }
  }

  filterOpen() {
    this.isOpen = this.isOpen = !this.isOpen;
    window.scroll(0, 0)
  }

  @HostListener('keydown.F2', ['$event'])
  onF2KeyPress(event: KeyboardEvent) { 
      alert('pippo');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = window.innerWidth;
    //this.mobileDetectResize();
  }

  ngAfterViewInit() {
    if (!this.isBrowser) {
      return;
    }
    this.paginator._intl.itemsPerPageLabel = 'Prodotti per pagina';
    this.paginator._intl.nextPageLabel = 'Pagina succesiva';
    this.paginator._intl.previousPageLabel = 'Pagina precedente'; 
    this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number): string => {
      const of = "di";
      if (length === 0 || pageSize === 0) {
        return "0 " + of + " " + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize > length ? (Math.ceil(length / pageSize) - 1) * pageSize : page * pageSize;
  
      const endIndex = Math.min(startIndex + pageSize, length);
      return startIndex + 1 + " - " + endIndex + " " + of + " " + length;
    };
  }

  ngOnInit(): void {
    this.mobileDetect();
    this.appService.stringSubject2.subscribe(() => {
      this.isSamePage = true;
    }
    );
    this.myEventSubscription = this.appService.stringSubject.subscribe(() => {
      this.isEnter = true;
    }
    );

    setTimeout(() => {
      if (!this.isSamePage) {
        this.isEnter = true
      }
    }, 200)

    this.activatedRoute.queryParams.subscribe((params: ProductsParams) => {
      let params_: ProductsParams = JSON.parse(JSON.stringify(params))
      if (params_.category == '1') {
        this.seoService.updateTitle("Catalogo biciclette");
        this.seoService.updateMetaTags([
          { name: 'description', content: "Biciclette muscolari a marchio Zecchini, progettate e realizzate in Italia. Un’ampia scelta tra citybike, moutain bike, biciclette vintage, gravel e ibride, pieghevoli e molte altro." },
          { property: 'og:title', content: "Zecchini - Catalogo biciclette" },
          { property: 'og:description', content: "Biciclette muscolari a marchio Zecchini, progettate e realizzate in Italia. Un’ampia scelta tra citybike, moutain bike, biciclette vintage, gravel e ibride, pieghevoli e molte altro." },
          { property: 'og:url', content: environment.apiUrl + "biciclette?category=1" },
          { property: 'og:image', content: environment.apiUrl + 'assets/images/opengraph_biciclette.jpg' },
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: 'Zecchini' },
          { property: 'fb:app_id', content: '141377273234274' },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:site", content: "Zecchini" },
          { name: "twitter:title", content: "Zecchini - Catalogo biciclette" },
          { name: "twitter:image", content: environment.apiUrl + 'assets/images/opengraph_biciclette.jpg' },
          { name: "twitter:description", content: "Biciclette muscolari a marchio Zecchini, progettate e realizzate in Italia. Un’ampia scelta tra citybike, moutain bike, biciclette vintage, gravel e ibride, pieghevoli e molte altro." }
        ]);
      } else if (params_.category == '2') {
        this.seoService.updateTitle("Catalogo e-bike");
        this.seoService.updateMetaTags([
          { name: 'description', content: "Le migliori e-bike (bici elettriche e a pedalata assistita) presenti sul mercato. Modelli che vanno dalle più classiche citybike, fino a quelli più sportivi e performanti." },
          { property: 'og:title', content: "Zecchini - Catalogo e-bike" },
          { property: 'og:description', content: "Le migliori e-bike (bici elettriche e a pedalata assistita) presenti sul mercato. Modelli che vanno dalle più classiche citybike, fino a quelli più sportivi e performanti." },
          { property: 'og:url', content: environment.apiUrl + "ebike?category=2" },
          { property: 'og:image', content: environment.apiUrl + 'assets/images/opengraph_ebike.jpg' },
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: 'Zecchini' },
          { property: 'fb:app_id', content: '141377273234274' },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:site", content: "Zecchini" },
          { name: "twitter:title", content: "Zecchini - Catalogo ebike" },
          { name: "twitter:image", content: environment.apiUrl + 'assets/images/opengraph_ebike.jpg' },
          { name: "twitter:description", content: "Le migliori e-bike (bici elettriche e a pedalata assistita) presenti sul mercato. Modelli che vanno dalle più classiche citybike, fino a quelli più sportivi e performanti." }
        ]);
      } else if (params_.category == '3') {
        this.seoService.updateTitle("Catalogo accessori");
        this.seoService.updateMetaTags([
          { name: 'description', content: "Tutti gli accessori per completare e personalizzare la vostra bicicletta. Selle, borse, manopole, borracce, cestini e prodotti legati alla sicurezza come fanalini e lucchetti." },
          { property: 'og:title', content: "Zecchini - Catalogo accessori" },
          { property: 'og:description', content: "Tutti gli accessori per completare e personalizzare la vostra bicicletta. Selle, borse, manopole, borracce, cestini e prodotti legati alla sicurezza come fanalini e lucchetti." },
          { property: 'og:url', content: environment.apiUrl + "accessori-bici?category=3" },
          { property: 'og:image', content: environment.apiUrl + 'assets/images/opengraph_accessori.jpg' },
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: 'Zecchini' },
          { property: 'fb:app_id', content: '141377273234274' },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:site", content: "Zecchini" },
          { name: "twitter:title", content: "Zecchini - Catalogo accessori" },
          { name: "twitter:image", content: environment.apiUrl + 'assets/images/opengraph_accessori.jpg' },
          { name: "twitter:description", content: "Tutti gli accessori per completare e personalizzare la vostra bicicletta. Selle, borse, manopole, borracce, cestini e prodotti legati alla sicurezza come fanalini e lucchetti." }
        ]);
      } else if (params_.category == '4') {
        this.seoService.updateTitle("Catalogo componenti");
        this.seoService.updateMetaTags([
          { name: 'description', content: "Le parti meccaniche e funzionali delle biciclette dei migliori marchi sul mercato come Shimano, Sram, Vittoria, Campagnolo. Catene, cambi, freni, camere d’aria, copertoni e molto altro." },
          { property: 'og:title', content: "Zecchini - Catalogo componenti" },
          { property: 'og:description', content: "Le parti meccaniche e funzionali delle biciclette dei migliori marchi sul mercato come Shimano, Sram, Vittoria, Campagnolo. Catene, cambi, freni, camere d’aria, copertoni e molto altro." },
          { property: 'og:url', content: environment.apiUrl + "componenti-bici?category=4" },
          { property: 'og:image', content: environment.apiUrl + 'assets/images/opengraph_componenti.jpg' },
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: 'Zecchini' },
          { property: 'fb:app_id', content: '141377273234274' },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:site", content: "Zecchini" },
          { name: "twitter:title", content: "Zecchini - Catalogo componenti" },
          { name: "twitter:image", content: environment.apiUrl + 'assets/images/opengraph_componenti.jpg' },
          { name: "twitter:description", content: "Le parti meccaniche e funzionali delle biciclette dei migliori marchi sul mercato come Shimano, Sram, Vittoria, Campagnolo. Catene, cambi, freni, camere d’aria, copertoni e molto altro." }
        ]);
      } else if (params_.category == '5') {
        this.seoService.updateTitle("Catalogo abbigliamento");
        this.seoService.updateMetaTags([
          { name: 'description', content: "Maglie, abbigliamento tecnico, cappellini, caschi, guanti e tutto ciò che serve per affrontare le sfide urbane e off-road, in qualunque stagione e condizione." },
          { property: 'og:title', content: "Zecchini - Catalogo abbigliamento" },
          { property: 'og:description', content: "Maglie, abbigliamento tecnico, cappellini, caschi, guanti e tutto ciò che serve per affrontare le sfide urbane e off-road, in qualunque stagione e condizione." },
          { property: 'og:url', content: environment.apiUrl + "abbigliamento-bici?category=5" },
          { property: 'og:image', content: environment.apiUrl + 'assets/images/opengraph_abbigliamento.jpg' },
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: 'Zecchini' },
          { property: 'fb:app_id', content: '141377273234274' },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:site", content: "Zecchini" },
          { name: "twitter:title", content: "Zecchini - Catalogo abbigliamento" },
          { name: "twitter:image", content: environment.apiUrl + 'assets/images/opengraph_abbigliamento.jpg' },
          { name: "twitter:description", content: "Maglie, abbigliamento tecnico, cappellini, caschi, guanti e tutto ciò che serve per affrontare le sfide urbane e off-road, in qualunque stagione e condizione." }
        ]);
      }

      // if (!this.isBrowser) {
      //   return;
      // }

      if (params.category) {
        let categories = Object.keys(this.shopService.categories.value).filter(category => category == params.category);
        if (categories.length == 0) {
          this.router.navigate(['/'])
        }
      }

      this.currentPage = params.page || 1;
      this.perPage = params.per_page || 15;
      if (!params.category) {
        params_.category = '1'
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: {
            category: '1'
          },
          queryParamsHandling: 'merge',
          skipLocationChange: true
        });
      }
      this.getProducts(params_);
      this.filterForm.patchValue({ ...params_ });
    })
  }

  // animEnd(event) {
  //   if (event.fromState == false && !this.isMobile) {
  //   this.tlFilter = gsap.timeline({
  //     scrollTrigger: {
  //       id: 'footerTl',
  //       trigger:'footer',
  //       start: 'top bottom',
  //       end: '100px bottom',
  //       toggleActions: "play none none reverse"
  //     }
  //   });
  //   this.tlFilter.to('.filter', {
  //     duration:0.5,
  //     x: '-100%'
  //   })
  // }
  // }

  ngOnDestroy() {
    ScrollTrigger.getAll().map((scrollTrigger) => {
      scrollTrigger.kill();
    });
  }

  resetFilterForm() {
    this.filterForm.reset();
    this.filter();
    if (this.isMobile) {
      this.isOpen = this.isOpen = !this.isOpen;
    }
  }

  filter() {
    if (this.isMobile) {
      this.isOpen = this.isOpen = !this.isOpen;
    }
    let filterValues: ProductsParams = this.filterForm.value;
    filterValues = this.cleanObject(filterValues)
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        ...filterValues,
        page: '1'
      },
      queryParamsHandling: 'merge',
    });
  }

  checkNumber(event) {
    if (isNaN(event.key) || event.key === ' ' || event.key === '') {
      event.returnValue = '';
    }
  }

  cleanObject(obj) {
    for (var propName in obj) {
      if (obj[propName] === '' || obj[propName] === undefined || obj[propName] === '0' || obj[propName] === '0') {
        obj[propName] = null
      }
    }
    return obj
  }

  viewItemListDataLayer(products: Product[] = []) {
    (<any>window).dataLayer.push({
      event: 'view_item_list',
      ecommerce: {
        items: products
      }
    });
  }

  categoryLabel: string;
  categoryId: string;
  getProducts(params: ProductsParams = {}) {
    let params_: ProductsParams = JSON.parse(JSON.stringify(params))
    if (!params_.hasOwnProperty('per_page')) {
      params_['per_page'] = '15';
    }
    if (!params_.hasOwnProperty('page')) {
      params_.page = '1';
    } else params_.page = parseInt(params_.page);

    params_.sortField = 'id';
    params_.sortOrder = 'DESC';
    this.categoryLabel = this.shopService.categories.value[params_.category];
    this.shopService.getProducts(params_).subscribe((res: any) => {
      this.pagination = res.pagination;
      this.products = res.data.map(product => new Product({ ...product }, this.shopService.categories.value, this.shopService.subcategories.value));
      this.categoryId = params_.category;
      let relatedAccssoriesDataLayer = []
      let googleMicrodataProducts = [];
      this.products.map((product: Product, index) => {
        relatedAccssoriesDataLayer.push(
          {
            item_name: product?.title, // Name or ID is required.
            item_id: product?.id, // Name or ID is required.
            price: product?.gross_price,
            item_brand: product?.producer_name,
            item_category: product?.category_label,
            item_category_2: product?.subcategory_label, // The categories can be up to 4
            // item_variant: 'Silver',
            // item_list_name: 'Shop list', // If associated with a list selection.
            // item_list_id: 'RL1', // If associated with a list selection.
            // index: index + 1, // If associated with a list selection.
            quantity: 1
          },
        )

        googleMicrodataProducts.push(
          {
            "@type": "ListItem",
            "position": (index + 1).toString(),
            "item": {
              "image": product?.product_image + '_800.jpg',
              "url": `${environment.siteUrl}prodotto/${product?.id}`,
              "name": product?.title,
              "description": product.notes ? product.notes : '',
              "brand": product?.producer_name,
              "offers": {
                "@type": "Offer",
                "price": product?.gross_price,
                "priceCurrency": "EUR"
              }
            }
          }
        )
      });
      let microdata = {
        "@context": "https://schema.org",
        "@type": "ItemList",
        // "url": "http://15.161.62.124/zecchini/zecchini-shop/#/catalogo?category=1",
        "url": `${environment.siteUrl}catalogo${params.category ? '?category=' + params.category : ''}`,
        "numberOfItems": googleMicrodataProducts.length.toString(),
        "itemListElement": googleMicrodataProducts
      }

      if (!this.isBrowser) {
        return
      }

      this.viewItemListDataLayer(relatedAccssoriesDataLayer)
      this.GoogleMicrodataService.setJsonLd(this.renderer2, microdata)

      this.subcategories = res.filters.product_subcategories;
      this.sizes = res.filters.cs_field_1;
      this.total = res.pagination.total;
    })
  }

  handlePageEvent(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.perPage = event.pageSize;
    this.router.navigate([], {
      skipLocationChange: true,
      relativeTo: this.activatedRoute,
      queryParams: {
        page: event.pageIndex + 1,
        per_page: event.pageSize
      },
      queryParamsHandling: 'merge',
    });
  }

  pageIndex() {
    return parseInt(JSON.stringify(JSON.parse(this.currentPage))) - 1;
  }

  viewItemDataLayer(product: Product) {
    (<any>window).dataLayer.push({
      event: 'select_item',
      ecommerce: {
        items: [{
          item_name: product?.title, // Name or ID is required.
          item_id: product?.id,  // Name or ID is required.
          price: product?.gross_price,
          item_brand: product?.producer_name,
          item_category: product?.category_label,
          item_category_2: product?.subcategory_label, // The categories can be up to 4
          // item_variant: '',
          // item_list_name: '',  // If associated with a list selection.
          // item_list_id: '',  // If associated with a list selection.
          // index: 1,  // If associated with a list selection.
          quantity: 1
        }]
      }
    });
  }
}
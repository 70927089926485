<section class="container container--double intro-page container--white">
    <div class="container__left">
        <div [@titleEnter]="isEnter">
            <p class="orange intro-page__subtitle" [@titleEnterText]="isEnter">Acquisto completato</p>
            <div class="intro-page__title"><span [@titleEnterSpan]="{value: isEnter, params: {durationAnim: 0.5}}">grazie</span></div>
            <div class="intro-page__title"><span [@titleEnterSpan]="{value: isEnter, params: {durationAnim: 0.6}}">per averci</span></div>
            <div class="intro-page__title"><span [@titleEnterSpan]="{value: isEnter, params: {durationAnim: 0.7}}">scelto.</span></div>
            <h1 [@titleEnterText]="isEnter">Ti aggiorneremo<br>sull’avanzamento dell’ordine.</h1>
        </div>
    </div>
    <div class="container__right">
        <div class="intro-page__img" [@imgEnter]="isEnter">
            <picture>
                <source sizes="(max-width: 1023px) 80vw, 40vw" type="image/webp" srcset="assets/images/thank-you-page/thankyou_800.webp 800w, assets/images/thank-you-page/thankyou_600.webp 600w, assets/images/thank-you-page/thankyou_400.webp 400w">
                <source sizes="(max-width: 1023px) 80vw, 40vw" type="image/jpg" srcset="assets/images/thank-you-page/thankyou_800.jpg 800w, assets/images/thank-you-page/thankyou_600.jpg 600w, assets/images/thank-you-page/thankyou_400.jpg 400w">
                <img [@imgScale]="isEnter" sizes="(max-width: 1023px) 80vw, 40vw" src="assets/images/thank-you-page/thankyou_800.jpg" srcset="assets/images/thank-you-page/thankyou_800.jpg 800w, assets/images/thank-you-page/thankyou_600.jpg 600w, assets/images/thank-you-page/thankyou_400.jpg 400w"
                    alt="bici al tramonto">
            </picture>
        </div>
    </div>
    <div class="bg--white-mobile"></div>
</section>
<section class="container--front container--white">
    <div class="grid__front">
        <div class="grid-mobile__center">
            <h2 class="orange split" #split1>Se ti è piaciuta l’esperienza d’acquisto lasciaci una recensione sui nostri canali.</h2>
            <div class="social">
                <p class="orange">Seguici su</p>
                <ul>
                    <li><a href="https://www.google.it/search?hl=it&sxsrf=ALeKk01moMVz7WWXiJv8x46-tbTnW7jiMg%3A1611661221360&ei=pf8PYLi_FaiFhbIP6dS4mAs&q=biciclette+zecchini&oq=bicilette&gs_lcp=Cgdnd3Mtd2l6EAMYADIHCCMQsQIQJzIHCCMQsQIQJzIHCCMQsQIQJzIHCAAQsQMQCjIHCAAQsQMQCjIECAAQCjIECAAQCjIECAAQCjIECAAQCjIECAAQCjoECAAQRzoECCMQJzoICC4QsQMQgwE6BQgAELEDOgsIABCxAxDHARCjAjoCCAA6CAgAELEDEIMBOgUILhCxAzoLCC4QiwMQnwMQqAM6DgguELEDEIsDEKgDEJ8DOggIABDHARCvAVCXsQFY27kBYN-_AWgAcAR4AIABrwKIAZQKkgEHNC4zLjEuMZgBAKABAaoBB2d3cy13aXrIAQK4AQLAAQE&sclient=gws-wiz#lrd=0x4781761280dd1e25:0x99f78910546bbc83,1"
                            target="_blank">google</a></li>
                    <li><a href="https://www.facebook.com/biciclettezecchini/reviews/?view_public_for=605837216167007&ref=page_internal&locale=it_IT" target="_blank">facebook</a></li>
                </ul>
            </div>
        </div>
    </div>
</section>

<!-- <div class="container">
    <div class="container--double">
        <div class="container__left">
            <div [@titleEnter]="isEnter"></div>
            <h1 [@titleEnterText]="isEnter">Acquisto completato</h1>
            <div class="title__container">
                <div class="intro-page__title"><span [@titleEnterSpan]="{value: isEnter, params: {durationAnim: 0.5}}">Grazie per</span></div>
                <div class="intro-page__title"><span [@titleEnterSpan]="{value: isEnter, params: {durationAnim: 0.6}}">aver scelto</span></div>
                <div class="intro-page__title"><span class="orange" [@titleEnterSpan]="{value: isEnter, params: {durationAnim: 0.7}}">Zecchini</span></div>
            </div>
            <p [@titleEnterText]="isEnter">Ti aggiorneremo sull’avanzamento dell’ordine.</p>

        </div>
        <div class="container__right">
            <div class="intro-page__img" [@imgEnter]="isEnter">
                <picture>
                    <source type="image/webp" srcset="assets/images/thank-you-page/bicicletta-corsa.webp">
                    <source type="image/jpg" srcset="assets/images/thank-you-page/bicicletta-corsa.jpg">
                    <img [@imgScale]="isEnter" src="assets/images/thank-you-page/bicicletta-corsa.jpg" alt="Bicicletta da corsa verso il tramonto">
                </picture>
            </div>
        </div>
    </div>
    <div class="container--double container--text">
        <div class="container__left">
            <p>Se ti è piaciuta l’esperienza d’acquisto lasciaci una recensione sui nostri canali.</p>
        </div>
        <div class="container__right">
            <p class="orange">Seguici su</p>
            <ul>
                <li><a href="https://www.google.it/search?hl=it&sxsrf=ALeKk01moMVz7WWXiJv8x46-tbTnW7jiMg%3A1611661221360&ei=pf8PYLi_FaiFhbIP6dS4mAs&q=biciclette+zecchini&oq=bicilette&gs_lcp=Cgdnd3Mtd2l6EAMYADIHCCMQsQIQJzIHCCMQsQIQJzIHCCMQsQIQJzIHCAAQsQMQCjIHCAAQsQMQCjIECAAQCjIECAAQCjIECAAQCjIECAAQCjIECAAQCjoECAAQRzoECCMQJzoICC4QsQMQgwE6BQgAELEDOgsIABCxAxDHARCjAjoCCAA6CAgAELEDEIMBOgUILhCxAzoLCC4QiwMQnwMQqAM6DgguELEDEIsDEKgDEJ8DOggIABDHARCvAVCXsQFY27kBYN-_AWgAcAR4AIABrwKIAZQKkgEHNC4zLjEuMZgBAKABAaoBB2d3cy13aXrIAQK4AQLAAQE&sclient=gws-wiz#lrd=0x4781761280dd1e25:0x99f78910546bbc83,1"
                        target="_blank">google</a></li>
                <li><a href="https://www.facebook.com/biciclettezecchini/reviews/?view_public_for=605837216167007&ref=page_internal&locale=it_IT" target="_blank">facebook</a></li>
            </ul>
        </div>
    </div>
</div> -->
<app-footer></app-footer>
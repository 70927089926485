<section class="container container--double intro-page">
    <div class="container__right">
        <div class="intro-page__img" [@imgEnter]="isEnter">
            <picture>
                <source sizes="(max-width: 1023px) 80vw, 40vw" type="image/webp" srcset="assets/images/storia/zecchini1_800.webp 800w, assets/images/storia/zecchini1_600.webp 600w, assets/images/storia/zecchini1_400.webp 400w">
                <source sizes="(max-width: 1023px) 80vw, 40vw" type="image/jpg" srcset="assets/images/storia/zecchini1_800.jpg 800w, assets/images/storia/zecchini1_600.jpg 600w, assets/images/storia/zecchini1_400.jpg 400w">
                <img [@imgScale]="isEnter" sizes="(max-width: 1023px) 80vw, 40vw" src="assets/images/storia/zecchini1_800.jpg" srcset="assets/images/storia/zecchini1_800.jpg 800w, assets/images/storia/zecchini1_600.jpg 600w, assets/images/storia/zecchini1_400.jpg 400w"
                    alt="storia Zecchini">
            </picture>
        </div>
    </div>
    <div class="container__left">
        <div [@titleEnter]="isEnter">
            <p class="orange intro-page__subtitle" [@titleEnterText]="isEnter">Un passato importante, che ha creato</p>
            <div class="intro-page__title"><span [@titleEnterSpan]="{value: isEnter, params: {durationAnim: 0.5}}">esperienza</span></div>
            <div class="intro-page__title"><span [@titleEnterSpan]="{value: isEnter, params: {durationAnim: 0.6}}">e competenze</span></div>
            <div class="intro-page__title"><span [@titleEnterSpan]="{value: isEnter, params: {durationAnim: 0.7}}">da leggenda.</span></div>
            <!-- <p class="intro-page__title">esperienza<br>e competenze<br>da leggenda.</p> -->
            <h1 [@titleEnterText]="isEnter" (@titleEnterText.done)="animEnd($event)">Un viaggio dalle corse<br>al mondo delle city bike.</h1>
        </div>
    </div>
</section>
<section id="storiaTl1">
    <div class="storia-img">
        <picture>
            <source sizes="(max-width: 1023px) 65vw, 25vw" type="image/webp" srcset="assets/images/storia/zecchini2_600.webp 600w, assets/images/storia/zecchini2_400.webp 400w">
            <source sizes="(max-width: 1023px) 65vw, 25vw" type="image/jpg" srcset="assets/images/storia/zecchini2_600.jpg 600w, assets/images/storia/zecchini2_400.jpg 400w">
            <img sizes="(max-width: 1023px) 65vw, 25vw" src="assets/images/storia/zecchini2_600.jpg" srcset="assets/images/storia/zecchini2_600.jpg 600w, assets/images/storia/zecchini2_400.jpg 400w" alt="storia Zecchini">
        </picture>
    </div>
    <div class="bg__white"></div>
</section>
<section class="container--front container--white container--double">
    <div class="container--double__left textOpacity parallaxText">
        <p>Sono passati più di 100 anni da quel lontano 1920, l’anno in cui Giulio Zecchini decise di trasformare la sua passione in un mestiere: quello del biciclettaio. Il suo pallino erano le biciclette da corsa e, nei primi anni di attività il negozio
            cominciò a farsi un nome proprio per la sua competenza in ambito sportivo.</p>
        <p class="padding-top--30">Una squadra ciclistica e le 18 edizioni della leggendaria Coppa Zecchini hanno contribuito a trasformare un’attività in un punto di riferimento assoluto, almeno in ambito provinciale.</p>
    </div>
    <div class="container--double__right storia-title parallaxText">
        <p class="frontend__title">Abbiamo trasferito tutte le conoscenze raccolte nel mondo delle bici da corsa all’interno delle nostre <a [routerLink]="['/biciclette']" [queryParams]="{category: 1}" appCursorHover [cursorUrl]="'url(assets/images/cursor/biciclette.png)'" class="orange">city bike</a>.</p>
        <h2 class="orange split" #split1>Biciclette di personalità, alla portata di tutti, durevoli personalizzabili su richiesta.
        </h2>
    </div>
</section>
<section class="container--front container--white slider--orange container--slider">
    <div class="bg__orange bg__orange--bottom"></div>
    <app-slider id="sliderStoria1" [sliderCursorPrev]="'url(assets/images/cursor/sx.png)'" [sliderCursorNext]="'url(assets/images/cursor/dx.png)'" [sliderFlip]='true' [sliderItem]=storiaSlider1 sliderTitle='LE BICI STORICHE'></app-slider>
</section>
<section class="container__storia container--orange">
    <div *ngIf="!isMobile" class="text-rotate">
        <p class="white mono">LE TAPPE DELLA STORIA</p>
    </div>
    <div class="container--front strava">
        <div>
            <p class="storia-subtitle" [@showHidden]="activeText == '1920' ? 'show' : 'hidden'">La nascita di<br>Biciclette Zecchini</p>
            <p class="storia-subtitle" [@showHidden]="activeText == '1923' ? 'show' : 'hidden'">La prima edizione<br>della Coppa Zecchini</p>
            <p class="storia-subtitle" [@showHidden]="activeText == '1927' ? 'show' : 'hidden'">La conquista di Parigi:<br>il record dell’ora</p>
            <p class="storia-subtitle" [@showHidden]="activeText == '1959' ? 'show' : 'hidden'">L’ultima edizione della<br>Coppa Zecchini</p>
            <p class="storia-subtitle" [@showHidden]="activeText == '1962' ? 'show' : 'hidden'">Da padre a figlia:<br>la seconda generazione</p>
            <p class="storia-subtitle" [@showHidden]="activeText == '1980' ? 'show' : 'hidden'">Il simbolo di una città<br>che pedala</p>
            <p class="storia-subtitle" [@showHidden]="activeText == '1990' ? 'show' : 'hidden'">Gli anni della bicicletta<br>da passeggio</p>
            <p class="storia-subtitle" [@showHidden]="activeText == '2014' ? 'show' : 'hidden'">L’arrivo di Alberto:<br>la terza generazione</p>
            <p class="storia-subtitle" [@showHidden]="activeText == '2017' ? 'show' : 'hidden'">L’arrivo delle prime<br>biciclette elettriche</p>
        </div>
        <div class="infinite-scroll" (mouseenter)="textPause(0.2)" (mouseleave)="textPlay(1)">
            <div *ngFor="let c of [1, 2]" class="infinite-scroll__container">
                <div class="infinite-scroll__title blue" [ngClass]="{'active': activeText == '1920'}" (mouseenter)="textInfiniteOver('1920')" (mouseleave)="textInfiniteOver('')">1920</div>
                <div class="infinite-scroll__title blue" [ngClass]="{'active': activeText == '1923'}" (mouseenter)="textInfiniteOver('1923')" (mouseleave)="textInfiniteOver('')">1923</div>
                <div class="infinite-scroll__title blue" [ngClass]="{'active': activeText == '1927'}" (mouseenter)="textInfiniteOver('1927')" (mouseleave)="textInfiniteOver('')">1927</div>
                <div class="infinite-scroll__title blue" [ngClass]="{'active': activeText == '1959'}" (mouseenter)="textInfiniteOver('1959')" (mouseleave)="textInfiniteOver('')">1959</div>
                <div class="infinite-scroll__title blue" [ngClass]="{'active': activeText == '1962'}" (mouseenter)="textInfiniteOver('1962')" (mouseleave)="textInfiniteOver('')">1962</div>
                <div class="infinite-scroll__title blue" [ngClass]="{'active': activeText == '1980'}" (mouseenter)="textInfiniteOver('1980')" (mouseleave)="textInfiniteOver('')">1980</div>
                <div class="infinite-scroll__title blue" [ngClass]="{'active': activeText == '1990'}" (mouseenter)="textInfiniteOver('1990')" (mouseleave)="textInfiniteOver('')">1990</div>
                <div class="infinite-scroll__title blue" [ngClass]="{'active': activeText == '2014'}" (mouseenter)="textInfiniteOver('2014')" (mouseleave)="textInfiniteOver('')">2014</div>
                <div class="infinite-scroll__title blue" [ngClass]="{'active': activeText == '2017'}" (mouseenter)="textInfiniteOver('2017')" (mouseleave)="textInfiniteOver('')">2017</div>
            </div>
        </div>
        <div class="strava__img">
            <picture [@showHidden]="activeText == '1920' ? 'show' : 'hidden'">
                <source sizes="(max-width: 1023px) 50vw, 20vw" type="image/webp" srcset="assets/images/storia/1920_400.webp 400w, assets/images/storia/1920_200.webp 200w">
                <source sizes="(max-width: 1023px) 50vw, 20vw" type="image/jpg" srcset="assets/images/storia/1920_400.jpg 400w, assets/images/storia/1920_200.jpg 200w">
                <img sizes="(max-width: 1023px) 50vw, 20vw" src="assets/images/storia/1920_400.jpg" srcset="assets/images/storia/1920_400.jpg 400w, assets/images/storia/1920_200.jpg 200w" alt="Zecchini anno 1920">
            </picture>
            <picture [@showHidden]="activeText == '1923' ? 'show' : 'hidden'">
                <source sizes="(max-width: 1023px) 50vw, 20vw" type="image/webp" srcset="assets/images/storia/1923_400.webp 400w, assets/images/storia/1923_200.webp 200w">
                <source sizes="(max-width: 1023px) 50vw, 20vw" type="image/jpg" srcset="assets/images/storia/1923_400.jpg 400w, assets/images/storia/1923_200.jpg 200w">
                <img sizes="(max-width: 1023px) 50vw, 20vw" src="assets/images/storia/1923_400.jpg" srcset="assets/images/storia/1923_400.jpg 400w, assets/images/storia/1923_200.jpg 200w" alt="Zecchini anno 1923">
            </picture>
            <picture [@showHidden]="activeText == '1927' ? 'show' : 'hidden'">
                <source sizes="(max-width: 1023px) 50vw, 20vw" type="image/webp" srcset="assets/images/storia/1928_400.webp 400w, assets/images/storia/1928_200.webp 200w">
                <source sizes="(max-width: 1023px) 50vw, 20vw" type="image/jpg" srcset="assets/images/storia/1928_400.jpg 400w, assets/images/storia/1928_200.jpg 200w">
                <img sizes="(max-width: 1023px) 50vw, 20vw" src="assets/images/storia/1928_400.jpg" srcset="assets/images/storia/1928_400.jpg 400w, assets/images/storia/1928_200.jpg 200w" alt="Zecchini anno 1923">
            </picture>
            <picture [@showHidden]="activeText == '1959' ? 'show' : 'hidden'">
                <source sizes="(max-width: 1023px) 50vw, 20vw" type="image/webp" srcset="assets/images/storia/1959_400.webp 400w, assets/images/storia/1959_200.webp 200w">
                <source sizes="(max-width: 1023px) 50vw, 20vw" type="image/jpg" srcset="assets/images/storia/1959_400.jpg 400w, assets/images/storia/1959_200.jpg 200w">
                <img sizes="(max-width: 1023px) 50vw, 20vw" src="assets/images/storia/1959_400.jpg" srcset="assets/images/storia/1959_400.jpg 400w, assets/images/storia/1959_200.jpg 200w" alt="Zecchini anno 1959">
            </picture>
            <picture [@showHidden]="activeText == '1962' ? 'show' : 'hidden'">
                <source sizes="(max-width: 1023px) 50vw, 20vw" type="image/webp" srcset="assets/images/storia/1962_400.webp 400w, assets/images/storia/1962_200.webp 200w">
                <source sizes="(max-width: 1023px) 50vw, 20vw" type="image/jpg" srcset="assets/images/storia/1962_400.jpg 400w, assets/images/storia/1962_200.jpg 200w">
                <img sizes="(max-width: 1023px) 50vw, 20vw" src="assets/images/storia/1962_400.jpg" srcset="assets/images/storia/1962_400.jpg 400w, assets/images/storia/1962_200.jpg 200w" alt="Zecchini anno 1962">
            </picture>
            <picture [@showHidden]="activeText == '1980' ? 'show' : 'hidden'">
                <source sizes="(max-width: 1023px) 50vw, 20vw" type="image/webp" srcset="assets/images/storia/1980_400.webp 400w, assets/images/storia/1980_200.webp 200w">
                <source sizes="(max-width: 1023px) 50vw, 20vw" type="image/jpg" srcset="assets/images/storia/1980_400.jpg 400w, assets/images/storia/1980_200.jpg 200w">
                <img sizes="(max-width: 1023px) 50vw, 20vw" src="assets/images/storia/1980_400.jpg" srcset="assets/images/storia/1980_400.jpg 400w, assets/images/storia/1980_200.jpg 200w" alt="Zecchini anno 1980">
            </picture>
            <picture [@showHidden]="activeText == '1990' ? 'show' : 'hidden'">
                <source sizes="(max-width: 1023px) 50vw, 20vw" type="image/webp" srcset="assets/images/storia/1990_400.webp 400w, assets/images/storia/1990_200.webp 200w">
                <source sizes="(max-width: 1023px) 50vw, 20vw" type="image/jpg" srcset="assets/images/storia/1990_400.jpg 400w, assets/images/storia/1990_200.jpg 200w">
                <img sizes="(max-width: 1023px) 50vw, 20vw" src="assets/images/storia/1990_400.jpg" srcset="assets/images/storia/1990_400.jpg 400w, assets/images/storia/1990_200.jpg 200w" alt="Zecchini anno 1990">
            </picture>
            <picture [@showHidden]="activeText == '2014' ? 'show' : 'hidden'">
                <source sizes="(max-width: 1023px) 50vw, 20vw" type="image/webp" srcset="assets/images/storia/2014_400.webp 400w, assets/images/storia/2014_200.webp 200w">
                <source sizes="(max-width: 1023px) 50vw, 20vw" type="image/jpg" srcset="assets/images/storia/2014_400.jpg 400w, assets/images/storia/2014_200.jpg 200w">
                <img sizes="(max-width: 1023px) 50vw, 20vw" src="assets/images/storia/2014_400.jpg" srcset="assets/images/storia/2014_400.jpg 400w, assets/images/storia/2014_200.jpg 200w" alt="Zecchini anno 2014">
            </picture>
            <picture [@showHidden]="activeText == '2017' ? 'show' : 'hidden'">
                <source sizes="(max-width: 1023px) 50vw, 20vw" type="image/webp" srcset="assets/images/storia/2017_400.webp 400w, assets/images/storia/2017_200.webp 200w">
                <source sizes="(max-width: 1023px) 50vw, 20vw" type="image/jpg" srcset="assets/images/storia/2017_400.jpg 400w, assets/images/storia/2017_200.jpg 200w">
                <img sizes="(max-width: 1023px) 50vw, 20vw" src="assets/images/storia/2017_400.jpg" srcset="assets/images/storia/2017_400.jpg 400w, assets/images/storia/2017_200.jpg 200w" alt="Zecchini anno 2017">
            </picture>
            <div class="mono orange">PROVA LE SFIDE</div>
        </div>
        <div class="storia__text" [@showHidden]="activeText == '1920' ? 'show' : 'hidden'">Il perfetto esempio di come trasformare l’amore per un sport in una professione e diventarne in poco tempo il punto di riferimento in città.</div>
        <div class="storia__text" [@showHidden]="activeText == '1923' ? 'show' : 'hidden'">La passione pedala forte e in soli tre anni Zecchini ha una gara tutta dedicata a lui, una trofeo riservato ai dilettanti di prima e seconda categoria.</div>
        <div class="storia__text" [@showHidden]="activeText == '1927' ? 'show' : 'hidden'">Una bicicletta Zecchini, ora esposta in negozio, batte il record dell'ora al Velodromo Internazionale di Parigi. Brescia conquista la Francia.</div>
        <div class="storia__text" [@showHidden]="activeText == '1959' ? 'show' : 'hidden'">Dopo 23 edizioni e un piccolo inevitabile stop causato dalla guerra, termina la Coppa Zecchini continuando però ad alimentare il mito.</div>
        <div class="storia__text" [@showHidden]="activeText == '1962' ? 'show' : 'hidden'">Giuseppina, figlia di Giulio, riceve in eredità non solo il negozio, ma tutta la passione e la dedizione nei confronti del cliente e del mondo delle bici.</div>
        <div class="storia__text" [@showHidden]="activeText == '1980' ? 'show' : 'hidden'">Zecchini si afferma come il punto di riferimento di una città devota alle due ruote: avere una bicicletta di qualità qui, a Brescia, vuol dire avere una Zecchini.</div>
        <div class="storia__text" [@showHidden]="activeText == '1990' ? 'show' : 'hidden'">Sono anni ormai che lo spirito delle corse sta lentamente cambiando. La voglia è quella di allargare sempre di più il pubblico, mantenendo la storica ed indiscutibile qualità.</div>
        <div class="storia__text" [@showHidden]="activeText == '2014' ? 'show' : 'hidden'">La leggenda non si può fermare! Anzi, deve cominciare a raccogliere le nuove sfide di un mondo che dopo anni, sta lentamente riscoprendo le due ruote.</div>
        <div class="storia__text" [@showHidden]="activeText == '2017' ? 'show' : 'hidden'">La vera tradizione è la passione che viene tramandata da Zecchini ed è per questo che l’avvento delle ebike è una nuova sfida che deve essere raccolta.</div>
    </div>
</section>
<section class="container--front container--orange container--double servizi">
    <div class="container--double__left parallaxText">
        <p class="light-blue frontend__subtitle split" #split2>“Oggi abbiamo concentrato la nostra attività nel diffondere la cultura della bicicletta a sempre più persone, e la city bike è perfetta per questo, perchè è alla portata di tutti.”</p>
        <p class="padding-top--30 white">Alberto Odorici</p>
    </div>
    <div class="container--double__right storia-title parallaxText">
        <p class="frontend__title white">Acquistare qui da Zecchini significa entrare a far parte di una famiglia pronta ad aiutarvi. È questo il valore che diamo alla storia.</p>
        <div class="action-float--right action-float--end padding-top--40">
            <div class="frontend-link">
                <a [routerLink]="['/servizi']">servizi</a>
                <div class="frontend-link__line"></div>
                <div class="frontend-link__line2"></div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.05 10"><path d="M9.06,4.22,6,1.12,7.07,0l5,5-5,5L5.92,8.86,9,5.78H0V4.22Z"/></svg>
            </div>
        </div>
    </div>
</section>
<section class="container--full">
    <div class="text-rotate">
        <p class="white mono">MODELLI A CATALOGO</p>
    </div>
    <div class="bg__orange bg__orange--image-change"></div>
    <div class="image-change__container" id="storia2Tl">
        <div class="image-change">
            <picture @imageFadeAnimation *ngIf="activeImg == 'citybike'">
                <source sizes="(max-width: 1023px) 80vw, 40vw" type="image/webp" srcset="assets/images/home/citybike_800.webp 800w, assets/images/home/citybike_600.webp 600w, assets/images/home/citybike_400.webp 400w">
                <source sizes="(max-width: 1023px) 80vw, 40vw" type="image/jpg" srcset="assets/images/home/citybike_800.jpg 800w, assets/images/home/citybike_600.jpg 600w, assets/images/home/citybike_400.jpg 400w">
                <img sizes="(max-width: 1023px) 80vw, 40vw" src="assets/images/home/citybike_800.jpg" srcset="assets/images/home/citybike_800.jpg 800w, assets/images/home/citybike_600.jpg 600w, assets/images/home/citybike_400.jpg 400w" alt="City bike Zecchini">
            </picture>
            <picture @imageFadeAnimation *ngIf="activeImg == 'mountain'">
                <source sizes="(max-width: 1023px) 80vw, 40vw" type="image/webp" srcset="assets/images/home/mountainbike_800.webp 800w, assets/images/home/mountainbike_600.webp 600w, assets/images/home/mountainbike_400.webp 400w">
                <source sizes="(max-width: 1023px) 80vw, 40vw" type="image/jpg" srcset="assets/images/home/mountainbike_800.jpg 800w, assets/images/home/mountainbike_600.jpg 600w, assets/images/home/mountainbike_400.jpg 400w">
                <img sizes="(max-width: 1023px) 80vw, 40vw" src="assets/images/home/mountainbike_800.jpg" srcset="assets/images/home/mountainbike_800.jpg 800w, assets/images/home/mountainbike_600.jpg 600w, assets/images/home/mountainbike_400.jpg 400w" alt="Mountain Zecchini">
            </picture>
            <picture @imageFadeAnimation *ngIf="activeImg == 'ebike'">
                <source sizes="(max-width: 1023px) 80vw, 40vw" type="image/webp" srcset="assets/images/home/ebike_800.webp 800w, assets/images/home/ebike_600.webp 600w, assets/images/home/ebike_400.webp 400w">
                <source sizes="(max-width: 1023px) 80vw, 40vw" type="image/jpg" srcset="assets/images/home/ebike_800.jpg 800w, assets/images/home/ebike_600.jpg 600w, assets/images/home/ebike_400.jpg 400w">
                <img sizes="(max-width: 1023px) 80vw, 40vw" src="assets/images/home/ebike_800.jpg" srcset="assets/images/home/ebike_800.jpg 800w, assets/images/home/ebike_600.jpg 600w, assets/images/home/ebike_400.jpg 400w" alt="Ebike Xp Bike">
            </picture>
            <div class="image-change__number">
                <span [ngClass]="{'active': activeImg == 'ebike'}">01</span>
                <span [ngClass]="{'active': activeImg == 'citybike'}">02</span>
                <span [ngClass]="{'active': activeImg == 'mountain'}">03</span></div>
        </div>
        <div class="text-change__container">
            <h2 (mouseenter)="txtOver('ebike')" [ngClass]="{'active': activeImg == 'ebike'}">ebike</h2>
            <h2 (mouseenter)="txtOver('citybike')" [ngClass]="{'active': activeImg == 'citybike'}">citybike</h2>
            <h2 (mouseenter)="txtOver('mountain')" [ngClass]="{'active': activeImg == 'mountain'}">mountain bike</h2>
        </div>
    </div>
</section>
<section class="container--front container container--double shop">
    <div class="container--double__left parallaxText">
        <div class="text-rotate text-rotate--no-mobile">
            <p class="orange mono">OGGI ZECCHINI È</p>
        </div>
        <h2 class="blue split" #split3>Un negozio storico che è anche una bottega, un’officina e un magazzino, dove ogni giorno si respira la storia.</h2>
    </div>
    <div class="container--double__right storia-title parallaxText">
        <p class="frontend__title">Oltre 3000 prodotti a catalogo tra <a [routerLink]="['/accessori-bici']" [queryParams]="{category: 3}" appCursorHover [cursorUrl]="'url(assets/images/cursor/accessori.png)'" class="orange">accessori</a>,
            <a [routerLink]="['/componenti-bici']" [queryParams]="{category: 4}" appCursorHover [cursorUrl]="'url(assets/images/cursor/componenti.png)'" class="orange">componenti</a> e ricambi per biciclette, selezionati tra i migliori marchi presenti sul mercato.</p>
        <div class="action-float--right action-float--end padding-top--40">
            <div class="frontend-link">
                <a [routerLink]="['/componenti-bici']" [queryParams]="{category: 4}">e-shop</a>
                <div class="frontend-link__line"></div>
                <div class="frontend-link__line2"></div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.05 10"><path d="M9.06,4.22,6,1.12,7.07,0l5,5-5,5L5.92,8.86,9,5.78H0V4.22Z"/></svg>
            </div>
        </div>
    </div>
</section>
<section class="container--front mobile-padding--0">
    <div class="grid__front">
        <div>
            <h2 class="orange split" #split4>Biciclette elettriche e a pedalata assistita: sappiamo guardare avanti.</h2>
        </div>
        <div class="textOpacity">
            <p>Per nostra natura non riusciamo proprio a stare fermi un secondo ed è per questo che sappiamo cogliere molto rapidamente i trend del mercato, senza perdere però la nostra identità.<br><br>È per questo che dal 2017, quando abbiamo deciso di
                distribuire i primi modelli di ebike, abbiamo deciso di affidarci a qualcuno che avesse più esperienza di noi nel settore.
            </p>
        </div>
        <div class="textOpacity">
            <p>Abbiamo identificato questo qualcuno in <span class="orange">XP Bikes</span>, un brand nato nel 2004, che unisce design, tecnica e tecnologia per offrire modelli performanti e molto affidabili.<br><br>Una costante ricerca, soprattutto in sicurezza,
                li classifica come uno dei brand leader in Italia nel settore delle biciclette elettriche oltre ad essere perfettamente in linea con i valori della famiglia Zecchini.</p>
        </div>
    </div>
</section>
<section class="container--front container--slider">
    <app-slider id="sliderStoria2" [sliderCursorPrev]="'url(assets/images/cursor/dx.png)'" [sliderCursorNext]="'url(assets/images/cursor/sx.png)'" [sliderFlip]='false' [sliderItem]=storiaSlider2 sliderTitle='EBIKE E CITY BIKE'></app-slider>
</section>
<section class="container--front mobile-padding--0">
    <div class="grid__front">
        <div>
            <h2 class="blue split" #split5>Amiamo la natura, ma il nostro obiettivo è rendere le città a misura di ciclista.</h2>
        </div>
        <div class="textOpacity">
            <p>È bellissimo esplorare la natura in sella ad una bicicletta: spazi immensi, scenari mozzafiato e, per chi è in grado di farlo, vette irraggiungibili sono il sogno di ogni ciclista o ciclo amatore, a qualunque livello.<br><br> Brescia è fantastica
                in questo perchè la sua provincia offre tutto, grandi campagne, laghi meravigliosi, salite estreme e passeggiate enogastronomiche dal grande fascino oltre ad una storia millenaria.
            </p>
        </div>
        <div class="textOpacity">
            <p>Il vero sogno per noi è però riuscire a provare le stesse emozioni in contesti dove questo mezzo che tanto amiamo può rappresentare una rivoluzione culturale: le nostre città.<br><br>È per questo che ci impegnamo a creare le opportunità perchè
                questo possa avvenire veramente, attraverso la diffusione della bicicletta come mezzo sostenibile</p>
        </div>
    </div>
</section>
<section class="container--front strava">
    <a href="https://www.strava.com/clubs/882628" target="_blank">
        <div class="infinite-scroll" (mouseenter)="textPause(0.5)" (mouseleave)="textPlay(1)">
            <div *ngFor="let c of [1, 2, 3]" class="infinite-scroll__container">
                <h3 [ngClass]="{'active': activeText2 == 'strava'}" (mouseenter)="textInfiniteOver('strava')" (mouseleave)="textInfiniteOver('')">#exploringurbanlandscapes</h3>
            </div>
        </div>
    </a>
    <div class="strava__img strava__img--storia">
        <picture @imageFadeAnimation *ngIf="activeText2 == 'strava'">
            <source type="image/webp" sizes="(max-width: 1023px) 50vw, 35vw" srcset="assets/images/home/exploring_400.webp 400w, assets/images/home/exploring_200.webp 200w">
            <source type="image/jpg" sizes="(max-width: 1023px) 50vw, 35vw" srcset="assets/images/home/exploring_400.jpg 400w, assets/images/home/exploring_200.jpg 200w">
            <img src="assets/images/home/exploring_400.jpg" sizes="(max-width: 1023px) 50vw, 35vw" srcset="assets/images/home/exploring_400.jpg 400w, assets/images/home/exploring_200.jpg 200w" alt="la bici giusta per te">
        </picture>
        <div class="mono orange">PROVA LE SFIDE</div>
    </div>
</section>
<section class="container--front container--end">
    <div class="frontend__title">C’è un po’ di Zecchini anche <span class="orange">in ogni nostro rivenditore</span>.</div>
    <div class="action-float--right action-float--end">
        <div class="frontend-link">
            <a [routerLink]="['/rivenditori']">scoprili</a>
            <div class="frontend-link__line"></div>
            <div class="frontend-link__line2"></div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.05 10"><path d="M9.06,4.22,6,1.12,7.07,0l5,5-5,5L5.92,8.86,9,5.78H0V4.22Z"/></svg>
        </div>
    </div>
</section>
<app-footer></app-footer>
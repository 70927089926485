import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { pairwise, startWith } from 'rxjs/operators';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { MustMatch } from 'src/app/shared/validators/must-match.validator';

@Component({
  selector: 'app-purchase-information',
  templateUrl: './purchase-information.component.html',
  styleUrls: ['./purchase-information.component.css']
})
export class PurchaseInformationComponent implements OnInit {
  public invoiceForm: FormGroup;

  PRIVATE_VALUE: string = 'private';
  COMPANY_VALUE: string = 'company';

  constructor(private formBuilder: FormBuilder, private userService: UserService, private _snackBar: MatSnackBar){
    this.invoiceForm = formBuilder.group({
      first_name: ['', Validators.required],
      user_type: ['',Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
      phone: ['', Validators.required],
      address: ['', Validators.required],
      postal_code: ['', Validators.required],
      city: ['', Validators.required],
      province: ['', Validators.required],
      sdi: [''],//company , Validators.required
      password: ['', [Validators.minLength(8), Validators.pattern('(?=.*[0-9])(?=.*[A-Z]).{8,}')]], //, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]
      confirmPassword: [''],
      fiscal_code: [''],//private [Validators.required, Validators.minLength(16), Validators.maxLength(16)]
      business_name: [''],// company , Validators.required
      vat_number: [''],//company , [Validators.required, Validators.minLength(11), Validators.maxLength(11)]
      pec: [''],//company , [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]
      receive_invoice: [false]
    },
    {
      validator: MustMatch('password', 'confirmPassword')
    });
   }

  ngOnInit(): void {
    this.updateFormValidators();
    this.getUser()
  }

  getUser(){
    this.userService.getUser().subscribe((user: User) => {
      this.invoiceForm.patchValue({...user})
    })
  }

  updateFormValidators(){
    let companyUserFields = [
      {
        formControlName: 'sdi',
        validations: [Validators.required]
      },
      {
        formControlName: 'vat_number',
        validations: [Validators.required, Validators.minLength(11), Validators.maxLength(11)]
      },
      {
        formControlName: 'pec',
        validations: [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]
      },
      {
        formControlName: 'business_name',
        validations: [Validators.required]
      }
    ]

    let privateUserFields = [
      {
        formControlName: 'fiscal_code',
        validations: [Validators.required, Validators.minLength(16), Validators.maxLength(16)]
      }
    ];

    this.invoiceForm.valueChanges.pipe(
      startWith(''),
      pairwise(),
    ).subscribe(([oldValue, newValue]) => {
      //change "Desidero la fattura" fields validation by receive_invoice and user_type values
      if(oldValue.user_type != newValue.user_type || oldValue.receive_invoice != newValue.receive_invoice){
        
        companyUserFields.map(field => {
          this.invoiceForm.controls[field.formControlName].setValidators(newValue.user_type == this.COMPANY_VALUE && newValue.receive_invoice == true ? field.validations : []);
          this.invoiceForm.controls[field.formControlName].updateValueAndValidity();
        })
        privateUserFields.map(field => {
          this.invoiceForm.controls[field.formControlName].setValidators(newValue.user_type == this.PRIVATE_VALUE && newValue.receive_invoice == true ? field.validations : []);
          this.invoiceForm.controls[field.formControlName].updateValueAndValidity();
        }) 
      }
    })
  }

  get f(){
    return this.invoiceForm.controls;
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
        control.markAsTouched();
    });
  }

  sendRegistrationForm() {
    if (this.invoiceForm.invalid) {
      this.markFormGroupTouched(this.invoiceForm);
      return
    }

    let data = this.invoiceForm.value;
    
    let formData = new FormData();
    //user data
    formData.append("client_type", data.user_type);
    // formData.append("accept_privacy", data.accept_privacy == true ? '1' : '0');
    // formData.append("newsletter_subscribed", data.newsletter_subscribed == true ? '1' : '0');
    if(data.receive_invoice) formData.append("receive_invoice", '1');
    if(data.different_shipping) formData.append("different_shipping",'1');

    //user data
    formData.append("first_name", data.first_name);
    formData.append("last_name", data.last_name);
    formData.append("email", data.email);
    formData.append("phone", data.phone);
    formData.append("address", data.address); //indirizzo
    formData.append("postal_code", data.postal_code); //postal_code
    formData.append("city", data.city);
    formData.append("province", data.province);

    if (data.password)
      formData.append("password", data.password);

    if (data.note)
      formData.append("note", data.note);
    if (data.receive_invoice == true || data.receive_invoice == '1') {
      if (data.user_type == this.PRIVATE_VALUE) {
        formData.append("fiscal_code", data.fiscal_code);
      } else if (data.user_type == this.COMPANY_VALUE) {
        formData.append(
          "business_name",
          data.business_name
        );
        formData.append("vat_number", data.vat_number);
        formData.append("pec", data.pec);
        formData.append("sdi", data.sdi);
      }
    }
    this.userService.updateProfile(formData).subscribe(res => {
      this._snackBar.open("Hai modificato il tuo profilo correttamente", "OK", {duration: 5000});
      this.userService.getProfile().subscribe((user: User) => {
        this.userService.setUser(user);
        this.invoiceForm.patchValue({...user})
        this.invoiceForm.patchValue({password: '', confirmPassword:''})
      })
    }, 
    err => {
      if(err.erorr && err.erorr.message){
        this._snackBar.open(err.error.message, 'OK', {
          duration: 5000
        });
      } else {
        for (const [key, value] of Object.entries(err.error)) {
          if (this.invoiceForm.get(key)) {
            this.invoiceForm.get(key).setErrors({ serverError: { message: value } });
          }
        }
      }
    })
  }
}

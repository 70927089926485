import { animate, animateChild, group, query, state, style, transition, trigger } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Pipe, PLATFORM_ID } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { AppRoutingTransitionService } from 'src/app/services/app-routing-transition.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-thank-you-page',
  templateUrl: './thank-you-page.component.html',
  styleUrls: ['./thank-you-page.component.css'],
  animations: [
    trigger('titleEnter', [
      state('false',
        style({
          opacity: 0
        })
      ),
      transition('false => true', [
        animate('0.3s cubic-bezier(0.55, 0.31, 0.15, 0.93)'),
        group([
          query('@titleEnterSpan', animateChild(), { optional: true }),
        ]),
        group([
          query('@titleEnterText', animateChild(), { optional: true }),
        ])
      ]),
    ]),
    trigger('titleEnterSpan', [
      transition('false => true', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('{{durationAnim}}s cubic-bezier(.6,.36,.07,.99)', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
    ]),
    trigger('titleEnterText', [
      transition('false => true', [
        style({ opacity: 0 }),
        animate('0.3s ease-in', style({ opacity: 1 })),
      ]),
    ]),
    trigger('imgEnter', [
      state('false',
        style({
          opacity: 0,
          transform: 'translateY(15vw)',
        })
      ),
      transition('false => true', [
        animate('1s cubic-bezier(.72,.01,.07,1)'),
        query('@imgScale', animateChild({ duration: 1 }), { optional: true }),
      ]),
    ]),
    trigger('imgScale', [
      state('false',
        style({
          transform: 'scale(1)'
        })
      ),
      transition('false => true', [
        style({
          transform: 'scale(1.25)'
        }),
        animate('1s cubic-bezier(.72,.01,.07,1)')
      ]),
    ])
  ]
})
export class ThankYouPageComponent implements OnInit, OnDestroy {
  public isEnter: boolean = false;
  isBrowser;
  myEventSubscription: Subscription;

  constructor(
    private _snackBar: MatSnackBar,
    @Inject(PLATFORM_ID) platformId: string,
    private appService: AppRoutingTransitionService,
    private userService: UserService) {
    this.isBrowser = isPlatformBrowser(platformId);

  }

  ngOnInit(): void {
    if (!this.isBrowser) {
      return;
    }
    this.myEventSubscription = this.appService.stringSubject.subscribe(() => {
      this.isEnter = true;
      // if the user has registered
      let showMessage = localStorage.getItem('show_guest_message')
      if(showMessage == '1'){
        this._snackBar.open("Ricordati di confermare la tua iscrizione tramite l'email che ti abbiamo inviato per poter usufruire dell'area ridervata e controllare lo stato tuo ordine", 'OK', {
          duration:5000
        });
        localStorage.setItem('show_guest_message', '0')
      }
      // this.userService.getProfile().subscribe((user: User)=>{
      //   if(user.registration_guest == '1' && user.active == '0'){
 
      //     // this._snackBar.open("Ricordati di confermare la tua iscrizione tramite l'email che ti abbiamo inviato per poter usufruire dell'area ridervata e controllare lo stato tuo ordine", 'OK', {});
      //   }
      // })
     
    }
    );
  }

  ngOnDestroy(){
    this.myEventSubscription.unsubscribe();
  }

}

import { Component, OnInit } from '@angular/core';
import { animate, animateChild, group, query, state, style, transition, trigger } from '@angular/animations';
import { AppRoutingTransitionService } from 'src/app/services/app-routing-transition.service';
import { NewsService } from 'src/app/services/news.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-consigli',
  templateUrl: './consigli.component.html',
  styleUrls: ['./consigli.component.css'],
  animations: [
    trigger('titleEnter', [
      state('false',
        style({
          opacity: 0
        })
      ),
      transition('false => true', [
        animate('0.3s cubic-bezier(0.55, 0.31, 0.15, 0.93)'),
        group([
          query('@titleEnterSpan', animateChild(), { optional: true }),
        ]),
        group([
          query('@titleEnterText', animateChild(), { optional: true }),
        ])
      ]),
    ]),
    trigger('titleEnterSpan', [
      transition('false => true', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('{{durationAnim}}s cubic-bezier(.6,.36,.07,.99)', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
    ]),
    trigger('titleEnterText', [
      transition('false => true', [
        style({ opacity: 0 }),
        animate('0.3s ease-in', style({ opacity: 1 })),
      ]),
    ]),
    trigger('sectionEnter', [
      state('false',
        style({ backgroundColor: 'white' })
      ),
      transition('false => true', [
        animate('0.5s cubic-bezier(.6,.36,.07,.99)',
          style({ backgroundColor: '#eef0f0' })),
        query('@sectionOpacity', animateChild(), { optional: true }),
      ]),
    ]),
    trigger('sectionOpacity', [
      state('false',
        style({ opacity: 0 })
      ),
      transition('false => true', [
        animate('0.2s cubic-bezier(.6,.36,.07,.99)',
          style({ opacity: 1 })),
        group([
          query('@sectionItemEnter, @sectionImgEnter', animateChild(), { optional: true }),
        ]),
      ]),
    ]),
    trigger('sectionItemEnter', [
      state('false',
        style({ opacity: 0, transform: 'translateY(20px)' }),
      ),
      transition('false => true', [
        animate('0.3s ease-in', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
    ]),
    trigger('sectionImgEnter', [
      transition('false => true', [
        style({
          opacity: 0,
          transform: 'scale(1.25)'
        }),
        animate('1s cubic-bezier(.72,.01,.07,1)', style({
          opacity: 1,
          transform: 'scale(1)'
        })),
      ]),
    ])
  ]
})
export class ConsigliComponent implements OnInit {
  public isEnter: boolean = false;
  isBrowser;
  news: any = [];
  myEventSubscription;
  assetsUrl = environment.assetsUrl;
  istanza;

  constructor(
    private appService: AppRoutingTransitionService,
    private newSerive: NewsService) {
  }

  ngOnInit(): void {
    this.getNews();
    this.myEventSubscription = this.appService.stringSubject.subscribe(() => {
      this.isEnter = true;
    }
    );
  }

  getNews() {
    this.newSerive.getNews().subscribe(res => {
      this.news = res;
    })
  }

}

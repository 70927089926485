<div class="right__container">
    <button [routerLink]='["/area-riservata/"]' class="link-underline link-underline--back">torna indietro</button>
    <p class="padding-top--40 padding-bottom--30 blue"><b>I tuoi dati di fatturazione</b></p>
    <form [formGroup]="invoiceForm">
        <div class="form__container">
            <mat-radio-group aria-label="Select an option" formControlName="user_type">
                <mat-radio-button #private [value]="PRIVATE_VALUE">Privato</mat-radio-button>
                <mat-radio-button #company [value]="COMPANY_VALUE">Azienda</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="form__container">
            <mat-form-field appearance="standard">
                <mat-label>nome *</mat-label>
                <input type="text" matInput formControlName="first_name">
                <mat-error *ngIf="(f.first_name.dirty || f.first_name.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>cognome *</mat-label>
                <input type="text" matInput formControlName="last_name">
                <mat-error *ngIf="(f.last_name.dirty || f.last_name.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>e-mail *</mat-label>
                <input type="email" matInput formControlName="email">
                <mat-error *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)">
                    <mat-error *ngIf="(f.email.errors.required)">
                        campo <strong>obbligatorio</strong>
                    </mat-error>
                    <mat-error *ngIf="(f.email.errors.pattern)">
                        inserisci un indirizzo e-mail <strong>valido</strong>
                    </mat-error>
                </mat-error>
                <mat-error *ngIf="(f?.email?.errors?.serverError)">
                    {{f.email.errors.serverError.message}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>telefono *</mat-label>
                <input type="tel" matInput formControlName="phone">
                <mat-error *ngIf="(f.phone.dirty || f.phone.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
                <mat-error *ngIf="(f?.phone?.errors?.serverError)">
                    {{f.phone.errors.serverError.message}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>indirizzo *</mat-label>
                <input type="text" matInput formControlName="address">
                <mat-error *ngIf="(f.address.dirty || f.address.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>CAP *</mat-label>
                <input type="number" matInput formControlName="postal_code">
                <mat-error *ngIf="(f.postal_code.dirty || f.postal_code.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
                <mat-error *ngIf="(f?.postal_code?.errors?.serverError)">
                    {{f.postal_code.errors.serverError.message}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>città *</mat-label>
                <input type="text" matInput formControlName="city">
                <mat-error *ngIf="(f.city.dirty || f.city.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>provincia *</mat-label>
                <input type="text" matInput formControlName="province">
                <mat-error *ngIf="(f.province.dirty || f.province.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>password</mat-label>
                <input type="password" matInput formControlName="password">
                <mat-hint>La password deve essere di minimo 8 caratteri di cui almeno una lettera maiuscola e un numero.
                </mat-hint>
                <mat-error *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)">
                    <mat-error *ngIf="(f.password.errors.required)">
                        campo <strong>obbligatorio</strong>
                    </mat-error>
                    <mat-error *ngIf="(f.password.errors.minlength)">
                        deve essere di minimo <strong>8 caratteri</strong>
                    </mat-error>
                    <mat-error *ngIf="(f.password.errors.pattern)">
                        La password deve essere di minimo 8 caratteri di cui almeno una lettera maiuscola e un numero.
                    </mat-error>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>conferma password</mat-label>
                <input type="password" matInput formControlName="confirmPassword">
                <mat-error *ngIf="f.confirmPassword.invalid && (f.confirmPassword.dirty || f.confirmPassword.touched)">
                    <mat-error *ngIf="(f.confirmPassword.errors.required)">
                        campo <strong>obbligatorio</strong>
                    </mat-error>
                    <mat-error *ngIf="(f.confirmPassword.errors.minlength)">
                        deve essere di minimo <strong>8 caratteri</strong>
                    </mat-error>
                    <mat-error *ngIf="(f.confirmPassword.errors.mustMatch)">
                        le password non coincidono
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form__container padding-top--30">
            <mat-checkbox #invoice formControlName="receive_invoice">Desidero la fattura</mat-checkbox>
        </div>
        <div *ngIf="invoice.checked" class="form__container">
            <mat-form-field *ngIf="private.checked" appearance="standard">
                <mat-label>codice fiscale *</mat-label>
                <input type="text" matInput formControlName="fiscal_code">
                <mat-error *ngIf="f.fiscal_code.invalid && (f.fiscal_code.dirty || f.fiscal_code.touched)">
                    <mat-error *ngIf="(f.fiscal_code.errors.required)">
                        campo <strong>obbligatorio</strong>
                    </mat-error>
                    <mat-error *ngIf="(f.fiscal_code.errors.minlength || f.fiscal_code.errors.maxlength)">
                        deve essere di <strong>16 caratteri</strong>
                    </mat-error>
                </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="company.checked" appearance="standard">
                <mat-label>ragione sociale *</mat-label>
                <input type="text" matInput formControlName="business_name">
                <mat-error *ngIf="(f.business_name.dirty || f.business_name.touched)">
                    campo <strong>obbligatorio</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="company.checked" appearance="standard">
                <mat-label>P.IVA *</mat-label>
                <input type="text" matInput formControlName="vat_number">
                <mat-error *ngIf="f.vat_number.invalid && (f.vat_number.dirty || f.vat_number.touched)">
                    <mat-error *ngIf="(f.vat_number.errors.required)">
                        campo <strong>obbligatorio</strong>
                    </mat-error>
                    <mat-error *ngIf="(f.vat_number.errors.minlength || f.vat_number.errors.maxlength)">
                        deve essere di <strong>11 caratteri</strong>
                    </mat-error>
                </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="company.checked" appearance="standard">
                <mat-label>PEC *</mat-label>
                <input type="email" matInput formControlName="pec">
                <mat-error *ngIf="f.pec.invalid && (f.pec.dirty || f.pec.touched)">
                    <mat-error *ngIf="(f.pec.errors.required)">
                        campo <strong>obbligatorio</strong>
                    </mat-error>
                    <mat-error *ngIf="(f.pec.errors.pattern)">
                        inserisci un indirizzo e-mail <strong>valido</strong>
                    </mat-error>
                </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="company.checked" appearance="standard">
                <mat-label>SDI</mat-label>
                <input type="text" matInput formControlName="sdi">
                <mat-error *ngIf="f.sdi.invalid && (f.sdi.dirty || f.sdi.touched)">
                    <mat-error *ngIf="(f.sdi.errors.required)">
                        campo <strong>obbligatorio</strong>
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>
    </form>
    <div class="action-float">
        <!-- <button (click)="setView('')" class="link-underline">annulla</button> -->
        <div class="button__container">
            <div class="button__background2"></div>
            <div class="button__background"></div>
            <!-- (click)="setView('')" -->
            <button class="action__button" (click)="sendRegistrationForm()">
            Salva
            <div class="arrow-left">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.14 16.48">
                    <line y1="8.24" x2="19.55" y2="8.24" />
                    <polyline points="11.4 0.5 19.14 8.24 11.4 15.98" />
                </svg>
            </div>
        </button>
        </div>
    </div>
    <button [routerLink]='["/area-riservata/"]' class="link-underline link-underline--back padding-top--30">annulla</button>
</div>
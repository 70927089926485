<div [@filterAnim]="isEnter">
    <div class="container container--white" *ngIf="isMobile" [@filterAnim]="2">
        <button class="search-button" (click)="filterOpen()" [ngClass]="{active: isOpen}">Ricerca</button>
    </div>
    <div class="filter" [ngClass]="{open: isOpen}">
        <form [formGroup]="filterForm" (ngSubmit)="filter()">
            <p *ngIf="!isMobile">Ricerca</p>
            <div class="form__container">
                <mat-form-field appearance="standard" class="grid--1-2">
                    <mat-label>tipologia</mat-label>
                    <mat-select formControlName="subcategory">
                        <mat-option *ngFor="let element of subcategories" [value]="element.id">{{element.name}}</mat-option>
                        <!-- <mat-option value="city-bike">city-bike</mat-option>
                        <mat-option value="e-bike">e-bike</mat-option>
                        <mat-option value="accessori">accessori</mat-option> -->
                    </mat-select>
                </mat-form-field>
                <!-- <mat-form-field appearance="standard" class="grid--1-2">
                    <mat-label>taglia</mat-label>
                    <mat-select formControlName="size">
                        <mat-option *ngFor="let item of sizes" [value]="item">{{item}}</mat-option>
                    </mat-select>
                </mat-form-field> -->
                <p class="grid--1-2">PREZZO</p>
                <mat-form-field appearance="standard">
                    <mat-label>da</mat-label>
                    <input matInput type="text" (keypress)="checkNumber($event)" formControlName="min_price">
                </mat-form-field>
                <mat-form-field appearance="standard">
                    <mat-label>a</mat-label>
                    <input matInput type="text" (keypress)="checkNumber($event)" formControlName="max_price">
                </mat-form-field>
            </div>
        </form>
        <div class="filter-button__container">
            <button class="link-underline" (click)="resetFilterForm()">reset filtri</button>
            <div class="button__container">
                <div class="button__background2"></div>
                <div class="button__background"></div>
                <button type="submit" class="action__button" (click)="filter()">
                Cerca
                <div class="arrow-left">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.14 16.48">
                        <line y1="8.24" x2="19.55" y2="8.24"/>
                        <polyline points="11.4 0.5 19.14 8.24 11.4 15.98"/>
                    </svg>
                </div>
            </button>
            </div>
        </div>
    </div>
</div>
<div class="container container--product" [@enterAnim]="isEnter">
    <div class="title">
        <h1>Catalogo <span>{{categoryLabel}}</span></h1>
        <p *ngIf="!isMobile">Prodotti disponibii <span>{{pagination?.total}}</span></p>
    </div>
    <div class="product__container grid__container grid__container--3">
        <div *ngFor="let product of products" class="product">
            <a [routerLink]="['/prodotto/', product.id]" (click)="viewItemDataLayer(product)" appCursorHover [cursorUrl]="'url(assets/images/cursor/scopri.png)'">
                <picture *ngIf="product.product_image">
                    <source sizes="(max-width: 1023px) 90vw, 20vw" type="image/webp" [srcset]="product.product_image + '_800.webp 800w, '+ product.product_image +'_400.webp 400w'">
                    <source sizes="(max-width: 1023px) 90vw, 20vw" type="image/jpg" [srcset]="product.product_image + '_800.jpg 800w, '+ product.product_image +'_400.jpg 400w'">
                    <img sizes="(max-width: 1023px) 90vw, 20vw" [src]="product.product_image + '_800.jpg'" [srcset]="product.product_image + '_800.jpg 800w, '+ product.product_image +'_400.jpg 400w'" alt="{{product.title}}">
                </picture>
                <picture *ngIf="!product.product_image">
                    <source sizes="(max-width: 1023px) 90vw, 20vw" type="image/webp" [srcset]="'assets/images/shop/bici-2_800.webp 800w, assets/images/shop/bici-2_400.webp 400w'">
                    <source sizes="(max-width: 1023px) 90vw, 20vw" type="image/jpg" srcset="assets/images/shop/bici-2_800.jpg 800w, assets/images/shop/bici-2_400.jpg 400w">
                    <img sizes="(max-width: 1023px) 90vw, 20vw" src="assets/images/shop/bici-2_800.jpg" srcset="assets/images/shop/bici-2_800.jpg 800w, assets/images/shop/bici-2_400.jpg 400w" alt="{{product.title}}">
                </picture>
                <div *ngIf="product.discount>0" class="labelDiscount">Sconto {{product.discount}}%</div>
                <!-- <img [src]="assetsUrl + 'writable/uploads/2_400.webp'" alt=""> -->
                <div class="info__container">
                    <p class="info__subtitle">{{product.subcategory_label}}</p>
                    <div class="info__title">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12.5" viewBox="0 0 20 16.6"><path d="M15,7,9.9,1.87,11.73,0,20,8.3l-8.27,8.3-1.9-1.9L15,9.6H0V7Z"/></svg>
                        <p>{{product.title}}</p>
                    </div>
                    <div class="price">
                        <p [ngClass]="{'info__price--discount': product.discount>0}" class="info__price">€{{product.gross_price}}</p>
                        <p *ngIf="product.discount>0" class="info__discount">€{{ (product.gross_price-(product.gross_price*(product.discount/100))).toFixed(2) }}</p>
                    </div>
                </div>
            </a>
        </div>
    </div>

    <mat-paginator #paginator showFirstLastButtons="false" (page)="handlePageEvent($event)" [length]="total" [pageSize]="perPage" [pageIndex]="pageIndex()" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>
<section class="container--full" [@enterAnim]="isEnter">
    <div class="grid__container grid__container--4">
        <div class="grid__text">
            Catalogo {{categoryLabel}}
        </div>
        <div class="grid--2-3">
            <p *ngIf="categoryId == '1'" class="subtitle orange">Biciclette muscolari a marchio Zecchini, progettate e realizzate in Italia. Un’ampia scelta tra citybike, moutain bike, biciclette vintage, gravel e ibride, pieghevoli e molto altro.</p>
            <p *ngIf="categoryId == '2'" class="subtitle orange">Le migliori e-bike (bici elettriche e a pedalata assistita) presenti sul mercato. Modelli che vanno dalle più classiche citybike, fino a quelli più sportivi e performanti.</p>
            <p *ngIf="categoryId == '3'" class="subtitle orange">Tutti gli accessori per completare e personalizzare la vostra bicicletta. Selle, borse, manopole, borracce, cestini e prodotti legati alla sicurezza come fanalini e lucchetti.</p>
            <p *ngIf="categoryId == '4'" class="subtitle orange">Le parti meccaniche e funzionali delle biciclette dei migliori marchi sul mercato come Shimano, Sram, Vittoria, Campagnolo. Catene, cambi, freni, camere d’aria, copertoni e molto altro.</p>
            <p *ngIf="categoryId == '5'" class="subtitle orange">Maglie, abbigliamento tecnico, cappellini, caschi, guanti e tutto ciò che serve per affrontare le sfide urbane e off-road, in qualunque stagione e condizione.</p>
        </div>
    </div>
</section>
<app-footer></app-footer>
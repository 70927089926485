import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from 'src/environments/environment';

import { HomepageComponent } from './views/homepage/homepage.component';
import { ShopComponent } from './views/shop/shop.component';
import { CartComponent } from './views/cart/cart.component';
import { LoginComponent } from './views/login/login.component';
import { RegistrationComponent } from './views/registration/registration.component';
import { CheckoutComponent } from './views/checkout/checkout.component';
import { ThankYouPageComponent } from './views/thank-you-page/thank-you-page.component';
import { ReservedAreaComponent } from './views/reserved-area/reserved-area.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { ProductDetailComponent } from './views/product-detail/product-detail.component';
import { ServiziComponent } from './views/servizi/servizi.component';
import { StoriaComponent } from './views/storia/storia.component';
import { ConsigliComponent } from './views/consigli/consigli.component';
import { RivenditoriDettaglioComponent } from './views/rivenditori-dettaglio/rivenditori-dettaglio.component';
import { ROUTE_NAMES } from './constants/route-names';
import { PurchaseInformationComponent } from './views/reserved-area/purchase-information/purchase-information.component';
import { ShippingAddressesComponent } from './views/reserved-area/shipping-addresses/shipping-addresses.component';
import { OrdersComponent } from './views/reserved-area/orders/orders.component';
import { HelpComponent } from './views/reserved-area/help/help.component';



import { NewsDetailComponent } from './views/news-detail/news-detail.component';
import { RivenditoriComponent } from './views/rivenditori/rivenditori.component';
import { ContactsComponent } from './views/contacts/contacts.component';
import { TermsComponent } from './views/terms/terms.component';
import { CookieComponent } from './views/cookie/cookie.component';
import { PrivacyComponent } from './views/privacy/privacy.component';
import { ActivateAccountComponent } from './views/activate-account/activate-account.component';



const routes: Routes = [
  {
    path: '', component: HomepageComponent, data: {
      animation: 'homepage',
      seo: {
        title: 'Biciclette Brescia',
        metaTags: [
          { name: 'description', content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
          { property: 'og:title', content: 'Zecchini biciclette' },
          { property: 'og:description', content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
          { property: 'og:image', content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { property: 'og:url', content: environment.apiUrl },
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: 'Zecchini biciclette' },
          { property: 'fb:app_id', content: '447396329761321' },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:site", content: "zecchini" },
          { name: "twitter:title", content: "Zecchini biciclette" },
          { name: "twitter:image", content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { name: "twitter:description", content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
        ]
      }
    }
  },{
    path: 'servizi', component: ServiziComponent, data: {
      animation: 'servizi',
      seo: {
        title: 'Servizi',
        metaTags: [
          { name: 'description', content: 'Assistenza, riparazione e manutenzione post vendita. Siamo sempre qui, a Brescia, disponibili per sistemare, gonfiare, avvitare, lubrificare, stringere e allentare.' },
          { property: 'og:title', content: 'Zecchini biciclette' },
          { property: 'og:description', content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
          { property: 'og:image', content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { property: 'og:url', content: environment.apiUrl + 'servizi' },
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: 'Zecchini biciclette' },
          { property: 'fb:app_id', content: '447396329761321' },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:site", content: "zecchini" },
          { name: "twitter:title", content: "Zecchini biciclette" },
          { name: "twitter:image", content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { name: "twitter:description", content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
        ]
      }
    }
  },{
    path: 'storia', component: StoriaComponent, data: {
      animation: 'storia',
      seo: {
        title: 'Negozio storico',
        metaTags: [
          { name: 'description', content: 'Un passato importante, esperienza e competenze da leggenda. Un negozio storico che è anche una bottega, un’officina e un magazzino, dove ogni giorno si respira la storia.' },
          { property: 'og:title', content: 'Zecchini biciclette' },
          { property: 'og:description', content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
          { property: 'og:image', content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { property: 'og:url', content: environment.apiUrl + 'storia'},
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: 'Zecchini biciclette' },
          { property: 'fb:app_id', content: '447396329761321' },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:site", content: "zecchini" },
          { name: "twitter:title", content: "Zecchini biciclette" },
          { name: "twitter:image", content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { name: "twitter:description", content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
        ]
      }
    }
  },{
    path: 'consigli', component: ConsigliComponent, data: {
      animation: 'consigli',
      seo: {
        title: 'Consigli',
        metaTags: [
          { name: 'description', content: 'Un viaggio nel nostro mondo e in tutto ciò che ci entusiasma. Consigli, novità, eventi, vita e passione per la bicicletta ed il mondo Zecchini.' },
          { property: 'og:title', content: 'Zecchini biciclette' },
          { property: 'og:description', content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
          { property: 'og:image', content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { property: 'og:url', content: environment.apiUrl + 'consigli' },
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: 'Zecchini biciclette' },
          { property: 'fb:app_id', content: '447396329761321' },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:site", content: "zecchini" },
          { name: "twitter:title", content: "Zecchini biciclette" },
          { name: "twitter:image", content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { name: "twitter:description", content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
        ]
      }
    }
  },{
    path: 'news/:newsId', component: NewsDetailComponent, data: {
      animation: 'dettaglio-news',
      seo: {
        title: 'Dettaglio news',
        metaTags: [
          { name: 'description', content: 'News Zecchini biciclette' },
          { property: 'og:title', content: 'Zecchini biciclette' },
          { property: 'og:description', content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
          { property: 'og:image', content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { property: 'og:url', content: environment.apiUrl },
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: 'Zecchini biciclette' },
          { property: 'fb:app_id', content: '447396329761321' },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:site", content: "zecchini" },
          { name: "twitter:title", content: "Zecchini biciclette" },
          { name: "twitter:image", content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { name: "twitter:description", content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
        ]
      }
    }
  },{
    path: 'rivenditori', component: RivenditoriComponent, data: {
      animation: 'rivenditori',
      seo: {
        title: 'Rivenditori',
        metaTags: [
          { name: 'description', content: 'Tutti i negozi in cui potete trovare biciclette a marchio Zecchini. Professionalità e competenza: ogni nostro rivenditore è una parte importante della famiglia.' },
          { property: 'og:title', content: 'Zecchini biciclette' },
          { property: 'og:description', content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
          { property: 'og:image', content: environment.apiUrl + 'assets/images/opengraph-rivenditori.jpg' },
          { property: 'og:url', content: environment.apiUrl + 'rivenditori' },
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: 'Zecchini biciclette' },
          { property: 'fb:app_id', content: '447396329761321' },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:site", content: "zecchini" },
          { name: "twitter:title", content: "Zecchini biciclette" },
          { name: "twitter:image", content: environment.apiUrl + 'assets/images/opengraph-rivenditori.jpg' },
          { name: "twitter:description", content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
        ]
      }
    }
  },{
    path: 'rivenditori/:rivenditoriId', component: RivenditoriDettaglioComponent, data: {
      animation: 'rivenditori-dettaglio',
      seo: {
        title: 'Dettaglio rivenditori',
        metaTags: [
          { name: 'description', content: 'Rivenditori Zecchini biciclette' },
          { property: 'og:title', content: 'Zecchini biciclette' },
          { property: 'og:description', content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
          { property: 'og:image', content: environment.apiUrl + 'assets/images/opengraph-rivenditori.jpg' },
          { property: 'og:url', content: environment.apiUrl },
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: 'Zecchini biciclette' },
          { property: 'fb:app_id', content: '447396329761321' },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:site", content: "zecchini" },
          { name: "twitter:title", content: "Zecchini biciclette" },
          { name: "twitter:image", content: environment.apiUrl + 'assets/images/opengraph-rivenditori.jpg' },
          { name: "twitter:description", content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
        ]
      }
    }
  },{
    path: 'contatti', component: ContactsComponent, data: {
      animation: 'contatti',
      seo: {
        title: 'Contatti',
        metaTags: [
          { name: 'description', content: 'Per informazioni, dubbi e domande, compila il form in ogni sua parte. Saremo rapidi, efficaci ed esaustivi: per noi il mondo delle due ruote non ha segreti.' },
          { property: 'og:title', content: 'Zecchini biciclette' },
          { property: 'og:description', content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
          { property: 'og:image', content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { property: 'og:url', content: environment.apiUrl + 'contatti' },
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: 'Zecchini biciclette' },
          { property: 'fb:app_id', content: '447396329761321' },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:site", content: "zecchini" },
          { name: "twitter:title", content: "Zecchini biciclette" },
          { name: "twitter:image", content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { name: "twitter:description", content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
        ]
      }
    }
  }, {
    path: 'carrello', component: CartComponent, data: {
      animation: 'carrello',
      seo: {
        title: 'Carrello',
        metaTags: [
          { name: 'description', content: "Seleziona dei prodotti per poter procedere all'acquisto'"},
          { property: 'og:title', content: 'Zecchini biciclette' },
          { property: 'og:description', content: "Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato." },
          { property: 'og:image', content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { property: 'og:url', content: environment.apiUrl + 'carrello' },
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: 'Zecchini biciclette' },
          { property: 'fb:app_id', content: '447396329761321' },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:site", content: "zecchini" },
          { name: "twitter:title", content: "Zecchini biciclette" },
          { name: "twitter:image", content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { name: "twitter:description", content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
        ]
      }
    }
  },{
    path: 'biciclette', component: ShopComponent, data: {
      animation: 'biciclette',
      category:'biciclette',
      seo: {
        title: 'Catalogo biciclette',
        metaTags: [
          { name: 'description', content: 'Biciclette muscolari a marchio Zecchini, progettate e realizzate in Italia. Un’ampia scelta tra citybike, moutain bike, biciclette vintage, gravel e ibride, pieghevoli e molte altro.' },
          { property: 'og:title', content: 'Zecchini - Catalogo biciclette' },
          { property: 'og:description', content: 'Biciclette muscolari a marchio Zecchini, progettate e realizzate in Italia. Un’ampia scelta tra citybike, moutain bike, biciclette vintage, gravel e ibride, pieghevoli e molte altro.' },
          { property: 'og:image', content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { property: 'og:url', content: environment.apiUrl + 'biciclette' },
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: 'Zecchini biciclette' },
          { property: 'fb:app_id', content: '447396329761321' },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:site", content: "zecchini" },
          { name: "twitter:title", content: "Zecchini - Catalogo biciclette" },
          { name: "twitter:image", content: environment.apiUrl + 'assets/images/opengraph_biciclette.jpg' },
          { name: "twitter:description", content: 'Biciclette muscolari a marchio Zecchini, progettate e realizzate in Italia. Un’ampia scelta tra citybike, moutain bike, biciclette vintage, gravel e ibride, pieghevoli e molte altro.' },
        ]
      }
    }
  },{
    path: 'ebike', component: ShopComponent, data: {
      animation: 'ebike',
      category:'ebike',
      seo: {
        title: 'Catalogo e-bike',
        metaTags: [
          { name: 'description', content: 'Le migliori e-bike (bici elettriche e a pedalata assistita) presenti sul mercato. Modelli che vanno dalle più classiche citybike, fino a quelli più sportivi e performanti.' },
          { property: 'og:title', content: 'Zecchini - Catalogo e-bike' },
          { property: 'og:description', content: 'Le migliori e-bike (bici elettriche e a pedalata assistita) presenti sul mercato. Modelli che vanno dalle più classiche citybike, fino a quelli più sportivi e performanti.' },
          { property: 'og:image', content: environment.apiUrl + 'assets/images/opengraph_ebike.jpg' },
          { property: 'og:url', content: environment.apiUrl + 'ebike' },
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: 'Zecchini - Catalogo ebike' },
          { property: 'fb:app_id', content: '447396329761321' },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:site", content: "zecchini" },
          { name: "twitter:title", content: "Zecchini - Catalogo ebike" },
          { name: "twitter:image", content: environment.apiUrl + 'assets/images/opengraph_ebike.jpg' },
          { name: "twitter:description", content: 'Le migliori e-bike (bici elettriche e a pedalata assistita) presenti sul mercato. Modelli che vanno dalle più classiche citybike, fino a quelli più sportivi e performanti.' },
        ]
      }
    }
  },{
    path: 'accessori-bici', component: ShopComponent, data: {
      animation: 'accessori',
      category:'accessori-bici',
      seo: {
        title: 'Catalogo accessori',
        metaTags: [
          { name: 'description', content: 'Tutti gli accessori per completare e personalizzare la vostra bicicletta. Selle, borse, manopole, borracce, cestini e prodotti legati alla sicurezza come fanalini e lucchetti.' },
          { property: 'og:title', content: 'Zecchini - Catalogo accessori' },
          { property: 'og:description', content: 'Tutti gli accessori per completare e personalizzare la vostra bicicletta. Selle, borse, manopole, borracce, cestini e prodotti legati alla sicurezza come fanalini e lucchetti.' },
          { property: 'og:image', content: environment.apiUrl + 'assets/images/opengraph_accessori.jpg' },
          { property: 'og:url', content: environment.apiUrl + 'accessori-bici' },
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: 'Zecchini biciclette' },
          { property: 'fb:app_id', content: '447396329761321' },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:site", content: "zecchini" },
          { name: "twitter:title", content: "Zecchini - Catalogo accessori" },
          { name: "twitter:image", content: environment.apiUrl + 'assets/images/opengraph_accessori.jpg' },
          { name: "twitter:description", content: 'Tutti gli accessori per completare e personalizzare la vostra bicicletta. Selle, borse, manopole, borracce, cestini e prodotti legati alla sicurezza come fanalini e lucchetti.' },
        ]
      }
    }
  },{
    path: 'componenti-bici', component: ShopComponent, data: {
      animation: 'componenti',
      category:'componenti-bici',
      seo: {
        title: 'Catalogo componenti',
        metaTags: [
          { name: 'description', content: 'Le parti meccaniche e funzionali delle biciclette dei migliori marchi sul mercato come Shimano, Sram, Vittoria, Campagnolo. Catene, cambi, freni, camere d’aria, copertoni e molto altro.' },
          { property: 'og:title', content: 'Zecchini - Catalogo componenti' },
          { property: 'og:description', content: 'Le parti meccaniche e funzionali delle biciclette dei migliori marchi sul mercato come Shimano, Sram, Vittoria, Campagnolo. Catene, cambi, freni, camere d’aria, copertoni e molto altro.' },
          { property: 'og:image', content: environment.apiUrl + 'assets/images/opengraph_componenti.jpg' },
          { property: 'og:url', content: environment.apiUrl + 'componenti-bici' },
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: 'Zecchini biciclette' },
          { property: 'fb:app_id', content: '447396329761321' },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:site", content: "zecchini" },
          { name: "twitter:title", content: "Zecchini - Catalogo componenti" },
          { name: "twitter:image", content: environment.apiUrl + 'assets/images/opengraph_componenti.jpg' },
          { name: "twitter:description", content: 'Le parti meccaniche e funzionali delle biciclette dei migliori marchi sul mercato come Shimano, Sram, Vittoria, Campagnolo. Catene, cambi, freni, camere d’aria, copertoni e molto altro.' },
        ]
      }
    }
  },{
    path: 'abbigliamento-bici', component: ShopComponent, data: {
      animation: 'abbigliamento',
      category:'abbigliamento-bici',
      seo: {
        title: 'Catalogo abbigliamento',
        metaTags: [
          { name: 'description', content: 'Maglie, abbigliamento tecnico, cappellini, caschi, guanti e tutto ciò che serve per affrontare le sfide urbane e off-road, in qualunque stagione e condizione.' },
          { property: 'og:title', content: 'Zecchini - Catalogo abbigliamento' },
          { property: 'og:description', content: 'Maglie, abbigliamento tecnico, cappellini, caschi, guanti e tutto ciò che serve per affrontare le sfide urbane e off-road, in qualunque stagione e condizione.' },
          { property: 'og:image', content: environment.apiUrl + 'assets/images/opengraph_abbigliamento.jpg' },
          { property: 'og:url', content: environment.apiUrl + 'abbigliamento-bici' },
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: 'Zecchini biciclette' },
          { property: 'fb:app_id', content: '447396329761321' },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:site", content: "zecchini" },
          { name: "twitter:title", content: "Zecchini - Catalogo abbigliamento" },
          { name: "twitter:image", content: environment.apiUrl + 'assets/images/opengraph_abbigliamento.jpg' },
          { name: "twitter:description", content: 'Maglie, abbigliamento tecnico, cappellini, caschi, guanti e tutto ciò che serve per affrontare le sfide urbane e off-road, in qualunque stagione e condizione.' },
        ]
      }
    }
  }
  ,{
    path: 'prodotto/:prodottoId', component: ProductDetailComponent, data: {
      animation: 'dettaglio-prodotto',
      seo: {
        title: 'Dettaglio prodotto',
        metaTags: [
          { name: 'description', content: 'Zecchini biciclette' },
          { property: 'og:title', content: 'Zecchini biciclette' },
          { property: 'og:description', content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
          { property: 'og:image', content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { property: 'og:url', content: environment.apiUrl },
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: 'Zecchini biciclette' },
          { property: 'fb:app_id', content: '447396329761321' },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:site", content: "zecchini" },
          { name: "twitter:title", content: "Zecchini biciclette" },
          { name: "twitter:image", content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { name: "twitter:description", content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
        ]
      }
    }
  },{
    path: 'login', component: LoginComponent, data: {
      animation: 'login',
      seo: {
        title: 'Login',
        metaTags: [
          { name: 'description', content: 'Accedi al tuo account Zecchini o registrati in maniera facile e veloce.' },
          { property: 'og:title', content: 'Zecchini biciclette' },
          { property: 'og:description', content: 'Accedi al tuo account Zecchini o registrati in maniera facile e veloce.' },
          { property: 'og:image', content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { property: 'og:url', content: environment.apiUrl + 'login' },
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: 'Zecchini biciclette' },
          { property: 'fb:app_id', content: '447396329761321' },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:site", content: "zecchini" },
          { name: "twitter:title", content: "Zecchini biciclette" },
          { name: "twitter:image", content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { name: "twitter:description", content: 'Accedi al tuo account Zecchini o registrati in maniera facile e veloce.' },
        ]
      }
    }
  },{
    path: 'informazioni-cliente', component: RegistrationComponent, data: {
      animation: 'informazioni-cliente',
      seo: {
        title: 'Informazioni cliente',
        metaTags: [
          { name: 'description', content: "Informazioni d'acquisto, indirizzi di spedizione, odine effettuati e tutto ciò che ti serve per gestire il tuo profilo" },
          { property: 'og:title', content: 'Zecchini biciclette' },
          { property: 'og:description', content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
          { property: 'og:image', content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { property: 'og:url', content: environment.apiUrl + 'informazioni-cliente' },
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: 'Zecchini biciclette' },
          { property: 'fb:app_id', content: '447396329761321' },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:site", content: "zecchini" },
          { name: "twitter:title", content: "Zecchini biciclette" },
          { name: "twitter:image", content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { name: "twitter:description", content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
        ]
      }
    }
  },{
    path: 'checkout', component: CheckoutComponent, data: {
      animation: 'checkout',
      seo: {
        title: 'Checkout',
        metaTags: [
          { name: 'description', content: 'Checkout Zecchini biciclette' },
          { property: 'og:title', content: 'Zecchini biciclette' },
          { property: 'og:description', content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
          { property: 'og:image', content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { property: 'og:url', content: environment.apiUrl + 'checkout' },
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: 'Zecchini biciclette' },
          { property: 'fb:app_id', content: '447396329761321' },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:site", content: "zecchini" },
          { name: "twitter:title", content: "Zecchini biciclette" },
          { name: "twitter:image", content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { name: "twitter:description", content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
        ]
      }
    }
  },{
    path: 'thank-you-page', component: ThankYouPageComponent, data: {
      animation: 'thank-you-page',
      seo: {
        title: 'Acquisto completato',
        metaTags: [
          { name: 'description', content: 'Grazie per averci scelto!' },
          { property: 'og:title', content: 'Zecchini biciclette' },
          { property: 'og:description', content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
          { property: 'og:image', content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { property: 'og:url', content: environment.apiUrl + 'thank-you-page' },
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: 'Zecchini biciclette' },
          { property: 'fb:app_id', content: '447396329761321' },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:site", content: "zecchini" },
          { name: "twitter:title", content: "Zecchini biciclette" },
          { name: "twitter:image", content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { name: "twitter:description", content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
        ]
      }
    }
  },{
    path: 'area-riservata', component: ReservedAreaComponent, data: {
      animation: 'area-riservata',
      seo: {
        title: 'Area riservata',
        metaTags: [
          { name: 'description', content: "Informazioni d'acquisto, indirizzi di spedizione, odine effettuati e tutto ciò che ti serve per gestire il tuo profilo" },
          { property: 'og:title', content: 'Zecchini biciclette' },
          { property: 'og:description', content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
          { property: 'og:image', content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { property: 'og:url', content: environment.apiUrl + 'area-riservata' },
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: 'Zecchini biciclette' },
          { property: 'fb:app_id', content: '447396329761321' },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:site", content: "zecchini" },
          { name: "twitter:title", content: "Zecchini biciclette" },
          { name: "twitter:image", content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { name: "twitter:description", content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
        ]
      }
    },
    children: [
      { path: ROUTE_NAMES.PURCHASE_INFORMATION, component: PurchaseInformationComponent },
      { path: ROUTE_NAMES.SHIPPING_ADDRESSES, component: ShippingAddressesComponent },
      { path: ROUTE_NAMES.ORDERS, component: OrdersComponent },
      { path: ROUTE_NAMES.HELP, component: HelpComponent },
      // { path: '**', redirectTo: ROUTE_NAMES.PURCHASE_INFORMATION }
    ]
  },{
    path: 'forgot-password', component: ForgotPasswordComponent, data: {
      animation: 'forgot-password',
      seo: {
        title: 'Forgot password',
        metaTags: [
          { name: 'description', content: 'Zecchini biciclette' },
          { property: 'og:title', content: 'Zecchini biciclette' },
          { property: 'og:description', content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
          { property: 'og:image', content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { property: 'og:url', content: environment.apiUrl + 'forgot-password' },
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: 'Zecchini biciclette' },
          { property: 'fb:app_id', content: '447396329761321' },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:site", content: "zecchini" },
          { name: "twitter:title", content: "Zecchini biciclette" },
          { name: "twitter:image", content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { name: "twitter:description", content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
        ]
      }
    }
  },
  {
    path: 'privacy', component: PrivacyComponent, data: {
      animation: 'privacy',
      seo: {
        title: 'Privacy Policy',
        metaTags: [
          { name: 'description', content: 'Privacy Policy di www.biciclettezecchini.it, raccoglie alcuni Dati Personali dei propri Utenti.' },
          { property: 'og:title', content: 'Zecchini biciclette' },
          { property: 'og:description', content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
          { property: 'og:image', content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { property: 'og:url', content: environment.apiUrl + 'privacy' },
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: 'Zecchini biciclette' },
          { property: 'fb:app_id', content: '447396329761321' },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:site", content: "zecchini" },
          { name: "twitter:title", content: "Zecchini biciclette" },
          { name: "twitter:image", content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { name: "twitter:description", content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
        ]
      }
    }
  },{
    path: 'cookie', component: CookieComponent, data: {
      animation: 'cookie',
      seo: {
        title: 'Cookie Policy',
        metaTags: [
          { name: 'description', content: 'Cookie Policy di www.biciclettezecchini.it, raccoglie alcuni Dati Personali dei propri Utenti.' },
          { property: 'og:title', content: 'Zecchini biciclette' },
          { property: 'og:description', content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
          { property: 'og:image', content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { property: 'og:url', content: environment.apiUrl + 'cookie' },
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: 'Zecchini biciclette' },
          { property: 'fb:app_id', content: '447396329761321' },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:site", content: "zecchini" },
          { name: "twitter:title", content: "Zecchini biciclette" },
          { name: "twitter:image", content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { name: "twitter:description", content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
        ]
      }
    }
  },{
    path: 'condizioni-di-vendita', component: TermsComponent, data: {
      animation: 'terms',
      seo: {
        title: 'Termini e condizion i di vendita',
        metaTags: [
          { name: 'description', content: 'Termini e condizioni di www.biciclettezecchini.it. Questi Termini disciplinano l’utilizzo di questa Applicazione e qualsiasi altro Accordo o rapporto giuridico con il Titolare in maniera vincolante.' },
          { property: 'og:title', content: 'Zecchini biciclette' },
          { property: 'og:description', content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
          { property: 'og:image', content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { property: 'og:url', content: environment.apiUrl + 'condizioni-di-vendita' },
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: 'Zecchini biciclette' },
          { property: 'fb:app_id', content: '447396329761321' },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:site", content: "zecchini" },
          { name: "twitter:title", content: "Zecchini biciclette" },
          { name: "twitter:image", content: environment.apiUrl + 'assets/images/opengraph.jpg' },
          { name: "twitter:description", content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
        ]
        }
      }
    },
    {
      path: 'conferma-registrazione', component: ActivateAccountComponent, data: {
        animation: 'forgot-password',
        seo: {
          title: 'Conferma Registrazione',
          metaTags: [
            { name: 'description', content: 'Zecchini biciclette' },
            { property: 'og:title', content: 'Zecchini biciclette' },
            { property: 'og:description', content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
            { property: 'og:image', content: environment.apiUrl + 'assets/images/opengraph.jpg' },
            { property: 'og:url', content: environment.apiUrl + 'conferma-registrazione' },
            { property: 'og:type', content: 'website' },
            { property: 'og:site_name', content: 'Zecchini biciclette' },
            { property: 'fb:app_id', content: '447396329761321' },
            { name: "twitter:card", content: "summary_large_image" },
            { name: "twitter:site", content: "zecchini" },
            { name: "twitter:title", content: "Zecchini biciclette" },
            { name: "twitter:image", content: environment.apiUrl + 'assets/images/opengraph.jpg' },
            { name: "twitter:description", content: 'Produzione di biciclette, vendita e assistenza, un negozio storico nel cuore di Brescia. Produciamo e vendiamo biciclette e i migliori accessori sul mercato.' },
          ]
        }
      }
    },
    { path: '**', redirectTo: '' }
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'top',
    onSameUrlNavigation: 'reload'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

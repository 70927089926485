import { Component, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { slider } from 'src/app/constants/slider';
import { AppRoutingTransitionService } from 'src/app/services/app-routing-transition.service';
import { imageFadeAnimation } from 'src/app/router/animations/component-animations';
import { isPlatformBrowser } from '@angular/common';
import { animate, animateChild, group, query, state, style, transition, trigger } from '@angular/animations';
import gsap from 'gsap';
import { SplitText } from 'gsap/all';
import { ScrollTrigger } from "gsap/ScrollTrigger";


@Component({
  selector: 'app-storia',
  templateUrl: './storia.component.html',
  styleUrls: ['./storia.component.css'],
  animations: [imageFadeAnimation,
    trigger('titleEnter', [
      state('false',
        style({
          opacity: 0
        })
      ),
      transition('false => true', [
        animate('0.3s cubic-bezier(0.55, 0.31, 0.15, 0.93)'),
        group([
          query('@titleEnterSpan', animateChild(), { optional: true }),
        ]),
        group([
          query('@titleEnterText', animateChild(), { optional: true }),
        ])
      ]),
    ]),
    trigger('titleEnterSpan', [
      transition('false => true', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('{{durationAnim}}s cubic-bezier(.6,.36,.07,.99)', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
    ]),
    trigger('titleEnterText', [
      transition('false => true', [
        style({ opacity: 0 }),
        animate('0.3s ease-in', style({ opacity: 1 })),
      ]),
    ]),
    trigger('imgEnter', [
      state('false',
        style({
          opacity: 0,
          transform: 'translateY(15vw)',
        })
      ),
      transition('false => true', [
        animate('1s cubic-bezier(.72,.01,.07,1)'),
        query('@imgScale', animateChild({ duration: 1 }), { optional: true }),
      ]),
    ]),
    trigger('imgScale', [
      state('false',
        style({
          transform: 'scale(1)'
        })
      ),
      transition('false => true', [
        style({
          transform: 'scale(1.25)'
        }),
        animate('1s cubic-bezier(.72,.01,.07,1)')
      ]),
    ]),
    trigger('showHidden', [
      state('show', style({
        opacity: 1
      })),
      state('hidden', style({
        opacity: 0
      })),
      transition('* => *', [
        animate('1s ease-in-out')
      ]),
    ]),]
})
export class StoriaComponent implements OnInit {
  public isEnter: boolean = false;
  windowWidth: number = window.innerWidth;
  isMobile: boolean = false;
  storiaSlider1 = slider.storiaSlider1;
  storiaSlider2 = slider.storiaSlider2;
  @ViewChild ('split1') split1;
  @ViewChild ('split2') split2;
  @ViewChild ('split3') split3;
  @ViewChild ('split4') split4;
  @ViewChild ('split5') split5;
  tlInfiniteText;
  activeText: string;
  activeText2: string;
  activeImg: string;
  isBrowser;
  storia1Tl;
  sliderTlAnim;
  sliderTlAnim2;
  tlSplit;
  storia2Tl;
  myEventSubscription;
  browser;
  stravaMobileTl;
  constructor(@Inject(PLATFORM_ID) platformId: string, private appService: AppRoutingTransitionService) {
    this.isBrowser = isPlatformBrowser(platformId);
   }

   mobileDetect() {
    if(this.windowWidth < 1023){
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
 } 

  textPlay(time) {
    this.tlInfiniteText.timeScale(time);
  }

  textPause(time) {
    this.tlInfiniteText.timeScale(time);
  }

  textInfiniteOver(view) {
    this.activeText = view;
    this.activeText2 = view;
  }

  txtOver(view) {
    this.activeImg = view;
}

@HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = window.innerWidth; 
    this.mobileDetect();
  }

  ngOnInit(): void {
    this.activeImg = 'citybike';
    this.mobileDetect();
    if (!this.isBrowser) {
      return;
    }
    this.myEventSubscription = this.appService.stringSubject.subscribe(() => {
        this.isEnter = true;
      }
    );
  }

  ngAfterViewInit() {
    if (!this.isBrowser) {
      return;
    }
    new SplitText([this.split1.nativeElement, this.split2.nativeElement, this.split3.nativeElement, this.split4.nativeElement, this.split5.nativeElement], { type: "lines", linesClass: "lineChild" });
    new SplitText([this.split1.nativeElement, this.split2.nativeElement, this.split3.nativeElement, this.split4.nativeElement, this.split5.nativeElement], { type: "lines", linesClass: "lineParent" });

    this.tlInfiniteText = gsap.timeline()
    this.tlInfiniteText.to('.infinite-scroll__container', {
      duration: 15,
      x: '-100%',
      ease: 'none',
      repeat: -1
    });
  }

  animEnd(event) {
    if (event.fromState == false) {
    this.storia1Tl = gsap.timeline({
      scrollTrigger: {
        id: "storia1Tl",
        toggleActions: "play complete none none",
        trigger: "#storiaTl1",
        start: "100px 90%",
      }
    })

    this.storia1Tl.from('.storia-img img', {
      duration: 1,
      opacity: 0.5,
      scale: 1.1,
      ease: "power2.out"
    }).from('.storia-img', {
      duration: 1,
      y: '5vw',
      ease: "power2.out"
    }, '-=1');

    this.storia2Tl = gsap.timeline({
      scrollTrigger: {
        id: "storia2Tl",
        toggleActions: "play complete none none",
        trigger: "#storia2Tl",
        start: "100px 90%"
      }
    })

    this.storia2Tl.from('#storia2Tl .image-change img', {
      duration: 1,
      opacity: 0.5,
      scale: 1.1,
      ease: "power2.out"
    }).from('#storia2Tl .image-change', {
      duration: 1,
      y: '10vw',
      ease: "power2.out"
    }, '-=1')

    this.sliderTlAnim = gsap.timeline({
      scrollTrigger: {
        id: 'sliderStoria',
        trigger: '#sliderStoria1',
        start: 'top 90%',
        scrub: 1
      }
    })

    this.sliderTlAnim.to('#sliderStoria1 .grid__front--small', {
      duration: 1,
      y: -100
    }).to('#sliderStoria1 .slider-big__container', {
      duration: 1,
      y: -50
    }, '-=1')

    this.sliderTlAnim2 = gsap.timeline({
      scrollTrigger: {
        id: 'sliderStori2',
        trigger: '#sliderStoria2',
        start: 'top 90%',
        scrub: 1
      }
    })

    this.sliderTlAnim2.to('#sliderStoria2 .grid__front--small', {
      duration: 1,
      y: -100
    }).to('#sliderStoria2 .slider-big__container', {
      duration: 1,
      y: -50
    }, '-=1')
    

    gsap.utils.toArray('.split').forEach(section => {
      let split: any = section;

      this.tlSplit = gsap.timeline({
        scrollTrigger: {
          id: 'splitStoria',
          trigger: split,
          start: 'top 90%',
          scrub: false
        }
      })

      this.tlSplit.from(split.querySelectorAll(".lineChild"), {
        duration: 0.5,
        opacity: 0,
        y: 30,
        stagger: 0.05,
        ease: "power2.inOut"
      })
    })

    gsap.utils.toArray(".textOpacity").forEach(section => {
      let textOpacity: any = section;

      gsap.from(textOpacity, {
        scrollTrigger: {
          id: 'rotate',
          trigger: textOpacity,
          start: 'top 90%',
          scrub: false
        },
        ease: "power3.out",
        opacity: 0.3,
        duration: 1,
      });
    });

    gsap.utils.toArray(".parallaxText").forEach(section => {
      let parallax: any = section;

      gsap.from(parallax, {
        scrollTrigger: {
          id: 'storiaParalax',
          trigger: parallax,
          start: 'top 90%',
          scrub: 2
        },
        ease: "power3.out",
        y: -100,
        duration: 1,
      });
    });

    gsap.utils.toArray(".text-rotate p").forEach(section => {
      let rotate: any = section;

      gsap.from(rotate, {
        scrollTrigger: {
          id: 'rotate',
          trigger: rotate,
          start: 'top 90%',
          scrub: false
        },
        ease: "power3.out",
        y: 40,
        duration: 1,
      });
    });
    if(this.isMobile) {
    this.stravaMobileTl = gsap.timeline({
      scrollTrigger: {
        id: 'strava-storia',
        trigger: '.strava__img--storia',
        start: 'top 80%',
        toggleActions: "play complete none reverse",
        onEnter: ()=> this.activeText2 = 'strava',
        onLeaveBack: ()=> this.activeText2 = ''
      }
    });
  }

  }
  }

  ngOnDestroy() {
    if (!this.isBrowser) {
      return;
    }
    ScrollTrigger.getAll().map((scrollTrigger) => {
      scrollTrigger.kill();
    })
    this.myEventSubscription.unsubscribe();
  }

}

<h2 mat-dialog-title>Tabella taglie bicicletta</h2>
<mat-dialog-content class="mat-typography">
    <table>
        <tr>
            <th>STATURA</th>
            <th>H. CAVALLO</th>
            <th class="orange">MOUNTAIN BIKE</th>
            <th class="orange">CITY BIKE</th>
            <th class="orange">BICI DA CORSA</th>
        </tr>
        <tr>
            <td>161-162</td>
            <td>74</td>
            <td class="blue">42</td>
            <td class="blue">47</td>
            <td class="blue">49</td>
        </tr>
        <tr>
            <td>163-164</td>
            <td>75</td>
            <td class="blue">43</td>
            <td class="blue">47</td>
            <td class="blue">50</td>
        </tr>
        <tr>
            <td>165-166</td>
            <td>76</td>
            <td class="blue">43</td>
            <td class="blue">48</td>
            <td class="blue">51</td>
        </tr>
        <tr>
            <td>167-168</td>
            <td>77</td>
            <td class="blue">44</td>
            <td class="blue">49</td>
            <td class="blue">51</td>
        </tr>
        <tr>
            <td>169-170</td>
            <td>79</td>
            <td class="blue">45</td>
            <td class="blue">50</td>
            <td class="blue">53</td>
        </tr>
        <tr>
            <td>171-174</td>
            <td>81</td>
            <td class="blue">46</td>
            <td class="blue">51</td>
            <td class="blue">54</td>
        </tr>
        <tr>
            <td>175-178</td>
            <td>82</td>
            <td class="blue">47</td>
            <td class="blue">52</td>
            <td class="blue">55</td>
        </tr>
        <tr>
            <td>179-180</td>
            <td>84</td>
            <td class="blue">48</td>
            <td class="blue">53</td>
            <td class="blue">56</td>
        </tr>
        <tr>
            <td>181-182</td>
            <td>86</td>
            <td class="blue">49</td>
            <td class="blue">54</td>
            <td class="blue">57</td>
        </tr>
        <tr>
            <td>183-186</td>
            <td>88</td>
            <td class="blue">50</td>
            <td class="blue">55</td>
            <td class="blue">59</td>
        </tr>
        <tr>
            <td>187-188</td>
            <td>90</td>
            <td class="blue">51</td>
            <td class="blue">57</td>
            <td class="blue">60</td>
        </tr>
        <tr>
            <td>189-192</td>
            <td>91</td>
            <td class="blue">52</td>
            <td class="blue">57</td>
            <td class="blue">61</td>
        </tr>
        <tr>
            <td>192-200</td>
            <td>94</td>
            <td class="blue">54</td>
            <td class="blue">59</td>
            <td class="blue">63</td>
        </tr>
    </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Chiudi</button>
</mat-dialog-actions>
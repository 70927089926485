import { trigger, transition, query, style, animate, keyframes, stagger, group, animateChild, state } from '@angular/animations';

const fade = [
  transition(':enter', [
      style({ opacity: 0}),
      animate('0.7s ease-in-out', style({ opacity: 1 })),
  ]),
  transition(':leave', [
      animate('0.4s ease-in-out', style({ opacity: 0 }))
  ])
]
export const imageFadeAnimation = trigger('imageFadeAnimation', fade);

const container = [
  transition(':enter', [
      query('@*', animateChild(), { optional: true }),
  ]),
  transition(':leave', [
      query('@*', animateChild(), { optional: true }),
  ])
]
export const containerAnimation = trigger('containerAnimation', container);

const slide = [
  transition(':enter', [
      style({ width:0}),
      animate('1s cubic-bezier(0.85, 0.06, 0.17, 0.95)', style({ width:'100%', zIndex:9})),
  ]),
]
export const slideAnimation = trigger('slideAnimation', slide);


const imageSlide = [
  transition(':enter', [
      style({ transform: 'scale(1.05) translateX(20px)', transformOrigin:'center center'}),
      animate('1s cubic-bezier(0.85, 0.06, 0.17, 0.95)', style({ transform: 'scale(1) translateX(0px)'})),
  ]),
  transition(':leave', [
      animate('1s cubic-bezier(0.85, 0.06, 0.17, 0.95)', style({ opacity: 0 }))
  ])
]
export const imageSlideAnimation = trigger('imageSlideAnimation', imageSlide);

const staggerOpacTrans =    [
    transition('* => *', [
      query(':enter', style({ opacity: 0 }), { optional: true }),

      query(':enter', stagger('100ms', [
        animate('0.3s ease-in-out', keyframes([
          style({ opacity: 0}),
          style({ opacity: 1}),
        ]))]), { optional: true })
    ]),
  ]

  export const staggerOpacTransAnimation = trigger('staggerOpacTransAnimation', staggerOpacTrans);

  const transitionMenu = [
    transition(':enter', [
      style({transform: 'translateY(-100%)'}),
      animate('0.7s cubic-bezier(.65,.04,.38,.96)', style({ transform: 'translateY(0)'})),
      group([
        query('@*', animateChild(), { optional: true }),
      ])
    ]),
    transition(':leave', [
      style({ transform: 'translateY(0)'}),
      animate('0.7s cubic-bezier(.65,.04,.38,.96)', style({ transform: 'translateY(-100%)'})),
      group([
        query('@*', animateChild(), { optional: true }),
      ])
    ])
  ]
  
  export const transitionMenuAnimation = trigger('transitionMenuAnimation', transitionMenu);

  const staggerOpacTransMobile =    [
  transition(':enter', [
    query(':enter', style({ opacity: 0 }), { optional: true }),
    query(':enter', stagger('100ms', [
      animate('0.3s ease-in-out', keyframes([
        style({ opacity: 0}),
        style({ opacity: 1}),
      ]))]), { optional: true })
]),
transition(':leave', [
    animate('0.3s ease-in-out', style({opacity:0 }))
])
]

export const staggerOpacTransAnimationMobile = trigger('staggerOpacTransAnimationMobile', staggerOpacTransMobile);




  
  
  
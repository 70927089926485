<div class="rivenditori__container">
    <div class="rivenditori__left" [@titleEnter]="isEnter">
        <a [@titleEnterText]="isEnter" [routerLink]="['/rivenditori']" class="back">torna ai rivenditori</a>
        <h1 class="intro-page__title orange"><span [@titleEnterSpan]="{value: isEnter, params: {durationAnim: 0.5}}">{{rivenditori.name}}</span></h1>
        <h2 class="intro-page__title"><span [@titleEnterSpan]="{value: isEnter, params: {durationAnim: 0.6}}">{{rivenditori.city}}</span></h2>
        <div class="grid__container grid__container--1" [@titleEnterText]="isEnter">
            <div>
                <p class="orange subtitle">DUE PAROLE SU DI NOI</p>
                <p>{{rivenditori.description}}</p>
            </div>
            <div>
                <p class="orange subtitle subtitle--2">DOVE</p>
                <p>{{rivenditori.city}}</p>
                <p>{{rivenditori.address}}<br><br></p>
                <a href="{{rivenditori.mapUrl}}" target="_blank" class="link-underline">Scopri sulla mappa →</a>
            </div>
        </div>
        <div class="grid__container" [@titleEnterText]="isEnter">
            <div>
                <p class="orange subtitle">CONTATTI</p>
                <p><b><a *ngIf="rivenditori.tel" class="blue" href="tel:{{rivenditori.tel}}">{{rivenditori.tel}}</a></b></p>
                <p><b><a *ngIf="rivenditori.email" class="blue" href="mailto:{{rivenditori.email}}">{{rivenditori.email}}</a></b></p>
            </div>
            <div>
                <p class="orange subtitle">Social</p>
                <p><b><a *ngIf="rivenditori.facebookUrl" class="blue" href="{{rivenditori.facebookUrl}}">facebook</a></b></p>
                <p><b><a *ngIf="rivenditori.instagramUrl" class="blue" href="{{rivenditori.instagramUrl}}">instagram</a></b></p>
            </div>
        </div>
    </div>
    <div class="rivenditori__right">
        <div class="rivenditori__img" [@imgEnter]="isEnter">
            <picture>
                <source sizes="(max-width: 1023px) 80vw, 20vw" type="image/webp" srcset="assets/images/rivenditori/{{rivenditori.img}}_600.webp 600w, assets/images/rivenditori/{{rivenditori.img}}_400.webp 400w">
                <source sizes="(max-width: 1023px) 80vw, 20vw" type="image/jpg" srcset="assets/images/rivenditori/{{rivenditori.img}}_600.jpg 600w, assets/images/rivenditori/{{rivenditori.img}}_400.jpg 400w">
                <img [@imgScale]="isEnter" sizes="(max-width: 1023px) 80vw, 20vw" src="assets/images/rivenditori/{{rivenditori.img}}_600.jpg" srcset="assets/images/rivenditori/{{rivenditori.img}}_600.jpg 600w, assets/images/rivenditori/{{rivenditori.img}}_400.jpg 400w"
                    alt="{{rivenditori.name}}">
            </picture>
        </div>
    </div>
</div>
<app-footer></app-footer>
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { ApiCommands } from "../http/api.commands";
import {API} from './api.constants';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private apiCommands: ApiCommands) { }

  public contactUs(body) {
    return this.apiCommands
      .post(API.CONTACT_US.url, body)
      .pipe(map((res) => res));
  }
}

<ngx-stripe-card [options]="cardOptions" [elementsOptions]="elementsOptions" (change)="onStripeChange($event)"></ngx-stripe-card>
<form [formGroup]="paymentForm">
    <div class="action-float action-float--confirm">
        <div>
            <mat-checkbox formControlName="paymentTerms" required>*Accetto le <a [routerLink]="['/termini-e-condizioni']"
                    class="link-underline">condizioni di
                    vendita</a></mat-checkbox>
            <mat-error *ngIf="f.paymentTerms.invalid && (f.paymentTerms.dirty || f.paymentTerms.touched)">
                campo <strong>obbligatorio</strong>
            </mat-error>
        </div>
        <div class="button__container">
            <div class="button__background2"></div>
            <div class="button__background"></div>
            <button (click)="paymentComplete()" type="submit" class="action__button" [disabled]="!paymentForm.valid || !isStripeValid">Conferma
                <div class="arrow-left"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.14 16.48">
                        <line y1="8.24" x2="19.55" y2="8.24" />
                        <polyline points="11.4 0.5 19.14 8.24 11.4 15.98" /></svg></div>
            </button>
        </div>
    </div>
</form>

import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Address, User } from 'src/app/models/user.model';
import { AddressService } from 'src/app/services/address.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-edit-shipping-addresses',
  templateUrl: './add-edit-shipping-addresses.component.html',
  styleUrls: ['./add-edit-shipping-addresses.component.css']
})
export class AddEditShippingAddressesComponent implements OnInit {
  public addressForm: FormGroup;
  @Input() addressId: string;
  @Input() state: string = 'create';
  @Output() onChange = new EventEmitter();
  // address

  constructor(
    private formBuilder: FormBuilder, 
    private addressService: AddressService, 
    private _snackBar: MatSnackBar,
    private userService: UserService) {
    this.addressForm = formBuilder.group({
      first_name : ['', Validators.required],
      last_name : ['', Validators.required],
      phone: ['', Validators.required],
      address: ['', Validators.required],
      postal_code: ['', Validators.required],
      city: ['', Validators.required],
      province: ['', Validators.required],
      shipping_notes: [''],
    });
   }

  user: User;
  isGuest: any;
  ngOnInit(): void {
    this.userService.getUser().subscribe((user: User) => {
      if(user){
        this.user = user;
        this.isGuest = user.user_type ? false : true;
      }
    })
  }

  @ViewChild('form') form;

  ngOnChanges(changes: SimpleChanges){
    if(changes.state.currentValue){
      if(changes.state.currentValue == 'edit'){
        this.userService.getUser().subscribe((user: User) => {
          if(user){
            this.isGuest = user.user_type ? false : true;
            if(!this.isGuest || localStorage.getItem('isRegisteredGuest') == '1'){
              this.addressService.getAddressesById(changes.addressId.currentValue)
                .subscribe((address: Address) => {
                  this.addressForm.patchValue({...address});
                })
            }
            else if(this.isGuest  && localStorage.getItem('isRegisteredGuest') != '1') {
              let guest_addresses = JSON.parse(localStorage.getItem('guest_addresses'));
              let selectedAddress = guest_addresses.filter(address => address.id == changes.addressId.currentValue)[0]
              this.addressForm.patchValue({...selectedAddress});
            }
          }
        })
      } 
    }
  }

  get f(){
    return this.addressForm.controls;
  }

  close(){
    this.addressForm.reset();
    this.form.resetForm();
    this.onChange.emit('close')
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
        control.markAsTouched();
    });
  }


  onFormSubmit(){
    if (this.addressForm.invalid) {
      this.markFormGroupTouched(this.addressForm);
      return
    }

    let address = this.addressForm.value
    let formData = new FormData();
    formData.append('address',address.address)
    formData.append('postal_code',address.postal_code)
    formData.append('city',address.city)
    formData.append('first_name',address.first_name)
    formData.append('last_name',address.last_name)
    formData.append('phone',address.phone)
    formData.append('province',address.province)
    if(address.shipping_notes) formData.append('shipping_notes',address.shipping_notes);

    if(!this.isGuest || localStorage.getItem('isRegisteredGuest') == '1') {
      if(this.state == 'edit'){
        this.addressService.editAddress(formData, this.addressId).subscribe(res => {
          this._snackBar.open("Indirizzo modificato correttamente", "OK", {});
          this.onChange.emit('edit')
        })
      }
      else {
        this.addressService.addAddress(formData).subscribe(res => {
          this.onChange.emit('create');
          this._snackBar.open("Indirizzo creato correttamente", "OK", {});
        })
      }
    } else if(this.isGuest && localStorage.getItem('isRegisteredGuest') != '1'){
      let guest_addresses = JSON.parse(localStorage.getItem('guest_addresses'));
      let guest_address: any = {}
      guest_address.address = address.address;
      guest_address.city = address.city;
      guest_address.first_name = address.first_name;
      guest_address.last_name = address.last_name;
      guest_address.shipping_notes = address.shipping_notes || '';
      guest_address.phone = address.phone;
      guest_address.postal_code = address.postal_code;
      guest_address.province = address.province;
      if(this.state == 'edit'){
        let addressIndex = guest_addresses.findIndex(address_ => address_.id == this.addressId);

        guest_addresses[addressIndex].address = address.address;
        guest_addresses[addressIndex].city = address.city;
        guest_addresses[addressIndex].first_name = address.first_name;
        guest_addresses[addressIndex].last_name = address.last_name;
        guest_addresses[addressIndex].shipping_notes = address.shipping_notes || '';
        guest_addresses[addressIndex].phone = address.phone;
        guest_addresses[addressIndex].postal_code = address.postal_code;
        guest_addresses[addressIndex].province = address.province;
        
        localStorage.setItem('guest_addresses', JSON.stringify(guest_addresses));
        this._snackBar.open("Indirizzo modificato correttamente", "OK", {
          duration: 5000
        });
        this.onChange.emit('edit');
      } else {
        guest_address.id = parseInt(guest_addresses[guest_addresses.length - 1].id) + 1;
        guest_address.primary_address = '0';
        guest_addresses.push(guest_address);
        localStorage.setItem('guest_addresses', JSON.stringify(guest_addresses));
        this.onChange.emit('create');
        this._snackBar.open("Indirizzo creato correttamente", "OK", {
          duration: 5000
        });
      } 
    } else {
      if(this.state == 'edit'){
        this.addressService.editAddress(formData, this.addressId).subscribe(res => {
          this._snackBar.open("Indirizzo modificato correttamente", "OK", {
            duration: 5000
          });
          this.onChange.emit('edit')
        })
      }
      else {
        this.addressService.addAddress(formData).subscribe(res => {
          this.onChange.emit('create');
          this._snackBar.open("Indirizzo creato correttamente", "OK", {
            duration: 5000
          });
        })
      }
    }
  }
}

import { animate, animateChild, group, query, state, style, transition, trigger } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppRoutingTransitionService } from 'src/app/services/app-routing-transition.service';
import { ContactService } from 'src/app/services/contact.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css'],
  animations: [
    trigger('titleEnter', [
      state('false',
        style({
          opacity: 0
        })
      ),
      transition('false => true', [
        animate('0.3s cubic-bezier(0.55, 0.31, 0.15, 0.93)'),
        group([
          query('@titleEnterSpan', animateChild(), { optional: true }),
        ]),
        group([
          query('@titleEnterText', animateChild(), { optional: true }),
        ])
      ]),
    ]),
    trigger('titleEnterSpan', [
      transition('false => true', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('{{durationAnim}}s cubic-bezier(.6,.36,.07,.99)', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
    ]),
    trigger('titleEnterText', [
      transition('false => true', [
        style({ opacity: 0 }),
        animate('0.3s ease-in', style({ opacity: 1 })),
      ]),
    ]),
    trigger('imgEnter', [
      state('false',
        style({
          opacity: 0,
          transform: 'translateY(15vw)',
        })
      ),
      transition('false => true', [
        animate('1s cubic-bezier(.72,.01,.07,1)'),
        query('@imgScale', animateChild({ duration: 1 }), { optional: true }),
      ]),
    ]),
    trigger('imgScale', [
      state('false',
        style({
          transform: 'scale(1)'
        })
      ),
      transition('false => true', [
        style({
          transform: 'scale(1.25)'
        }),
        animate('1s cubic-bezier(.72,.01,.07,1)')
      ]),
    ]),
    trigger('sectionEnter', [
      state('false',
      style({backgroundColor: 'white'})
    ),
      transition('false => true', [
        animate('1s cubic-bezier(.6,.36,.07,.99)',
        style({ backgroundColor: '#eef0f0' })),
        group([
          query('@sectionItemEnter', animateChild(), { optional: true }),
        ])
      ]),
    ]),
    trigger('sectionItemEnter', [
      state('false',
      style({ opacity: 0 }),
    ),
      transition('false => true', [
        animate('0.3s ease-in', style({ opacity: 1 })),
      ]),
    ]),
  ]
})
export class ContactsComponent implements OnInit {
  public contactForm: FormGroup;
  public isEnter: boolean = false;
  isBrowser;
  myEventSubscription;
  @ViewChild('form') form;
  isLoading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(PLATFORM_ID) platformId: string, 
    private appService: AppRoutingTransitionService,
    private contactService: ContactService,
    private _snackBar: MatSnackBar,
    private cd: ChangeDetectorRef) { 
    this.isBrowser = isPlatformBrowser(platformId);

    this.contactForm = formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
      type: ['', Validators.required],
      message: ['', Validators.required],
      privacyFlag: [false, Validators.requiredTrue]
    })
  }

  get f(){
    return this.contactForm.controls;
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
    });
  }

  ngOnInit(): void {
    if (!this.isBrowser) {
      return;
    }
    this.myEventSubscription = this.appService.stringSubject.subscribe(() => {
        this.isEnter = true;
      }
    );
  }


  sendContactForm(){
    if (this.contactForm.invalid) {
      this.markFormGroupTouched(this.contactForm);
      return;
    }
    this.isLoading = true;
    let data = this.contactForm.value
    const formData = new FormData();

    formData.append('first_name', data.first_name)
    formData.append('last_name', data.last_name)
    // formData.append('request_type', data.type)
    formData.append('email', data.email)
    formData.append('message', data.message)
    formData.append('accept_privacy', '1')
    formData.append('source', 'contact_us')
    formData.append('request_type', data.type)

    this.contactService.contactUs(formData).subscribe(res => {
      this.form.resetForm();
      (<any>window).dataLayer.push({
        event:'contact_form',
        attributes: {
          name: data.type,
        }
      });
      this._snackBar.open("Messaggio inviato correttamente", 'OK', {
        duration: 5000
      });
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    })
  }
}
export interface ProductCategoryModel {
    id: string;    
    name: string;
    description: string;
    img?: string;
};

const shopCategoryImages = {
    1: 'url(assets/images/cursor/biciclette.png)',
    2: 'url(assets/images/cursor/ebike.png)',
    3: 'url(assets/images/cursor/accessori.png)',
    4: 'url(assets/images/cursor/componenti.png)',
    5: 'url(assets/images/cursor/abbigliamento.png)'
  }

export class ProductCategory implements ProductCategoryModel {
    id: string;    
    name: string;
    description: string;
    img?: string;
    
	constructor(category: ProductCategoryModel) {
        this.img = shopCategoryImages[category.id]
        if(category) Object.assign(this, category);
	}
}
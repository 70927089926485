import { animate, animateChild, group, query, state, style, transition, trigger } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AppRoutingTransitionService } from 'src/app/services/app-routing-transition.service';
import {rivenditori} from '../../constants/rivenditori'

@Component({
  selector: 'app-rivenditori',
  templateUrl: './rivenditori.component.html',
  styleUrls: ['./rivenditori.component.css'],
  animations: [
    trigger('titleEnter', [
      state('false',
        style({
          opacity: 0
        })
      ),
      transition('false => true', [
        animate('0.3s cubic-bezier(0.55, 0.31, 0.15, 0.93)'),
        group([
          query('@titleEnterSpan', animateChild(), { optional: true }),
        ]),
        group([
          query('@titleEnterText', animateChild(), { optional: true }),
        ])
      ]),
    ]),
    trigger('titleEnterSpan', [
      transition('false => true', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('{{durationAnim}}s cubic-bezier(.6,.36,.07,.99)', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
    ]),
    trigger('titleEnterText', [
      transition('false => true', [
        style({ opacity: 0 }),
        animate('0.3s ease-in', style({ opacity: 1 })),
      ]),
    ]),
    trigger('sectionEnter', [
      state('false',
      style({backgroundColor: 'white'})
    ),
      transition('false => true', [
        animate('1s cubic-bezier(.6,.36,.07,.99)',
        style({ backgroundColor: '#eef0f0' })),
        group([
          query('@sectionItemEnter', animateChild(), { optional: true }),
        ])
      ]),
    ]),
    trigger('sectionItemEnter', [
      state('false',
      style({ opacity: 0 }),
    ),
      transition('false => true', [
        animate('0.3s ease-in', style({ opacity: 1 })),
      ]),
    ]),
  ]
})
export class RivenditoriComponent implements OnInit {
  rivenditori = rivenditori;
  public isEnter: boolean = false;
  isBrowser;
  myEventSubscription;
  constructor(@Inject(PLATFORM_ID) platformId: string, private appService: AppRoutingTransitionService) {
    this.isBrowser = isPlatformBrowser(platformId);
   }

  ngOnInit(): void {
    if (!this.isBrowser) {
      return;
    }
    this.myEventSubscription = this.appService.stringSubject.subscribe(() => {
        this.isEnter = true;
      }
    );
  }

}

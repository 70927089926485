import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { ApiCommands } from "../http/api.commands";
import { CartItem } from '../models/cart.model';
import { API } from './api.constants';
import { ShopService } from './shop.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private cartItems: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public isOnCheckoutProcess: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  constructor(private apiCommands: ApiCommands, private shopService: ShopService) { }

  public setCartItems(items){
    items = items.map(item => new CartItem(item, this.shopService.categories.value, this.shopService.subcategories.value))
    this.cartItems.next(items);
  }
  
  public getCartItems(): Observable<any> {
    return this.cartItems.pipe(map(cart => cart));
  }

  public isOnCheckoutProcessValue(): Observable<boolean> {
    return this.isOnCheckoutProcess.asObservable().pipe(share());
  }

  public getCart() {
    return this.apiCommands
      .get(API.CART.url, this.getToken())
      .pipe(map((res) => res));
  }

  public deleteCart() {
    return this.apiCommands
      .delete(API.CART.url, this.getToken())
      .pipe(map((res) => res));
  }

  public addToCart(body) {
    return this.apiCommands
      .post(API.CART.url, body, this.getToken())
      .pipe(map((res) => res));
  }

  public updateCart(id, body) {
      return this.apiCommands
        .post(API.CART.url + '/' + id, body, this.getToken())
        .pipe(map((res) => res));
  }

  public deleteCartItem(id){
    return this.apiCommands
      .delete(API.CART.url + '/' + id, this.getToken())
      .pipe(map((res) => res));
  }

  public checkDiscountCode(body){
    return this.apiCommands
    .post(API.CHECK_DISCOUNT.url, body, this.getToken())
    .pipe(map((res) => res));
  }

  public mergeCarts(body){
    return this.apiCommands
    .post(API.MERGE_CARTS.url, body, this.getToken())
    .pipe(map((res) => res));
  } 

  private getToken() {
    return `${localStorage.getItem("zecchini_token")}`;
  }
}

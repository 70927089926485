import { environment } from "src/environments/environment";
import { ShopService } from "../services/shop.service";

export interface ProductModel {
    id: string;    
    product_category_id: string;
    product_subcategory_id: string;
    danea_internal_id: string;
    code: string;
    title: string;
    description: string;
    vat: string;
    net_price: string;
    gross_price: any;
    supplier_code: string;
    supplier_name: string;
    supplier_net_price: string;
    supplier_gross_price: string;
    um: string;
    size_um: string;
    weight_um: string;
    producer_name: string;
    available_quantity: string;
    notes: string;
    cs_field_1: string;
    cs_field_2: string;
    cs_field_3: string;
    cs_field_4: string;
    subcategory_label?: string;
    category_label?: string;
    product_image?: string;
    product_images?: any[];
    variants?: Variants;
    filtered_variants?: Variants;
    quantity?: any;
    product_variants: ProductVariant[];
    related_products: Product[];
    related_accessories: Product[];
    price: any;
    show_shop: any;
    shipping_fee: any;
};

interface Variants {
    sizes?: any[];
    colors?: any[];
}

export interface ProductVariant {
    available_quantity: string;
    barcode: string;
    color: string;
    id: string;
    product_id: string;
    size: string;
    product_variant_images: any[];
}

interface Variant {
    item: string;
    disabled: boolean;
}

const assetsUrl = environment.assetsUrl;

export class Product implements ProductModel {
    id: string;    
    product_category_id: string;
    product_subcategory_id: string;
    danea_internal_id: string;
    code: string;
    title: string;
    description: string;
    vat: string;
    net_price: string;
    gross_price: any;
    supplier_code: string;
    supplier_name: string;
    supplier_net_price: string;
    supplier_gross_price: string;
    um: string;
    size_um: string;
    weight_um: string;
    producer_name: string;
    available_quantity: string;
    notes: string;
    cs_field_1: string;
    cs_field_2: string;
    cs_field_3: string;
    cs_field_4: string;
    subcategory_label?: string;
    category_label?: string;
    product_image?: string;
    product_images?: any[];
    variants?: Variants;
    filtered_variants?: Variants;
    quantity?: any;
    product_variants: ProductVariant[];
    related_products: Product[];
    related_accessories: Product[];
    price: any;
    show_shop: any;
    shipping_fee: any;
    discount: Number;
    
	constructor(product: ProductModel, categories, subcategories) {
        if(product?.product_images && product?.product_images.length > 0 && product?.product_images[0].file_name_noext){
            this.product_image = assetsUrl + product.product_images[0].file_name_noext;
        }
        this.subcategory_label = subcategories[product.product_subcategory_id];
        this.category_label = categories[product.product_category_id];
        if(product) Object.assign(this, product);
        this.quantity = 1;
        if(product.gross_price){
            this.price = parseFloat(product.gross_price);
        }
	}
}
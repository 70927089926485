import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiCommands } from '../http/api.commands';
import { ProductCategory, ProductCategoryModel } from '../models/product-category.model';
import { Product } from '../models/product.model';
import {API} from './api.constants';

export interface ProductsParams {
  category?: string;
  categoryId?: string;
  subcategory?: string;
  min_price?: string;
  max_price?: string;
  page?: any;
  size?: string;
  per_page?: any;
  sortField?: any;
  sortOrder?: any;
}

export interface ProductFilterParams {
  size?: string;
  color?: string;
}

@Injectable({
  providedIn: 'root'
})

export class ShopService {

  constructor(private apiCommands: ApiCommands) { }
  public categories: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public subcategories: BehaviorSubject<any> = new BehaviorSubject<any>({});

  public getProductCategories(){
    return this.apiCommands
      .get<ProductCategory[]>(API.PRODUCT_CATEGORIES.url, this.getToken())
      // .pipe(map((category: any) => new ProductCategory(category)));
      .pipe(
        map(data => {
          return data.map((category: any) => {
            return new ProductCategory({
              id: category['id'],
              name: category['name'],
              description: category['description']
            })
          })
        })
      )
  }

  public getProductSubCategories(){
    return this.apiCommands
      .get(API.PRODUCT_SUBCATEGORIES.url, this.getToken())
      .pipe(map((res) => res));
  }

  public getBestSellingProducts(){
    return this.apiCommands
      .get(API.BEST_SELLING_PRODUCTS.url, this.getToken())
      .pipe(map((res) => res));
  }

  public getProducts(obj: ProductsParams){
    let params = "?";
    if(obj.category) params += 'product_category_id=' + obj.category + '&';
    if(obj.min_price) params += 'min_price=' + obj.min_price + '&';
    if(obj.max_price) params += 'max_price=' + obj.max_price + '&';
    if(obj.size) params += 'cs_field_1=' + obj.size + '&';
    if(obj.subcategory) params += 'product_subcategory_id=' + obj.subcategory + '&';
    if(obj.per_page) params += 'per_page=' + obj.per_page + '&';
    if(obj.sortField) params += 'sortField=' + obj.sortField + '&';
    if(obj.sortOrder) params += 'sortOrder=' + obj.sortOrder + '&';
    if(obj.page) params += 'page=' + obj.page;
    return this.apiCommands
      .get(API.PRODUCTS.url + params, this.getToken())
        .pipe(map((res) => res));
  }


  public getProductById(id, obj: ProductFilterParams = {}){
    let params = "?";
    if(obj.color) params += 'color=' + obj.color + '&';
    if(obj.size) params += 'size=' + obj.size + '&';
    return this.apiCommands
      .get(API.PRODUCTS.url + '/' + id + params, this.getToken())
        .pipe(map((res) => res));
  }


  private getToken() {
    return `${localStorage.getItem("zecchini_token")}`;
  }
}

import { Component, Inject, OnInit, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { Product } from 'src/app/models/product.model';
import { CartService } from 'src/app/services/cart.service';
import { GoogleMicrodataService } from 'src/app/services/google-microdata.service';
import { ShopService } from 'src/app/services/shop.service';
import { SeoService } from 'src/app/services/seo.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { SizeDialogComponent } from '../../shared/size-dialog/size-dialog.component';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent implements OnInit {
  prodotti: any;
  product: Product;
  urlSite: string;
  isBrowser;

  slideConfig = {
    autoplay: false,
    slidesToShow: 1,
    arrows: false,
    dots: true,
  };

  isLoggedIn: boolean = false;
  selectedSize: string = '';
  selectedColor: string = '';
  productId: string;
  assetsUrl = environment.assetsUrl;
  loading: boolean = false;
  singleDiscount = 0;
  discountAmount: any;
  @ViewChild('slickModal') slider: SlickCarouselComponent;

  sliderImages: any[] = [];
  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    private route: ActivatedRoute, 
    private router: Router,
    private seoService: SeoService, 
    private cartService: CartService,
    private userService: UserService,
    public dialog: MatDialog,
    private shopService: ShopService,
    private _snackBar: MatSnackBar,
    private GoogleMicrodataService: GoogleMicrodataService,
    private renderer2: Renderer2,
    private meta: Meta) {
      this.router.onSameUrlNavigation = 'reload';
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.isBrowser = isPlatformBrowser(platformId);
    }

   openDialog(): void {
    const dialogRef = this.dialog.open(SizeDialogComponent, {
      width: '80%',
    });
  }

  // slickInit(e) {
  //   console.log(e);
  // }

  ngOnDestroy(): void {
    // this.slider.initSlick();
  }

  ngOnInit(): void {  
    this.route.params.subscribe(params => {
      this.productId = params.prodottoId; 
      this.getProduct(params.prodottoId);
    });
    this.userService.isLoggedIn().subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
    });
  }

  changeQuantity(type){
    if(!this.selectedColor || !this.selectedSize){
      this._snackBar.open(`Seleziona una taglia e un colore`, 'OK', {
        duration: 5000,
      });
      return;
    }
    
    if(type == 'increase' && this.availableQuantity == this.product?.quantity){
      this._snackBar.open(`La quantità massima è ${this.availableQuantity}`, 'OK', {
        duration: 5000,
      });
    }
    if(type == 'increase' && this.availableQuantity > this.product?.quantity){
      this.product.quantity++;
    } else if(type == 'decrease' && this.product?.quantity > 1){
      this.product.quantity--;
    }
  }

  text:any;
  getProduct(id){
    this.shopService.getProductById(id).subscribe((product: Product) => {
      if(product.show_shop == '0'){
        this.router.navigate(['/'])
      } else {
        this.sliderImages = [];
        product = new Product(product, this.shopService.categories.value, this.shopService.subcategories.value);

        this.singleDiscount = +product?.discount;
        this.discountAmount = (product?.price * product?.quantity)*(this.singleDiscount/100);
        // console.log(this.discountAmount, product?.price, product?.quantity, this.singleDiscount/100);

        product?.product_images.map(product_image => {
          this.sliderImages.push({id: product_image.id, url: this.assetsUrl + product_image.file_name_noext})
        });
        product?.product_variants.map(product_variant => {
          product_variant.product_variant_images.map(product_variant_image => {
            this.sliderImages.push({id: product_variant_image.id, url: this.assetsUrl + product_variant_image.file_name_noext})
          })
        });
        
        this.seoService.updateTitle(product.title);
        this.seoService.updateMetaTags([
          { name: 'description', content: product?.notes },
          { property: 'og:title', content: "Zecchini - " + product?.title },
          { property: 'og:description', content: product?.notes+'.' },
          { property: 'og:url', content:  environment.apiUrl + "prodotto/" + this.productId },
          { property: 'og:image', content: this.sliderImages[0].url ? this.sliderImages[0].url + '_1500.jpg' : ''},
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: 'Zecchini' },
          { property: 'fb:app_id', content: '141377273234274' },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:site", content: "Zecchini" },
          { name: "twitter:title", content: "Zecchini - " + product?.title },
          { name: "twitter:image", content: this.sliderImages[0].url + '_1500.jpg' },
          { name: "twitter:description", content: product?.notes }
        ]);

        let microdata = {
          "@context": "https://schema.org",
          "@type": "Product",
          "image": this.sliderImages[0].url ? this.sliderImages[0].url + '_800.jpg' : '',
          "url": `${environment.siteUrl}prodotto/${id}`,
          "name": product?.title,
          "description": product?.notes,
          "category": product?.category_label,
          "brand": product?.producer_name,
          "offers": {
            "@type": "Offer",
            "price": product?.gross_price,
            "priceCurrency": "EUR",
          },
        };
        
        // product.related_products.map(item => new Product(item, this.shopService.categories.value, this.shopService.subcategories.value))
        // product.related_accessories.map(item => new Product(item, this.shopService.categories.value, this.shopService.subcategories.value))
        
        // if (!this.isBrowser) {
        //   return;
        // }
        // const parser = new DOMParser(); 
        // let text:any = parser.parseFromString(product?.description, 'text/html');
        // console.log(text);
        // this.text = text.children[0].children[1].innerHTML.toString();
       
        this.product = new Product(product, this.shopService.categories.value, this.shopService.subcategories.value);
  
        this.product.related_accessories = product?.related_accessories.map(product => new Product({...product}, this.shopService.categories.value, this.shopService.subcategories.value));
        this.product.related_products = product?.related_products.map(product => new Product({...product}, this.shopService.categories.value, this.shopService.subcategories.value));
        this.product.variants.colors = product?.variants.colors.map((color) => ({item: color, disabled: false}))
        this.product.variants.sizes = product?.variants.sizes.map((size:string) => ({item: size, disabled: false}))

        this.GoogleMicrodataService.setJsonLd(this.renderer2, microdata)

        if (!this.isBrowser) {
          return;
        }
        this.viewItemDataLayer(product);
  
        if(product?.related_products && product?.related_products.length > 0){
          let relatedProductsDataLayer = [];

          product?.related_products.map((product: Product, index) => {
            product = new Product(product, this.shopService.categories.value, this.shopService.subcategories.value);
            relatedProductsDataLayer.push(
              {
                item_name: product?.title, // Name or ID is required.
                item_id: product?.id, // Name or ID is required.
                price: product?.gross_price,
                item_brand: product?.producer_name,
                item_category: product?.category_label,
                item_category_2: product?.subcategory_label, // The categories can be up to 4
                // item_variant: 'Silver',
                // item_list_name: 'Related list', // If associated with a list selection.
                // item_list_id: 'RL1', // If associated with a list selection.
                // index: index + 1, // If associated with a list selection.
                item_discount: product?.discount,
                quantity: 1
               },
            )
          });
          switch(product?.category_label) {
            case 'Biciclette':  this.urlSite = 'biciclette';
            break;
            case 'E-bike':  this.urlSite = 'ebike';
            break;
            case 'Accessori':  this.urlSite = 'accessori-bici';
            break;
            case 'Componenti':  this.urlSite = 'componenti-bici';
            break;
            case 'Abbigliamento':  this.urlSite = 'abbigliamento-bici';
          }
          this.viewItemListDataLayer(relatedProductsDataLayer)
        }
        if(product?.related_accessories && product?.related_accessories.length > 0){
          let relatedAccssoriesDataLayer = [];
          product?.related_accessories.map((product: Product, index) => {
            product = new Product(product, this.shopService.categories.value, this.shopService.subcategories.value);
            relatedAccssoriesDataLayer.push(
              {
                item_name: product?.title, // Name or ID is required.
                item_id: product?.id, // Name or ID is required.
                price: product?.gross_price,
                item_brand: product?.producer_name,
                item_category: product?.category_label,
                item_category_2: product?.subcategory_label, // The categories can be up to 4
                // item_variant: 'Silver',
                // item_list_name: 'Related list', // If associated with a list selection.
                // item_list_id: 'RL1', // If associated with a list selection.
                // index: index + 1, // If associated with a list selection.
                quantity: 1
               },
            )
          });
          this.viewItemListDataLayer(relatedAccssoriesDataLayer)
        }
        setTimeout(() => {
          this.slider.slickGoTo(0);
          this.slider.unslick = ()=>{};
        }, 0);
      }
    }, err => {
      this.router.navigate(['/'])
    })
  }

  availableQuantity: any;
  availableVariants: any = [];
  showSlider = false;
  selectItem(item, type){
    if(type == 'color'){
      if(this.selectedColor == item) this.selectedColor = '';
      else this.selectedColor = item;
    }
    else if(type == 'size'){
      if(this.selectedSize == item) this.selectedSize = '';
      else this.selectedSize = item;
    }
    this.shopService.getProductById(this.productId, {color: this.selectedColor, size: this.selectedSize})
      .subscribe((product: Product) => {
        this.product.quantity = 1;
        this.product.filtered_variants = product?.filtered_variants;
        this.availableVariants = product?.product_variants;
        // if(product?.filtered_variants.colors.length == 0){ // reset colors
        //   this.product?.variants.colors = this.product?.variants.colors.map(color => ({item: color.item, disabled: false}))
        // } else {
        //   this.product?.variants.colors = this.product?.variants.colors.map(color => ({item: color.item, disabled: false})) //reset colors
        //   let colorsToDisable = [...this.product?.variants.colors].filter(color => {
        //     return !product?.filtered_variants.colors.some(f_color => color.item == f_color)
        //   }); 
          
        //   this.product?.variants.colors.map(color => { //disable colors
        //     colorsToDisable.map(d_color => {
        //       if(d_color.item == color.item){
        //         color.disabled = true
        //       }
        //     })
        //   })
        // }

        // if(product?.filtered_variants.sizes.length == 0){// reset sizes
        //   this.product?.variants.sizes = this.product?.variants.sizes.map(size => ({item: size.item, disabled: false}))
        // } else {
        //   this.product?.variants.sizes = this.product?.variants.sizes.map(size => ({item: size.item, disabled: false}))// reset sizes
          
        //   let sizesToDisable = [...this.product?.variants.sizes].filter(size => {
        //     return !product?.filtered_variants.sizes.some(f_size => size.item == f_size)
        //   });// get sizes for disable
          
        //   this.product?.variants.sizes.map(size => {//disable sizes
        //     sizesToDisable.map(d_size => {
        //       if(d_size.item == size.item){
        //         size.disabled = true
        //       }
        //     })
        //   })
        // }

        if(this.selectedColor && this.selectedSize){
          this.availableQuantity = product?.product_variants[0].available_quantity;
        }

        if(type == 'color'){ 
          let sliderImages = [];
          product?.product_variants.map(product_variant => {
            product_variant.product_variant_images.map(product_variant_image => {
              sliderImages.push(product_variant_image)
            })
          }) 
          if(sliderImages.length > 0){
            this.slider.slickGoTo(this.sliderImages.findIndex(sliderImage => sliderImage.id == sliderImages[0].id))
          }
        }
    })
  }
  // "per acquistare seleziona taglia e colore"
  addToCart(){
    if(!this.selectedSize || !this.selectedColor){
      this._snackBar.open(`Per acquistare seleziona taglia e colore`, 'OK', {
        duration: 5000,
      });
      return;
    }

    let formData = new FormData();
    formData.append('product_id', this.product?.id);
    formData.append('product_variant_id', this.availableVariants[0].id);
    formData.append('quantity', this.product?.quantity);

    this.cartService.addToCart(formData).subscribe(res => {
      this.addToCartDataLayer(this.product)
      this._snackBar.open(`Il prodotto ${this.product?.title} è stato aggiunto al carrello`, 'OK', {
        duration: 5000,
      });
      this.cartService.getCart().subscribe(cartItems => {
        this.cartService.setCartItems(cartItems);
      })
    }, err => {
      this._snackBar.open(err.error.message, 'OK', {
        duration: 5000,
      });
    })
  }

  viewItemDataLayer(product: Product){
    if (!this.isBrowser) {
      return;
    }
    (<any>window).dataLayer.push({
      event: 'view_item',
      ecommerce: {
        items: [{
          item_name: product?.title, // Name or ID is required.
          item_id: product?.id,  // Name or ID is required.
          price: product?.gross_price,
          item_brand: product?.producer_name,
          item_category: product?.category_label,
          item_category_2: product?.subcategory_label, // The categories can be up to 4
          // item_variant: '',
          // item_list_name: '',  // If associated with a list selection.
          // item_list_id: '',  // If associated with a list selection.
          // index: 1,  // If associated with a list selection.
          // quantity: product?.available_quantity
          quantity: 1
        }]
      }
    });
  }

  addToCartDataLayer(product: Product){
    (<any>window).dataLayer.push({
      event: 'add_to_cart',
      ecommerce: {
        items: [{
          item_name: product?.title, // Name or ID is required.
          item_id: product?.id,  // Name or ID is required.
          price: ((product?.price * product?.quantity)-(product?.price * product?.quantity*(+product?.discount/100))).toFixed(2), // DISCOUNT ADD HERE?
          item_brand: product?.producer_name,
          item_category: product?.category_label,
          item_category_2: product?.subcategory_label, // The categories can be up to 4
          item_variant: this.availableVariants[0],
          // item_list_name: '',  // If associated with a list selection.
          // item_list_id: '',  // If associated with a list selection.
          // index: 1,  // If associated with a list selection.
          quantity: product?.quantity
        }]
      }
    });
  }

  selectItemDataLayer(product: Product){
    (<any>window).dataLayer.push({
      event: 'select_item',
      ecommerce: {
        items: [{
          item_name: product?.title, // Name or ID is required.
          item_id: product?.id,  // Name or ID is required.
          price: product?.gross_price,
          item_brand: product?.producer_name,
          item_category: product?.category_label,
          item_category_2: product?.subcategory_label, // The categories can be up to 4
          item_variant: this.availableVariants[0],
          item_list_name: 'Related list',  // If associated with a list selection.
          // item_list_id: '',  // If associated with a list selection.
          // index: 1,  // If associated with a list selection.
          quantity: 1
        }]
      }
    });
  }

  viewItemListDataLayer(products: Product[] = []){
    (<any>window).dataLayer.push({
      event: 'view_item_list',
      ecommerce: {
        items: products
      }
    });
  }

}


// addToCart(){
//   this.cartService.getCart().subscribe((cart: CartItem[]) => {
//     let cartPorduct = cart.filter(cartItem => cartItem.product_id == this.product.id && cartItem.product_variant_id == this.availableVariants[0].id);
//     console.log(cartPorduct);
//     console.log(this.product);
//     if(cartPorduct && cartPorduct.length > 0){
//       // console.log(cartPorduct[0].quantity - this.product.quantity);
//       console.log((this.product.quantity + parseInt(cartPorduct[0].quantity)));
//       console.log(parseInt(cartPorduct[0].product_variant.available_quantity));
//       if((this.product.quantity + parseInt(cartPorduct[0].quantity)) > parseInt(cartPorduct[0].product_variant.available_quantity)){
//         this._snackBar.open(`Block`, 'OK', {
//           duration: 5000,
//         });
//       } else this.init()
//     } else {
//       this.init();
//     }
//   })   
// }

//  init() {
//   if(!this.selectedSize || !this.selectedColor){
//     this._snackBar.open(`Per acquistare seleziona taglia e colore`, 'OK', {
//       duration: 5000,
//     });
//     return;
//   }

//   let formData = new FormData();
//   formData.append('product_id', this.product?.id);
//   formData.append('product_variant_id', this.availableVariants[0].id);
//   formData.append('quantity', this.product?.quantity);

//   this.cartService.addToCart(formData).subscribe(res => {
//     this.addToCartDataLayer(this.product)
//     this._snackBar.open(`Il prodotto ${this.product?.title} è stato aggiunto al carrello`, 'OK', {
//       duration: 5000,
//     });
//     this.cartService.getCart().subscribe(cartItems => {
//       this.cartService.setCartItems(cartItems);
//     })
//   }, err => {
//     this._snackBar.open(err.error.message, 'OK', {
//       duration: 5000,
//     });
//   })
// }

import { isPlatformBrowser } from "@angular/common";
import {
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Address, User } from "src/app/models/user.model";
import { UserService } from "src/app/services/user.service";
// import * as Bowser from "bowser";
import { AddressService } from "src/app/services/address.service";
import { Router } from "@angular/router";
import { CartService } from "src/app/services/cart.service";
import { CartItem } from "src/app/models/cart.model";
import { MatDialog } from "@angular/material/dialog";
import { ErrorDialogComponent } from "src/app/shared/components/error-dialog/error-dialog.component";
import { environment } from "src/environments/environment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { shippingFee } from "src/app/constants/shipping-fee";
import { Product } from "src/app/models/product.model";
import { AccessoriesShippingFeesComponent } from "src/app/shared/accessories-shipping-fees/accessories-shipping-fees.component";
import { BikeShippingFeesComponent } from "src/app/shared/bike-shipping-fees/bike-shipping-fees.component";

@Component({
  selector: "app-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.css"],
})
export class CheckoutComponent implements OnInit {
  public invoiceForm: FormGroup;
  public shippingForm: FormGroup;
  activeView: string = "info";
  windowWidth: number = window.innerWidth;
  isMobile: boolean = false;
  browser;
  isBrowser;

  @ViewChild("step2") step2;
  @ViewChild("step3") step3;

  shippingFee = shippingFee;

  user: User;
  isGuest: any;
  orderItems:any = [];
  analyticsOrderItems:any = [];
  discountCode: string = '';
  buyerDetails: any = {}

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private router: Router,
    @Inject(PLATFORM_ID) platformId: string,
    private addressService: AddressService,
    private cartService: CartService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar

  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  
  ngOnInit(): void {
    this.userService.getProfile().subscribe((user:User) => {
      if(user){
        let isGuest = user.user_type ? false : true;
        if(isGuest && !localStorage.getItem('guest_user') && user.registration_guest != '1') {
          this.router.navigate(['/'])
        }
      }
    })
    this.getCart();
    this.appendDiscountCode();
    this.getInformations();
  }

  getInformations(){
    this.userService.getUser().subscribe((user: User) => {
      if (user.id) {
        this.isGuest = user.user_type ? false : true;
        if(!this.isGuest || localStorage.getItem('isRegisteredGuest') == '1'){
          this.user = user;
          this.addressService.getAddresses().subscribe((addresses: any) => {
            // this.createBuyerDetails(user, addresses);
            let otherAddresses = [];
            addresses.map((address) => {
              if (address.primary_address == "1") this.primaryAdrress = address;
              else otherAddresses.push(address);
            });
            this.addresses = otherAddresses;
          });
        } else if(this.isGuest && localStorage.getItem('isRegisteredGuest') != '1') {
          let guest_user: User = JSON.parse(localStorage.getItem('guest_user'))
          let guest_addresses = JSON.parse(localStorage.getItem('guest_addresses'));
          this.user = guest_user;
          // this.createBuyerDetails(guest_user, guest_addresses);
          let guest_address = JSON.parse(localStorage.getItem('guest_addresses'));
          let otherAddresses = [];
          guest_address.map((address) => {
            if (address.primary_address == "1") this.primaryAdrress = address;
            else otherAddresses.push(address);
          });
          this.addresses = otherAddresses;  
        }
      } 
      else {
        this.router.navigate(['/'])
      }
    });
  }

  mobileDetect() {
    if (this.windowWidth < 1023) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.windowWidth = window.innerWidth;
    this.mobileDetect();
  }

  get f() {
    return this.invoiceForm.controls;
  }

  get f2() {
    return this.shippingForm.controls;
  }

  changeView(view) {
    if(view =='payment'){
      if(this.cart.length == 0) {
        this._snackBar.open(`Il carrello è vuoto`, 'OK', {
          duration: 5000,
        });
        return;
      }
    }
    window.scroll(0, 0);
    this.activeView = view;
  }

  shippingComplete() {
    window.scroll(0, 0);
    this.activeView = "info";
    //   (<any>window).dataLayer.push({
    //     event: 'add_shipping_info',
    //     ecommerce: {
    //       shipping_tier: 'SDA', //required
    //       items: [{
    //         item_name: 'Cinelli Zydeco 2021',
    //         item_id: '67890',
    //         price: '1890.00',
    //         item_brand: 'Cinelli',
    //         item_category: 'Bike',
    //         item_category_2: 'Gravel',
    //         item_variant: 'Black',
    //         quantity: '1'
    //       }]
    //     }
    // });
  }

  //shipping addresess
  primaryAdrress: Address;
  addresses: Address[];

  selectedAddressId: number;
  state: any;

  assetsUrl = environment.assetsUrl;

  goToEditAddressScreen(selectedAddressId) {
    this.selectedAddressId = selectedAddressId;
    this.changeView("shipping");
    this.state = "edit";
  }

  onShippingAddressChange(change) {
    this.state = '';
    this.changeView("info");
    this.getAddresses();
  }

  goToCreateAddress() {
    this.changeView("shipping");
    this.state = "create";
  }

  makePrimary(id) {
    if(!this.isGuest || localStorage.getItem('isRegisteredGuest') == '1'){
      this.addressService.changePrimaryAddress(id).subscribe((res) => {
        this.getAddresses();
      });
    } else if(this.isGuest && localStorage.getItem('isRegisteredGuest') != '1'){
      let guest_addresses = JSON.parse(localStorage.getItem('guest_addresses'));
      let addressIndex = guest_addresses.findIndex(address_ => address_.id == id);
      let prevPrimaryAddress = guest_addresses.findIndex(address_ => address_.primary_address == '1');
      guest_addresses[addressIndex].primary_address = '1';
      guest_addresses[prevPrimaryAddress].primary_address = '0';
      localStorage.setItem('guest_addresses', JSON.stringify(guest_addresses));
      this.getAddresses();
    }
  }

  getAddresses() {
    if(!this.isGuest || localStorage.getItem('isRegisteredGuest') == '1'){
      this.addressService.getAddresses().subscribe((addresses: any) => {
        let otherAddresses = [];
        addresses.map((address) => {
          if (address.primary_address == "1") this.primaryAdrress = address;
          else otherAddresses.push(address);
        });
        this.addresses = otherAddresses;
        // this.createBuyerDetails(this.user, this.addresses);
      });
    } else if(this.isGuest && localStorage.getItem('isRegisteredGuest') != '1'){
      let guest_address = JSON.parse(localStorage.getItem('guest_addresses'));
      
      let otherAddresses = [];
      guest_address.map((address) => {
        if (address.primary_address == "1") this.primaryAdrress = address;
        else otherAddresses.push(address);
      });
      this.addresses = otherAddresses;
      // this.createBuyerDetails(this.user, this.addresses);

    }
  }

  onEditBillingChange(status) {    
    if(this.isGuest && localStorage.getItem('isRegisteredGuest') != '1') {
      let guest_user: User = JSON.parse(localStorage.getItem('guest_user'))
      this.user = guest_user;
    }
    this.changeView("info");
  }

  cart: CartItem[] = [];
  totalPrice:any = 0;

  getCart(){
    this.cartService.getCartItems().subscribe((cart: CartItem[]) => {
      cart.map(item => {
        if(item.hasQuantity && item.hasCorrectQuantity){
          this.orderItems.push({product_id: item.product_id, product_variant_id: item.product_variant_id, quantity: item.quantity})
          this.analyticsOrderItems.push({
            item_name: item.product.title,
            item_id: item.product_id,
            price: item.product.gross_price,
            item_brand: '',
            item_category: item.product.category_label,
            item_category_2: item.product.subcategory_label,
            quantity: item.quantity,
            item_variant: item.product_variant.barcode
          })
        }
      });
      // if(this.orderItems.length == 0) this.router.navigate(['/'])
      // let totalPrice = 0;
      // cart.map(item => {
      //   if(item.hasCorrectQuantity && item.hasQuantity){
      //     let itemPrice = item.product.price * item.quantity;
      //     totalPrice = (totalPrice + itemPrice) + shippingFee;
      //   }
      // })

      this.calculateTotalPrice(cart)

      cart.map(item => {
        if(item.product_variant.product_variant_images.length > 0){
          item.image = this.assetsUrl  + item.product_variant.product_variant_images[0].file_name_noext;
        } 
        else if(item.product.product_images.length > 0){
          item.image = this.assetsUrl  + item.product.product_images[0].file_name_noext;
        }
      })

      this.cart = cart;
      // this.totalPrice = totalPrice.toFixed(2);
    })
  }

  paymentLoading: boolean = false;
  paymentLoadingEmitter($event){
    this.paymentLoading = $event;
  }

  createBuyerDetails(user, addresses){
    let primaryAddress = addresses.filter(item => item.primary_address == '1')[0];

    this.buyerDetails.first_name = user?.first_name;
    this.buyerDetails.last_name = user?.last_name;
    this.buyerDetails.email = user?.email;
    this.buyerDetails.phone = user?.phone;
    this.buyerDetails.address = user?.address;
    this.buyerDetails.postal_code = user?.postal_code;
    this.buyerDetails.city = user?.city;
    this.buyerDetails.province = user?.province;
    this.buyerDetails.notes = user?.notes;
    this.buyerDetails.receive_invoice = user?.receive_invoice;
    this.buyerDetails.newsletter_subscribed = user?.newsletter_subscribed;
    this.buyerDetails.accept_privacy = user?.accept_privacy;
    this.buyerDetails.business_name = user?.business_name;
    this.buyerDetails.vat_number = user?.vat_number;
    this.buyerDetails.pec = user?.pec;
    this.buyerDetails.sdi = user?.sdi;
    this.buyerDetails.client_type = user?.client_type;
    this.buyerDetails.different_shipping = user?.different_shipping;
    this.buyerDetails.fiscal_code = user?.fiscal_code;
    this.buyerDetails.note = user?.note || user?.notes;

    this.buyerDetails.shipping_first_name = primaryAddress?.first_name;
    this.buyerDetails.shipping_last_name = primaryAddress?.last_name;
    this.buyerDetails.shipping_phone = primaryAddress?.phone;
    this.buyerDetails.shipping_province = primaryAddress?.province;
    this.buyerDetails.shipping_city = primaryAddress?.city;
    this.buyerDetails.shipping_address = primaryAddress?.address;
    this.buyerDetails.shipping_postal_code = primaryAddress?.postal_code;
    this.buyerDetails.shipping_notes = primaryAddress?.shipping_notes || primaryAddress?.notes;
  }

  discountPercentage: any;
  appendDiscountCode(){
    if(localStorage.getItem('discount_code')){
      this.discountPercentage = '';
      let formData = new FormData();
      formData.append('discount_code', localStorage.getItem('discount_code'))
      this.cartService.checkDiscountCode(formData).subscribe((res:any) => {
        this.discountPercentage = parseFloat(res.discount_percentage);
        this.discountCode = res.discount_code;
      }, err => {
      })
    }
  }

  proceed(){
    // this.getInformations();
    this.changeView('payment');
    let dataLayerCartItems = [];
    this.cart.map((item,index) => {
      dataLayerCartItems.push(
        {
          item_name: item?.product?.title, // Name or ID is required.
          item_id: item?.product?.id, // Name or ID is required.
          price: item?.product?.price * item?.product?.quantity,
          item_brand: item?.product?.producer_name,
          item_category: item?.product?.category_label,
          item_category_2: item?.product?.subcategory_label, // The categories can be up to 4
          item_variant: item.product_variant,
          // index: index+1, // If associated with a list selection.
          quantity: item?.quantity
        }
      ) 
    })
    this.addShippingInfoDataLayer(dataLayerCartItems);
  }

  addShippingInfoDataLayer(products: Product[] = []){
    (<any>window).dataLayer.push({
      event: 'add_shipping_info', 
      ecommerce: {
        shipping_tier: 'SDA', // Required.
        items: products
      }
    });
  }

  accessoryTotalPrice = 0;
  totalPriceWithoutShipping = 0;
  totalShippingFee = 0;
  calculateTotalPrice(items: CartItem[]){
    let totalPrice = 0;
    this.accessoryTotalPrice = 0;
    this.totalPriceWithoutShipping = 0;
    this.totalShippingFee = 0;
    items.map(item => {
      if(item.hasQuantity){
        let itemPrice = item.product.price * item.quantity;
        this.totalPriceWithoutShipping = this.totalPriceWithoutShipping + itemPrice;
        if(item.product.product_category_id == '1' || item.product.product_category_id == '2'){
          item.product.shipping_fee = 30;
          totalPrice = (totalPrice + itemPrice) + (30 * item.quantity);
          this.totalShippingFee = this.totalShippingFee + (30 * item.quantity);
        } else {
          totalPrice = totalPrice + itemPrice;
          this.accessoryTotalPrice = this.accessoryTotalPrice + itemPrice ;
        }
      } 
    })
        
    if(this.accessoryTotalPrice > 1 && this.accessoryTotalPrice <= 100) {
      totalPrice = totalPrice + 4;
      this.totalShippingFee = this.totalShippingFee + 4;
    }
    this.totalPrice = totalPrice.toFixed(2);
  }

  openDialogAccessories(): void {
    const dialogRef = this.dialog.open(AccessoriesShippingFeesComponent, {
      width: '400px',
    });
  }

  openDialogBike(): void {
    const dialogRef = this.dialog.open(BikeShippingFeesComponent, {
      width: '400px',
    });
  }
}

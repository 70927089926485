<app-full-page-loader *ngIf="paymentLoading"></app-full-page-loader>

<div class="background" *ngIf="!isMobile">
    <div class="container--white"></div>
    <div></div>
</div>
<div class="container container--cart">
    <div *ngIf="activeView == 'info' || activeView == 'invoice' || activeView == 'shipping'" class="container--double">
        <section class="container__left" *ngIf="activeView == 'info' || activeView == 'invoice' && !isMobile  || activeView == 'shipping' && !isMobile">
            <h1>Check out</h1>
            <h2>Ciao <span class="orange">{{user?.first_name}}</span></h2>
            <p class="padding-top--30 padding-bottom--30 blue"><b>Le tue informazioni di fatturazione</b></p>
            <div class="step2__info step2__info--border">
                <div>
                    <p>{{user?.first_name}} {{user?.last_name}}</p>
                    <p>{{user?.address}} - {{user?.postal_code}} {{user?.city}}</p>
                    <p>{{user?.email}}</p>
                    <p>{{user?.province}}</p>
                    <p>{{user?.phone}}</p>
                    <ng-container *ngIf="user?.user_type == 'private'">
                        <p>{{user?.fiscal_code}}</p>
                    </ng-container>
                    <ng-container *ngIf="user?.user_type == 'company'">
                        <p>{{user?.business_name}}</p>
                        <p>{{user?.vat_number}}</p>
                        <p>{{user?.pec}}</p>
                        <p>{{user?.sdi}}</p>
                    </ng-container>
                    <p>{{user?.shipping_notes}}</p>

                </div>
                <div *ngIf="activeView != 'invoice'">
                    <button (click)="changeView('invoice')" class="link-underline">modifica</button>
                </div>
            </div>
            <ng-container *ngIf="primaryAdrress">
                <p class="padding-top--30 padding-bottom--30 blue"><b>Indirizzo di spedizione principale</b></p>
                <div class="step2__info step2__info--border">
                    <div>
                        <p>{{primaryAdrress.first_name}} {{primaryAdrress.last_name}}</p>
                        <!-- <p>Via Marco Aurelio 25 - 20127 Milano</p> -->
                        <p>{{primaryAdrress.address}} - {{primaryAdrress.postal_code}} {{primaryAdrress.city}}</p>
                        <p>{{primaryAdrress.province}}</p>
                        <p>{{primaryAdrress.phone}}</p>
                        <p *ngIf="primaryAdrress.note">{{primaryAdrress.note}}</p>
                        <p *ngIf="primaryAdrress.shipping_notes">{{primaryAdrress.shipping_notes}}</p>
                        <p *ngIf="primaryAdrress.notes">{{primaryAdrress.notes}}</p>
                    </div>
                    <div>
                        <button *ngIf="state != 'create'" (click)="goToEditAddressScreen(primaryAdrress.id)" class="link-underline">modifica</button>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="addresses && addresses.length > 0">
                <p class="padding-bottom--30 blue padding-top--30"><b>Altri indirizzi</b></p>
                <ng-container *ngFor="let address of addresses; let i = index">
                    <div class="step2__info step2__info--border" [ngClass]="{'padding-top--30': i >= 1}">
                        <div>
                            <p>{{address.first_name}} {{address.last_name}}</p>
                            <!-- <p>Via Marco Aurelio 25 - 20127 Milano</p> -->
                            <p>{{address.address}} - {{address.postal_code}} {{address.city}}</p>
                            <p>{{address.province}}</p>
                            <p>{{address.phone}}</p>
                            <p *ngIf="address.note">{{address.note}}</p>
                            <p *ngIf="address.notes">{{address.notes}}</p>
                            <p *ngIf="address.shipping_notes">{{address.shipping_notes}}</p>
                        </div>
                        <div>
                            <button *ngIf="state != 'create'" (click)="goToEditAddressScreen(address.id)" class="link-underline">modifica</button>
                            &nbsp;
                            <button class="link-underline" (click)="makePrimary(address.id)">rendi principale</button>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <!--  SHIPPING ADDRESSES END -->
            <div *ngIf="activeView != 'shipping'" class="add-address">
                <button (click)="goToCreateAddress()" class="link-underline">aggiungi indirizzo</button>
            </div>
        </section>
        <section class="container__right">
            <div *ngIf="activeView == 'invoice'">
                <app-edit-billing-informations (onChange)="onEditBillingChange($event)" [showCancel]="true"></app-edit-billing-informations>
            </div>
            <div *ngIf="activeView == 'info'">
                <p class="padding-top--40 padding-bottom--30 blue"><b>Recap ordine</b></p>
                <div class="cart__product">
                    <table width="100%">
                        <tbody>
                            <tr *ngFor="let cartItem of cart">
                                <ng-container *ngIf="cartItem.hasQuantity && cartItem.hasCorrectQuantity">
                                    <td class="td--img">
                                        <div class="cart__img">
                                            <!-- <picture>
                                                <source sizes="100px" type="image/webp" srcset="assets/images/shop/bici-2_400.webp 400w">
                                                <source sizes="100px" type="image/jpg" srcset="assets/images/shop/bici-2_400.jpg 400w">
                                                <img sizes="100px" src="assets/images/shop/bici-2_400.jpg" srcset="assets/images/shop/bici-2_400.jpg 400w" alt="City bike Zecchini">
                                            </picture> -->
                                            <picture *ngIf="cartItem.image">
                                                <source sizes="100px" type="image/webp" [srcset]="cartItem.image + '_400.webp 400w'">
                                                <source sizes="100px" type="image/jpg" [srcset]="cartItem.image + '_400.jpg 400w'">
                                                <img sizes="100px" [src]="cartItem.image + '_400.jpg'" [srcset]="cartItem.image + '_400.jpg 400w, '+ cartItem.image +'_400.jpg 400w'" alt="{{cartItem.product.title}}">
                                            </picture>
                                            <picture *ngIf="!cartItem.image">
                                                <source sizes="100px" type="image/webp" srcset="assets/images/shop/bici-2_400.webp 400w">
                                                <source sizes="100px" type="image/jpg" srcset="assets/images/shop/bici-2_400.jpg 400w">
                                                <img sizes="100px" src="assets/images/shop/bici-2_400.jpg" srcset="assets/images/shop/bici-2_400.jpg 400w" alt="{{cartItem.product.title}}">
                                            </picture>
                                        </div>
                                    </td>
                                    <td class="td--info">
                                        <div class="cart__info">
                                            <p class="info__name">{{cartItem.product.title}} - {{cartItem.product_variant.color}} / {{cartItem.product_variant.size}}</p>
                                            <p class="info__typology">{{cartItem.product.subcategory_label}}</p>
                                        </div>
                                    </td>
                                    <td class="td--quantity">
                                        <div class="cart__quantity">
                                            <p><span class="orange">X </span>{{cartItem.quantity}}</p>
                                        </div>
                                    </td>
                                    
                                    <td class="td--price">
                                        <div class="cart__price">
                                            <!-- <p class="price__partial"><span>€</span>{{(cartItem.product.price * cartItem.quantity) | number : '1.2-2'}}</p> -->
                                            <p class="price__partial"><span>€</span>{{(cartItem.product.price * cartItem.quantity) | number : '1.2-2'}}</p>
                                            <p class="price__shipment" *ngIf="cartItem.product.product_category_id == '1' || cartItem.product.product_category_id == '2'">
                                                <span>€</span>{{cartItem.product.shipping_fee * cartItem.quantity}}
                                            </p>
                                        </div>
                                    </td>
                                </ng-container>
                            </tr>
                    </table>
                </div>
                <p class="padding-bottom--30 cart-total__text">Controlla bene le informazioni inserite prima di procedere con l’acquisto
                </p>
                <div class="cart-subtotal" *ngIf="discountPercentage">
                    <div class="cart-subtotal__discount">
                        <p class="discount-code__title">SCONTO APPLICATO</p>
                        <p><span class="cart-subtotal__price cart-subtotal__price--corss-line">{{totalPriceWithoutShipping | number : '1.2-2'}}€</span> - {{discountPercentage}}%</p>
                    </div>
                </div>
                <div class="cart-subtotal padding-top--10">
                    <div class="cart-subtotal__discount">
                        <p class="discount-code__title grey">SPESE DI SPEDIZIONE BICI <button class="button__info" (click)="openDialogBike()">?</button></p>
                        <p><span class="price__shipment">€ {{accessoryTotalPrice > 0 && accessoryTotalPrice <= 100 ? totalShippingFee-4 : totalShippingFee}}</span></p>
                    </div>
                </div>
                <div class="cart-subtotal" *ngIf="accessoryTotalPrice > 0 && accessoryTotalPrice <= 100">
                    <div class="cart-subtotal__discount">
                        <p class="discount-code__title grey">SPESE DI SPEDIZIONE ARTICOLI <button class="button__info" (click)="openDialogAccessories()">?</button></p>
                        <p><span class="price__shipment">€ 4</span></p>
                    </div>
                </div>
                <!-- <div class="cart-subtotal" *ngIf="discountPercentage">
                    <div class="cart-subtotal__discount">
                        <p class="discount-code__title">SCONTO APPLICATO</p>
                        <p><span class="cart-subtotal__price cart-subtotal__price--corss-line">{{totalPriceWithoutShipping | number : '1.2-2'}}€</span> - {{discountPercentage}}%</p>
                    </div>
                </div>
                <div class="cart-subtotal">
                    <div class="cart-subtotal__discount">
                        <p class="discount-code__title">SPESE DI SPEDIZIONE</p>
                        <p><span class="cart-subtotal__price">{{totalShippingFee}}€</span></p>
                    </div>
                </div>-->
                <div class="cart-total__container">
                    <div class="cart__total">
                        <p class="discount-code__title">TOTALE</p>
                        <div class="total" *ngIf="!discountPercentage">
                            <p><span>€</span>{{totalPrice  | number : '1.2-2'}}</p>
                        </div>
                        <div class="total" *ngIf="discountPercentage">
                            <p><span>€</span>{{((totalPriceWithoutShipping - (discountPercentage / 100) * totalPriceWithoutShipping) + totalShippingFee) | number : '1.2-2'}}</p>
                        </div>
                        <div class="button__container">
                            <div class="button__background2"></div>
                            <div class="button__background"></div>
                            <a class="action__button" (click)="proceed()">Procedi
                            <div class="arrow-left"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.14 16.48"><line y1="8.24" x2="19.55" y2="8.24"/><polyline points="11.4 0.5 19.14 8.24 11.4 15.98"/></svg></div>
                                </a>
                        </div>
                    </div>
                </div> 
              
            </div>
            <div *ngIf="activeView == 'shipping'">
                <app-add-edit-shipping-addresses (onChange)="onShippingAddressChange($event)" [state]="state" [addressId]="selectedAddressId">
                </app-add-edit-shipping-addresses>
            </div>
        </section>
    </div>
    <div #step3 *ngIf="activeView == 'payment'" class="container--double step3">
        <div class="container__left">
            <h2>Metodo di pagamento</h2>
            <p class="padding-top--30">Seleziona un metodo di pagamento</p>
            <div class="padding-top--30 padding-bottom--30">
                <mat-radio-group aria-label="Select an option">
                    <mat-radio-button #stripe value="Carta">Carta di credito</mat-radio-button>
                    <mat-radio-button #paypal value="PayPal">PayPal</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="button__container">
                <div class="button__background2"></div>
                <div class="button__background"></div>
                <button (click)="changeView('info')" class="action__button">Torna indietro
                    <div class="arrow-left"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.14 16.48">
                            <line x1="20.14" y1="8.24" x2="0.59" y2="8.24" />
                            <polyline points="8.74 15.98 1 8.24 8.74 0.5" /></svg></div>
                </button>
            </div>
        </div>
        <div class="container__right">
            <div *ngIf="paypal.checked">
                <app-paypal-payment [analyticsOrderItems]="analyticsOrderItems" [orderItems]="orderItems" [discountCode]="discountCode" (onPaymentLoading)="paymentLoadingEmitter($event)"></app-paypal-payment>
            </div>
            <div *ngIf="stripe.checked">
                <app-stripe-payment [analyticsOrderItems]="analyticsOrderItems" [orderItems]="orderItems" [discountCode]="discountCode" (onPaymentLoading)="paymentLoadingEmitter($event)"></app-stripe-payment>
                <!-- [buyerDetails]="buyerDetails" -->
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
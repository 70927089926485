<div class="container container--double">
    <section class="container__left container--white">
        <h1>Cambio password</h1>
        <form [formGroup]="forgotPassword">
            <div class="form__container">
                <mat-form-field appearance="standard">
                    <mat-label>nuova password</mat-label>
                    <input type="password" matInput formControlName="newPassword" required>
                    <mat-hint>La password deve essere di minimo 8 caratteri di cui almeno una lettera maiuscola e un numero.</mat-hint>
                    <mat-error *ngIf="f.newPassword.invalid && (f.newPassword.dirty || f.newPassword.touched)">
                        <mat-error *ngIf="(f.newPassword.errors.required)">
                            campo <strong>obbligatorio</strong>
                        </mat-error>
                        <mat-error *ngIf="(f.newPassword.errors.minlength)">
                            deve essere di minimo <strong>8 caratteri</strong>
                        </mat-error>
                        <mat-error *ngIf="(f.newPassword.errors.pattern)">
                            La password deve essere di minimo 8 caratteri di cui almeno una lettera maiuscola e un numero.
                        </mat-error>
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="standard">
                    <mat-label>conferma password</mat-label>
                    <input type="password" matInput formControlName="confirmPassword" required>
                    <mat-error *ngIf="f.confirmPassword.invalid && (f.confirmPassword.dirty || f.confirmPassword.touched)">
                        <mat-error *ngIf="(f.confirmPassword.errors.required)">
                            campo <strong>obbligatorio</strong>
                        </mat-error>
                        <mat-error *ngIf="(f.confirmPassword.errors.minlength)">
                            deve essere di minimo <strong>8 caratteri</strong>
                        </mat-error>
                        <mat-error *ngIf="(f.confirmPassword.errors.mustMatch)">
                            le password non coincidono
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </form>
        <div class="action-float action-float--right">
            <div class="button__container">
                <div class="button__background2"></div>
                <div class="button__background"></div>
                <button (click)="forgotPasswordSend()" class="action__button">Invia
        <div class="arrow-left"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.14 16.48"><line y1="8.24" x2="19.55" y2="8.24"/><polyline points="11.4 0.5 19.14 8.24 11.4 15.98"/></svg></div>
        </button>
            </div>
        </div>
    </section>
    <section class="container__right"></section>
</div>